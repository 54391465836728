import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import Fab from '@mui/material/Fab';

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { vutils } from "../../config/voterConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import PzInput from "../../common/PzInput";
import AzInputForName from "../../auth/azcomp/AzInputForName";
import EMWithPzServer from "../../eventmonitor/EMWithPzServer";
import EMSplash from "../../eventmonitor/EMSplash";

export default function TryCheckinAsPoller() {
    const [cookies, removeCookie] = useCookies(['vauth']);
    const vauth = (cookies && cookies.vauth ? jwtDecode(cookies.vauth) : null);
    const location = useLocation();
    const navigate = useNavigate();
    const [qparams] = useSearchParams();
    const pguid = qparams.get("p");
    const pname = qparams.get("name");
    const torun = Boolean(qparams.get("run"));
    const [intro, setIntro] = React.useState({pguid:pguid});
    const [vname, setVName] = React.useState({value:(pname ? pname : ''), errmsg:''});
    const [vcode, setVCode] = React.useState({value:'', errmsg:''});
    const [errMsg, setErrMsg] = React.useState('');
    const [splashMsg, setSplashMsg] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [localeMap, setLocaleMap] = React.useState(utils.selectMapByLocale('en'));

    const ptrial = (location.pathname.startsWith("/y/pcheckin"));
    const rehearsal = (location.pathname.startsWith("/y/rcheckin"));
    const simopt = useSelector((state)=>state.ucfg.simopt);
    const [nMin, setNMin] = React.useState(simopt?.nmin ? simopt.nmin :  60);
    const [nMax, setNMax] = React.useState(simopt?.nmax ? simopt.nmax : 120);
    const [vMethod, setVMethod] = React.useState(simopt?.vmethod ? simopt.vmethod : 'R');
    const dispatch = useDispatch();

    React.useEffect(() => {
        // removeCookie("vauth", null);
        if (vauth && vauth.pguid === pguid) {
            if (rehearsal) navigate("/y/rrun?p=" + pguid);
            else           navigate("/y/prun?p=" + pguid);
        } else {
            fetchIntro();
        }
    }, []);

    const fetchIntro = ()=>{
        if (pguid === 'THANKYOU') {
            setSplashMsg("THANKYOU");
            return;
        } else if (!pguid || pguid.length !== 32) {
            setSplashMsg("Sorry, the address is not a valid entrance.");
            return;
        }
        setSplashMsg('Loading intro...');
        appConfig.getAPI().post("/api/v/intro", {pguid:pguid})
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const intro2 = pzdata.resource; // {}
            let new_localeMap = utils.selectMapByLocale(intro2.locale);
            setIntro(intro2);
            setVName({value:(rehearsal ? intro2.poller : pname ? pname : ''), errmsg:''});
            setLocaleMap(new_localeMap);
            setSplashMsg(''); 
            if (ptrial && pname) {
                checkin();
            }
        })
        .catch((error) => {
            console.log("PzAPI 'v/intro' error : ", error);
            let respmsg = error.response?.data?.respmsg;
            if (respmsg && (respmsg.includes('pguid invalid') || respmsg.includes('pguid not found'))) {
                setSplashMsg("Sorry, the address is not a valid entrance.");
            } else {
                setSplashMsg(respmsg ? respmsg : '');
            }
        })
        .finally(() => { });
    };
    
    function checkin() {

        const onSuccessToCheckin = (respval)=>{
            let path_to_jump = (rehearsal ? "/y/rrun?p=" : "/y/prun?p=") + pguid;
            setTimeout(()=>{
                navigate(path_to_jump);
                setLoading(false);
            }, 1000); // wait for 'vauth' to be completed, and then navigate.
        };
        const onErrorToCheckin = (respmsg, respval)=>{
            if        (respmsg && respmsg.includes('poll not found')) {
                setErrMsg(localeMap["voter.checkin.err.pnotfound"]);
            } else if (respmsg && respmsg.includes('poll open')) {
                setErrMsg(localeMap["voter.checkin.err.pclosed"]);
            } else if (respmsg && respmsg.includes('poll closed')) {
                setErrMsg(localeMap["voter.checkin.err.pclosed"]);
            } else {
                setErrMsg(respmsg);
            }
            setLoading(false);
        };
    
        setLoading(true);
        setErrMsg('');
        setTimeout(()=>{
            if (rehearsal) {
                EMWithPzServer.checkinAsPoller(intro, onSuccessToCheckin, onErrorToCheckin);
            } else {
                EMWithPzServer.checkinAsVoter(intro, vname.value, vcode.value, onSuccessToCheckin, onErrorToCheckin);
            }
        }, 500); // wait for a second
    };

    function onChangeDoneToNMin(v) { 
        let new_nmax = (v > nMax ? v : nMax);
        setNMax(new_nmax);
        dispatch(utils.setSimOpt({nmin:v, nmax:new_nmax, vmethod:vMethod}));
    }
    function onChangeDoneToNMax(v) { 
        let new_nmin = (v < nMin ? v : nMin);
        setNMin(new_nmin);
        dispatch(utils.setSimOpt({nmin:new_nmin, nmax:v, vmethod:vMethod}));
    }
    function onChangeDoneToVMethod(vm) { 
        dispatch(utils.setSimOpt({nmin:nMin, nmax:nMax, vmethod:vm}));
    }
    
    const title = ( rehearsal ?
        utils.completeLocaleMapText(localeMap, 'entrance.rehearsal.title', [intro?.ptitle], intro?.pkind)
    : 
        utils.completeLocaleMapText(localeMap, 'entrance.trial.title', [intro?.ptitle], intro?.pkind)
    );
    const titles = (title ? title.split("\n") : ['']);
    // console.log("titles: ", titles);

    if (pguid === 'THANKYOU') {
        let splash_message = localeMap["monitor.splash.thankyou"]
        return (<EMSplash msg={splash_message} gohome={true} xclose={true} />);
    } else if (!intro) {
        return (<EMSplash msg={splashMsg} locale='en' />);
    }

    return (
        <PzArea.Container className="bg-slate-100">
            <PzArea.MwhBoard className="p-5 bg-white select-none" xclose={true} >

                <div>&nbsp;</div>

                <div className="w-full flex flex-col justify-start items-start py-4 gap-10">

                    <div className="w-full flex flex-col justify-start items-center gap-1">
                        {titles.map((title, idx)=>{
                            return(<h1 key={idx}>{title}</h1>);
                        })}
                    </div>

                    { rehearsal ? 
                        <div className="w-full flex flex-col justify-start items-center gap-1">
                            <div className="w-auto flex flex-col justify-start items-start gap-1">
                                <div className="w-auto grid grid-cols-[auto_1fr] justify-start items-start gap-x-4 gap-y-1 text-md">
                                    <span className="text-md text-right">{localeMap["entrance.rehearsal.poller"]} :</span>
                                    <span className="text-md">{intro.poller}</span>
                                    <span className="text-md text-right">{localeMap["entrance.rehearsal.nvoters"]} :</span>
                                    <div className="w-auto flex flex-row justify-start items-center gap-1 text-md">
                                        <PzInput.ForInt value={nMin} setValue={setNMin} min={0} max={999999} onChangeDone={onChangeDoneToNMin} className="w-10 text-md"/>
                                        <span className="text-md">~</span>
                                        <PzInput.ForInt value={nMax} setValue={setNMax} min={0} max={999999} onChangeDone={onChangeDoneToNMax} className="w-12 text-md"/>
                                    </div>
                                    <span className="text-md text-right">{localeMap["entrance.rehearsal.vmethod"]} :</span>
                                    <span className="text-md">Random</span>
                                    {/* <PzInput.ForInt value={} setValue={} min={0} max={999999} /> */}
                                </div>
                            </div>
                        </div>
                    :
                        <div className={"w-full flex flex-col justify-start items-center gap-1 " + (ptrial && pname ? "invisible" : "")}>
                            <p className={`flex flex-row justify-center text-slate-500 `}>
                                &nbsp;{localeMap['entrance.trial.label']}&nbsp;
                            </p>
                            <div className="w-[20rem] flex flex-col justify-start items-stretch gap-1">
                                <AzInputForName  v={vname} setV={setVName} 
                                    maxLength={30} 
                                    onChange={()=>{if (errMsg!=='') setErrMsg('');}}
                                    placeholder={localeMap['voter.checkin.label.name']} />
                            </div>
                        </div>
                    }

                    <div className={"w-full flex flex-col justify-start items-center gap-2 " + (ptrial && pname ? "invisible" : "")}>
                        <p className="flex flex-row justify-center text-slate-500" >
                            &nbsp;{errMsg}&nbsp;
                        </p>

                        <PzButton.Std className="w-[20rem]" text={localeMap["entrance.button.openevent"]} size='2xl' 
                            onClick={()=>{checkin()}} loading={loading} disabled={vname.value.trim()==='' || loading || vauth} />
                    </div>

                    <div className="w-full flex flex-col justify-start items-center mt-10">
                        <h4 className="text-slate-500">Hosted by <em className="font-bold">Pollerz.net</em></h4>
                        {/* <AzButtonToNavigate label={localeMap["voter.checkin.nav.label"]} button={localeMap["voter.checkin.nav.button"]} destination={"/home"} /> */}
                    </div>

                </div>
                
                <div>&nbsp;</div>

                <div className="absolute bottom-5 left-0 w-full text-center" >
                    <h4 className="text-slate-400">{localeMap["entrance.simulated"]}</h4>
                </div>

            </PzArea.MwhBoard> 
        </PzArea.Container>
    );

}

export function ButtonForCheckout() {
    const [cookies, removeCookie] = useCookies();
    const vauth = (cookies && cookies?.vauth ? jwtDecode(cookies.vauth) : null);
    const checkins = useSelector((state)=>state.vcfg.checkins);
    const pguid = vauth?.pguid;
    const dispatch = useDispatch();

    function onClickToCheckout() {
        removeCookie("vauth", null);
        // dispatch(vutils.delVoterCheckin(checkins, pguid));
        // dispatch(vutils.setCheckins({}));
        dispatch(vutils.setPGuid(''));
        dispatch(vutils.setVid(''));
    }

    return (
        <Fab variant="extended" size="small" onClick={onClickToCheckout}
            style={{ margin: 0, top: 'auto', right: 'auto', bottom: 20, left: 20, position: 'fixed' }} >
            <label className="font-extrabold text-xs">COut</label>
        </Fab>
    );
}
