import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzButton from "../common/PzButton";
import PzSymbol from "../common/PzSymbol";
import PzText from "../common/PzText";
import { ReactComponent as IconButtonSub } from "../resource/icons/pz-button-sub.svg";
import { ReactComponent as IconButtonAdd } from "../resource/icons/pz-button-add.svg";
import PollPqstAnswer from "./PollPqstAnswer";
import PollPqstGoal from "./PollPqstGoal";

const PollPqst = {
    List:       PollPqstList,
    Card:       PollPqstCard,

    addQuestion:   addQuestion,
    subQuestion:   subQuestion,
};
export default PollPqst;


function PollPqstList({ poll, setPoll, size='xl', editable=false, className='' }) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [qSelected, setQSelected] = React.useState('');   // active 'qguid'
    if (editable && qSelected === '' && poll?.pqstlist?.length === 1) {
        setQSelected(poll.pqstlist[0].qguid);
    }
    // console.log('editable:', editable, '  qSelected:', qSelected);

    function setPollWithPqst(qidx, new_pqst) {
        if (!new_pqst) return;
        let pqstlist = [ ...poll.pqstlist ];
        pqstlist[qidx] = new_pqst;
        setPoll({ ...poll, pqstlist:pqstlist });
    }

    function callForPqstToSelect(qguid) {
        // clear 'pqst.justadded' of previously selected Pqst
        for (let i = 0; i < poll.pqstlist.length; i++) {
            let pqst = poll.pqstlist[i];
            if (pqst.qguid === qSelected && pqst.qguid !== qguid) {
                let new_pqst = { ...poll.pqstlist[i], justadded: false };
                setPollWithPqst(i, new_pqst);
            }
        }
        if (!qguid) {
            setQSelected('');
        } else if (qguid === qSelected) {
            setQSelected('');
        } else {
            setQSelected(qguid);
        }
    }

    function onClickToAddPqst(e) {
        let [n, qguid] = PollPqst.addQuestion(poll, setPoll, localeMap); 
        if (typeof setQSelected === 'function') {
            setQSelected(qguid);
        }
        e.stopPropagation();
    }

    function onClickToSubPqst(e) {
        let q0 = (poll.pqstlist.length >= 1 ? poll.pqstlist[0].qguid : '');
        let q1 = (poll.pqstlist.length >= 2 ? poll.pqstlist[1].qguid : '');
        let n = PollPqst.subQuestion(poll, setPoll, qSelected);
        if (typeof setQSelected === 'function') {
            if (n > 1) setQSelected('');
            else       setQSelected(q0 && q0 === qSelected ? q1 : q0);
        }
        e.stopPropagation();
    }

    return (
        <>

            { poll && poll.pqstlist && poll.pqstlist.map((pqst, qidx) => (
                <PollPqstCard key={qidx} poll={poll} setPoll={setPoll} qidx={qidx} size={size} 
                    editable={editable} className={className}
                    qSelected={qSelected} setQSelected={setQSelected} callToSelect={callForPqstToSelect} />
            )) }

            { editable &&
                <div className="w-full flex flex-row justify-center items-center gap-4">
                    <PzButton.Icon Icon={IconButtonSub} onClick={onClickToSubPqst} disabled={poll.pqstlist.length <= 1} />
                    <PzButton.Icon Icon={IconButtonAdd} onClick={onClickToAddPqst} />
                </div>
            }

            { editable && <div className="w-full h-1">&nbsp;</div> }

        </>
    );
}

function PollPqstCard({ poll, setPoll, qidx, size='xl', className='', 
    qSelected='', callToSelect=undefined, editable=false }) {

    const localeMap = utils.selectMapByLocale(poll?.locale);
    const pqst = (qidx >= 0 && qidx < poll.pqstlist.length ? poll.pqstlist[qidx] : undefined);
    const q_selected = (pqst?.qguid === qSelected);

    function onClickToCard(e) {
        e.stopPropagation();
        if (typeof callToSelect === 'function') callToSelect(pqst.qguid);
    }

    function onClickToSymbol(e) {
        console.log(pqst);
    }

    function setPollWithPqstQuestion(new_question) {
        if (!pqst || !editable) return;
        let new_pqst = { ...pqst, question: new_question };
        setPollWithPqst(new_pqst);
    }

    function setPollWithPqst(new_pqst) {
        if (!new_pqst || !editable) return;
        let pqstlist = [ ...poll.pqstlist ];
        pqstlist[qidx] = new_pqst;
        setPoll({ ...poll, pqstlist:pqstlist });
    }

    let tsize = "text-md", ssize = "text-sm", px = " ", py = " ", gap = " gap-0 ";
    if      (size === '2xl') { tsize=" text-2xl"; ssize="xl"; px = " px-3 "; py = " py-3 "; gap=" gap-3 "; }
    else if (size ===  'xl') { tsize=" text-xl";  ssize="lg"; px = " px-3 "; py = " py-3 "; gap=" gap-3 "; }
    else if (size ===  'lg') { tsize=" text-lg";  ssize="md"; px = " px-2 "; py = " py-2 "; gap=" gap-2 "; }
    else if (size ===  'md') { tsize=" text-md";  ssize="sm"; px = " px-2 "; py = " py-2 "; gap=" gap-2 "; }
    else if (size ===  'sm') { tsize=" text-sm";  ssize="xs"; px = " px-1 "; py = " py-1 "; gap=" gap-1 "; }
    else                     { tsize=" text-sm";  ssize="xs"; px = " px-1 "; py = " py-1 "; gap=" gap-1 "; }
    let CLASSNAME = " w-full bg-white border border-slate-800 rounded-2xl flex flex-col justify-start items-start ";
    const SSIZE = utils.getSizeRelative(size, -1);

    if (!pqst) return (<div>Undefined pqst</div>);

    return (
        <div className={CLASSNAME + py + gap + className} onClick={onClickToCard} >  
            <div className={"w-full max-w-[32rem] flex flex-row justify-start items-center" + px + gap} >  
                <PzSymbol.Text text={'Q'+(qidx+1)} size={ssize} 
                    className="!bg-purple-900 !text-white hover:cursor-pointer"
                    onClick={onClickToSymbol} />
                <PzText.Touch 
                    text={pqst.question} setText={(txt)=>{if (editable) setPollWithPqstQuestion(txt);}} 
                    size={size} truncate={pqst.qguid !== qSelected} editable={editable && q_selected}
                    placeholder={localeMap["poll02.question.placeholder"]}
                    setFocusLater={(editable && q_selected && poll.pqstlist.length===1) || pqst.justadded} />
            </div>
            { q_selected &&
            <>
                <div className="w-full h-[1px] bg-slate-200"></div>

                <PollPqstAnswer.List poll={poll} setPoll={setPoll} qidx={qidx} size={size}
                    selectable={true} selection={[]} setSelection={()=>{}} className="w-full px-10 "
                    editable={editable} onClick={(e)=>{e.stopPropagation();}} />

                <div className="w-full h-[1px] bg-slate-800"></div>

                <PollPqstGoal.Footer   poll={poll} setPoll={setPoll} qidx={qidx} initFolded={true} size={SSIZE}
                    ranswers={typeof pqst.answers[0] === 'string' ? pqst.answers : pqst.answers.slice(1)} 
                    className={px} editable={editable} onClick={(e)=>{e.stopPropagation();}} />
            </>
            }
        </div>
    )
}

function addQuestion(poll, setPoll, localeMap) {
    if (poll && setPoll) {
        let newpqst = undefined; 
        let qguid = dayjs().format('YYYYMMDDHHmmssSSS');
        if (poll.pqstlist.length >= 3) {
            let answers = [ ...(localeMap["poll02.answers.S"] ? localeMap["poll02.answers.S"].split('\n').slice(0,2) : []) ];
            newpqst = {qguid:qguid, qseq:poll.pqstlist.length-1, qkind:'S', question: utils.completeMessageText(localeMap["poll02.question.example"],[(poll.pqstlist.length+1)]), qroundmax:3, answers: answers, nanswers:answers.length, nwanted:1, qoption:{c2win:'Most',c2drop:'Zero'}, justadded:true };
        } else if (poll.pqstlist.length === 2) {
            let answers = [ ...(localeMap["poll02.answers.E"] ? localeMap["poll02.answers.E"].split('\n') : []) ];
            newpqst = {qguid:qguid, qseq:poll.pqstlist.length-1, qkind:'E', question:localeMap["poll02.question.example.E"], qroundmax:3, answers: answers, nanswers:answers.length, nwanted:1, qoption:{c2win:'Majority',c2drop:'Zero'}, justadded:true };
        } else if (poll.pqstlist.length === 1) {
            let answers = [ ...(localeMap["poll02.answers.S"] ? localeMap["poll02.answers.S"].split('\n') : []) ];
            newpqst = {qguid:qguid, qseq:poll.pqstlist.length-1, qkind:'S', question:localeMap["poll02.question.example.S"], qroundmax:3, answers: answers, nanswers:answers.length, nwanted:2, qoption:{c2win:'Most',c2drop:'Zero'}, justadded:true };
        } else {
            let answers = [ ...(localeMap["poll02.answers.A"] ? localeMap["poll02.answers.A"].split('\n') : []) ];
            newpqst = {qguid:qguid, qseq:poll.pqstlist.length-1, qkind:'A', question:localeMap["poll02.question.example.A"], qroundmax:1, answers: answers, nanswers:answers.length, nwanted:1, qoption:{c2win:'Majority',c2drop:'Zero'}, justadded:true };
        }
        let new_list = [];
        for (let i = 0; i < poll.pqstlist.length; i++) {
            new_list.push({ ...poll.pqstlist[i], justadded: false });
        }
        new_list.push(newpqst);
        let new_poll = { ...poll, pqstlist: new_list };
        if (typeof setPoll === 'function') setPoll(new_poll);
        return [new_poll.pqstlist.length, qguid];
    } else {
        return [poll.pqstlist.length, ''];
    }
}

function subQuestion(poll, setPoll, qguid) {
    console.log('subQuestion:', qguid);
    if (poll && setPoll && poll.pqstlist.length > 1) {
        let qidx = poll.pqstlist.length - 1;
        for (let i = 0; i < poll.pqstlist.length; i++) {
            if (poll.pqstlist[i].qguid === qguid) { qidx = i; break; }
        }
        let before = (qidx === 0 ? [] : poll.pqstlist.slice(0, qidx));
        let after  = (qidx === poll.pqstlist.length-1 ? [] : poll.pqstlist.slice(qidx+1));
        let new_list = before.concat(after);
        let new_poll = { ...poll, pqstlist: new_list };
        if (typeof setPoll === 'function') setPoll(new_poll);
        return new_poll.pqstlist.length;
    } else {
        return poll.pqstlist.length;
    }
}

