import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import EventTimer from "../../event/EventTimer";
import PollCard from "../../event/PollCard";
import EvtVoterList from "../../event/EvtVoterList";
import EntranceForVoters from "../../entrance/EntranceForVoters";
import UserSplash from "../UserSplash";
import { ReactComponent as IconStepNext } from "../../resource/icons/pz-step-next.svg";
import { ReactComponent as IconStepPrev } from "../../resource/icons/pz-step-prev.svg";

export default function PrepareEvent() {
    const [cookies] = useCookies();
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const navigate = useNavigate();
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const localeMap = useSelector(utils.selectLocaleMap);
    const [open4EntView, setOpen4EntView] = React.useState(false);
    const [open4EntPNG, setOpen4EntPNG] = React.useState(false);
    const [open4EntPDF, setOpen4EntPDF] = React.useState(false);

    const POLL_URL_PATH = '/v/vote?p=' + upoll?.pguid;

    // React.useEffect(() => {
    // }, []);

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    }  

    return (
        <PzArea.Container >
            <PzArea.TBoard >
    `            <div className="w-full px-5 flex flex-col justify-start items-start gap-4">
                    <PollCard.Item poll={upoll} size='lg' expandable={true} />
                    <div className="w-full flex flex-row justify-center items-center">
                        <EventTimer.WallClock poll={upoll} size='2xl' />
                    </div>
                </div>

                <div className="w-full flex flex-row justify-between items-center">
                    <h2>{localeMap["menu.staff.prepare"]}</h2>
                    <div className={"flex flex-row justify-start items-center "} >
                        <label className={"text-xl"}>Supporting as</label> &emsp;
                        <label className={"text-xl font-bold"}>{localeMap["poll.card.staff.myrole."+upoll?.xrole?.toLowerCase()]}</label> &emsp;
                    </div>
                </div>

                <div className="w-full flex flex-col justify-start items-start gap-5">
                    <h3>{"이벤트 공지"}</h3>
                    <div className="w-full flex flex-row justify-center items-start">
                        <div className="w-fit flex flex-col justify-start items-center gap-5">
                            <PzButton.Std text={localeMap["staff.view.entrance.qr"]} size='xl' className="w-full" onClick={(e)=>{setOpen4EntView(true);}} />
                            <PzButton.Std text={localeMap["staff.view.entrance.png"]}  size='xl' className="w-full" onClick={(e)=>{setOpen4EntView(true);}} />
                        </div>
                    </div>
                </div>

                {/* Voters */}
                <EvtVoterList.Module poll={upoll} asPoller={false} />

                <div className="w-full flex flex-row justify-between items-center gap-5 mt-10">
                    <PzButton.Icon Icon={IconStepPrev} size='xl' onClick={()=>{navigate(-1);}}/>
                    <PzButton.Std  Icon={IconStepNext} text={localeMap["menu.staff.support"]} size='lg' 
                            onClick={()=>{navigate("/pages/staff/support");}} 
                            disabled={!(EventTimer.isOpenable(upoll) || upoll?.pstatus?.startsWith('O'))} />
                </div>

            </PzArea.TBoard >

            { open4EntView && 
                <EntranceForVoters.Dialog poll={upoll} onClose={(e)=>{setOpen4EntView(false);}} />
            }

        </PzArea.Container >
    );
}
