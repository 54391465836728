import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";

export default function PrivacyPolicy() {
    const localeMap = useSelector(utils.selectLocaleMap);

    return (
        <PzArea.Container>
            <PzArea.ABoard className="gap-12" >

                <label className={"text-3xl font-extrabold"}>{localeMap["home.page.privacy.title"]}</label>

                <div className="w-full max-w-[44rem] px-10 flex flex-col gap-10">
                </div>

            </PzArea.ABoard>
        </PzArea.Container>
    );
}

