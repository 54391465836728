import React from 'react'
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Fab from '@mui/material/Fab';

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import PzBasicModal from "../../common/PzBasicModal";
import PzButton from "../../common/PzButton";
import PzInput from "../../common/PzInput";

const UserFeedback = {
    Dialog:         UserFeedbackDialog,
};
export default UserFeedback;


function UserFeedbackDialog({onClose=()=>{}}) {
    const ulocale = useSelector((state)=>state.ucfg.locale);
    const localeMap = useSelector(utils.selectLocaleMap);
    const location = useLocation();
    const [ftype, setFtype] = React.useState(['C']);
    const [ftext, setFtext] = React.useState('');
    const [subsc, setSubsc] = React.useState(false);

    function onChangeToFType(event) {
        switch (event.target.value) {
            case 'C': setFtype('C');  break;
            case 'B': setFtype('B');  break;
            case 'F': setFtype('F');  break;
            default:  break;
        }
    }

    function onChangeToFText(text) {
        setFtext(text.trim());
    }

    function onChangeToSubsc(event) {
        setSubsc(event.target.checked);
    }

    function onClickToSendFeedback() {
        const pathname = location.pathname;
        // console.log("Send Feedback : ", ftype, pathname, ftext, subsc, ulocale);
        appConfig.getAPI().post("/api/my/feedback", {fbtype: ftype[0], fbpath: pathname, fbtext: ftext, locale: ulocale })
        .then((response)=>{
            console.log("PzAPI 'my/feedback' success : ", response.data);
        })
        .catch((error)=>{
            console.log("PzAPI 'my/feedback' error : ", error);
        })
        .finally(()=>{
            if (typeof onClose === 'function') onClose();
        });
    }

    return (
        <PzBasicModal onClose={onClose} xclose={true} >
            <div className="min-w-[35rem] flex flex-col justify-start items-stretch p-10 gap-5">
                <h2 className="font-extrabold">{localeMap["about.feedback.title"]}</h2>
                <div className="flex flex-col justify-start items-stretch gap-1">
                    <label className="text-md text-slate-500">{localeMap["about.feedback.desc"]}</label>
                    <div className="flex flex-row justify-between items-center">
                        <PzInput.Radio2 value='C' selection={ftype} setSelection={setFtype} size="md" ><span className="text-sm">{localeMap["about.feedback.type0"]}</span></PzInput.Radio2>
                        <PzInput.Radio2 value='B' selection={ftype} setSelection={setFtype} size="md" ><span className="text-sm">{localeMap["about.feedback.type1"]}</span></PzInput.Radio2>
                        <PzInput.Radio2 value='F' selection={ftype} setSelection={setFtype} size="md" ><span className="text-sm">{localeMap["about.feedback.type2"]}</span></PzInput.Radio2>
                    </div>
                    <PzInput.ForTextArea value={ftext} onChange={onChangeToFText} rows={6} className="!p-2" />
                </div>
                <div className="flex flex-col justify-start items-stretch gap-1">
                    <div className="flex flex-row justify-between items-center">
                        <label className="text-md">{localeMap["about.feedback.newsletter"]}</label>
                        <PzInput.Checkbox1 checked={subsc} onChange={onChangeToSubsc}>
                            <span className="text-md">{localeMap["about.feedback.newsletter.subscribe"]}</span>
                        </PzInput.Checkbox1>
                    </div>
                    <label className="text-sm text-slate-500">{localeMap["about.feedback.newsletter.desc"]}</label>
                </div>
                <div className="flex flex-col justify-start items-end gap-0">
                    <PzButton.Std text={localeMap["about.feedback.send"]} size='lg' 
                        onClick={onClickToSendFeedback} disabled={ftext === ''} />
                </div>
            </div>
        </PzBasicModal>
    );
}

