import React from "react";

import { utils } from "../config/userConfig";
import PzInput from "../common/PzInput";
import PzSymbol from "../common/PzSymbol";
import EMModals from "./EMModals";

export default function EMComQstOrder({poll, size='xl', className='', invisible=false}) {
    const [open4QSummary, setOpen4QSummary] = React.useState(false);
    const [qSelected, setQSelected] = React.useState(null);
    const visibility = (invisible || poll.pqstlist.length <= 1 ? " invisible " : " ");
    const itemgap = (poll.pqstlist.length >= 10 ? " gap-3 " : " gap-4 ")
    const WHSIZE = ((size==='3xl' || size==='2xl') ? "0.8rem" : "0.7rem");

    function onClickToViewQSummary(q) {
        setQSelected(q); 
        setOpen4QSummary(true);
    }

    if (!poll) {
        return (<h3>poll is null</h3>);
    }

    return (
        <>
            <div className={"w-full flex flex-row justify-center items-center gap-4 " + visibility + itemgap + className}>
                { poll.pqstlist.map((q, qidx)=>{
                    return ( qidx === poll.qactive || (invisible && qidx == 0) ?
                        <PzSymbol.Text key={qidx} text={'Q'+q.qseq} size={size}
                            className="!bg-purple-900 !text-white hover:cursor-pointer" 
                            onClick={()=>{}} />
                    : q.qstatus < 'Vx' ? // not voted yet
                        <div key={qidx} className={"w-["+WHSIZE+"] h-["+WHSIZE+"] border-2 border-slate-700 bg-white     hover:cursor-pointer rounded-md"} 
                            onClick={()=>{ onClickToViewQSummary(q); }} />
                    :
                        <div key={qidx} className={"w-["+WHSIZE+"] h-["+WHSIZE+"] border-2 border-slate-700 bg-slate-400 hover:cursor-pointer rounded-md"} 
                            onClick={()=>{ onClickToViewQSummary(q); }} />
                    ); 
                })}
            </div>

            { open4QSummary &&
                <EMModals.QSummary poll={poll} pqst={qSelected} onClose={()=>{setOpen4QSummary(false);}} xclose={true} />
            }
        </>
    );
}

