import React from "react";
import { useNavigate } from "react-router-dom";

import { utils } from "../config/userConfig";
import PzArea from "../common/PzArea";

export default function EMSplash({msg='', locale='en', gohome=false, xclose=false}) {
    const localeMap = utils.selectMapByLocale(locale);
    const navigate = useNavigate();
    const clsCenter = "absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2";

    return (
        <PzArea.MwhBoardInContainer xclose={xclose} className="relative" >

            <div className={clsCenter + " flex flex-col justify-center items-center gap-10"}>
                <h1 className="font-extrabold text-5xl cursor-pointer" onClick={()=>{if (gohome) navigate('/');}}>
                    Pollerz.net
                </h1>
                { msg === 'THANKYOU' ?
                    <h3 className="font-bold ">&nbsp;{localeMap["monitor.splash.thankyou"]}&nbsp;</h3>
                :
                    <h3 className="font-bold ">&nbsp;{msg}&nbsp;</h3>
                }
            </div>

        </PzArea.MwhBoardInContainer> 
    );
}
