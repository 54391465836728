import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useInterval } from "react-use";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import { setUPoll } from "../config/upollConfig";
import PzButton from "../common/PzButton";
import PzDialog from "../common/PzDialog";
import PzSymbol from "../common/PzSymbol";
import PzText from "../common/PzText";
import EntranceToRehearse from "../entrance/EntranceToRehearse";
import EntranceForVoters from "../entrance/EntranceForVoters";
import { ReactComponent as IconVotePollbox } from "../resource/icons/pz-vote-pollbox.svg";
import { ReactComponent as IconSaveServer } from "../resource/icons/pz-save-server-down.svg";
import { ReactComponent as IconToolSettings } from "../resource/icons/pz-tool-settings.svg";
import { ReactComponent as IconToolTrash }  from "../resource/icons/pz-tool-trash.svg";
import { ReactComponent as IconToolSmartphone }  from "../resource/icons/pz-tool-smartphone.svg";
import { ReactComponent as IconQRCode }  from "../resource/icons/pz-qrcode.svg";
import PollEvent from "./PollEvent";
import PollPqst from "./PollPqst";

const PollCard = {
    List:       PollCardList,
    Item:       PollCardItem,
    Empty:      PollCardEmpty,
};
export default PollCard;


function PollCardList({plist, size='xl', className=''}) {
    const dispatch = useDispatch();
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const [pSelected, setPSelected] = React.useState('');   // pguid
    if (upoll && upoll.pguid != pSelected) {
        // for (let i = 0; i < plist.length; i++) {
        //     if (plist[i].pguid === upoll.pguid) { setPSelected(upoll.pguid); break; }
        // }
        setPSelected(upoll.pguid);
    }

    function setPoll(newpoll) {
        dispatch(setUPoll(newpoll));
    }

    function callForPCardToSelect(pguid) {
        if (!pguid) {
            setPSelected('');
            dispatch(setUPoll(null));
        } else {
            setPSelected(pguid);
            for (let i = 0; i < plist.length; i++) {
                if (plist[i].pguid === pguid) { 
                    dispatch(setUPoll(plist[i]));
                    break;
                }
            }
        }
    }

    return (
        <> 
            { plist.map((p) =>
                <PollCardItem key={p.pguid} poll={p} setPoll={setPoll} size={size} 
                    expandable={true} editable={false} className={className}
                    pSelected={pSelected} callToSelect={callForPCardToSelect} /> )
            }
        </>
    );
}

function PollCardItem({ poll, setPoll=()=>{}, size='xl', 
    expandable=false, editable=false, className='', buttons=null,
    pSelected=false, callToSelect=undefined }) {

    const localeMap = utils.selectMapByLocale(poll?.locale);
    const navigate = useNavigate();
    const utzone = useSelector((state)=>state.ucfg.timezone); // timezone from user settings
    const [pqstFolded, setPqstFolded] = React.useState(poll?.pguid !== pSelected);
    const [open4SimTest, setOpen4SimTest] = React.useState(false);
    const [open4Delete, setOpen4Delete] = React.useState(false);
    const [open4Save, setOpen4Save] = React.useState(false);
    const p_selected = (poll?.pguid === pSelected && callToSelect);
    const fqinf = appConfig.getFQInf();
    if (pqstFolded === p_selected && callToSelect) setPqstFolded(!pqstFolded);
    // console.log('PollCard:', poll?.pguid, '  qguid:', qSelected);

    function onClickToCard(e) {
        if (!poll) return;
        // console.log('PollCard onClickToCard', expandable, pqstFolded);
        e.stopPropagation();
        if (typeof callToSelect === 'function') callToSelect(p_selected ? '' : poll.pguid);
        if (expandable) setPqstFolded(!pqstFolded);
    }

    function onClickToEventSymbol(e) {
        if (!poll) return;
        console.log('PollCard:', poll);
    }

    function onConfirmToDelete() {
        if (!poll) return;
        // setLoading(true);
        appConfig.getAPI().post("/api/mp/delete", {pguid:poll.pguid})
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("PzAPI '/api/mp/delete' success : ", pzdata);
            navigate("/pages/myevents");
        })
        .catch((error) => {
            console.log("PzAPI '/api/mp/delete' error : ", error);
        })
        .finally(() => {
            // setLoading(false);
        });
    }

    function onConfirmToSave() {
        if (!poll) return;
        // setLoading(true);
        appConfig.getAPI().post("/api/mp/update", poll)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            // console.log("PzAPI '/api/mp/update' success : ", pzdata);
            setPoll(pzdata.resource);
        })
        .catch((error) => {
            console.log("PzAPI '/api/mp/update' error : ", error);
        })
        .finally(() => {
            // setLoading(false);
        });
    }

    let TEXTSIZE = 'text-'+size, tsize = size, ssize = size, sssize = size, padding = " ", gap = " gap-0 ";
    if      (size === '2xl') { tsize="3xl"; ssize="xl"; sssize="lg"; padding = " p-5 "; gap=" gap-3 "; }
    else if (size ===  'xl') { tsize="2xl"; ssize="lg"; sssize="md"; padding = " p-5 "; gap=" gap-3 "; }
    else if (size ===  'lg') { tsize="xl";  ssize="md"; sssize="sm"; padding = " p-4 "; gap=" gap-2 "; }
    else if (size ===  'md') { tsize="lg";  ssize="sm"; sssize="xs"; padding = " p-3 "; gap=" gap-2 "; }
    else if (size ===  'sm') { tsize="md";  ssize="xs"; sssize="xs"; padding = " p-2 "; gap=" gap-1 "; }
    else                     { tsize="sm";  ssize="xs"; sssize="xs"; padding = " p-1 "; gap=" gap-1 "; }
    let CLASSNAME = " w-full border border-slate-800 rounded-lg flex flex-col justify-start items-start hover:cursor-pointer ";
    CLASSNAME += (poll.pstatus === 'Xx' ? " bg-gray-200 " : " bg-slate-200 ")

    return (
    <>
        <div className={CLASSNAME + padding + gap + className} onClick={onClickToCard} >  

            {/* Title */}
            <div className={"w-full flex flex-row justify-between items-center " + gap} >
                <div className={"w-full flex flex-row justify-start items-center " + gap} >
                    <PzButton.Icon Icon={poll?.pkind[0] === 'P' ? IconVotePollbox : IconToolSettings} size={size} 
                        className="!p-1 !bg-purple-900 !text-white hover:cursor-pointer"
                        onClick={onClickToEventSymbol} />
                    <PzText.Touch 
                        text={poll?.ptitle} setText={(txt)=>{if (editable) setPoll({...poll,ptitle:txt});}} 
                        size={tsize} editable={editable} truncate={pSelected ? false : true} />
                </div>
                { !editable &&
                    <div className={"w-10 flex flex-row justify-end items-center relative"} >  
                        &nbsp;
                        <div className={"w-[30rem] text-md text-right absolute top-0 right-0 "} >  
                            {getEventExpiration(poll, localeMap, TEXTSIZE, true, utzone, fqinf)}
                        </div>
                    </div>
                }
            </div>

            { !editable && // By & When 
            <div className={"w-full pl-5 flex flex-row justify-between items-center " + gap} >
                <div className={"flex flex-row justify-start items-center "} >
                    <label className={TEXTSIZE}>by</label> &nbsp;
                    <label className={TEXTSIZE+" font-bold"}>{poll?.uname}</label> &emsp;
                </div>
                <PollEvent.PWhen poll={poll} size={size} />
            </div>
            }

            { !editable && // Status 
            <div className={"w-full pl-5 flex flex-row justify-between items-center " + gap} >
                <div className={"flex flex-row justify-start items-center "} >
                    <PzSymbol.Text text='Q' size={ssize} className="!bg-purple-900" op50={true} /> &nbsp;
                    <label className={TEXTSIZE}>{poll.pqstlist.length}</label> &emsp;
                    <PzSymbol.Text text='S' size={ssize} className="!bg-purple-900" op50={true} /> &nbsp;
                    <label className={TEXTSIZE}>{poll.nstaff}</label> &emsp;
                    <PzSymbol.Text text='V' size={ssize} className="!bg-purple-900" op50={true} /> &nbsp;
                    { (poll.pstatus >= 'Oo' && poll.nelg > 0) ? 
                        <label className={TEXTSIZE}>{poll.natt} / {poll.nelg} (R)</label> 
                    : (poll.pstatus >= 'Oo' && poll.nelg === 0) ? 
                        <label className={TEXTSIZE}>{poll.natt} / {poll.nmax} (A)</label> 
                    : poll.nelg > 0 ?
                        <label className={TEXTSIZE}>{poll.nelg}</label> 
                    : 
                        <label className={TEXTSIZE}>{'Anyone'}</label> 
                    }
                    &emsp;&emsp;
                    <label className={TEXTSIZE + ""} >
                        {getEventStatus(poll, localeMap, TEXTSIZE, true, utzone, fqinf)}
                    </label> 
                </div>
                { !(poll.xrole === 'M' || poll.xrole === 'S') &&
                    <div className={"flex flex-row justify-end items-center "} >
                        <PollCardAction poll={poll} size={sssize} />
                    </div>
                }
            </div>
            }

            { !editable && (poll.xrole === 'M' || poll.xrole === 'S') && // Supporting As 
            <div className={"w-full pl-5 flex flex-row justify-between items-center " + gap} >
                <div className={"flex flex-row justify-start items-center "} >
                        <label className={TEXTSIZE}>Supporting as</label> &nbsp;
                        <label className={TEXTSIZE+" font-bold"}>{localeMap["poll.card.staff.myrole."+poll.xrole?.toLowerCase()]}</label> &emsp;
                </div>
                <div className={"flex flex-row justify-end items-center "} >
                    <PollCardAction poll={poll} size={sssize} />
                </div>
            </div>
            }

            { editable &&
            <div className={"w-full px-5 pt-5 flex flex-row justify-between items-center " + gap} >
                <PollEvent.Editor poll={poll} setPoll={setPoll} size={size} visibility={{t:false, u:true, w:true}} />
            </div>
            }

            {/* Questions */}
            { !pqstFolded &&
            <div className="w-full px-5 pt-5 flex flex-col justify-start items-stretch gap-3">
                <PollPqst.List poll={poll} setPoll={setPoll} size={size} editable={editable} />
            </div>
            }

            {/* Buttons (Delete,SimTest,Save)  */}
            { buttons &&
            <div className="w-full px-5 pt-5 flex flex-row justify-between items-center">
                { buttons.delete &&
                    <PzButton.Icon Icon={IconToolTrash} size='xl' 
                        onClick={()=>{setOpen4Delete(true);}} />
                }
                { buttons.simtest && poll.pstatus <= 'Ax' && 
                    <PzButton.Std Icon={IconToolSmartphone} text={localeMap["poll.edit.simtest"]}  size='lg' 
                        onClick={()=>{setOpen4SimTest(true);}} />
                }
                { buttons.save &&
                    <PzButton.Icon Icon={IconSaveServer} 
                        size='xl' className={poll.edited ? "heartbeat" : ""}
                        onClick={()=>{setOpen4Save(true);}} />
                }
            </div>
            }

        </div>

        { open4SimTest &&
            <EntranceToRehearse.Dialog poll={poll} onClose={()=>setOpen4SimTest(false)} />
        }
        { open4Delete && 
            <PzDialog.Simple title={localeMap["poll.edit.delete"]} 
                message={"\"" + poll.ptitle + "\"\n"+ localeMap["poll.edit.deletemsg"]} 
                onClose={()=>{setOpen4Delete(false);}}
                onButtonCancel={()=>{setOpen4Delete(false);}}
                onButtonOk={onConfirmToDelete} />
        }
        { open4Save && 
            <PzDialog.Simple title={localeMap["poll.edit.save"]} 
                message={"\"" + poll.ptitle + "\"\n"+ localeMap["poll.edit.savemsg"]} 
                onClose={()=>{setOpen4Save(false);}}
                onButtonCancel={()=>{setOpen4Save(false);}}
                onButtonOk={()=>{onConfirmToSave(); setOpen4Save(false);}} />
        }
    </>
    )
}

function getEventExpiration(poll, localeMap, TEXTSIZE, bold, utzone, fqinf) {
    // description
    let pdesc = '', pkind = (poll.pkind[0]==='Y' ? poll.pkind[1]+'ra' : poll.pkind);
    let nvoters = [ (poll.nelg === fqinf ? "∞" : poll.nelg), (poll.nmax === fqinf ? "∞" : poll.nmax), poll.natt ]
    if (poll.willBeExpiredAt) {
        let expireAt = dayjs(poll.willBeExpiredAt);
        let remaining = expireAt.diff(dayjs(), 'minute');
        if (remaining > 0 && remaining < 60) {
            if (poll.pstatus < 'O ') {
                pdesc = utils.completeTimeText(localeMap["poll.summary.expire.notopened"], expireAt, utzone);
                return pdesc;
            } else if (poll.pstatus < 'X ') {
                pdesc = utils.completeTimeText(localeMap["poll.summary.expire.notclosed"], expireAt, utzone);
                return pdesc;
            }
        }
    } else {
        return '';
    }
}

function getEventStatus(poll, localeMap, TEXTSIZE, bold, utzone, fqinf) {
    let status = '';
    if      (poll.pstatus  <  'Ax') status = localeMap["poll.summary.status." + poll.pstatus];
    else if (poll.pstatus === 'Ax') status = localeMap["poll.summary.status." + poll.pstatus];
    else if (poll.pstatus === 'Oo') status = localeMap["poll.summary.status." + poll.pstatus];
    else if (poll.pstatus === 'Os') status = localeMap["poll.summary.status." + poll.pstatus];
    else if (poll.pstatus === 'Oz') status = localeMap["poll.summary.status." + poll.pstatus];
    else if (poll.pstatus  >= 'X')  status = localeMap["poll.summary.status." + poll.pstatus];
    return status;  
}

function PollCardAction({ poll, size='md' }) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open4EntView, setOpen4EntView] = React.useState(false);

    // console.log("EventCard", poll.pguid, foldedCard, selectable, pollSelected);
    // console.log(axRemaining);

    // useInterval(() => {
    // }, 5000);

    function navigateForPoller(poll, target) {
        if (poll.xrole !== 'P') return;
        dispatch(setUPoll(poll));
        if      (target === 'editevt') navigate("/pages/poller/editevent");
        else if (target === 'getready') navigate("/pages/poller/getready");
        else if (target === 'invite')  navigate("/pages/poller/invite");
        else if (target === 'runpoll') navigate("/pages/poller/runpoll");
        else if (target === 'xresult') navigate("/pages/poller/xresult");
    }

    function navigateForStaff(poll, target) {
        if (poll.xrole !== 'M' && poll.xrole !== 'S') return;
        dispatch(setUPoll(poll));
        if      (target === 'prepare') navigate("/pages/staff/prepare");
        else if (target === 'support') navigate("/pages/staff/support");
        else if (target === 'sresult') navigate("/pages/staff/sresult");
    }

    if (!poll || !poll.pkind || !poll.pstatus) {
        console.log("Something wrong with the poll : ", poll);
        return (<div></div>);
    }
    
    return (
        <>
        { !location.pathname.endsWith('/pages/myevents') ?
            ( poll.pstatus >= 'Ax' && poll.pstatus <= 'Oz' ?
                <PzButton.Icon Icon={IconQRCode} size={size} 
                    onClick={(e)=>{setOpen4EntView(true); e.stopPropagation();}} className="!p-2" />
            :
                <div>&nbsp;</div>
            )
        : poll.xrole === 'P' ?  // for POLLER
            ( poll.pstatus < 'Ax' ? 
                <div className="flex flex-row justify-start items-center gap-2">
                    <PzButton.Std text={localeMap["poll.card.button.edit"]} size={size} bold={false} 
                        onClick={(e)=>{navigateForPoller(poll,'editevt'); e.stopPropagation();}} />
                    <PzButton.Std text={localeMap["poll.card.button.getready"]} size={size} bold={false} 
                        onClick={(e)=>{navigateForPoller(poll,'getready'); e.stopPropagation();}} />
                </div>
            : poll.pstatus === 'Ax' ? 
                <div className="flex flex-row justify-start items-center gap-2">
                    <PzButton.Std text={localeMap["poll.card.button.invite"]} size={size} bold={false} 
                        onClick={(e)=>{navigateForPoller(poll,'invite'); e.stopPropagation();}} />
                    { (!poll.pwhen || dayjs(poll.pwhen).diff(dayjs(), 'minute') < 60) &&
                        <PzButton.Std text={localeMap["poll.card.button.open"]} size={size} bold={false} 
                            onClick={(e)=>{navigateForPoller(poll,'runpoll'); e.stopPropagation();}} />
                    }
                </div>
            : poll.pstatus.startsWith('O') ? 
                <PzButton.Std text={localeMap["poll.card.button.run"]} size={size} bold={false} 
                    onClick={(e)=>{navigateForPoller(poll,'runpoll'); e.stopPropagation();}} />
            : poll.pstatus.startsWith('X') ? 
                <PzButton.Std text={localeMap["poll.card.button.xresult"]} size={size} bold={false} 
                    onClick={(e)=>{navigateForPoller(poll,'xresult'); e.stopPropagation();}} />
            :
                <div>&nbsp;</div>
            )
        : // for STAFF
            ( poll.pstatus < 'Ax' ? 
                <div>&nbsp;</div>
            : poll.pstatus === 'Ax' ? 
                <div className="flex flex-row justify-start items-center gap-2">
                    <PzButton.Std text={localeMap["poll.card.button.prepare"]} size={size} bold={false} 
                        onClick={(e)=>{navigateForStaff(poll, 'prepare'); e.stopPropagation();}} />
                    { (poll.xrole === 'M' && (!poll.pwhen || dayjs(poll.pwhen).diff(dayjs(), 'minute') < 60)) &&
                        <PzButton.Std text={localeMap["poll.card.button.support"]} size={size} bold={false} 
                            onClick={(e)=>{navigateForStaff(poll,'support'); e.stopPropagation();}} />
                    }
                </div>
            : poll.pstatus.substring(0,1) === 'O' ? 
                <PzButton.Std text={localeMap["poll.card.button.support"]} size={size} bold={false} 
                    onClick={(e)=>{navigateForStaff(poll, 'support'); e.stopPropagation();}} />
            : poll.pstatus >= 'X ' ? 
                <PzButton.Std text={localeMap["poll.card.button.xresult"]} size={size} bold={false} 
                    onClick={(e)=>{navigateForStaff(poll, 'sresult'); e.stopPropagation();}} />
            :
                <div>&nbsp;</div>
            )
        }
        { open4EntView && 
            <EntranceForVoters.Dialog poll={poll} onClose={(e)=>{setOpen4EntView(false);}} />
        }
        </>
    );
}

function PollCardEmpty({size='xl', mode='planned', className=''}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    let CLASSNAME = " w-full bg-slate-100 border border-slate-800 rounded-lg flex flex-col justify-start items-start ";

    return (
        <div className={CLASSNAME + className} >  
            <div className="w-full p-5 flex flex-col justify-start items-center gap-4" >
                <div className="flex flex-row justify-start items-center gap-4" >
                    <PzButton.Icon Icon={IconVotePollbox} size={size} 
                            className="!p-1 !bg-purple-900 !text-white hover:cursor-pointer opacity-50" />
                    { mode === 'planned' ?
                        <h4>{localeMap["myevents.none.planned"]}</h4>
                    : mode === 'archived' ?
                        <h4>{localeMap["myevents.none.archived"]}</h4>
                    : 
                        <h4>{localeMap["myevents.none.sysevent"]}</h4>
                    }
                </div>
                { mode === 'archived' &&
                    <h5>{localeMap["myevents.none.archmsg"]}</h5>
                }
            </div>

        </div> 
    );
}

