import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from "../common/PzBasicModal";
import PzSymbol from "../common/PzSymbol";
import PzTable, {PztShortTooltip} from "../common/PzTable";
import PollPqst from "../event/PollPqst";
import PollPqstResult from "../event/PollPqstResult";
import EMWithPzServer from "./EMWithPzServer";
import EMWithSimulator from "./EMWithSimulator";
import { ReactComponent as IconUserPoller } from "../resource/icons/pz-user-b1-sw2.svg";
import { ReactComponent as IconUserStaff } from "../resource/icons/pz-user-b2-sw2.svg";
import { ReactComponent as IconUserVoter } from "../resource/icons/pz-user-voter.svg";
import { ReactComponent as IconTimeClock } from "../resource/icons/pz-time-clock.svg";
import { ReactComponent as IconDoorOpen } from "../resource/icons/pz-door-open.svg";
import { ReactComponent as IconDoorClose } from "../resource/icons/pz-door-close.svg";
import { ReactComponent as IconStepStart } from "../resource/icons/pz-step-start.svg";
import { ReactComponent as IconStepStop } from "../resource/icons/pz-step-stop.svg";
import { ReactComponent as IconToolWorld } from "../resource/icons/pz-tool-world.svg";

const EMModals = {
    EventInfo:      EMModalForEventInfo,
    StaffList:      EMModalForStaffList,
    VoterList:      EMModalForVoterList,

    QSummary:       EMModalForQSummary,
    RoundHistory:   EMModalForRoundHistory,
};
export default EMModals;



function EMModalForEventInfo({ poll, onClose=()=>{} }) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const utzone = useSelector((state)=>state.ucfg.timezone); // user's current timezone selection
    const [show4Opened,  setShow4Opened] = React.useState(false);
    const [show4Started, setShow4Started] = React.useState(false);
    const [show4Finished, setShow4Finished] = React.useState(false);
    const [show4Closed,  setShow4Closed] = React.useState(false);
    const TEXTSIZE = 'text-xl', ICONSIZE="1.7rem";

    return (
        <PzBasicModal onClose={onClose} >
            <div className="w-auto max-w-[34rem] flex flex-col justify-start items-center p-10 gap-5">

                <h2 className="w-full text-center">{poll.ptitle}</h2>

                <div className="w-auto flex flex-col justify-start items-start gap-5">
                    <div className="w-full grid grid-cols-[auto_1fr] justify-start items-center gap-x-4 gap-y-1">
                        <IconUserPoller width={ICONSIZE} height={ICONSIZE} />
                        <label className={TEXTSIZE}>{poll.uname}</label>
                    </div>

                    <div className="w-full grid grid-cols-[auto_auto_1fr] justify-start items-center gap-x-4 gap-y-1">
                        <IconTimeClock width={ICONSIZE} height={ICONSIZE} />
                        { poll.pwhen ?
                            <>
                            <label className={TEXTSIZE}>{utils.getTimeInUserTimezone(poll.pwhen, "YYYY/MM/DD", utzone)}</label>
                            <label className={TEXTSIZE+" text-right"}>{utils.getTimeInUserTimezone(poll.pwhen, "h:mm:ss A", utzone)}</label>
                            </>
                        :
                            <>
                            <label className={TEXTSIZE}>{utils.getTimeInUserTimezone(dayjs(), "YYYY/MM/DD", utzone)}</label>
                            <label className={TEXTSIZE+" text-right"}>{localeMap["poll01.pwhen.rightnow"]}</label>
                            </>
                        }

                        <IconDoorOpen width={ICONSIZE} height={ICONSIZE} onMouseEnter={()=>{setShow4Opened(true);}} onMouseLeave={()=>{setShow4Opened(false);}} />
                        <label className={TEXTSIZE+" opacity-50"}>{show4Opened ? 'Opened at' : ''}</label>
                        <label className={TEXTSIZE+" text-right"} onMouseEnter={()=>{setShow4Opened(true);}} onMouseLeave={()=>{setShow4Opened(false);}}>{utils.getTimeInUserTimezone(poll.startedat, "h:mm:ss A", utzone)}</label>

                        <IconStepStart width={ICONSIZE} height={ICONSIZE} onMouseEnter={()=>{setShow4Started(true);}} onMouseLeave={()=>{setShow4Started(false);}} />
                        <label className={TEXTSIZE+" opacity-50"}>{show4Started ? 'Started at' : ''}</label>
                        <label className={TEXTSIZE+" text-right"} onMouseEnter={()=>{setShow4Started(true);}} onMouseLeave={()=>{setShow4Started(false);}}>{utils.getTimeInUserTimezone(poll.startedat, "h:mm:ss A", utzone)}</label>

                        <IconStepStop width={ICONSIZE} height={ICONSIZE} onMouseEnter={()=>{setShow4Finished(true);}} onMouseLeave={()=>{setShow4Finished(false);}} />
                        <label className={TEXTSIZE+" opacity-50"}>{show4Finished ? 'Finished at' : ''}</label>
                        <label className={TEXTSIZE+" text-right"} onMouseEnter={()=>{setShow4Finished(true);}} onMouseLeave={()=>{setShow4Finished(false);}}>{utils.getTimeInUserTimezone(poll.stoppedat, "h:mm:ss A", utzone)}</label>

                        <IconDoorClose width={ICONSIZE} height={ICONSIZE} onMouseEnter={()=>{setShow4Closed(true);}} onMouseLeave={()=>{setShow4Closed(false);}} />
                        <label className={TEXTSIZE+" opacity-50"}>{show4Closed ? 'Closed at' : ''}</label>
                        <label className={TEXTSIZE+" text-right"} onMouseEnter={()=>{setShow4Closed(true);}} onMouseLeave={()=>{setShow4Closed(false);}}>{utils.getTimeInUserTimezone(poll.closedat, "h:mm:ss A", utzone)}</label>
                    </div>

                    <div className="w-full grid grid-cols-[auto_1fr] justify-start items-center gap-x-4 gap-y-1">
                        <IconToolWorld width={ICONSIZE} height={ICONSIZE} />
                        <label className={TEXTSIZE}>{appConfig.getLanguageFromLocale(poll?.locale, true)}</label>
                    </div>

                    <div className="w-full grid grid-cols-[auto_1fr] justify-start items-center gap-x-4 gap-y-1">
                        <IconUserStaff width={ICONSIZE} height={ICONSIZE} />
                        <label className={TEXTSIZE}>{poll.nstaff >= 2 ? `${poll.nstaff} staff members` : poll.nstaff === 1 ? `${poll.nstaff} staff member` : 'Nobody as staff'}</label>
                    </div>

                    <div className="w-full grid grid-cols-[auto_1fr] justify-start items-center gap-x-4 gap-y-1">
                        <IconUserVoter width={ICONSIZE} height={ICONSIZE} />
                        <label className={TEXTSIZE}>{poll.nelg > 0 ? `${poll.nelg} registered` : 'Anyone'}</label>
                    </div>
                </div>
            </div>
        </PzBasicModal>
    );
}

function EMModalForVoterList({ poll, onClose=()=>{}, xclose=false }) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const location = useLocation();
    const [pgOpts, setPgOpts] = React.useState({pguid:poll?.pguid,page:0,pageSize:10});
    const [pgData, setPgData] = React.useState({page:0,pageSize:10,pageData:[],total:0});

    React.useEffect(() => {
        fetchVoterList(pgOpts);
    }, []);

    function fetchVoterList(opts) {
        const simulate = (location.pathname.startsWith("/y/"));
        if (simulate) return EMWithSimulator.fetchVoterList(poll, opts, setPgData);
        else          return  EMWithPzServer.fetchVoterList(poll, opts, setPgData);
    }

    return (
        <PzBasicModal onClose={onClose} xclose={xclose} >

            <div className="w-[34rem] flex flex-col px-5 py-10 gap-5">

                <h2 className="w-full text-center">{localeMap["monitor.help.voterlist"]}</h2>

                <PzTable 
                    data={pgData} opts={pgOpts} setOpts={setPgOpts} fetchData={fetchVoterList}
                    rowKey="vid" searchable={true} configurable={false}
                    columns={[
                        { title: 'VID', key: 'vid', hidden:true,
                            render: ({vid})=>{ return (<PztShortTooltip value={vid} stt={-14}/>); } },
                        { title: 'Name', key: 'vname' },
                        { title: 'S', key: 'vstatus', hidden:true },
                        { title: 'BDate', key: 'vbdate', hidden:true },
                        { title: 'Phone', key: 'vphone', hidden:(poll.pstatus <= 'Oo' ? false : true) },
                        { title: 'Email', key: 'vemail', hidden:true },
                        { title: 'CheckIn', key: 'checkin', datetime: 'HH:mm:ss', hidden:false },
                        { title: 'LastVote', key: 'lastvote', datetime: 'HH:mm:ss', hidden:(poll.pstatus <= 'Oo' ? true : false) },
                        { title: 'CheckOut', key: 'checkout', datetime: 'HH:mm:ss', hidden:true },
                        { title: 'IP', key: 'voterip', hidden:true },
                        { title: 'Location', key: 'voterloc', hidden:true },
                    ]} />

            </div>
            
        </PzBasicModal>
    );

}

function EMModalForStaffList({ poll, onClose=()=>{}, xclose=false }) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const location = useLocation();
    const [pgOpts, setPgOpts] = React.useState({pguid:poll?.pguid,page:0,pageSize:10});
    const [pgData, setPgData] = React.useState({page:0,pageSize:10,pageData:[],total:0});

    React.useEffect(() => {
        fetchStaffList(pgOpts);
    }, []);

    const fetchStaffList = (opts)=>{
        appConfig.getAPI().post("/api/mp/staff/list", opts)
        .then((response) => {
            // console.log("PzApi 'stafflist' responded : ", response.data);
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // {total:108, page:1, pageSize:10, pageData:[...]}
            setPgData(pgdata);
        })
        .catch((error) => {
            console.log("PzAPI 'stafflist' error : ", error);
        })
        .finally(() => {});
    };

    return (
        <PzBasicModal onClose={onClose} xclose={xclose} >

            <div className="w-[34rem] flex flex-col px-5 py-10 gap-5">

                <h2 className="w-full text-center">{localeMap["monitor.help.stafflist"]}</h2>

                <PzTable 
                    data={pgData} opts={pgOpts} setOpts={setPgOpts} fetchData={fetchStaffList}
                    rowKey="uemail" searchable={true} configurable={false}
                    columns={[
                        { title: 'Name', key: 'uname' },
                        { title: 'Email', key: 'uemail' },
                        { title: 'Role', key: 'role',
                            render: ({role})=>{return (<p>{role==='M' ? 'Manager' : 'Staff'}</p>)} },
                        { title: 'CreatedAt', key: 'createdat', datetime:'YYYY/MM/DD HH:mm', hidden:true },
                        { title: 'CheckedAt', key: 'checkedat', datetime:'YYYY/MM/DD HH:mm' },
                    ]} />

            </div>
            
        </PzBasicModal>
    );

}

function EMModalForRoundHistory({poll, pqst, onClose=()=>{}, xclose=false}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const rounds = get_round_results();

    function get_round_results() {
        let rounds = [];
        if (!poll || !pqst) return rounds;
        for (let r = 0; r <= pqst.qround; r++) {
            let before = (r <= 1 ? [] : utils.getChosenAnswers(pqst, 1, r-1));
            let nc4s  = (r === 0 ? pqst.nwanted : utils.countValidAnswers(pqst, r));
            let nc2s  = (r === 0 ? pqst.nwanted : pqst.nwanted - before.length);
            let goal  = utils.completeLocaleMapText(localeMap, "monitor.quest.goal." + pqst.qkind, [ nc2s, nc4s], poll.pkind);
            let chosen  = (r === 0 ? utils.getChosenAnswers(pqst, 1,pqst.qround)    : utils.getChosenAnswers(pqst, r,r));
            let dropped = (r === 0 ? utils.countDroppedAnswers(pqst, 1,pqst.qround) : utils.countDroppedAnswers(pqst, r,r));
            rounds.push( { goal:goal, chosen:chosen, dropped:dropped, last:(r===pqst.qround) } );
        }
        return rounds;
    }

    let clsDiv1 = " w-full p-5  min-w-[24rem] max-w-[34rem] ";
    let clsDiv2 = " w-full p-5 max-h-[40rem] overflow-y-scroll ";
    let clsFlex = " flex flex-col justify-start items-stretch gap-5 ";

    return (
        <PzBasicModal onClose={onClose} xclose={xclose} >
            <div className={clsDiv1 + clsFlex}>

                <h2 className="w-auto text-center">{utils.completeMessageText(localeMap["monitor.help.rhistory"], [`Q${pqst.qseq}`])}</h2>

                <div className={clsDiv2 + clsFlex}>
                    { pqst.qroundmax > 1 && rounds.slice(1).map((rnd,idx)=>(
                        <div key={idx} className="grid grid-cols-[5rem_1fr] justify-start items-start gap-2">
                            <div><PzSymbol.Text text={'Round'+(idx+1)} size="lg" op50={true}/></div>
                            <div className="grid grid-cols-[auto_1fr] gap-x-2 gap-y-0">
                                <label className="text-right">{localeMap["monitor.quest.goal.label"]} : </label>
                                <label className="font-bold">{rnd.goal}</label> 
                                <label className="text-right">{localeMap["monitor.quest.goal.selected"]} : </label>
                                <label className="font-bold">{rnd.chosen.length > 0 ? rnd.chosen.join(', ') : '-'}</label>
                                { pqst.qkind !== 'A' &&
                                    <>
                                        <label className="text-right">{localeMap["monitor.quest.goal.dropped"]} : </label>
                                        <label className="font-bold">{rnd.last && pqst.qstatus <= 'Vt' ? '-' : rnd.dropped}</label>
                                    </>
                                }
                            </div>
                        </div>
                    )) }
                    <div className="grid grid-cols-[5rem_1fr] justify-start items-start gap-2">
                        <div><PzSymbol.Text text={'Result'} size="lg" op50={true} /></div>
                        <label className="font-bold">{rounds[0].chosen.length > 0 ? rounds[0].chosen.join(', ') : '-'}</label>
                    </div>
                </div>
            </div>

        </PzBasicModal>
    );
}

function EMModalForQSummary({poll, pqst, onClose=()=>{}, xclose=false}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const rounds = get_round_results();

    function get_round_results() {
        let rounds = [];
        if (!poll || !pqst) return rounds;
        for (let r = 0; r <= pqst.qround; r++) {
            let before = (r <= 1 ? [] : utils.getChosenAnswers(pqst, 1, r-1));
            let nc4s  = (r === 0 ? pqst.nwanted : utils.countValidAnswers(pqst, r));
            let nc2s  = (r === 0 ? pqst.nwanted : pqst.nwanted - before.length);
            let goal  = utils.completeLocaleMapText(localeMap, "monitor.quest.goal." + pqst.qkind, [ nc2s, nc4s], poll.pkind);
            let chosen  = (r === 0 ? utils.getChosenAnswers(pqst, 1,pqst.qround)    : utils.getChosenAnswers(pqst, r,r));
            let dropped = (r === 0 ? utils.countDroppedAnswers(pqst, 1,pqst.qround) : utils.countDroppedAnswers(pqst, r,r));
            rounds.push( { goal:goal, chosen:chosen, dropped:dropped, last:(r===pqst.qround) } );
        }
        return rounds;
    }

    let clsDiv1 = " w-full p-5  min-w-[30rem] max-w-[34rem] ";
    let clsDiv2 = " w-full p-5 max-h-[40rem] overflow-y-scroll ";
    let clsFlex = " flex flex-col justify-start items-stretch gap-5 ";

    return (
        <PzBasicModal onClose={onClose} xclose={xclose} >
            <div className={clsDiv1 + clsFlex}>

                <h2 className="w-auto text-center">{utils.completeMessageText(localeMap["monitor.help.qsummary"], [`Q${pqst.qseq}`])}</h2>

                <div className={clsDiv2}>
                    { pqst.qstatus >= 'Vx' ? 
                        <PollPqstResult.Card poll={poll} qidx={pqst.qseq-1} size='lg' qSelected={pqst.qguid} />
                    :
                        <PollPqst.Card poll={poll} qidx={pqst.qseq-1} size='lg' qSelected={pqst.qguid} />
                    }
                </div>
            </div>

        </PzBasicModal>
    );
}

