import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useInterval } from "react-use";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzButton from "../common/PzButton";
import PzDialog from "../common/PzDialog";
import EMWithPzServer from "./EMWithPzServer";
import EMWithSimulator from "./EMWithSimulator";

const EMButtonForPoller = {
    Action:     EMButtonForPollerAction,
    Menu:       EMButtonForPollerMenu,
};
export default EMButtonForPoller;


function EMButtonForPollerAction({poll, qidx, pqst, setPoll, size='xl'}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const location = useLocation();
    const navigate = useNavigate();
    const pstatus = (poll ? poll.pstatus : ' ');
    const qstatus = (pqst ? pqst.qstatus : ' ');
    const [quitting, setQuitting] = React.useState(isQuitting());
    const [disabled, setDisabled] = React.useState(isNextDisabled());
    const [visible, setVisible] = React.useState(false);
    // const [invisible, setInvisible] = React.useState(!diff ? false : diff < 1000);
    const simulating = (location.pathname.startsWith("/y/"));
    let [actionSymbol, actionText] = getActionSymbolText();
    // if (!visible && utils.getDiffSince(poll?.updatedat) > 1000) setVisible(true);
    // console.log(poll.qactive, poll.qactive_selected);

    useInterval(() => {
        if (!poll) return;
        if (quitting !== isQuitting()) setQuitting(isQuitting());
        if (disabled !== isNextDisabled()) setDisabled(isNextDisabled());
        let new_visible = (actionText !== '' && utils.getDiffSince(poll?.updatedat) > 1000);
        if (!visible && new_visible) setVisible(new_visible);
        // console.log("disabled:", disabled, "  invisible:", invisible);
    }, 500);

    function isQuitting() {
        if (!poll) return false;
        let closed_pqst = 0;
        for (let i = 0; i < poll.pqstlist.length; i++) {
            if (poll.pqstlist[i].qstatus >= 'Vx') closed_pqst++;
        }
        return (closed_pqst > 0 && (closed_pqst === poll.pqstlist.length || poll.qactive_selected === -1));
    }

    function isNextDisabled() {
        if (!poll) return false;
        let diff = utils.getDiffSince(poll.updatedat);
        let TOO_EARLY = (!diff || diff < 1000);
        switch (pstatus) {
        case 'Ax': return (TOO_EARLY);
        case 'Oo': return (TOO_EARLY || poll.natt < poll.nmin);
        case 'Os':
            switch (qstatus) {
            case ' ' : 
                return (TOO_EARLY ? true : quitting ? false : !(poll?.qactive_selected >= 0));
            case 'Vs': return (TOO_EARLY || poll.natt < poll.nmin);
            case 'Vt': return (TOO_EARLY);
            case 'Vx': return (TOO_EARLY);
            default:   return false;
            }
        case 'Oz': return (TOO_EARLY);
        default:   return false;
        }
    }

    function getActionSymbolText() {
        if (!poll) return ['',''];
        const last_quest = (poll && (poll.qactive === poll.pqstlist.length-1));
        const last_round = (pqst && (pqst.qround  === pqst.qroundmax || (pqst.chosen.length-1) >= pqst.nwanted));
        const qr_symbol = (pqst && pqst.qround > 1 ? `Q${poll.qactive+1} Round${pqst.qround}` : `Q${poll.qactive+1}`);

        switch (pstatus) {
        case 'Ax': 
            return [``, utils.completeLocaleMapText(localeMap, "monitor.action.event.open", [], poll.pkind)];
        case 'Oo': 
            if (poll.natt > poll.nmin) {
                return [``, utils.completeLocaleMapText(localeMap, "monitor.action.event.start", [], poll.pkind)];
            } else {
                return [``, ``];
            }
        case 'Os':
            switch (qstatus) {
            case ' ' : 
                if (quitting) {
                    return [``, utils.completeLocaleMapText(localeMap, "monitor.action.event.final", [], poll.pkind)];
                } else if (poll.qactive_selected >= 0) {
                    return [`Q${poll.qactive_selected+1}`, utils.completeLocaleMapText(localeMap, "monitor.action.quest.vstart", [], poll.pkind)];
                } else {
                    return [``, utils.completeLocaleMapText(localeMap, "monitor.action.quest.vstart", [], poll.pkind)];
                }
            case 'Vs': 
                return [qr_symbol, utils.completeLocaleMapText(localeMap, "monitor.action.quest.vstop", [], poll.pkind)];
            case 'Vt': 
                if (!(utils.getDiffSince(poll.updatedat) > 9000)) return ['', ''];
                return [qr_symbol, utils.completeLocaleMapText(localeMap, "monitor.action.quest.vstop", [], poll.pkind)];
            case 'Vx':
                if (last_round) {
                    let new_qr_symbol = `Q${poll.qactive+1}`;
                    return [new_qr_symbol, utils.completeLocaleMapText(localeMap, "monitor.action.quest.close", [], poll.pkind)];
                } else {
                    let new_qr_symbol = (pqst ? `Q${poll.qactive+1} Round${pqst.qround+1}` : `Q${poll.qactive+1}`);
                    return [new_qr_symbol, utils.completeLocaleMapText(localeMap, "monitor.action.quest.nextr", [], poll.pkind)];
                }
            default: return [``, `invalid qstatus (${qstatus})`];
            }
        case 'Oz': return [``, utils.completeLocaleMapText(localeMap, "monitor.action.event.close", [], poll.pkind)];
        default:   return [``, `invalid pstatus (${pstatus})`];
        }
    }

    function gotoNextStep() {
        const last_quest = (poll && (poll.qactive === poll.pqstlist.length-1));
        const last_round = (pqst && (pqst.qround === pqst.qroundmax || (pqst.chosen.length-1) >= pqst.nwanted));

        if      (pstatus === 'Ax') { setPollStatus(poll, setPoll, 'Oo'); }
        else if (pstatus === 'Oo') { setPollStatus(poll, setPoll, 'Os'); } // this may start the only question
        else if (pstatus === 'Os') {
            if      (qstatus === ' ') {
                if   (quitting) setPollStatusDone(poll, setPoll);
                else            setPqstStart(poll, setPoll, (poll.qactive_selected ? poll.qactive_selected : 0));
            } 
            else if (qstatus === 'Vs') { setPqstStatus(poll, setPoll, 'Vt'); }
            else if (qstatus === 'Vt') { /* do nothing */ }
            else if (qstatus === 'Vx') { 
                if (last_round && quitting) setPollStatusDone(poll, setPoll);           // next question
                else if (last_round)        setPqstStatusDone(poll, setPoll); 
                else                        setPqstStart(poll, setPoll, poll.qactive);  // next round
            }
        } 
        else if (pstatus === 'Oz') { /* do nothing */ }
        else { console.log("pstatus invalid (" + pstatus + ")"); }
        setVisible(false);
    }

    function setPollStatus(poll, setPoll, new_pstatus) {
        if (simulating) EMWithSimulator.setPollStatus(poll, setPoll, new_pstatus); // this may start the only question 
        else             EMWithPzServer.setPollStatus(poll, setPoll, new_pstatus);
    }
    function setPollStatusDone(poll, setPoll) {
        if (simulating) EMWithSimulator.setPollStatusDone(poll, setPoll);
        else             EMWithPzServer.setPollStatusDone(poll, setPoll);
    }
    function setPqstStart(poll, setPoll, new_qactive) {
        if (simulating) EMWithSimulator.setPqstStart(poll, setPoll, new_qactive);
        else             EMWithPzServer.setPqstStart(poll, setPoll, new_qactive);
    }
    function setPqstStatus(poll, setPoll, new_qstatus) {
        if (simulating) EMWithSimulator.setPqstStatus(poll, setPoll, new_qstatus);
        else             EMWithPzServer.setPqstStatus(poll, setPoll, new_qstatus);
    }
    function setPqstStatusDone(poll, setPoll) {
        if (simulating) EMWithSimulator.setPqstStatusDone(poll, setPoll);
        else             EMWithPzServer.setPqstStatusDone(poll, setPoll);
    }

    return (
        pstatus >= 'Ax' && pstatus < 'Oz' ?
            <PzButton.EMAction symbol={actionSymbol} text={actionText} size={size}
                disabled={disabled} invisible={!visible || actionText===''} 
                onClick={gotoNextStep} />
        : 
            <></>
    );

}

function EMButtonForPollerMenu({poll, setPoll, pqst, size='xl'}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const navigate = useNavigate();
    const pstatus = (poll ? poll.pstatus : ' ');
    const [open4XPoll, setOpen4XPoll] = React.useState(false);
    const location = useLocation();
    const simulating = (location.pathname.startsWith("/y/"));

    function onConfirmToCloseEvent() {
        if (!poll || poll.pstatus !== 'Oz') return;
        if (simulating) EMWithSimulator.setPollStatusClosed(poll, setPoll);
        else             EMWithPzServer.setPollStatusClosed(poll, setPoll);
    }

    return (
        <>
            <PzButton.EMMenu size={size} // className={pstatus === 'Oz' ? '' : 'hidden'}
                items={[
                    { text:localeMap["monitor.action.copyresult"], callback:()=>{console.log('copied')}, className:'opacity-50', disabled:true },
                    { text:localeMap["poller.runpoll.close.event"], callback:()=>{setOpen4XPoll(true);} },
                ]} />
            { open4XPoll && 
                <PzDialog.Simple title={localeMap["poller.runpoll.close.event"]} 
                    message={localeMap["poller.runpoll.close.desc"]} 
                    onClose={()=>{setOpen4XPoll(false);}}
                    onButtonCancel={()=>{setOpen4XPoll(false);}}
                    onButtonOk={()=>{onConfirmToCloseEvent(); setOpen4XPoll(false);}} />
            }
        </>
    );

}

