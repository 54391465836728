import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useInterval } from "react-use";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import EMAreaHeader from "../../eventmonitor/EMAreaHeader";
import EMAreaBodyAsPoller from "../../eventmonitor/EMAreaBodyAsPoller";
import EMButtonForPoller from "../../eventmonitor/EMButtonForPoller";
import EMButtonForStaff from "../../eventmonitor/EMButtonForStaff";
import EMWithPzServer  from "../../eventmonitor/EMWithPzServer";
import EMWithSimulator from "../../eventmonitor/EMWithSimulator";
import EMSplash from "../../eventmonitor/EMSplash";


export default function TryRun() {
    const location = useLocation();
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies(['vauth']);
    const [vauth, setVAuth] = React.useState(cookies && cookies.vauth ? jwtDecode(cookies.vauth) : null);
    const [qparams] = useSearchParams();
    const pguid = qparams.get("p");
    const pname = qparams.get("name");  // poller name
    const [poll, setPoll] = React.useState(null);
    const pqst = (poll?.qactive >= 0 && poll?.pqstlist ? poll?.pqstlist[poll?.qactive] : null);
    const [qIndex, setQIndex] = React.useState(-1);
    const [qRound, setQRound] = React.useState(-1);
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const [errMsg, setErrMsg] = React.useState('');

    const ptrial = (location.pathname.startsWith("/y/prun"));
    const rehearsal = (location.pathname.startsWith("/y/rrun"));
    const simopt = useSelector((state)=>state.ucfg.simopt);

    React.useEffect(() => {
        let checkedin = (vauth != null);
        if (vauth && vauth.pguid !== pguid) {
            removeCookie('vauth', null);
            setVAuth(null);
            checkedin = false;
        }
        if (!checkedin && pguid?.length === 32) { 
            if (rehearsal) navigate("/y/rcheckin?p=" + pguid);
            else           navigate("/y/pcheckin?p=" + pguid + (pname ? "&name=" + pname : ""));
        }
    }, []);
    
    useInterval(() => {
        updateEventInfo();
    }, 1000);

    function updateEventInfo() {
        if (errMsg !== '') return;
        else if (!poll) EMWithPzServer.updateEventInfo(pguid, poll, setPoll2, '/api/v/event', 'V', setErrMsg);
        else           EMWithSimulator.updateEventInfo(pguid, poll, setPoll2, false, setErrMsg);
    }

    function setPoll2(new_poll) {
        // This function is called on poll update is SUCCESS & CHANGED 
        if (!poll) {    // first time
            let nmin = (rehearsal && simopt?.nmin ? simopt.nmin :  60);
            let nmax = (rehearsal && simopt?.nmax ? simopt.nmax : 120);
            setPoll({ ...new_poll, uname: (rehearsal ? new_poll.uname : vauth.vname), pstatus:'Oo', xrole:'P', nmin:nmin, nmax:nmax });
        } else {
            setPoll({ ...new_poll, uname: (rehearsal ? new_poll.uname : vauth.vname) });
        }
        const qactive = (new_poll ? new_poll.qactive : null);
        const qround = (qactive >= 0 && new_poll?.pqstlist ? new_poll?.pqstlist[qactive].qround : null);
        if (qactive !== qIndex || (qactive === qIndex && qround !== qRound)) {
            // the current question & round has been changed
            setQIndex(qactive);
            setQRound(qround);
        }
    }

    if (pguid === 'THANKYOU') {
        return (<EMSplash msg={localeMap["monitor.splash.thankyou"]} gohome={true} xclose={true} />);
    } else if (errMsg === 'badaddr') {
        return (<EMSplash msg={localeMap["monitor.splash.badaddr"]} gohome={true} xclose={true} />);
    } else if (errMsg === 'checkin') {
        return (<EMSplash msg={localeMap["monitor.splash.checkin"]} gohome={true} xclose={true} />);
    } else if (errMsg === 'closed') {
        return (<EMSplash msg={localeMap["monitor.splash.closed"]} gohome={true} xclose={true} />);
    } else if (errMsg === 'loading') {
        return (<EMSplash msg={localeMap["monitor.splash.loading"]} />);
    } else if (errMsg  != '') {
        return (<EMSplash msg={errMsg} />);
    } else if (!poll) {
        return (<EMSplash msg={localeMap["monitor.splash.loading"]} />);
    }

    return (
        <PzArea.Container>
            <PzArea.MwhBoard className="relative  px-0 py-10 select-none bg-white gap-10">

                <div className="w-full px-5 ">
                    <EMAreaHeader poll={poll} asVoter={false} size='3xl' />
                </div>
                <div className="w-full px-5 h-[calc(100dvh-8rem)] flex flex-col justify-start gap-10 overflow-y-scroll">
                    <EMAreaBodyAsPoller   poll={poll} qidx={poll.qactive} pqst={pqst} setPoll={setPoll} size='3xl' />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>

                {/* ACTION Button */}
                <div className="absolute bottom-[2.5rem] left-1/2 -translate-x-1/2 ">
                    { poll.pstatus < 'Ox' ?
                        <EMButtonForPoller.Action poll={poll} setPoll={setPoll} pqst={pqst} size='2xl' />
                    :
                        <EMButtonForPoller.Menu   poll={poll} setPoll={setPoll} pqst={pqst} size='2xl' />
                    }
                </div>

                {/* MENU Button */}
                <div className="absolute bottom-[2.5rem] left-[2rem] ">
                    <EMButtonForStaff.Menu   poll={poll} setPoll={setPoll} pqst={pqst} asHelp={true} size='2xl' />
                </div>

                {/* simulation notice */}
                <div className="absolute bottom-0  left-0  w-full text-center" >
                    <h4 className="text-slate-400">{localeMap["entrance.simulated"]}</h4>
                </div>

            </PzArea.MwhBoard >
        </PzArea.Container>
    );

}

