import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import { utils } from "../../config/userConfig";
import { setUPoll } from "../../config/upollConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import EntranceToRunPoll from "../../entrance/EntranceToRunPoll";
import PollCard from "../../event/PollCard";
import EvtStaffList from "../../event/EvtStaffList";
import EvtVoterList from "../../event/EvtVoterList";
import GetReadyPca from "./GetReadyPca";
import UserSplash from "../UserSplash";
import { ReactComponent as IconStepNext } from "../../resource/icons/pz-step-next.svg";
import { ReactComponent as IconStepPrev } from "../../resource/icons/pz-step-prev.svg";

export default function GetReady() {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [cookies] = useCookies(['uauth']);
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const navigate = useNavigate();
    const [aSummary, setASummary] = React.useState({summary:'', heartbeat:false});
    const dispatch = useDispatch();
    let ready2run = (upoll?.pstatus >= 'Ax');

    function setUPoll2(new_poll) {
        dispatch(setUPoll(new_poll));
    }

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event."} /> );
    }  

    return (
        <PzArea.TBoardInContainer >

            <div className="w-full px-5 flex flex-col justify-start items-start gap-5">
                <PollCard.Item poll={upoll} size='lg' expandable={true} />
            </div>

            <h2>{localeMap["menu.poller.getready"]}</h2>

            {/* Staff */}
            <EvtStaffList.Module poll={upoll} asPoller={true} />

            {/* Voters */}
            <EvtVoterList.Module poll={upoll} asPoller={true} />

            {/* PCA */}
            <div className="w-full flex flex-col justify-start items-start gap-5">
                <div className="w-full flex flex-row justify-between items-center">
                    <h3>{localeMap["poller.getready.pca.title"]}</h3>
                    <p className={"text-xl font-bold " + (aSummary.heartbeat ? 'heartbeat' : '')}>{aSummary.summary}</p>
                </div>
                <GetReadyPca poll={upoll} setPoll={setUPoll2} setSummary={setASummary}/>
            </div>

            <div className="w-full flex flex-row justify-between items-center gap-1 mt-10">
                <PzButton.Icon Icon={IconStepPrev} size='xl' onClick={()=>{navigate(-1);}}/>
                <div className="w-auto flex flex-row justify-end items-center gap-5">
                    <PzButton.Std Icon={IconStepNext} text={localeMap["menu.poller.invite"]}  size='lg' onClick={()=>{navigate("/pages/poller/invite");}} disabled={!ready2run}/>
                    <PzButton.Std Icon={IconStepNext} text={localeMap["menu.poller.runpoll"]} size='lg' onClick={()=>{navigate("/pages/poller/runpoll");}} disabled={!ready2run}/>
                </div>
            </div>

        </PzArea.TBoardInContainer>
    );
}
