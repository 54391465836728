import React from "react";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import EMWithPzServer from "./EMWithPzServer";

const EMWithSimulator = {
    updateEventInfo:        updateEventInfo,

    fetchVoterList:         fetchVoterList,
    setPollStatus:          setPollStatus,
    setPqstStart:           setPqstStart,
    setPqstStatus:          setPqstStatus,
    setNextPqstStart:       setNextPqstStart,
    setPqstStatusDone:      setPqstStatusDone,
    setPollStatusDone:      setPollStatusDone,
    setPollStatusClosed:    setPollStatusClosed,

    addVoterToVoterList:    addVoterToVoterList,          
    castVoteAsVoter:        castVoteAsVoter,

};

export default EMWithSimulator;


let voterlist = [];
let TIME_TO_PROCEED = 3000;
let loadingResult = false;

function updateEventInfo(pguid, poll, setPoll, forVoter=false, setErrMsg=()=>{}) {
    if (!poll) return;
    if (poll && (poll.pstatus >= 'Xc' && poll.pstatus <= 'Xx')) {
        if (typeof setErrMsg === 'function') setErrMsg('closed');
        return;
    }
    if (poll.pstatus === 'Oo') {
        // console.log("updateEventInfo: ", poll.pstatus);
        // add 15% of voters        for each 'updateEventInfo()' request
        let nstep = Math.floor(poll.nmax * (poll.natt < poll.nmax/2 ? 8 : 15) / 100);
        if (nstep < 1) nstep += 1;
        for (let i = 0; i < nstep && voterlist.length < (poll.nmax * 80/100); i++) {
            let v = generate_random_voter_to_checkin(pguid, poll.locale, voterlist.length);
            voterlist.push(v);
        }
        let now = dayjs().toISOString();
        if (!poll.openedat) {
            let new_poll = { ...poll, natt:voterlist.length, simulated:true, updatedat:now, openedat:now }
            setPoll(new_poll);
        } else if (poll.natt < voterlist.length) {
            // update the poll info
            let new_poll = { ...poll, natt:voterlist.length, simulated:true, updatedat:now }
            setPoll(new_poll);
        } else if (forVoter && utils.getDiffSince(poll.updatedat) > TIME_TO_PROCEED) {
            setPollStatus(poll, setPoll, 'Os');         // proceed to the next stage 'Os'
        }
    } else if (poll.pstatus === 'Os') {
        const pqst = (poll.qactive >= 0 && poll.pqstlist ? poll.pqstlist[poll.qactive] : null);
        // console.log("updateEventInfo: ", poll.pstatus, pqst?.qstatus);
        if (!pqst) {
            // console.log("something wrong (invalid pqst) : ", poll);
            if (forVoter && utils.getDiffSince(poll.updatedat) > TIME_TO_PROCEED) {
                let qactive = 0;
                for (let i = 0; i < poll.pqstlist.length; i++) {
                    if (poll.pqstlist[i].qstatus < 'Vs') { qactive = i; break; }
                }
                setPqstStart(poll, setPoll, qactive);
            }
        } else if (pqst.qstatus === 'Vs') {
            let cur_nvcast = pqst.nvcast;
            let max_nvcast = (pqst.qoption.nvote ? pqst.qoption.nvote : poll.natt);
            if (forVoter && cur_nvcast <= 0) {
                // do nothing (wait for the user to vote first)
            } else if (!forVoter || (forVoter && cur_nvcast < max_nvcast)) {
                // simulate 'nstep' voters casting votes
                // (add 15% of vote castings for each 'updateEventInfo()' request)
                let nbatch = Math.floor(poll.natt * 25 / 100);
                if (nbatch < 1) nbatch += 1;
                let newly_voted = simulate_voters_to_cast_vote(nbatch, cur_nvcast, max_nvcast);
                if (newly_voted > 0) {
                    updatePqstNVcast(poll, setPoll, cur_nvcast + newly_voted);
                }
            } else if (forVoter && utils.getDiffSince(poll.updatedat) > TIME_TO_PROCEED) {
                setPqstStatus(poll, setPoll, 'Vt');     // proceed to the next stage 'Vt'
            }
        } else if (pqst.qstatus === 'Vt') {
            // count the vote castings
            if (loadingResult) {
                // just wait, since it's has not recieved the result from PzServer
                // console.log(`loadingResult round ${pqst.qround} result`);
            } else if (!pqst.rresult || pqst.rresult.length <= pqst.qround) {
                // start evaluation (send a query to PzServer)
                const setLoadingResult = (loading)=>{ loadingResult = loading; };
                count_votes_and_evaluate_round_result(poll, setPoll, pqst, setLoadingResult, setErrMsg);
            } else if (!forVoter || (forVoter && utils.getDiffSince(poll.updatedat) > 1000)) {
                // evaluation finished. set qstatus to 'Vx'
                setPqstStatus(poll, setPoll, 'Vx');     // proceed to the next stage 'Vx'
            }
        } else if (pqst.qstatus === 'Vx') {
            if (forVoter && utils.getDiffSince(poll.updatedat) > TIME_TO_PROCEED * 2) {
                let rr = pqst.rresult[pqst.qround];
                // console.log("rr : ", rr);
                if (rr) {
                    let nextact = rr.nextact;
                    if ((nextact === 'NQ' || nextact === 'Nq') && poll.qactive >= poll.pqstlist.length-1) nextact = 'Oz';
                    switch (nextact) {
                    case 'NR': 
                        setPqstStart(poll, setPoll, poll.qactive);     // proceed to the next round 
                        break;
                    case 'Nq': 
                    case 'NQ': 
                        updatePollQActive(poll, setPoll, -1);   // close the question
                        break;
                    case 'Oz': 
                        setPollStatusDone(poll, setPoll);       // all questions done
                        break;
                    default  : 
                        console.log("invalid rresult : ", rr);
                        if (typeof setErrMsg === 'function') setErrMsg('invalid rresult');
                        return;
                    }
                }
            }
        } else {
            console.log("something wrong (invalid qstatus) : ", pqst.qstatus);
        }
    } else if (poll.pstatus === 'Oz') {
        // if (forVoter && utils.getDiffSince(poll.updatedat) > TIME_TO_PROCEED * 2) {
        //     setPollStatusClosed(poll, setPoll);
        // }
    } else {
        console.log("something wrong (invalid pstatus) : ", poll.pstatus);
        if (typeof setErrMsg === 'function') setErrMsg('');
        return;
    }
    if (typeof setErrMsg === 'function') setErrMsg('');
}

// ----------------------------------------------------------------------------
// as Poller / Staff
// ----------------------------------------------------------------------------

function fetchVoterList(poll, opts, setPgData) {
    let stt = opts.pageSize * opts.page;
    let end = stt + opts.pageSize;
    if (end > voterlist.length) end = voterlist.length;
    let voters = voterlist.slice(stt, end);
    let pgData = {total:voterlist.length, page:opts.page, pageSize:opts.pageSize, pageData:voters};
    if (typeof setPgData === 'function') setPgData(pgData);
    return;
}

function setPollStatus(poll, setPoll, pstatus) {
    if (!poll || !setPoll) return;
    let now = dayjs().toISOString();
    switch (pstatus) {
    case 'Os':
        if (poll.pqstlist.length == 1) {
            // set 'pstatus = 'Os' and start 0-th question right away
            setPqstStart(poll, setPoll, 0, false);
        } else {
            // set 'pstatus = 'Os' only
            setPoll({ ...poll, pstatus:'Os', qactive:-1, startedat:now, updatedat:now, simulated:true });
        }
        break;
    case 'Xc':
    }
    // console.log("Simulator setPollStatus() : ", poll.pstatus, pstatus);
}

function setPqstStart(poll, setPoll, qidx, check=true) {
    if (check && (!poll || !setPoll || poll.pstatus !== 'Os')) return;
    let new_pqstlist = [ ...poll.pqstlist ];
    let now = dayjs().toISOString();
    if (qidx >= 0 && qidx < poll.pqstlist.length) {
        let old_pqst = poll.pqstlist[qidx];
        let new_pqst = { ...old_pqst, qstatus: 'Vs', qround: (old_pqst.qround+1), nvcast: 0, voted: false, startedat: now };
        for (let i = 0; i < new_pqst.answers.length; i++) {
            if (new_pqst.answers[i].astat[1] === '-') {
                new_pqst.answers[i].astat = `${new_pqst.qround}-`;
            }
        }
        prepare_voters_with_cleared_choices();
        prepare_voters_with_random_choices(poll, new_pqst);  // 'U', 'N', etc.
        new_pqstlist[qidx] = new_pqst;
        let poll_startedat = (!poll.startedat ? now : poll.startedat);
        setPoll({ ...poll, pstatus:'Os', qactive:qidx, pqstlist:new_pqstlist, simulated:true, startedat: poll_startedat, updatedat:now });
    } else {
        setPoll({ ...poll, pstatus:'Os', qactive:-1,   pqstlist:new_pqstlist, simulated:true });
    }
}

function setPqstStatus(poll, setPoll, new_qstatus) {
    if (!poll || !setPoll) return;
    let qactive = poll.qactive;
    if (qactive >= poll.pqstlist.length) { console.log(`qactive invalid (${qactive})`); return; }
    let answers_deepcopy = JSON.parse(JSON.stringify(poll.pqstlist[qactive].answers));
    let pqst = { ...poll.pqstlist[qactive], answers: answers_deepcopy };
    let now = dayjs().toISOString();
    switch (new_qstatus) {
    // case 'Vs':
    //     pqst.qstatus = 'Vs';
    //     pqst.voted = false;
    //     pqst.nvcast = 0;
    //     prepare_voters_with_random_choices(poll, pqst);  // 'U', 'N', etc.
    //     break;
    case 'Vt':
        pqst.qstatus = 'Vt';
        pqst.stoppedat = now;
        break;
    case 'Vx':
        pqst.qstatus = 'Vx';
        break;
    }
    pqst.updatedat = now;
    let new_pqstlist = [ ...poll.pqstlist ];
    new_pqstlist[qactive] = pqst;
    setPoll({ ...poll, pqstlist:new_pqstlist, updatedat:now, simulated:true });
    // console.log("Simulator setPqstStatus() : ", poll.pstatus, new_qstatus);
}

function setNextPqstStart(poll, setPoll) {
    let next_qactive = poll.qactive + 1;
    if (next_qactive >= poll.pqstlist.length) { console.log(`next_qactive invalid (${next_qactive})`); return; }
    let next_pqst = { ...poll.pqstlist[next_qactive], qround: 1, qstatus: 'Vs', nvcast: 0 };
    let new_pqstlist = [ ...poll.pqstlist ];
    new_pqstlist[next_qactive] = next_pqst;
    setPoll({ ...poll, qactive: next_qactive, pqstlist:new_pqstlist, updatedat:dayjs(), simulated:true });
    // console.log("Simulator setNextPqstStart() : ", poll.pstatus);
}

function updatePollQActive(poll, setPoll, qactive) {
    setPoll({ ...poll, qactive: qactive, updatedat:dayjs(), simulated:true });
    // console.log("Simulator setNextPqstStart() : ", poll.pstatus);
}

function updatePqstVoted(poll, setPoll, voted) {
    let qactive = poll.qactive;
    if (qactive < 0 || qactive >= poll.pqstlist.length) { console.log(`qactive invalid (${qactive})`); return; }
    let old_pqst = poll.pqstlist[qactive];
    let new_pqst = { ...old_pqst, voted: voted, nvcast: 1 };
    let new_pqstlist = [ ...poll.pqstlist ];
    new_pqstlist[qactive] = new_pqst;
    setPoll({ ...poll, pqstlist:new_pqstlist, updatedat:dayjs(), simulated:true });
    // console.log("Simulator updatePqstVoted() :  new_pqst:", new_pqst);
}

function updatePqstNVcast(poll, setPoll, nvcast) {
    let qactive = poll.qactive;
    if (qactive < 0 || qactive >= poll.pqstlist.length) { console.log(`qactive invalid (${qactive})`); return; }
    let old_pqst = poll.pqstlist[qactive];
    let new_pqst = { ...old_pqst, nvcast: nvcast };
    let new_pqstlist = [ ...poll.pqstlist ];
    new_pqstlist[qactive] = new_pqst;
    setPoll({ ...poll, pqstlist:new_pqstlist, updatedat:dayjs(), simulated:true });
    // console.log("Simulator updatePqstNVcast() : ", poll.pstatus);
}

function setPqstStatusDone(poll, setPoll) {
    if (poll.qactive >= 0 && poll.qactive < poll.pqstlist.length) {
        let q = poll.pqstlist[poll.qactive];
        let new_pqst = { ...q, qstatus:'Vz', qround:0 };
        let new_list = [ ...poll.pqstlist ];
        new_list[poll.qactive] = new_pqst;
        setPoll({ ...poll, qactive:-1, updatedat:dayjs(), simulated:true, pqstlist:new_list });
    } else {
        setPoll({ ...poll, qactive:-1, updatedat:dayjs(), simulated:true });
    }
}

function setPollStatusDone(poll, setPoll) {
    // set 'pstatus' to 'Oz', and set all the 'qstatus' to 'Vz'
    let pqstlist = [];
    let now = dayjs().toISOString();
    for (let i = 0; i < poll.pqstlist.length; i++) {
        let q = poll.pqstlist[i];
        let startedat = (q.rresult.length >= 2 && q.rresult[1].startedat < q.startedat ? q.rresult[1].startedat : q.startedat);
        let pqst = { ...q, qstatus:'Vz', qround:0, startedat: startedat };
        pqstlist.push(pqst);
    }
    setPoll({ ...poll, pstatus:'Oz', pqstlist:pqstlist, qactive:0, stoppedat:now, updatedat:now, simulated:true });
}

function setPollStatusClosed(poll, setPoll) {
    setPoll({ ...poll, pstatus:'Xc', qactive:0, updatedat:dayjs(), simulated:true });
}

// ----------------------------------------------------------------------------
// as Voter
// ----------------------------------------------------------------------------

function addVoterToVoterList(intro, vid, vname) {
    const voter = {
        pguid: intro.pguid,  vid: vid,  vname: vname,  vstatus: '',
        checkin: dayjs().toISOString(),  checkout: '',  lastvote: '',
        voterip: '127.0.0.1',  voterloc: 'Unknown, UN',
    };
    voterlist.push(voter);
}

function castVoteAsVoter(vid, poll, setPoll, pqst, qRound, selection, onSuccess, onError) {
    if (!poll || poll.pstatus != 'Os') { console.log("castvote() poll invalid"); return; }
    if (!pqst || pqst.qstatus != 'Vs') { console.log("castvote() pqst invalid"); return; }
    if (qRound < 0) { console.log("qRound invalid"); return; }
    // console.log("castVoteAsVoter() :  pqst.voted:", pqst.voted, "  selection:", selection);
    setTimeout(()=>{
        updatePqstVoted(poll, setPoll, true);
        // console.log("Simulator '/v/vote' success ");
        if (typeof onSuccess === 'function') onSuccess(vid);
    }, 100); // wait 0.1 second
}


// ----------------------------------------------------------------------------
// private functions for simulation
// ----------------------------------------------------------------------------

function generate_random_voter_to_checkin(pguid, locale, vidx) {
    return {
        pguid: pguid,
        vid: generate_random_vid(vidx),
        vname: generate_random_name(locale),
        vstatus: '',
        checkin: dayjs().toISOString(),
        checkout: '',
        lastvote: '',
        voterip: '127.0.0.1',
        voterloc: 'Unknown, UN',
    };
}

const ko0 = "강경고곽권금길김류마문민박백서송심안염오윤이임장전정조주차최한허홍황";
const ko1 = "강건경광덕동미민봉상성세선성소수승신연영예용우은인재정준지진채태한현형혜호";
const ko2 = "경곤국규령룡린민범상성수숙순신아연영예옥원욱윤인일정주지진철하한학혁호환희";
const en0 = ["Bill", "Elon", "Henry", "Jack", "Jeff", "Jensen", "Larry", "Leo", "Sam", "Sergey", "Steve", "Tim"];
const en1 = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
const en2 = ["Altman", "Bezos", "Brin", "Cook", "Cruise", "Ford", "Gates", "Hwang", "Jobs", "Musk", "Page", "Welch"];
const hex = "0123456789abcdef";
function random1(max) { let r = Math.random(); return Math.floor(r * max); }
function random2(max) { let r = Math.random(); return Math.floor(r * r * max); }
function random3(max) { let r = Math.random(); return Math.floor(r * r * r * max); }

function generate_random_name(locale) {
	switch (locale) {
    case "ko":
        return ko0[random1(ko0.length)] + ko1[random1(ko1.length)] + ko2[random1(ko2.length)];
    default:
        return en0[random1(en0.length)] + " " + en1[random1(en1.length)] + " " + en2[random1(en2.length)];
    }
}

function generate_random_vid(idx) {
    let vid = "";
    if      (idx < 10) vid += "000" + idx.toString();
    else if (idx < 100) vid += "00" + idx.toString();
    else if (idx < 1000) vid += "0" + idx.toString();
    else                  vid += "" + idx.toString();
    for (let i=0; i < 14; i++) vid += hex[random1(16)];
    return vid;
}

function prepare_voters_with_cleared_choices() {
    for (let k = 0; k < voterlist.length; k++) {
        voterlist[k].choice = null; // prepared choice
        voterlist[k].vcast = null;  // actual vote casting
    }
}

function prepare_voters_with_random_choices(poll, pqst) {
    if (!pqst) return;
    let nvote = (pqst.qoption.nvote ? pqst.qoption.nvote : poll.natt);
    let vdist = (pqst.qoption.vdist ? pqst.qoption.vdist : 'N');
    const get_valid_round_answers = (pqst)=>{
        let round_alist = [];
        for (let i = 1; i < pqst.answers.length; i++) {
            let a = pqst.answers[i];
            if (a.astat[1] === '-') round_alist.push(a.aidx);
        }
        return round_alist;
    };
    let valist = get_valid_round_answers(pqst);
    let nc2s = pqst.nwanted - (pqst.chosen.length-1);
    for (let k = 0; k < voterlist.length; k++) {
        voterlist[k].choice = get_vcast_choice(valist, nc2s, vdist, k);
        voterlist[k].vcast = null;
    }
    // console.log("votes simulated (c2w:" + pqst.qoption.c2win + ", c2d:" + pqst.qoption.c2drop + ", nv:" + nvote + "/" + poll.natt + ", vdist:'" + vdist + "')");
}

function simulate_voters_to_cast_vote(nbatch, curr_nvcast, max_nvcast) {
    let new_nbatch = 0;
    for (let i = 0; i < nbatch && curr_nvcast + new_nbatch < max_nvcast; i++) {
        let found_vidx = -1;
        for (let k = 0; k < voterlist.length; k++) {
            if (!voterlist[k].vcast) { found_vidx = k; break; }
        }
        if (found_vidx < 0) break;
        voterlist[found_vidx].vcast = voterlist[found_vidx].choice;
        new_nbatch += 1;
    }
    return new_nbatch;
}

function count_votes_and_evaluate_round_result(poll, setPoll, pqst, setLoadingResult, setErrMsg) {
    // 
    setLoadingResult(true);
    // let nattendees = pqst.nvcast;
    let nvtotal = 0;
    // count the votes of current round  (JS implementation of pqst.CollectRoundVotes())
    for (let aidx = 0; aidx < pqst.answers.length; aidx++) {
        if (pqst.answers[aidx].rvcount === null) pqst.answers[aidx].rvcount = [];
        let astat = pqst.answers[aidx].astat;
        if (astat.endsWith('O') || astat.endsWith('X')) continue; // skip won/dropped answers
        while (pqst.answers[aidx].rvcount.length <= pqst.qround) pqst.answers[aidx].rvcount.push(0);
    }
    for (let k = 0; k < voterlist.length; k++) {
        let vcast = voterlist[k].vcast;
        if (!vcast) continue;
        for (let i = 0; i < vcast.length; i++) {
            let aidx = vcast[i];
            // if (pqst.answers[aidx].rvcount === null) pqst.answers[aidx].rvcount = [];
            // while (pqst.answers[aidx].rvcount.length <= pqst.qround) pqst.answers[aidx].rvcount.push(0);
            if (pqst.answers[aidx].astat[1] !== '-') {
                console.log("invalid vcast found : ", vcast);
                continue;
            }
            pqst.answers[aidx].rvcount[pqst.qround] += 1;
            nvtotal += 1;
        }
    }
    // console.log(`counted votes : nvcast=${pqst.nvcast} nvtotal=${nvtotal}`);

    // get the round result, by asking PzServer with '/api/v/qeval'
    const onSuccess = (payload, rrinfo)=>{
        // console.log("/api/v/qeval  success : ", payload, rrinfo);
        let qactive = poll.qactive;
        let pqst = { ...poll.pqstlist[qactive], answers: rrinfo.answers, chosen: rrinfo.chosen, rresult: rrinfo.rresult };
        let new_pqstlist = [ ...poll.pqstlist ];
        new_pqstlist[qactive] = pqst;
        setPoll({ ...poll, pqstlist:new_pqstlist });
        if (setLoadingResult) setLoadingResult(false);
    };
    const onError = (payload, status)=>{
        console.log("/api/v/qeval  failure : ", payload, status);
        if (setErrMsg)  setErrMsg(status);
        if (setLoadingResult) setLoadingResult(false);
    }
    EMWithPzServer.evaluateRoundResult(pqst, poll.natt, onSuccess, onError);

}

function get_vcast_choice(valist, nc2s, sampling, k) {
    let choice = [];
    if (nc2s >= valist.length) return choice;
    switch (sampling) {
    case 'U':   // uniform (in turn)
        for (let i = 0; i < nc2s; i++) {
            let idx = (k + i) % valist.length;
            choice.push(valist[idx]);
        }
        break;
    case 'N':   // naturally biased 
        for (let i = 0; i < nc2s; i++) {
            let new_aidx = 0, vahalf = Math.floor(valist.length/2);
            for (let j = 0; j < nc2s; j++) {
                let aidx = valist[ random3(vahalf/2) + (k%5<=1 ? 0 : vahalf) ];
                if (!choice.includes(aidx)) { new_aidx = aidx; break; }
            }
            if (new_aidx > 0) choice.push(new_aidx);
        }
        break;
    case '0':   // everyone selects 0 for the first choice
        for (let i = 0; i < nc2s; i++) {
            if (i === 0) {
                choice.push(valist[0]);
            } else {
                let new_aidx = 0, vahalf = Math.floor(valist.length/2);
                for (let j = 0; j < nc2s; j++) {
                    let aidx = valist[ (random2(vahalf) * 2) % valist.length ];
                    if (!choice.includes(aidx)) { new_aidx = aidx; break; }
                }
                if (new_aidx > 0) choice.push(new_aidx);
            }
        }
        break;
    case '2':   // first & middle (only two kinds of choices)
        let pos = (k%2 === 0 ? 0 : Math.floor(valist.length/2));
        for (let i = 0; i < nc2s; i++) {
            if (i === 0) {
                choice.push(valist[(pos+0) % valist.length]);
            } else {
                let new_aidx = 0, vahalf = Math.floor(valist.length/2);
                for (let j = 0; j < nc2s; j++) {
                    let aidx = valist[ (random2(vahalf) * 2) % valist.length ];
                    if (!choice.includes(aidx)) { new_aidx = aidx; break; }
                }
                if (new_aidx > 0) choice.push(new_aidx);
            }
        }
        break;
    case 'L':
        for (let i = 0; i < nc2s; i++) {
            if (i === 0 && k % 2 === 0) {
                choice.push(valist[valist.length-1]);
            } else {
                let new_aidx = 0, vahalf = Math.floor(valist.length/2);
                for (let j = 0; j < nc2s; j++) {
                    let aidx = valist[ (random1(vahalf) * 2) % valist.length ];
                    if (!choice.includes(aidx)) { new_aidx = aidx; break; }
                }
                if (new_aidx > 0) choice.push(new_aidx);
            }
        }
        break;
    case 'E':   // randomly from the even answers
        for (let i = 0; i < nc2s; i++) {
            let new_aidx = 0, vahalf = Math.floor(valist.length/2);
            for (let j = 0; j < nc2s; j++) {
                let aidx = valist[ (random1(vahalf) * 2) % valist.length ];
                if (!choice.includes(aidx)) { new_aidx = aidx; break; }
            }
            if (new_aidx > 0) choice.push(new_aidx);
        }
        break;
    default: // randomly uniform
        for (let i = 0; i < nc2s; i++) {
            let new_aidx = 0;
            for (let j = 0; j < nc2s; j++) {
                let aidx = valist[ random1(valist.length) ];
                if (!choice.includes(aidx)) { new_aidx = aidx; break; }
            }
            if (new_aidx > 0) choice.push(new_aidx);
        }
        break;
    }
    return choice.sort();
};
