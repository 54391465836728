import React from "react";
import { useInterval } from "react-use";
import dayjs from "dayjs";

import { ReactComponent as IconHourglass } from "../resource/icons/pz-time-hourglass.svg";


const PzTime = {
    getRemainingTimeInString:   getRemainingTimeInString,
    getElapsedTimeInString:     getElapsedTimeInString,

    Remaining:      PzTimeRemaining,
    RemainingExp:   PzTimeRemainingExp,
};

export default PzTime;

function PzTimeRemaining({when, format='hh:mm:ss', size='md', className=''}) {
    const [remaining, setRemaining] = React.useState('');
    const [ICONSIZE, GAPSIZE] = getIconAndGapSize(size);
    const TEXTSIZE = "text-" + size;

    useInterval(() => {
        if (!format.endsWith('ss') && !format.endsWith('mm')) return;
        setRemaining( getRemainingTimeInString(when, format) );
    }, 1000);

    return (
        remaining ?
            <div className={"flex flex-row justify-start items-center " + GAPSIZE + className}>
                <IconHourglass width={ICONSIZE} height={ICONSIZE} />
                <label className={TEXTSIZE}>{remaining}</label>
            </div>
        :
            <></>
    );
}

function PzTimeRemainingExp({poll, format='hh:mm:ss', size='md', className=''}) {
    const [remaining, setRemaining] = React.useState('');
    const [ICONSIZE, GAPSIZE] = getIconAndGapSize(size);
    const TEXTSIZE = "text-" + size;

    useInterval(() => {
        if (!format.endsWith('ss') && !format.endsWith('mm')) return;
        if (poll.willBeExpiredAt) {
            let expireAt = dayjs(poll.willBeExpiredAt);
            setRemaining( getRemainingTimeInString(expireAt, format) );
        } else {
            if (remaining !== '') setRemaining('');
        }
    }, 1000);

    return (
        remaining ?
            <div className={"flex flex-row justify-start items-center " + GAPSIZE + className}>
                <IconHourglass width={ICONSIZE} height={ICONSIZE} />
                <label className={TEXTSIZE}>{remaining}</label>
            </div>
        :
            <></>
    )
}

function getIconAndGapSize(size='md') {
    let iconsize = " ", gapsize = " ";
    if      (size === '4xl') { iconsize = "2.25rem "; gapsize = " gap-3 "; }
    else if (size === '3xl') { iconsize = "1.87rem "; gapsize = " gap-3 "; }
    else if (size === '2xl') { iconsize = "1.50rem "; gapsize = " gap-3 "; }
    else if (size === 'xl')  { iconsize = "1.25rem "; gapsize = " gap-2 "; }
    else if (size === 'lg')  { iconsize = "1.12rem "; gapsize = " gap-2 "; }
    else if (size === 'md')  { iconsize = "1.00rem "; gapsize = " gap-2 "; }
    else if (size === 'sm')  { iconsize = "0.87rem "; gapsize = " gap-1 "; }
    else if (size === 'xs')  { iconsize = "0.75rem "; gapsize = " gap-1 "; }
    // let width = "1rem", tsize = "text-md", height = "1rem", class_size = " ";
    // if      (size === '2xl') { width="1.6rem"; height="1.6rem"; tsize=" text-2xl"; class_size = " gap-1 px-1 py-0"; }
    // else if (size ===  'xl') { width="1.5rem"; height="1.5rem"; tsize=" text-xl";  class_size = " gap-1 px-1 py-0"; }
    // else if (size ===  'lg') { width="1.4rem"; height="1.4rem"; tsize=" text-lg";  class_size = " gap-1 px-1 py-0"; }
    // else if (size ===  'md') { width="1.3rem"; height="1.3rem"; tsize=" text-md";  class_size = " gap-1 px-1 py-0"; }
    // else if (size ===  'sm') { width="1.2rem"; height="1.2rem"; tsize=" text-sm";  class_size = " gap-1 px-[0.2rem] py-[0.08rem]"; }
    // else                     { width="1.1rem"; height="1.1rem"; tsize=" text-sm";  class_size = " gap-1 px-[0.2rem] py-[0.08rem]"; }
    return [ iconsize, gapsize ];
}

function getRemainingTimeInString(when, format='h:mm:ss') {
    if (format == 'fromNow') {
        return when.fromNow(true);
    } else {
        let hh = 0, mm = 0, ss = 0;
        let diff = dayjs(when).diff(dayjs(), 'second');
        if (diff > 0) {
            hh = Math.floor(diff / 3600);
            let diff_h = diff - (hh * 3600);
            mm = Math.floor(diff_h / 60);
            ss = diff_h - (mm * 60);
        }
        switch (format) {
            case 'hh:mm:ss':
            case 'h:mm:ss':
                return `${hh}:${mm>9 ? mm : '0'+mm.toString()}:${ss>9 ? ss : '0'+ss.toString()}`;
            case 'mm:ss':
                if (hh > 0) return `${hh}:${mm>9 ? mm : '0'+mm.toString()}:${ss>9 ? ss : '0'+ss.toString()}`;
                else        return `${mm>9 ? mm : '0'+mm.toString()}:${ss>9 ? ss : '0'+ss.toString()}`;
            default:
                return `${diff} seconds`;
        }
    }
}

function getElapsedTimeInString(from, format='h:mm:ss') {
    if (format == 'toNow') {
        return from.toNow();
    } else {
        let hh = 0, mm = 0, ss = 0;
        let diff = dayjs().diff(dayjs(from), 'second');
        if (diff > 0) {
            hh = Math.floor(diff / 3600);
            let diff_h = diff - (hh * 3600);
            mm = Math.floor(diff_h / 60);
            ss = diff_h - (mm * 60);
        }
        switch (format) {
            case 'hh:mm:ss':
            case 'h:mm:ss':
                case 'mm:ss':
                if (hh > 0) return `${hh}:${mm>9 ? mm : '0'+mm.toString()}:${ss>9 ? ss : '0'+ss.toString()}`;
                else        return `${mm>9 ? mm : '0'+mm.toString()}:${ss>9 ? ss : '0'+ss.toString()}`;
            default:
                return `${diff} seconds`;
        }
    }
}

