import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import PzDialog from "../../common/PzDialog";
import PollCard from "../../event/PollCard";
import PollPqstResult from "../../event/PollPqstResult";
import UserSplash from "../UserSplash";
import { ReactComponent as IconToolArchive } from "../../resource/icons/pz-tool-archive.svg";
import { ReactComponent as IconToolTrash }  from "../../resource/icons/pz-tool-trash.svg";
import { ReactComponent as IconStepPrev } from "../../resource/icons/pz-step-prev.svg";

export default function XPollerResult() {
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const localeMap = useSelector(utils.selectLocaleMap);
    const [open4Delete, setOpen4Delete] = React.useState(false);
    const [open4Archive, setOpen4Archive] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        // if (upoll && upoll.pqstlist && upoll.pqstlist.length >= 1 && currQidx == -1) setCurrQidx(0);
    }, []);

    function onConfirmToDeleteEvent() {
        // setLoading(true);
        appConfig.getAPI().post("/api/mp/delete", {pguid:upoll.pguid, archived:(upoll.pstatus==='Xx' ? true : false)})
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("PzAPI '/api/mp/delete' success : ", pzdata);
            navigate("/pages/myevents");
        })
        .catch((error) => {
            console.log("PzAPI '/api/mp/delete' error : ", error);
        })
        .finally(() => {
            // setLoading(false);
        });
    }

    function onConfirmToArchiveEvent() {
        // setLoading(true);
        appConfig.getAPI().post("/api/mp/archive", {pguid:upoll.pguid})
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("PzAPI '/api/mp/archive' success : ", pzdata);
            navigate("/pages/myevents");
        })
        .catch((error) => {
            console.log("PzAPI '/api/mp/archive' error : ", error);
        })
        .finally(() => {
            // setLoading(false);
        });
    }


    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event."} /> );
    }  

    return (
        <PzArea.TBoardInContainer >

            <div className="w-full px-5 flex flex-col justify-start items-start gap-5">
                <PollCard.Item poll={upoll} size='lg' expandable={true} />
            </div>

            <h2>{localeMap["menu.poller.xresult"]}</h2>

            <div className="w-full flex flex-col justify-start items-start gap-5">
                <div className="w-full px-10 flex flex-col justify-start items-start gap-5">
                    <PollPqstResult.List poll={upoll} size='xl' initSelected={upoll.pqstlist[0].qguid} />
                </div>
            </div>

            <div className="w-full flex flex-row justify-between items-center gap-5 mt-10">
                <PzButton.Icon Icon={IconStepPrev} size='xl' onClick={()=>{navigate(-1);}}/>
                <div className="w-auto flex flex-row justify-end items-center gap-5">
                    <PzButton.Std Icon={IconToolTrash} text={localeMap["poll.edit.delete"]} size='lg' onClick={()=>{setOpen4Delete(true);}} />
                    { upoll.pstatus !== 'Xx' &&
                    <PzButton.Std Icon={IconToolArchive} text={localeMap["menu.poller.archive"]} size='lg' onClick={()=>{setOpen4Archive(true);}} />
                    }
                </div>
            </div>

            { open4Delete && 
                <PzDialog.Simple title={localeMap["poll.edit.delete"]} 
                    message={"\"" + upoll.ptitle + "\"\n"+ localeMap["poll.edit.deletemsg"]} 
                    onClose={()=>{setOpen4Delete(false);}}
                    onButtonCancel={()=>{setOpen4Delete(false);}}
                    onButtonOk={onConfirmToDeleteEvent} />
            }

            { open4Archive && 
                <PzDialog.Simple title={localeMap["poll.edit.archive"]} 
                    message={"\"" + upoll.ptitle + "\"\n"+ localeMap["poll.edit.archivemsg"]} 
                    onClose={()=>{setOpen4Archive(false);}}
                    onButtonCancel={()=>{setOpen4Archive(false);}}
                    onButtonOk={onConfirmToArchiveEvent} />
            }

        </PzArea.TBoardInContainer>
    );
}
