import React from 'react'
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Fab from '@mui/material/Fab';

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from "./PzBasicModal";
import PzButton from "./PzButton";
import PzInput from "./PzInput";

const PzDialog = {
    Empty:          PzDialogEmpty,
    Simple:         PzDialogSimple,

    // Beta:          ButtonAlpha,
};

export default PzDialog;


function PzDialogEmpty({ children, onClose=()=>{}, className='' }) {
    const localeMap = useSelector(utils.selectLocaleMap);

    return (
        <PzBasicModal onClose={onClose}>
            <div className={" flex flex-col justify-start items-center p-5 gap-5 " + className}>
                { children }
            </div>
        </PzBasicModal>
    );
}

function PzDialogSimple({ title='', message='', className='', onClose=()=>{}, 
    onButtonOk=undefined, onButtonCancel=undefined, onButtonClose=undefined }) {
    const localeMap = useSelector(utils.selectLocaleMap);

    return (
        <PzBasicModal onClose={onClose}>
            <div className={" min-w-[20rem] max-w-[40rem] flex flex-col justify-start items-center p-10 gap-10 " + className}>
                { title &&
                    <h2 className=" font-extrabold">{title}</h2>
                }
                <div className="flex flex-col justify-start items-center gap-0">
                    { typeof message === 'string' ?
                        message.split('\n').map((line,idx)=>(<p key={idx} className="text-center text-lg">{line.trim()}</p>))
                    :
                        <></>
                    }
                </div>
                <div className="w-full flex flex-row justify-center items-center gap-5">
                    { onButtonClose  && <PzButton.Std text={localeMap["comm.button.close"]}  size='lg' className="!py-1" onClick={onButtonClose} /> }
                    { onButtonCancel && <PzButton.Std text={localeMap["comm.button.cancel"]} size='lg' className="!py-1" onClick={onButtonCancel} /> }
                    { onButtonOk     && <PzButton.Std text={localeMap["comm.button.ok"]}     size='lg' className="!py-1" onClick={onButtonOk} /> }
                </div>
            </div>
        </PzBasicModal>
    );
}

// function ButtonBeta() {
//     const localeMap = useSelector(utils.selectLocaleMap);
//     const [open, setOpen] = React.useState(false);

//     return (
//         <>
//             <PzButton.Minimal type='' className="p-0" onClick={()=>{setOpen(true);}}>
//                 <label className="font-extrabold text-xs text-red-900 hover:text-red-700 hover:cursor-pointer">
//                     Beta
//                 </label>
//             </PzButton.Minimal>

//             { open &&
//                 <PzDialogSimple className="w-[29rem]"
//                     title={localeMap["beta.noti.title"]}
//                     message={localeMap["beta.noti.message"]}
//                     onClose={()=>{setOpen(false);}} />
//             }
//         </>
//     );
// }
