import React from "react";

import PzSymbol from "./PzSymbol";
import { ReactComponent as IconRadio0 } from "../resource/icons/pz-button-radio-0.svg";
import { ReactComponent as IconRadio1 } from "../resource/icons/pz-button-radio-1.svg";
import { ReactComponent as IconCheckbox0 } from "../resource/icons/pz-checkbox-p0.svg";
import { ReactComponent as IconCheckbox1 } from "../resource/icons/pz-checkbox-p1.svg";

const PzInput = {
    // Text input fields
    ForText:        PzInputForText,
    ForTextArea:    PzInputForTextArea,

    // Number input fields
    ForInt:         PzInputForInt,

    // Radio/Checkbox input fields
    Radio1:         PzInputRadio1,
    Radio2:         PzInputRadio2,
    Checkbox1:      PzInputCheckbox1,
    Checkbox2:      PzInputCheckbox2,
    Question:       PzInputQuestion,
    Answer:         PzInputAnswer,
};
export default PzInput;


function PzInputForText({value, setValue=undefined, size='lg',
    onChange=undefined, onChangeDone=undefined, tabIndex=0, placeholder='', maxLength=-1, 
    name=undefined, className='', disabled=false, autocomplete='off', autoFocus=false}) {
    const id = React.useId();
    const [tmpValue, setTmpValue] = React.useState(value);
    const useTmp = (typeof setValue !== 'function');
    let ICONSIZE = "1rem", TEXTSIZE = "text-"+size, PGSIZE = " ";
    if      (size === '2xl') { ICONSIZE="1.6rem"; PGSIZE = " gap-1 px-2 py-1"; }
    else if (size ===  'xl') { ICONSIZE="1.5rem"; PGSIZE = " gap-1 px-2 py-1"; }
    else if (size ===  'lg') { ICONSIZE="1.4rem"; PGSIZE = " gap-1 px-1 py-0.5"; }
    else if (size ===  'md') { ICONSIZE="1.3rem"; PGSIZE = " gap-1 px-1 py-0.5"; }
    else if (size ===  'sm') { ICONSIZE="1.2rem"; PGSIZE = " gap-1 px-[0.2rem] py-[0.08rem]"; }
    else                     { ICONSIZE="1.1rem"; PGSIZE = " gap-1 px-[0.2rem] py-[0.08rem]"; }

    React.useEffect(() => {
        if (tmpValue !== value) setTmpValue(value);
    }, [value]);

    return (
        <input 
            id={id} name={name} className={TEXTSIZE + PGSIZE + " ring-inset ring-1 rounded-sm text-left " + className} 
            disabled={disabled ? 'disabled' : ''} tabIndex={tabIndex}
            autoFocus={autoFocus} autoComplete={autocomplete} 
            type="text" maxLength={maxLength} placeholder={placeholder} 
            value={useTmp ? tmpValue : value} 
            onChange={(e)=>{ 
                if (useTmp) setTmpValue(e.target.value); else setValue(e.target.value);
                if (typeof onChange === 'function') onChange(e.target.value); 
            }}
            onKeyDown={(e)=>{if (e.key==='Enter' && typeof onChangeDone === 'function') onChangeDone(e.target.value);}}
            onBlur={(e)=>{ if (typeof onChangeDone === 'function') onChangeDone(e.target.value); }}
            onFocus={(e)=>{ e.target.select();}}
        />
    );
}

function PzInputForTextArea({value, setValue=undefined, placeholder='', rows=2, 
    onChange=undefined, onChangeDone=undefined, tabIndex=0,
    name=undefined, className='', disabled=false, selection=undefined}) {
    const id = React.useId();
    const [tmpValue, setTmpValue] = React.useState(value);
    const useTmp = (typeof setValue !== 'function');

    React.useEffect(() => {
        if (tmpValue !== value) setTmpValue(value);
    }, [value]);

    // let stt = (typeof selection === 'string' && selection !== '' ? value.indexOf(selection) : -1);
    // console.log(`stt:'${stt}'`, `  selection:'${selection}'`, `  value:'${value}'`);
    // if (stt >= 0) {
    //     let input = document.getElementById(id);
    //     if (input) input.setSelectionRange(stt, stt + selection.length);
    //     // input.focus();
    // } else {
    //     let input = document.getElementById(id);
    //     if (input) input.setSelectionRange(0,0);
    // }
        
    return (
        <textarea 
            id={id} name={name} className={"px-1.5 py-0.5 ring-inset ring-1 rounded-sm text-left " + className} 
            disabled={disabled ? 'disabled' : ''} tabIndex={tabIndex}
            autoFocus={false} autoComplete='off'
            placeholder={placeholder}  rows={rows} 
            value={useTmp ? tmpValue : value} 
            onChange={(e)=>{ 
                if (useTmp) setTmpValue(e.target.value); else setValue(e.target.value); 
                if (typeof onChange === 'function') onChange(e.target.value); 
            }}
            onBlur={(e)=>{ if (typeof onChangeDone === 'function') onChangeDone(e.target.value); }}
        />
    );
}

function PzInputForInt({value, setValue=undefined, placeholder=0, min=undefined, max=undefined, 
    onChange=undefined, onChangeDone=undefined, tabIndex=0,
    name=undefined, className='', disabled=false}) {
    const id = React.useId();
    const [tmpValue, setTmpValue] = React.useState(value);
    const useTmp = (typeof setValue !== 'function');
    const minValid = (min !== undefined);
    const maxValid = (max !== undefined && minValid && max >= min);
    const regex = /^[+-]?\d*$/;

    function onInputChange(text) {
        if (!regex.test(text)) return;
        if (text === '') {
            if (useTmp) setTmpValue(text);
            else        setValue(text);
        } else {
            const v = Number(text);
            // console.log("min:", min, " max:", max);
            if ((!minValid || v >= min) && (!maxValid || v <= max)) {
                if (useTmp) setTmpValue(v);
                else        setValue(v);
            }
        }
    }

    return (
        <input 
            id={id} name={name} className={"px-1.5 py-0.5 ring-inset ring-1 rounded-sm text-right " + className} 
            disabled={disabled ? 'disabled' : ''} tabIndex={tabIndex}
            autoFocus={false} autoComplete='off' 
            type="text" maxLength={6} placeholder={placeholder} 
            value={useTmp ? tmpValue : value} 
            onChange={(e)=>{
                onInputChange(e.target.value);
                if (typeof onChange === 'function') onChange(); 
            }}
            onKeyDown={(e)=>{if (e.key==='Enter' && typeof onChangeDone === 'function') onChangeDone(e.target.value);}}
            onBlur={(e)=>{ if (typeof onChangeDone === 'function') onChangeDone(e.target.value); }}
        />
    );
}

function PzInputRadio1({children, name, value, checked=false, onChange=()=>{}, onClick=()=>{}, className='', disabled=false}) {
    let classes = " flex flex-row items-center text-slate-700 " + (disabled ? "disabled:opacity-50 " : " ") + className;
    return (
        <label className={classes} disabled={disabled}>
            <input type="radio" className="align-middle" 
                name={name} value={value} checked={checked} onChange={onChange} onClick={onClick} disabled={disabled} />
            &nbsp; {children}   {/* Note that <p> or <span> tags are desirable for 'children' */}
        </label>
    )
}

function PzInputRadio2({children, value, selection=[], setSelection=()=>{}, text='', 
    size='md', className='', disabled=false, deselectable=false, onChange=undefined}) {
    let checked = selection.includes(value);
    let class_name = " flex flex-row justify-start items-center " + (disabled ? "opacity-50 " : " ");
    let ICONSIZE = "1rem", TEXTSIZE = "text-"+size, class_size = " ";
    if      (size === '2xl') { ICONSIZE="1.6rem"; class_size = " gap-1 px-1 py-0"; }
    else if (size ===  'xl') { ICONSIZE="1.5rem"; class_size = " gap-1 px-1 py-0"; }
    else if (size ===  'lg') { ICONSIZE="1.4rem"; class_size = " gap-1 px-1 py-0"; }
    else if (size ===  'md') { ICONSIZE="1.3rem"; class_size = " gap-1 px-1 py-0"; }
    else if (size ===  'sm') { ICONSIZE="1.2rem"; class_size = " gap-1 px-[0.2rem] py-[0.08rem]"; }
    else                     { ICONSIZE="1.1rem"; class_size = " gap-1 px-[0.2rem] py-[0.08rem]"; }

    function onClick(e) {
        if (disabled) return;
        if (checked) {
            if ( selection.includes(value) && deselectable) {
                if (typeof onChange === 'function') onChange(e, selection, []);
                setSelection( [] );
            }
        } else {
            if (!selection.includes(value)) {
                if (typeof onChange === 'function') onChange(e, selection, [value]);
                setSelection( [value] );
            }
        }
    }

    return (
        <label onClick={onClick} className={class_size + class_name + (checked ? ' font-bold ' : ' ') + className} >
            { checked ? 
                <IconRadio1 width={ICONSIZE} height={ICONSIZE} />
            :
                <IconRadio0 width={ICONSIZE} height={ICONSIZE} />
            }
            { text && <span className={TEXTSIZE + (checked ? ' font-bold ' : ' ') + className}>{text}</span> }
            { children && <>{children}</> }
        </label>
    )
}

function PzInputCheckbox1({children, name, value, checked=false, onChange=()=>{}, className='', disabled=false, fixed=false}) {
    let classes = " flex flex-row items-center text-slate-700 " + (disabled ? "disabled:opacity-50 " : " ") + className;
    return (
        <label className={classes} disabled={disabled} >
            <input type="checkbox" className="align-middle" 
                name={name} value={value} checked={checked} onChange={onChange} disabled={disabled || fixed} />
            &nbsp; {children}   {/* Note that <p> or <span> tags are desirable for 'children' */}
        </label>
    )
}

function PzInputCheckbox2({children, value, text, selection=[], setSelection=()=>{}, size='md', className='', disabled=false, deselectable=true, onChange=undefined}) {
    let checked = selection.includes(value);
    let class_name = " flex flex-row justify-start items-center " + (disabled ? "opacity-50 " : " ");
    let width = "1rem", tsize = "text-md", height = "1rem", class_size = " ";
    if      (size === '2xl') { width="1.6rem"; height="1.6rem"; tsize=" text-2xl"; class_size = " gap-1 px-1 py-0"; }
    else if (size ===  'xl') { width="1.5rem"; height="1.5rem"; tsize=" text-xl";  class_size = " gap-1 px-1 py-0"; }
    else if (size ===  'lg') { width="1.4rem"; height="1.4rem"; tsize=" text-lg";  class_size = " gap-1 px-1 py-0"; }
    else if (size ===  'md') { width="1.3rem"; height="1.3rem"; tsize=" text-md";  class_size = " gap-1 px-1 py-0"; }
    else if (size ===  'sm') { width="1.2rem"; height="1.2rem"; tsize=" text-sm";  class_size = " gap-1 px-[0.2rem] py-[0.08rem]"; }
    else                     { width="1.1rem"; height="1.1rem"; tsize=" text-sm";  class_size = " gap-1 px-[0.2rem] py-[0.08rem]"; }

    function onClick(e) {
        if (disabled) return;
        if (checked) {
            if ( selection.includes(value) && deselectable) {
                if (typeof onChange === 'function') onChange(e, selection, []);
                setSelection( [] );
            }
        } else {
            if (!selection.includes(value)) {
                if (typeof onChange === 'function') onChange(e, selection, [value]);
                setSelection( [value] );
            }
        }
    }

    return (
        <label onClick={onClick} className={class_size + class_name + (checked ? ' font-bold ' : ' ') + className} >
            { checked ? 
                <IconCheckbox1 width={width} height={height} />
            :
                <IconCheckbox0 width={width} height={height} />
            }
            { text && <span className={tsize + (checked ? ' font-bold ' : ' ') + className}>{text}</span> }
            { children && <>{children}</> }
        </label>
    )
}

function PzInputQuestion({question, value, size='xl', className='', selectable=false, checked=false, disabled=false, selection=[], setSelection=()=>{}}, deselectable=false) {

    function onClick(e) {
        if (!selectable) return;
        if (checked) {
            if (selection.includes(value) && deselectable) setSelection( [] );
        } else {
            if (!selection.includes(value)) setSelection( [value] );
        }
    }

    let iconsize = "1rem", TEXTSIZE = "text-"+size, ssize = "xs", clssize = " ";
    if      (size === '4xl') { iconsize="2.25rem "; ssize="3xl"; clssize = " gap-2 px-2 py-1"; }
    else if (size === '3xl') { iconsize="1.87rem "; ssize="2xl"; clssize = " gap-2 px-2 py-1"; }
    else if (size === '2xl') { iconsize="1.6rem";   ssize="xl";  clssize = " gap-1 px-1 py-0"; }
    else if (size ===  'xl') { iconsize="1.5rem";   ssize="lg";  clssize = " gap-1 px-1 py-0"; }
    else if (size ===  'lg') { iconsize="1.4rem";   ssize="md";  clssize = " gap-1 px-1 py-0"; }
    else if (size ===  'md') { iconsize="1.3rem";   ssize="sm";  clssize = " gap-1 px-1 py-0"; }
    else if (size ===  'sm') { iconsize="1.2rem";   ssize="xs";  clssize = " gap-1 px-[0.2rem] py-[0.08rem]"; }
    else                     { iconsize="1.1rem";   ssize="xs";  clssize = " gap-1 px-[0.2rem] py-[0.08rem]"; }

    return (
        <label onClick={onClick} 
            className={clssize + " w-full flex flex-row justify-start items-center gap-2" + (disabled ? " opacity-50 " : " ") + className} >

            { selectable &&
                ( checked ? 
                    <IconRadio1 width={iconsize} height={iconsize} className="text-blue-700" />
                :
                    <IconRadio0 width={iconsize} height={iconsize} />
                )
            }

            <div key={value} className="w-[90%] p-4 border border-slate-800 rounded-2xl flex flex-row justify-start items-center gap-4">  
                <PzSymbol.Text text={'Q'+(value+1)} size={ssize} className="!bg-purple-900 !text-white hover:cursor-pointer" />
                <label className={TEXTSIZE + " font-bold w-full" + (checked ? " " : " truncate ") + className}>{question}</label>
            </div>

        </label>
    )
}


function PzInputAnswer({text, value, size='xl', className='', 
    checked=false, disabled=false, preview=false, heartbeat=false, 
    selection=[], setSelection=()=>{}, nSelection=1}) {
    const font = (checked && !preview ? ' font-bold' : ' font-normal');

    function onClick(e) {
        if (disabled) return;
        if (checked) {
            // console.log("- old:", selection, "  on", value);
            if (selection.includes(value)) {
                let new_selection = [ ...selection ];
                new_selection.splice(new_selection.indexOf(value), 1); // 2nd parameter means remove one item only
                setSelection( new_selection );
                // console.log("new:", new_selection);
            }
        } else {
            // console.log("+ old:", selection, "  on", value);
            if (!selection.includes(value)) {
                let new_selection = [ ...selection ];
                if (new_selection.length >= nSelection) new_selection = new_selection.slice(0, nSelection-1);
                new_selection.push(value);
                setSelection( new_selection );
                // console.log("new:", new_selection);
            }
        }
    }

    let iconsize = "1rem", tsize = "text-md", clssize = " ";
    if      (size === '3xl') { iconsize="1.8rem"; tsize=" text-2xl"; clssize = " gap-1 px-1 py-0"; }
    else if (size === '2xl') { iconsize="1.6rem"; tsize=" text-2xl"; clssize = " gap-1 px-1 py-0"; }
    else if (size ===  'xl') { iconsize="1.5rem"; tsize=" text-xl";  clssize = " gap-1 px-1 py-0"; }
    else if (size ===  'lg') { iconsize="1.4rem"; tsize=" text-lg";  clssize = " gap-1 px-1 py-0"; }
    else if (size ===  'md') { iconsize="1.3rem"; tsize=" text-md";  clssize = " gap-1 px-1 py-0"; }
    else if (size ===  'sm') { iconsize="1.2rem"; tsize=" text-sm";  clssize = " gap-1 px-[0.2rem] py-[0.08rem]"; }
    else                     { iconsize="1.1rem"; tsize=" text-sm";  clssize = " gap-1 px-[0.2rem] py-[0.08rem]"; }
    let clsFlex = " flex flex-row justify-start items-center gap-1 ";
    let clsRadio = (preview ? " invisible " : (disabled ? "" : (heartbeat ? " heartbeat2 " : ""))); 

    return (
        <label onClick={onClick} 
            className={clssize + clsFlex + (disabled ? " opacity-50 " : " ") + className} >

            { checked ? 
                <IconRadio1 width={iconsize} height={iconsize} className={clsRadio + " text-blue-700"}/>
            :
                <IconRadio0 width={iconsize} height={iconsize} className={clsRadio}/>
            }

            <span className={" text-" + size + font + ' ' + className}>{text}</span>

        </label>
    )
}


