import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import PzPop from "../../common/PzPop";
import EventTimer from "../../event/EventTimer";
import PollCard from "../../event/PollCard";
import EvtStaffList from "../../event/EvtStaffList";
import EvtVoterList from "../../event/EvtVoterList";
import EntranceForVoters from "../../entrance/EntranceForVoters";
import UserSplash from "../UserSplash";
import { ReactComponent as IconStepNext } from "../../resource/icons/pz-step-next.svg";
import { ReactComponent as IconStepPrev } from "../../resource/icons/pz-step-prev.svg";
import { ReactComponent as IconToolViewer } from "../../resource/icons/pz-tool-viewer.svg";
import { ReactComponent as IconToolEditor } from "../../resource/icons/pz-tool-editor.svg";

export default function InviteToEvent() {
    const [cookies] = useCookies();
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const navigate = useNavigate();
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const localeMap = useSelector(utils.selectLocaleMap);
    const [open4EntView, setOpen4EntView] = React.useState(false);
    const [open4EntPNG, setOpen4EntPNG] = React.useState(false);
    const [open4EntPDF, setOpen4EntPDF] = React.useState(false);
    const [vState, setVState] = React.useState({nt:0,np:0,ne:0,na:0,updated:dayjs(),folded:true});
    const vSummary = utils.completeMessageText(localeMap["poller.getready.voters." + (vState.nt===0 ? "sum0" : "sum1")], [vState.nt]);
    const TEXTSIZE = "text-lg";

    // React.useEffect(() => {
    //   uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    //   if (!uauth) navigate("/");
    // }, [cookies]);

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event."} /> );
    }  

    return (
        <PzArea.Container >
            <PzArea.TBoard >

                <div className="w-full px-5 flex flex-col justify-start items-start gap-4">
                    <PollCard.Item poll={upoll} size='lg' expandable={true} />
                    <div className="w-full flex flex-row justify-center items-center">
                        <EventTimer.WallClock poll={upoll} size='2xl' />
                    </div>
                </div>


                <h2>{localeMap["menu.poller.invite"]}</h2>

                <div className="w-full flex flex-col justify-start items-start gap-5">
                    <h3>{"이벤트 공지"}</h3>
                    <div className="w-full flex flex-row justify-center items-start">
                        <div className="w-fit flex flex-col justify-start items-center gap-5">
                            <PzButton.Std text={localeMap["staff.view.entrance.qr"]} size='xl' className="w-full" 
                                onClick={(e)=>{setOpen4EntView(true);}} />
                            <PzButton.Std text={localeMap["staff.view.entrance.png"]}  size='xl' className="w-full"/>
                            {/* <PzButton.Std text={localeMap["staff.view.entrance.pdf"]}  size='xl' className="w-full"/> */}
                        </div>
                    </div>
                </div>

                {/* Staff */}
                <EvtStaffList.Module poll={upoll} asPoller={true} />

                {/* Voters */}
                <EvtVoterList.Module poll={upoll} asPoller={true} />

                <div className="w-full flex flex-row justify-between items-center gap-1 mt-10">
                    <PzButton.Icon Icon={IconStepPrev} size='xl' onClick={()=>{navigate(-1);}}/>
                    <PzButton.Std  Icon={IconStepNext} text={localeMap["menu.poller.runpoll"]}  size='lg' 
                        onClick={()=>{navigate("/pages/poller/runpoll");}} 
                        disabled={!(EventTimer.isOpenable(upoll) || upoll?.pstatus?.startsWith('O'))} />
                </div>

            </PzArea.TBoard >

            { open4EntView && 
                <EntranceForVoters.Dialog poll={upoll} onClose={(e)=>{setOpen4EntView(false);}} />
            }

        </PzArea.Container >
    );
}
