import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import appConfig from "../../config/appConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import PzTable from "../../common/PzTable";
import UserSplash from "../UserSplash";

export default function PzAdminDashboard() {
    const navigate = useNavigate();
    const [cookies] = useCookies();
    const [pgOpts, setPgOpts] = React.useState({page:0,pageSize:10});
    const [pgData, setPgData] = React.useState({page:0,pageSize:10,pageData:[],total:0});
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const [tabValue, setTabValue] = React.useState(0);

    const fetchStatsList = (opts)=>{
        appConfig.getAPI().post("/api/pza/sttslist", opts)
        .then((response)=>{
            let pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            let pgdata = pzdata.resource; // {total:100, page:0, pageSize:10, pageData:[...]}
            setPgData(pgdata);
        })
        .catch((error)=>{
            console.log("PzAPI 'pza/sttslist' error : ", error);
        })
        .finally(()=>{});
    };

    React.useEffect(() => {
        if (!uauth?.ispza) { navigate("/"); return; }
        fetchStatsList(pgOpts);
    }, []);

    const onClickToTab = (event, newValue) => {
        setTabValue(newValue);
    };

    if (!uauth || !uauth.ispza) {
        return (<UserSplash msg={"Unauthorized access"} />);
    }  

    return (
        <PzArea.Container>
            <PzArea.AdminBoard >

                <h2>{"PzA - Dashboard"}</h2>

                <Tabs value={tabValue} onChange={onClickToTab} >
                    <Tab label="User"   id="tab-0" />
                    <Tab label="Poll"   id="tab-1" />
                    <Tab label="Survey" id="tab-2" />
                    <Tab label="Quiz"   id="tab-3" />
                    <Tab label="Delay"  id="tab-4" />
                </Tabs>

                <PzaTabPanel value={tabValue} index={0}>
                    <PzTable data={pgData} opts={pgOpts} setOpts={setPgOpts} fetchData={fetchStatsList} rowKey="ymd" searchable={true} 
                        columns={[
                            { title: "YMD", key: "ymd" },
                            { title: "uTry", key: "untry", align: "right" },
                            { title: "uSgn", key: "unsgn", align: "right" },
                            { title: "uLgn", key: "unlgn", align: "right" },
                            { title: "uDel", key: "undel", align: "right" },
                        ]}
                    />
                </PzaTabPanel>

                <PzaTabPanel value={tabValue} index={1}>
                    <PzTable data={pgData} opts={pgOpts} setOpts={setPgOpts} fetchData={fetchStatsList} rowKey="ymd" searchable={true} 
                        columns={[
                            { title: "YMD", key: "ymd" },
                            { title: "pCrt", key: "pncrt", align: "right" },
                            { title: "pStt", key: "pnstt", align: "right" },
                            { title: "pCls", key: "pncls", align: "right" },
                            { title: "pDel", key: "pndel", align: "right", hidden: false },
                            { title: "PmNMax", key: "pmnmax", align: "right" },
                            { title: "PsNMax", key: "psnmax", align: "right" },
                            { title: "PsNAtt", key: "psnatt", align: "right" },
                        ]}
                    />
                </PzaTabPanel>

                <PzaTabPanel value={tabValue} index={2}>
                    <PzTable data={pgData} opts={pgOpts} setOpts={setPgOpts} fetchData={fetchStatsList} rowKey="ymd" searchable={true} 
                        columns={[
                            { title: "YMD", key: "ymd" },
                            { title: "sCrt", key: "sncrt", align: "right" },
                            { title: "sStt", key: "snstt", align: "right" },
                            { title: "sCls", key: "sncls", align: "right" },
                            { title: "sDel", key: "sndel", align: "right", hidden: false },
                            { title: "smNMax", key: "smnmax", align: "right" },
                            { title: "ssNMax", key: "ssnmax", align: "right" },
                            { title: "ssNAtt", key: "ssnatt", align: "right" },
                        ]}
                    />
                </PzaTabPanel>

                <PzaTabPanel value={tabValue} index={3}>
                    <PzTable data={pgData} opts={pgOpts} setOpts={setPgOpts} fetchData={fetchStatsList} rowKey="ymd" searchable={true} 
                        columns={[
                            { title: "YMD", key: "ymd" },
                            { title: "qCrt", key: "qncrt", align: "right" },
                            { title: "qStt", key: "qnstt", align: "right" },
                            { title: "qCls", key: "qncls", align: "right" },
                            { title: "qDel", key: "qndel", align: "right", hidden: false },
                            { title: "qmNMax", key: "qmnmax", align: "right" },
                            { title: "qsNMax", key: "qsnmax", align: "right" },
                            { title: "qsNAtt", key: "qsnatt", align: "right" },
                        ]}
                    />
                </PzaTabPanel>

                <PzaTabPanel value={tabValue} index={4}>
                    <PzTable data={pgData} opts={pgOpts} setOpts={setPgOpts} fetchData={fetchStatsList} rowKey="ymd" searchable={true} 
                        columns={[
                            { title: "YMD", key: "ymd" },
                            { title: "MaxAcc", key: "xaccmax", align: "right",
                                render: ({xaccmax})=>{return(<p>{xaccmax > 0 ? xaccmax.toFixed(3) : ' '}</p>)} },
                            { title: "AvgAcc", key: "xaccsum", align: "right",
                                render: ({xaccsum, xaccnum})=>{return (<p>{xaccnum > 0 ? (xaccsum/xaccnum).toFixed(3) : ' '}</p>)} },
                            { title: "MaxVote", key: "xvotmax", align: "right",
                                render: ({xvotmax})=>{return(<p>{xvotmax > 0 ? xvotmax.toFixed(3) : ' '}</p>)} },
                            { title: "AvgVote", key: "xvotsum", align: "right" ,
                                render: ({xvotsum, xvotnum})=>{return (<p>{xvotnum > 0 ? (xvotsum/xvotnum).toFixed(3) : ' '}</p>)} },
                        ]}
                    />
                </PzaTabPanel>

                <div className="w-full flex flex-col justify-start items-center gap-5 mt-10">
                    <div className="w-full flex flex-row justify-between items-center gap-2">
                        <PzButton.Std text={"Users"}  size='md' onClick={()=>{navigate("/pages/pza/users");}} />
                        <PzButton.Std text={"Events"} size='md' onClick={()=>{navigate("/pages/pza/polls");}} />
                        <PzButton.Std text={"ArcEvents"} size='md' onClick={()=>{navigate("/pages/pza/archived");}} />
                        <PzButton.Std text={"SysEvents"} size='md' onClick={()=>{navigate("/pages/pza/sysevts");}} />
                    </div>
                    <div className="w-full flex flex-row justify-between items-center gap-2">
                        <PzButton.Std text={"Feedback"}  size='md' onClick={()=>{navigate("/pages/pza/feedback");}} />
                        <PzButton.Std text={"SysDesc"} size='md' onClick={()=>{navigate("/pages/pza/sysdesc");}} />
                        <PzButton.Std text={"SysLogs"} size='md' onClick={()=>{navigate("/pages/pza/syslogs");}} />
                    </div>
                </div>

            </PzArea.AdminBoard>
        </PzArea.Container>
    );
}

function PzaTabPanel({ children, value, index, ...other }) {
  
    return (
        <div role="tabpanel" hidden={value !== index}
            id={`tabpanel-${index}`} {...other} >
            {value === index && <div>{children}</div>}
        </div>
    );
}
  