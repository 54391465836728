import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
// import { getTimeInUserTimezone } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import PzTable, {PztShortNavigate, PztShortTooltip} from "../../common/PzTable";
import { PzaBasicModal } from "./components/PzaBasicModal";
import UserSplash from "../UserSplash";
import PollPqst from "../../event/PollPqst";
import PollPqstResult from "../../event/PollPqstResult";

export default function PzAdminPollInfo() {
    const location = useLocation();
    const pguid    = location?.state?.pguid;
    const archived = Boolean(location?.state?.archived);
    const [pinfo, setPInfo] = React.useState({uguid: '', pguid: pguid, pqstlist:[]});
    const navigate = useNavigate();
    const [cookies] = useCookies();
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const [modalOpen, setModalOpen] = React.useState(false);
    const utzone = useSelector((state)=>state.ucfg.timezone);  // timezone from user settings
    const [vPgOpts, setVPgOpts] = React.useState({page:0,pageSize:10,pguid:pguid});
    const [vPgData, setVPgData] = React.useState({page:0,pageSize:10,pageData:[],total:0});
    const [sPgOpts, setSPgOpts] = React.useState({page:0,pageSize:10,pguid:pguid});
    const [sPgData, setSPgData] = React.useState({page:0,pageSize:10,pageData:[],total:0});

    React.useEffect(() => {
        if (!uauth?.ispza) { navigate("/"); return; }
        fetchPollInfo();
        fetchVoterList(vPgOpts);
        fetchStaffList(sPgOpts)
    }, []);

    const fetchPollInfo = ()=>{
        if (!uauth?.ispza || !pguid) return;
        appConfig.getAPI().post("/api/pza/pollinfo", {"pguid":pguid, "archived":archived})
        .then((response)=>{
            // console.log("PzAPI 'pza/pollinfo' fetched : ", response.data);
            let pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            setPInfo(pzdata.resource);
        })
        .catch((error)=>{
            console.log("PzAPI 'pza/pollinfo' error : ", error);
        })
        .finally(()=>{});
    };
    
    const fetchVoterList = (opts)=>{
        if (!uauth?.ispza || !pguid || archived) return;
        appConfig.getAPI().post("/api/pza/voterlist", {...opts, pguid:pguid})
        .then((response) => {
            // console.log("PzApi 'voterlist' responded : ", response.data);
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // {total:108, page:1, pageSize:10, pageData:[...]}
            setVPgData(pgdata);
        })
        .catch((error) => {
            console.log("PzAPI 'voterlist' error : ", error);
        })
        .finally(() => {});
    };

    const fetchStaffList = (opts)=>{
        if (!uauth?.ispza || !pguid || archived) return;
        appConfig.getAPI().post("/api/pza/stafflist", {...opts, pguid:pguid})
        .then((response) => {
            // console.log("PzApi 'stafflist' responded : ", response.data);
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // {total:108, page:1, pageSize:10, pageData:[...]}
            setSPgData(pgdata);
        })
        .catch((error) => {
            console.log("PzAPI 'stafflist' error : ", error);
        })
        .finally(() => {});
    };

    if (!uauth || !uauth.ispza) {
        return (<UserSplash msg={"Unauthorized access"} />);
    }  

    return (
        <PzArea.Container>
            <PzArea.AdminBoard >

                <h2>{`PzA - ${archived ? 'Archived ' : ''}Event Info`}</h2>

                <div className="grid grid-cols-[100px_1fr] gap-x-5 gap-1 text-sm">
                    <h5>PGuid</h5>    <p>{pinfo?.pguid}</p>
                    <h5>Poller</h5>    
                    <div className="flex flex-row">
                        <PzButton.ToNavigate to="../userinfo" state={{uguid:pinfo?.uguid}}>{pinfo?.uguid}</PzButton.ToNavigate>
                        <label>&emsp;'{pinfo?.uname}'</label>
                    </div>
                    <h5>Title</h5>    <p>{pinfo?.ptitle || 0}</p>
                    <h5>When</h5>     <p>{utils.getTimeInUserTimezone(pinfo.pwhen, "YYYY/MM/DD HH:mm:ss", utzone, true)}</p>
                    <h5>Type</h5>  
                    <div className="grid grid-cols-[45px_1fr_45px_1fr] gap-1">
                        <p>Kind:</p> <p>{pinfo?.pkind}</p>
                        <p>Status:</p> <p>{pinfo?.pstatus}</p>
                    </div>
                    <h5>Country</h5>    <p>{pinfo?.country || 0}</p>
                    <h5>Locale</h5>    <p>{pinfo?.locale || 0}</p>
                    <h5>Plan Time</h5>  
                    <div className="grid grid-cols-[60px_1fr_60px_1fr] gap-1">
                        <p>Created:</p>  <p>{utils.getTimeInUserTimezone(pinfo.createdat, "MM/DD HH:mm:ss", utzone, true)}</p>
                        <p>Updated:</p>  <p>{utils.getTimeInUserTimezone(pinfo.updatedat, "MM/DD HH:mm:ss", utzone, true)}</p>
                    </div>
                    <h5>Run Time</h5>  
                    <div className="grid grid-cols-[60px_1fr_60px_1fr] gap-1">
                        <p>Opened:</p>  <p>{utils.getTimeInUserTimezone(pinfo.openedat, "MM/DD HH:mm:ss", utzone, true)}</p>
                        <p>Closed:</p>  <p>{utils.getTimeInUserTimezone(pinfo.closedat, "MM/DD HH:mm:ss", utzone, true)}</p>
                    </div>
                    <h5>Questions</h5> 
                    <PzTable data={pinfo.pqstlist} rowKey="qguid" configurable={true}
                        columns={[
                            { title: 'QGuid', key: 'qguid',
                                render: ({qguid}) => {return (<PztShortNavigate value={qguid} to="../qstinfo" state={{qguid:qguid,archived:archived}} stt={8} end={40} />)} },
                            { title: 'Seq', key: 'qseq' },
                            { title: 'Kind', key: 'qkind' },
                            { title: 'Status', key: 'qstatus', hidden:false },
                            { title: 'Question', key: 'question' },
                            { title: 'Choice', key: 'nwanted', render: ({nwanted, nanswers}) => {return (<>{nwanted} / {nanswers}</>)} },
                            { title: 'Round', key: 'qround', render: ({qround, qroundmax}) => {return (<>{qround} / {qroundmax}</>);} },
                        ]} />
                    <h5>Voters</h5>  
                    <div className="grid grid-cols-[auto_1fr_auto_1fr_auto_1fr] gap-1">
                        <p>NElg:</p> <p>{pinfo?.nelg}</p>
                        <p>NMax:</p> <p>{pinfo?.nmax}</p>
                        <p>NAtt:</p> <p>{pinfo?.natt}</p>
                    </div>
                    { !archived && <>
                        <h5>&nbsp;</h5>
                        <PzTable 
                            data={vPgData} opts={vPgOpts} setOpts={setVPgOpts} fetchData={fetchVoterList}
                            rowKey="vid" searchable={true} configurable={true} 
                            columns={[
                                { title: 'ID', key: 'vid',
                                    render: ({vid})=>{return (<PztShortTooltip value={vid} stt={vid.length-14} end={vid.length}/>);} },
                                { title: 'Name', key: 'vname' },
                                { title: 'VCode', key: 'vcode', hidden:true },
                                { title: 'BDate', key: 'vbdate', hidden:true },
                                { title: 'Phone', key: 'vphone', hidden:true },
                                { title: 'Email', key: 'vemail', hidden:true },
                                { title: 'Status', key: 'vstatus', hidden:false },
                                { title: 'CheckIn', key: 'checkin', hidden:false, datetime:'MM/DD HH:mm:ss' },
                                { title: 'LastVote', key: 'lastvote', hidden:false, datetime:'HH:mm:ss' },
                                { title: 'CheckOut', key: 'checkout', hidden:false, datetime:'HH:mm:ss' },
                            ]}  />
                    </>}
                    { !archived && <>
                        <h5>Staff</h5>
                        <PzTable 
                            data={sPgData} opts={sPgOpts} setOpts={setSPgOpts} fetchData={fetchStaffList}
                            rowKey="uemail" searchable={true} configurable={true} 
                            columns={[
                                { title: 'Name', key: 'uname' },
                                { title: 'Email', key: 'uemail' },
                                { title: 'Role', key: 'role',
                                    render: ({role})=>{return (<p>{role==='M' ? 'Manager' : 'Staff'}</p>)} },
                                { title: 'CreatedAt', key: 'createdat', datetime:'YYYY/MM/DD HH:mm', hidden:true },
                                { title: 'CheckedAt', key: 'checkedat', datetime:'YYYY/MM/DD HH:mm' },
                                ]}  />
                    </>}

                    { pinfo.pstatus < 'Xc' ?
                        <>
                            <div>&nbsp;</div> <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div className="w-full flex flex-col justify-start items-center gap-2">
                                <PollPqst.List poll={pinfo} />
                            </div>
                        </>
                    :
                        <>
                            <div>&nbsp;</div> <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div className="w-full flex flex-col justify-start items-center gap-2">
                                <PollPqstResult.List poll={pinfo} initSelected={pinfo.pqstlist[0].qguid} />
                            </div>
                        </>
                    }

                </div>
                <div className="flex flex-row justify-between">
                    <PzButton.Std text='pinfo' size='sm' bold={false} Click={()=>console.log(pinfo)} />
                </div>

            </PzArea.AdminBoard>

            {modalOpen && (
                <PzaBasicModal onClick={() => setModalOpen(false)} onKeyDown={() => setModalOpen(false)} width={370} height={220}>
                    <h3 style={{ lineHeight: 1.8 }}>라이센스 삭제</h3>
                    <h5 style={{ lineHeight: 1.4 }}>라이센스를 정말 삭제하시겠습니까?</h5>
                    <button
                        className="basic-button mt-3"
                        // onClick={() => deleteLicense({ license_key: location?.state?.licenseKey })}
                    >
                        삭제하기
                    </button>
                </PzaBasicModal>
            )}

        </PzArea.Container>
    );
}
