import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzInput from "../../common/PzInput";
import PzPop from "../../common/PzPop";

export default function PricingPolicy() {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [cookies] = useCookies(['uauth']);
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const utzone = useSelector((state)=>state.ucfg.timezone); // timezone from user settings
    const navigate = useNavigate();
    const [nVoters, setNVoters] = React.useState(10);
    const [nQuests, setNQuests] = React.useState(3);
    const [sel4OnInv, setSel4OnInv] = React.useState([]);
    const fqmax = appConfig.getFQMax();
    const [balance, setBalance] = React.useState({ fq: { qtrem: fqmax, qtmax: fqmax, qtlast: '' } });
    const now = dayjs();
    const next_month_in_utc = now.utc().add(1, 'month').date(1).hour(0).minute(0)
    const next_refill = utils.getTimeInUserTimezone(next_month_in_utc, "YYYY/MM/DD h:mm A", utzone) ;
    const fqinf = appConfig.getFQInf();
    const fqUsable = appConfig.isFreeQuotaUsable(nVoters, nQuests, balance.fq.qtrem, sel4OnInv.includes('online') ? 'online' : 'offline');
    const alternatives = [
        { name: "Doodle", url: "https://doodle.com/en/premium?currency=USD" }, 
        { name: "eBallot", url: "https://www.eballot.com/pricing" },
        { name: "Election Buddy", url: "https://electionbuddy.com/pricing/" },
        { name: "Election Runner", url: "https://electionrunner.com/pricing" },
        { name: "OpaVote", url: "https://opavote.com/pricing" },
        { name: "Poll Everywhere", url: "https://www.polleverywhere.com/plans" },
        { name: "Simply Voting", url: "https://www.simplyvoting.com/pricing/" },
        { name: "V8TE", url: "https://www.v8te.com/en/pricing/associations" },
        { name: "VidaLoop", url: "https://vidaloop.com/products/votingapp.html" },
        { name: "Voatz", url: "https://voatz.com/security-and-technology/" },
        { name: "VoxVote", url: "https://www.voxvote.com/Products" },
        { name: "and many more...", url: "" },
    ]

    let mfactor = (nQuests <= 0 ? 0.0 : nQuests <= 3 ? 1.0 : (1.0 + (nQuests-3) * 0.1));

    React.useEffect(() => {
        if (!uauth) return;
        fetchMyBalance();
    }, []);

    function fetchMyBalance() {
        if (!uauth) return;
        // fetch user account information (Free Quota balance)
        appConfig.getAPI().post("/api/my/balance")
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            setBalance(pzdata.resource);
        })
        .catch((error) => {
            console.log("PzAPI 'balance' error : ", error);
        })
        .finally(() => {
        });
    }

    return (
        <PzArea.Container>
            <PzArea.ABoard className="gap-12" >

                <label className={"text-3xl font-extrabold"}>{localeMap["home.page.pricing.title"]}</label>

                <div className="w-full max-w-[44rem] px-0 flex flex-col gap-10">

                    <div className="flex flex-col justify-start gap-0">
                        <div className="flex flex-row justify-start items-center ">
                            <label className="text-2xl font-bold italic text-left">{'We are committed to'}&nbsp;</label>
                            <label className="text-2xl font-bold italic text-left hover:cursor-pointer" onClick={()=>{navigate("/about/mission");}}>Our Mission</label>
                            <label className="text-2xl font-bold italic text-left">,</label>
                        </div>
                        <label className="text-2xl font-bold italic text-right">and do our best to lower the cost of our users.</label>
                    </div>

                    <div className="flex flex-col gap-4">
                        {/* SERVICE PRICE */}
                        <label className="w-full text-2xl font-bold text-left">{localeMap["about.pricing.sp.title"]}</label>

                        <div className="grid grid-cols-[20rem_auto_auto] justify-start items-start gap-x-4 gap-y-1">
                            <label className={"text-xl"}>{localeMap["about.pricing.sp.basic"]} (BP)</label>
                            <label className={"text-xl"}>:</label>
                            <p className={"text-xl"}><b>{appConfig.getPricePerVoter(true, true)}</b> &nbsp; / voter</p>

                            <label className={"text-xl"}>{localeMap["about.pricing.sp.extra"]} (EP)</label>
                            <label className={"text-xl"}>:</label>
                            <p className={"text-xl"}><b>{appConfig.getPriceToInvite(true, true)}</b> &nbsp; / voter</p>

                            <label className={"text-xl"}>{localeMap["about.pricing.sp.mfactor"]} (MF)</label>
                            <label className={"text-xl"}>:</label>
                            <p className={"text-xl"}>{'1.0 + ([nQ-3] × 0.1)'}</p>

                            <label className={"text-xl"}>{localeMap["about.pricing.sp.formula"]}</label>
                            <label className={"text-xl"}>:</label>
                            <p className={"text-xl"}>{'nV × (BP + EP) × MF'}</p>
                        </div>
                        <div className="w-full max-w-[32rem] flex flex-col justify-start items-start gap-x-4 gap-y-1">
                            <label className="text-xl text-center opacity-50">
                                {`As of ${dayjs().format('YYYY/MM/DD')}, we are in the middle of the Beta Test. Our pricing policy will be evaluated later and can be changed at the official service launch of Pollerz.net.`}
                            </label>
                        </div>
                    </div>

                    <div className="flex flex-col gap-4">
                        {/* FREE QUOTA */}
                        <label className="w-full text-2xl font-bold text-left">{localeMap["about.pricing.fq.title"]}</label>

                        <div className="grid grid-cols-[20rem_auto_auto] justify-start items-center gap-x-4 gap-y-1">
                            <label className={"text-xl"}>{localeMap["about.pricing.fq.forall"]}</label>
                            <label className={"text-xl"}>:</label>
                            <p className={"text-xl"}><b>{appConfig.getFQMax()}</b> voters</p>

                            { uauth && <>

                            <label className={"text-xl"}>{localeMap["about.pricing.fq.qtrem"]}</label>
                            <label className={"text-xl"}>:</label>
                            <p className={"text-xl"}><b>{balance.fq.qtrem === appConfig.getFQInf() ? '∞' : balance.fq.qtrem}</b> voters</p>

                            <label className={"text-xl"}>{localeMap["about.pricing.fq.qtmax"]}</label>
                            <label className={"text-xl"}>:</label>
                            <p className={"text-xl"}><b>{balance.fq.qtmax === appConfig.getFQInf() ? '∞' : balance.fq.qtmax}</b> voters</p>

                            <label className={"text-xl"}>{localeMap["about.pricing.fq.refill"]}</label>
                            <label className={"text-xl"}>:</label>
                            <p className={"text-xl"}><b>{next_refill}</b></p>

                            </>}
                        </div>
                    </div>

                    <div className="flex flex-col gap-4">
                        {/* COST CALCULATOR */}
                        <label className="w-full text-2xl font-bold text-left">{localeMap["about.pricing.cc.title"]}</label>

                        <div className="w-full flex flex-row justify-start items-center gap-10">
                            <div className="w-fit flex flex-row justify-start items-center gap-2">
                                <PzInput.ForInt value={nVoters} setValue={setNVoters} 
                                    min={0} max={1000000} className="w-[6rem] text-xl font-bold" />
                                <label className={"text-xl"}>{localeMap["about.pricing.cc.voter"]} (nV),</label>
                            </div>
                            <PzInput.Checkbox2 size='xl' value={'online'} 
                                text={sel4OnInv.includes('online') ? localeMap["about.pricing.cc.online"] : localeMap["about.pricing.cc.offline"]} 
                                selection={sel4OnInv} setSelection={setSel4OnInv} />
                        </div>
                        <div className="w-full flex flex-row justify-start items-center gap-10">
                            <div className="w-fit flex flex-row justify-start items-center gap-2">
                                <PzInput.ForInt value={nQuests} setValue={setNQuests} 
                                    min={0} max={100} className="w-[6rem] text-xl font-bold" />
                                <label className={"text-xl"}>{localeMap["about.pricing.cc.quest"]} (nQ),</label>
                            </div>
                            <label className={"text-xl"}>{`MF = ${mfactor.toFixed(1)}`}</label>
                        </div>
                        <div className="grid grid-cols-[20rem_auto_auto] justify-start items-center gap-x-4 gap-y-1">

                            <label className={"text-xl"}>{localeMap["about.pricing.cc.ested"]}</label>
                            <label className={"text-xl"}>:</label>
                            <PzPop.Popover 
                                base={
                                    <p className={"text-xl font-bold hover:cursor-pointer"}>
                                        {appConfig.getTotalPrice(nVoters, nQuests, sel4OnInv.includes('online') ? 'online' : 'offline', true, true)}
                                    </p>
                                }
                                popover={
                                    <div className={"px-4 py-2 text-xl text-right flex flex-col gap-1"}>
                                        <label className="text-xl">
                                            {'nV × (BP + EP) × MF'} &nbsp; = &nbsp; 
                                        </label>
                                        <label className="text-xl">
                                            {appConfig.getPriceFormula(nVoters, nQuests, sel4OnInv.includes('online') ? 'online' : 'offline')}
                                        </label>
                                    </div>
                                } ha='center' offset='4px' />

                            <label className={"text-xl"}>{localeMap["about.pricing.cc.usefq"]}</label>
                            <label className={"text-xl"}>:</label>
                            <p className={"text-xl font-bold"}>
                                { fqUsable ? 'YES' : 'NO' }
                            </p>

                            <label className={"text-xl"}>{localeMap["about.pricing.cc.final"]}</label>
                            <label className={"text-xl"}>:</label>
                            <p className={"text-xl font-bold"}>
                                { fqUsable ?
                                    appConfig.getFormattedPrice(0, true)
                                :
                                    appConfig.getTotalPrice(nVoters, nQuests, sel4OnInv.includes('online') ? 'online' : 'offline', true, true)
                                }
                            </p>
                        </div>
                        <div>&nbsp;</div>
                    </div>

                    <div className="flex flex-col gap-4">
                        {/* COMPARISON */}
                        <label className="w-full text-2xl font-bold text-left">{localeMap["about.pricing.cm.title"]}</label>

                        <div className="w-auto max-w-[30rem] flex flex-wrap justify-center items-center gap-x-5 gap-y-2">
                            { alternatives.map((alt,idx)=>{ return (
                                alt.url ?
                                    <label key={idx} className="text-xl hover:cursor-pointer" 
                                        onClick={()=>{
                                            const win = window.open(alt.url, "");
                                            if (win != null) win.focus();
                                        }}>
                                        {alt.name}
                                    </label>
                                :
                                    <label key={idx} className="text-xl">{alt.name}</label>
                            )}) }
                        </div>
                    </div>

                </div>

            </PzArea.ABoard>
        </PzArea.Container>
    );
}

