import React from "react";

import TextField from '@mui/material/TextField';
import TextareaAutosize from 'react-textarea-autosize';

const PzText = {
    Field:      PzTextField,
    Touch:      PzTextTouch,
};
export default PzText;


function PzTextField({placeholder='', onEnter=(text)=>{}}) {

    return (
        <TextField 
            size="small" 
            placeholder={placeholder} // 
            sx={{ width: 200 }}
            inputProps={{ style: { padding: 0 } }}
            InputProps={{ style: { padding: 5 }, sx: { borderRadius: 1 } }} 
            onEditing={(e)=>{}}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    onEnter(e.target.value);
                }
            }}
        />
    )
}

function PzTextTouch({name, text, setText, size='lg', bold=true, className='', 
    placeholder='', maxLength=256, editable=false, truncate=true, 
    callToEditChange=undefined, setFocusLater=false}) {

    const TEXTSIZE = 'text-' + size;
    const CLASSNAMES = ` ${bold ? 'font-bold' : ''} `;
    const CLASSBORDER = ` px-1.5 py-0.5 border-2 rounded-sm `;
    const [editing, setEditing] = React.useState(false);
    if (!editable && editing) setEditing(false);

    React.useEffect(() => {
        if (setFocusLater) {
            setTimeout(()=>{ setEditing(true); }, 1000); // wait 1 second
        }
    }, []);

    function onClickToLabel(e) {
        // console.log('PzText.Touch onClickToLabel:', editable, editing);
        if (editable && !editing) {
            setEditing(true);
            if (typeof callToEditChange === 'function') callToEditChange('start');
            e.stopPropagation();
        } else {
            //
        }
    }

    function onChangeDone(e) {
        e.stopPropagation();
        let new_text = e.target.value;
        if (new_text != text) {
            if (typeof setText  === 'function') setText(new_text);
            if (typeof callToEditChange === 'function') callToEditChange('end', new_text);
        }
        setEditing(false);
        // console.log('onChangeDone()', new_text);
    }

    return ( !editing ?
        <label onClick={onClickToLabel} 
            className={TEXTSIZE + CLASSNAMES + (truncate ? ' truncate ' : '') + ' hover:cursor-pointer ' + className} >
            {text}
        </label>
    :
        <TextareaAutosize 
            name={name} className={TEXTSIZE + CLASSNAMES + CLASSBORDER + ' autoFocus w-[90%] ' + className} 
            tabIndex={-1} autoFocus={true} autoComplete={-1} rows={1}
            maxLength={maxLength} placeholder={placeholder} 
            defaultValue={text}
            // value={text} onEditing={onChange}
            onKeyDown={(e)=>{if (e.key==='Enter' && typeof onChangeDone === 'function') onChangeDone(e);}}
            onBlur={(e)=>{ if (typeof onChangeDone === 'function') onChangeDone(e); }}
            onFocus={(e)=>{e.target.select();}}
        />
    );
}

