import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzArea from "../common/PzArea";
import PzButton from "../common/PzButton";
import PzDialog from "../common/PzDialog";
import UserFeedback from "../pages/home/UserFeedback";

export default function PzBottombar({className=''}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [cookies] = useCookies(['uauth']);
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const navigate = useNavigate();
    const [open4Feedback, setOpen4Feedback] = React.useState(false);
    const [open4NotAvail, setOpen4NotAvail] = React.useState(false);
    const SIZE = 'md';

    return (
        <>
        <PzArea.Container>
            <PzArea.FooterBoard className={className}>

                <div className="w-full px-5 grid grid-cols-[1fr_1fr_1fr_1fr] justify-start items-start gap-x-2 opacity-80">

                    <div className="w-auto h-full flex flex-col justify-start items-start gap-1">
                        <label className='text-2xl font-extrabold hover:cursor-pointer' onClick={()=>{navigate('/');}}>Pollerz.net</label>
                    </div>

                    <div className="w-auto h-full flex flex-col justify-start items-end gap-0">
                        <label className={"text-"+SIZE + " font-extrabold"}>Product</label>
                        <PzButton.NavMenu text={'Features'} path={'/about/features'} size={SIZE} disabled={false} />
                        <PzButton.NavMenu text={'Pricing'} path={'/about/pricing'} size={SIZE} disabled={false} />
                        <PzButton.NavMenu text={'What\'s New'} path={'/about'} size={SIZE} disabled={true} />
                        <PzButton.NavMenu text={'Roadmap'} path={'/about'} size={SIZE} disabled={true} />
                    </div>

                    <div className="w-auto h-full flex flex-col justify-start items-end gap-0">
                        <label className={"text-"+SIZE + " font-extrabold"}>Help</label>
                        <PzButton.NavMenu text={'Get Started'} path={'/about'} size={SIZE} disabled={true} />
                        <PzButton.NavMenu text={'Help Center'} path={'/about'} size={SIZE} disabled={true} />
                        <PzButton.NavMenu text={'Report Abuse'} path={'/about'} size={SIZE} disabled={true} />
                        <PzButton.NavMenu text={'Give us Feedback'} size={SIZE} disabled={false} 
                            callback={()=>{ if (uauth) setOpen4Feedback(true); else setOpen4NotAvail(true); }} />
                    </div>
                    
                    <div className="w-auto h-full flex flex-col justify-start items-end gap-0">
                        <label className={"text-"+SIZE + " font-extrabold"}>About us</label>
                        <PzButton.NavMenu text={'Our Mission'} path={'/about/mission'} size={SIZE} disabled={false} />
                        <PzButton.NavMenu text={'Privacy Policy'} path={'/about/privacy'} size={SIZE} disabled={false} />
                        <PzButton.NavMenu text={'Business Inquiry'} path={'/about'} size={SIZE} disabled={true} />
                    </div>
                    
                </div>

            </PzArea.FooterBoard>
        </PzArea.Container>

            { open4Feedback &&
                <UserFeedback.Dialog onClose={()=>{setOpen4Feedback(false);}} />
            }
            { open4NotAvail &&
                <PzDialog.Simple className="" onClose={()=>{setOpen4NotAvail(false);}}
                    message={localeMap["about.login.required"]} onButtonOk={()=>{setOpen4NotAvail(false);}} />
            }

        </>
    );
}

function PzBarNavLink({text, path}) {
    const navigate = useNavigate();
    return (
        <label className='text-sm hover:cursor-pointer' 
            onClick={()=>{if (path) navigate(path);}}>
            {text}
        </label>
    );
}
