import React from "react";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import { utils } from "../config/userConfig";
import PzButton from "../common/PzButton";
import EntranceForVoters from "../entrance/EntranceForVoters";
import EMComQstOrder from "./EMComQstOrder";
import EMModals from "./EMModals";
import { ReactComponent as IconQRCode } from "../resource/icons/pz-qrcode.svg";

export default function EMAreaHeader({poll, className='', asVoter=false, size='3xl'}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const pstatus = (poll ? poll.pstatus : ' ');
    const pqst = (poll?.qactive >= 0 && poll?.pqstlist ? poll?.pqstlist[poll?.qactive] : null);
    const [cookies, removeCookie] = useCookies(['vauth']);
    const [vauth, setVAuth] = React.useState(cookies && cookies.vauth ? jwtDecode(cookies.vauth) : null);
    const [open4EvtInfo, setOpen4EvtInfo] = React.useState(false);
    const [open4Voters, setOpen4Voters] = React.useState(false);
    const [open4Entrance, setOpen4Entrance] = React.useState(false);

    function showVoterList() {
        if (!asVoter) {
            setOpen4Voters(true);
        }
    }

    const   TEXTSIZE = 'text-' + size;
    const  STEXTSIZE = 'text-' + utils.getSizeRelative(size,-1);
    const SSTEXTSIZE = 'text-' + utils.getSizeRelative(size,-2);
    const SSSIZE = utils.getSizeRelative(size,-2);

    return (
        <div className={" w-full flex flex-col justify-center items-stretch gap-2 " + className}>

            <label className={TEXTSIZE + " pb-2 font-bold text-center hover:cursor-pointer"} 
                onClick={()=>{console.log(poll); setOpen4EvtInfo(true)}}>
                {poll.ptitle}
            </label>

            <div className="w-full flex flex-row justify-center items-center">
            <div className="w-auto grid grid-cols-[1fr_auto_auto_auto_1fr] justify-center items-stretch gap-5">
                {/* Poller */}
                <div className="flex flex-col justify-start items-center gap-1">
                    <label className={SSTEXTSIZE + " font-bold"}>{utils.completeLocaleMapText(localeMap, "monitor.event.poller.label", [], poll.pkind)}</label>
                    <label className={STEXTSIZE + " font-bold"}>{poll.uname}</label>
                </div>
                {/* Entrance */}
                { !asVoter && 
                    <>
                    <div className="w-[1px] bg-slate-300"></div>
                    <div className="flex flex-col justify-center items-center gap-1">
                        <button onClick={()=>{setOpen4Entrance(true);}} className='cursor-pointer' tabIndex='-1'>
                            <IconQRCode width='1.5rem' height='1.5rem' tabIndex='-1' />
                        </button>
                    </div>
                    </>
                }
                {/* Voters */}
                <div className="w-[1px] bg-slate-300"></div>
                { pstatus <= 'Oo' ?
                    <div className="flex flex-col justify-start items-center gap-1" onClick={showVoterList} >
                        <label className={SSTEXTSIZE + " font-bold"}>{utils.completeLocaleMapText(localeMap, "monitor.event.eligibles.label", [], poll.pkind)}</label>
                        <label className={STEXTSIZE  + " font-bold"}>{poll.nmax}</label>
                    </div>
                :
                    <div className="flex flex-col justify-start items-center gap-1" onClick={showVoterList} >
                        <label className={SSTEXTSIZE + " font-bold"}>{utils.completeLocaleMapText(localeMap, "monitor.event.attendee.label", [], poll.pkind)}</label>
                        <PzButton.Minimal><label className={STEXTSIZE + " font-bold"}>{poll.natt} / {poll.nmax}</label></PzButton.Minimal>
                    </div>
                }
                {/* Voter Checked-in */}
                { asVoter && 
                    <>
                    <div className="w-[1px] bg-slate-300"></div>
                    <div className="flex flex-col justify-start items-center gap-1">
                        <label className={SSTEXTSIZE + " font-bold"}>{utils.completeLocaleMapText(localeMap, "monitor.event.myname.label", [], poll.pkind)}</label>
                        <label className={STEXTSIZE  + " font-bold overflow-clip"}>{vauth.vname}</label>
                    </div>
                    </>
                }
            </div>
            </div>

            <EMComQstOrder poll={poll} className="pt-1" size={SSSIZE} invisible={pstatus < 'Os' || pstatus >= 'Oz' || !pqst} />

            {open4EvtInfo && 
                <EMModals.EventInfo poll={poll} onClose={()=>{setOpen4EvtInfo(false);}} />
            }
            {open4Voters && 
                <EMModals.VoterList poll={poll} onClose={()=>{setOpen4Voters(false);}} />
            }
            {open4Entrance && 
                <EntranceForVoters.Dialog poll={poll} onClose={()=>{setOpen4Entrance(false);}} />
            }
        </div>
    );
}

