import React from 'react';
import Popover from '@mui/material/Popover';

import { ReactComponent as IconQuestion } from "../resource/icons/pz-circled-question.svg";

const PzPop = {
    Popover:    PzPopPopover,
    Dropdown:   PzPopDropdown,
    HelpMsg:    PzPopHelpMsg,
};
export default PzPop;


function PzPopPopover ({base, popover, ha='right', offset='0px'}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleOpen  = (event) => { if (popover) setAnchorEl(event.currentTarget); };
    const handleClose = (event) => { setAnchorEl(null); };
    const open = Boolean(anchorEl);

    return (
        <div>
            <div className="w-auto" 
                onMouseEnter={handleOpen} onMouseLeave={handleClose}  >
                {base}
            </div>
            <Popover open={open} anchorEl={anchorEl} onClose={handleClose} 
                sx={{ pointerEvents: 'none' }}  style={{transform: `translate(0,-${offset})`}}
                anchorOrigin={{vertical:'top',horizontal:(typeof ha === 'string' ? ha : 'right')}} 
                transformOrigin={{vertical:'bottom',horizontal:(typeof ha === 'string' ? ha : 'right')}}
                disableRestoreFocus >
                {popover}
            </Popover>
        </div>
    );
}

function PzPopDropdown ({base, popover, ha='right', offset='0px', upward=false, open=false, setOpen=null}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open2, setOpen2] = React.useState(open);
    const onClick = (e) => { setAnchorEl(e.currentTarget); updateOpen(!open2);  e.stopPropagation(); };
    const onClose = (e) => { setAnchorEl(null);            updateOpen(false);   if (open2) e.stopPropagation(); };
    const aOrigin = (upward ? 'top' : 'bottom');
    const tOrigin = (upward ? 'bottom' : 'top');
    if (setOpen && open != open2) setOpen2(open);  // synchronize it, if 'open' had changed

    function updateOpen(newvalue) { 
        // We just want to make  'open'  & 'setOpen'  to be OPTIONAL.
        // That's why we defined 'open2' & 'setOpen2' again !
        setOpen2(newvalue); 
        if (typeof setOpen === 'function') setOpen(newvalue); 
    }

    return (
        <div>
            <div className="w-auto" onClick={onClick}>
                {base}
            </div>
            <Popover open={open2} anchorEl={anchorEl} onClose={onClose} 
                style={{transform: `translate(0,-${offset})`}} 
                anchorOrigin={{vertical:aOrigin,horizontal:(typeof ha === 'string' ? ha : 'right')}} 
                transformOrigin={{vertical:tOrigin,horizontal:(typeof ha === 'string' ? ha : 'right')}}>
                {popover}
            </Popover>
        </div>
    );
}

function PzPopHelpMsg ({msg, size='lg', ha='center', invisible=false, className=''}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleOpen = (event) => { if (msg && !invisible) setAnchorEl(event.currentTarget); };
    const handleClose = (event) => { setAnchorEl(null); };
    const open = Boolean(anchorEl);

    let iconsize = " ", padsize = " ";
    if      (size === '3xl') { iconsize = "2.25rem "; padsize = "px-3 py-3 "; }
    else if (size === '2xl') { iconsize = "1.87rem "; padsize = "px-3 py-3 "; }
    else if (size === 'xl')  { iconsize = "1.50rem "; padsize = "px-3 py-3 "; }
    else if (size === 'lg')  { iconsize = "1.25rem "; padsize = "px-2 py-2 "; }
    else if (size === 'md')  { iconsize = "1.12rem "; padsize = "px-2 py-2 "; }
    else if (size === 'sm')  { iconsize = "1.00rem "; padsize = "px-1 py-1 "; }
    else if (size === 'xs')  { iconsize = "0.87rem "; padsize = "px-[0.1rem] py-[0.1rem] "; }
    else if (size === '2xs') { iconsize = "0.75rem "; padsize = "px-[0.1rem] py-[0.1rem] "; }
    else if (size.endsWith('rem')) iconsize = size;

    return (
        <div>
            <div className={"w-fit"}
                onMouseEnter={handleOpen} onMouseLeave={handleClose}  >
                <div className="w-fit p-1 hover:cursor-pointer rounded-3xl ">
                    <IconQuestion width={iconsize} height={iconsize} />
                </div>
            </div>
            <Popover open={open} anchorEl={anchorEl} 
                sx={{ pointerEvents: 'none' }}
                anchorOrigin={{vertical:'top',horizontal:(typeof ha === 'string' ? ha : 'center')}} 
                transformOrigin={{vertical:'bottom',horizontal:(typeof ha === 'string' ? ha : 'center')}}
                onClose={handleClose} disableRestoreFocus >
                <div className={"px-2 py-1 text-"+size}>{msg}</div>
            </Popover>
        </div>
    );
}


