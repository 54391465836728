import React from 'react';

import appConfig from "../config/appConfig";
import {utils} from "../config/userConfig";
import PzInput from "./PzInput";
import PzPop from "./PzPop";

const PzSetting = {
    C2Win:  PzSettingC2Win,
    C2Drop: PzSettingC2Drop,
    NVote:  PzSettingNVote,
    VDist:  PzSettingVDist,
};

export default PzSetting;

export function PzSettingC2Win({criteria, setCriteria=()=>{}, size='sm', className='', disabled=false, locale='en'}) {
    if (!criteria) criteria = 'Most';
    const localeMap = utils.selectMapByLocale(locale);
    const [selection, setSelection] = React.useState([criteria]);
    // const [olist, vlist] = getOpAndValues();
    // const [oidx2, vidx2] = getOpAndValueIndex();
    // const [oidx, setOidx] = React.useState(oidx2);
    // const [vidx, setVidx] = React.useState(vidx2);
    const text_size = 'text-' + size;
    const ncriteria = (criteria.startsWith('N >') ? criteria    : 'N >= 2/3');
    const ncclasses = (criteria.startsWith('N >') ? 'font-bold' : '') + ' ' + text_size;
    if (selection[0] != criteria) setSelection([criteria]);
    // console.log("PzSettingC2Win : ", criteria, selection, oidx2, vidx2);

    function setSelection2(new_selection) {
        if (disabled) return;
        setSelection(new_selection);
    }

    function onChangeToRadio(e, old_selection, new_selection) {
        if (disabled) return;
        if (new_selection.length > 0) setCriteria(new_selection[0]);
        else setCriteria('');
        e.stopPropagation();
    }

    return (
        <div className={"flex flex-row justify-between items-center " + className}>
            <PzInput.Radio2 selection={selection} setSelection={setSelection2} size={size} onChange={onChangeToRadio} value={'Majority'} text={locale==='ko' ? utils.translateCriteria('Majority',localeMap) : 'Majority'} disabled={disabled && !selection.includes('Majority')} />
            <PzInput.Radio2 selection={selection} setSelection={setSelection2} size={size} onChange={onChangeToRadio} value={'Most'}     text={locale==='ko' ? utils.translateCriteria('Most',localeMap) : 'Most'} disabled={disabled && !selection.includes('Most')} />
            <PzInput.Radio2 selection={selection} setSelection={setSelection2} size={size} onChange={onChangeToRadio} value={ncriteria}  disabled={disabled && !selection[0].startsWith('N ')} >
                <PzSettingCriteria2Win criteria={criteria} setCriteria={setCriteria} size={size} editable={!disabled} locale={locale} />
            </PzInput.Radio2>
        </div>
    );
};


export function PzSettingC2Drop({criteria, setCriteria=()=>{}, size='sm', className='', disabled=false, locale='en'}) {
    if (!criteria) criteria = 'Zero';
    const localeMap = utils.selectMapByLocale(locale);
    const [selection, setSelection] = React.useState([criteria]);
    const tvList = ['Top2x', 'Top3x', 'Top4x'];
    const ttList = [localeMap["poll.criteria.Top2x"], localeMap["poll.criteria.Top3x"], localeMap["poll.criteria.Top4x"]];
    const [olist, vlist] = getOpAndValues();
    const [oidx2, vidx2] = getOpAndValueIndex();
    const [oidx, setOidx] = React.useState(oidx2);
    const [vidx, setVidx] = React.useState(vidx2);
    const [tidx, setTidx] = React.useState(getTopIndex());
    const text_size = 'text-' + size;
    const tcriteria = (criteria.startsWith('Top') ? criteria    : 'Top2x');
    const tcclasses = (criteria.startsWith('Top') ? 'font-bold' : '');
    const ncriteria = (criteria.startsWith('N <') ? criteria    : 'N < 1/100');
    const ncclasses = (criteria.startsWith('N <') ? 'font-bold' : '') + ' ' + text_size;
    if (selection[0] != criteria) setSelection([criteria]);
    // console.log("PzSettingC2Drop : ", criteria, selection);

    function setSelection2(new_selection) {
        if (disabled) return;
        setSelection(new_selection);
    }

    function getTopIndex() {
        if (!criteria || !criteria.startsWith('Top')) return 0;
        tvList.indexOf(criteria);
    }

    function getOpAndValues() {
        let crtlist = appConfig.getCriteriaListToDrop();
        let values = [];
        for (let i = 0; i < crtlist.length; i++) {
            if (!crtlist[i].startsWith("N <")) continue;
            let split = crtlist[i].split(' ');
            if (split.length === 3 && !values.includes(split[2])) {
                values.push(split[2]);
            }
        }
        return [ [ '<', '<=' ], values ];
    }

    function getOpAndValueIndex() {
        let ncriteria = (!criteria || !criteria.startsWith('N <') ? 'N < 1/100' : criteria);
        // if (!criteria || !criteria.startsWith('N <')) return [ 0, 0 ];
        let split = ncriteria.split(' '), oidx2 = 0, vidx2 = 0;
        if (split.length === 3) {
            if (olist && olist.includes(split[1])) oidx2 = olist.indexOf(split[1]);
            if (vlist && vlist.includes(split[2])) vidx2 = vlist.indexOf(split[2]);
        }
        return [ oidx2, vidx2 ];
    }

    function onChangeToRadio(e, old_selection, new_selection) {
        if (disabled) return;
        if (new_selection.length > 0) setCriteria(new_selection[0]);
        else setCriteria('');
        e.stopPropagation();
    }

    function onChangeToTidx(old_tidx, new_tidx) {
        if (disabled) return;
        // console.log(selection, tcriteria, new_tidx, tlist[new_tidx]);
        setCriteria( tvList[new_tidx] );
        setSelection( tvList[new_tidx] );
    }

    function onChangeToOidx(e, old_oidx, new_oidx) {
        if (disabled) return;
        setCriteria( `N ${olist[new_oidx]} ${vlist[vidx]}` );
    }

    function onChangeToVidx(e, old_vidx, new_vidx) {
        if (disabled) return;
        setCriteria( `N ${olist[oidx]} ${vlist[new_vidx]}` );
    }

    return (
        <div className={"flex flex-row justify-between items-center " + className}>
            <PzInput.Radio2 selection={selection} setSelection={setSelection2} size={size} onChange={onChangeToRadio} value={'Zero'}  text={locale==='ko' ? utils.translateCriteria('Zero',localeMap) : 'Zero'}  disabled={disabled && !selection.includes('Zero')} />
            <PzInput.Radio2 selection={selection} setSelection={setSelection2} size={size} onChange={onChangeToRadio} value={'Least'} text={locale==='ko' ? utils.translateCriteria('Least',localeMap): 'Least'} disabled={disabled && !selection.includes('Least')} />
            <PzInput.Radio2 selection={selection} setSelection={setSelection2} size={size} onChange={onChangeToRadio} value={tcriteria} disabled={disabled && !selection[0].startsWith('Top')} >
                <PzSettingDropdown values={tvList} texts={ttList} vidx={tidx} setVidx={setTidx} 
                    size={size} ha='center' className={tcclasses} disabled={disabled} onChange={onChangeToTidx} upward={true} />
            </PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection2} size={size} onChange={onChangeToRadio} value={ncriteria} disabled={disabled && !selection[0].startsWith('N ')} >
                <PzSettingCriteria2Drop criteria={criteria} setCriteria={setCriteria} 
                    size={size} editable={!disabled} locale={locale} />
            </PzInput.Radio2>
        </div>
    );
};

export function PzSettingNVote({nVote, setNVote, maxNVote, size='sm', className='', disabled=false, op50=false}) {
    const [selection, setSelection] = React.useState([nVote]);

    function onChangeToRadio(e, old_selection, new_selection) {
        if (disabled) return;
        if (new_selection.length > 0) setNVote(new_selection[0]);
        else setNVote(0);
        e.stopPropagation();
    }

    return (
        <div className={" flex flex-row justify-between items-center " + className}>
            { maxNVote > 6 &&
                <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={6}>{6}</PzInput.Radio2>
            }
            { maxNVote > 10 &&
                <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={10}>{10}</PzInput.Radio2>
            }
            { maxNVote > 12 &&
                <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={12}>{12}</PzInput.Radio2>
            }
            { maxNVote > 24 &&
                <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={24}>{24}</PzInput.Radio2>
            }
            { maxNVote > 48 &&
                <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={48}>{48}</PzInput.Radio2>
            }
            { maxNVote > 100 &&
                <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={100}>{100}</PzInput.Radio2>
            }
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={maxNVote}>{maxNVote}</PzInput.Radio2>
        </div>
    );
};

export function PzSettingVDist({vdist, setVDist, size='sm', className='', disabled=false, op50=false}) {
    const [selection, setSelection] = React.useState([vdist]);

    function onChangeToRadio(e, old_selection, new_selection) {
        if (disabled) return;
        if (new_selection.length > 0) setVDist(new_selection[0]);
        else setVDist('N');
        e.stopPropagation();
    }

    return (
        <div className={" flex flex-row justify-between items-center " + className}>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'U'}>{'U'}</PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'N'}>{'N'}</PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'1'}>{'1'}</PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'1r'}>{'1r'}</PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'2'}>{'2'}</PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'2r'}>{'2r'}</PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'3'}>{'3'}</PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'3r'}>{'3r'}</PzInput.Radio2>
        </div>
    );
};


function PzSettingDropdown({ values, texts=null, vidx=0, setVidx=()=>{}, 
    size='sm', className='', ha='center', disabled=false, op50=false, upward=false, onChange=()=>{} }) {
    const [open, setOpen] = React.useState(false);
    // console.log("PzSettingsDropdown  vidx:", vidx);

    function onClick(idx) {
        if (idx !== vidx) {
            if (typeof onChange === 'function') onChange(vidx, idx);
            setVidx(idx);
        }
        setOpen(false);
    }

    return (
        <PzPop.Dropdown 
            base={
                <span className={` text-${size} ${op50 ? `opacity-50 ` : ``} cursor-pointer ` + className} onClick={()=>{if (!disabled) setOpen(true);}}>
                    {values[vidx]}
                </span>
            }
            popover={
                <ul className="p-2 flex flex-col items-start justify-center text-center gap-0">
                    {values.map((value,idx)=>{
                        return (
                            texts ? 
                                <label key={idx} onClick={()=>{onClick(idx);}}
                                    className={`text-${size} ` + (idx === vidx ? `text-bold` : ``)} >
                                    <span>{value}</span> &nbsp; <span className="opacity-50">{texts[idx]}</span>
                                </label>
                            :
                                <label key={idx} onClick={()=>{onClick(idx);}}
                                    className={`text-${size} ` + (idx === vidx ? `text-bold` : ``)} >
                                    <span>{value}</span>
                                </label>
                        );
                    })}
                </ul>
            } 
            ha={ha} open={open} setOpen={setOpen} upward={upward} offset='4px' />
    );
}

function PzSettingCriteria2Win ({criteria, setCriteria, size='xl', className='', locale='en'}) {
    const localeMap = utils.selectMapByLocale(locale);
    const editable = (typeof setCriteria === 'function');
    const [criteriaL, setCriteriaL] = React.useState(criteria.includes('N ') ? criteria : 'N >= 2/3');
    const [open, setOpen] = React.useState(false);
    const [olist, vlist] = getOpAndValues();
    const [oidx2, vidx2] = getOpAndValueIndex();
    const [oidx, setOidx] = React.useState(oidx2);
    const [vidx, setVidx] = React.useState(vidx2);
    const [type, setType] = React.useState([criteriaL.includes('/') ? 'R' : 'A']);  // Relative_N vs Absolute_N 
    const [absV, setAbsV] = React.useState(1);
    const TEXTSIZE = 'text-' + size, BSIZE = utils.getSizeRelative(size, +1);
    const BTEXTSIZE = 'text-' + BSIZE;

    function setCriteriaAll(c) {
        setCriteriaL(c);
        if (typeof setCriteria === 'function') setCriteria(c);
    }

    function getOpAndValues() {
        let crtlist = appConfig.getCriteriaListToWin();
        let values = [];
        for (let i = 0; i < crtlist.length; i++) {
            if (!crtlist[i].startsWith("N >")) continue;
            let split = crtlist[i].split(' ');
            if (split.length === 3 && !values.includes(split[2])) {
                values.push(split[2]);
            }
        }
        return [ [ '>', '>=' ], values ];
    }

    function getOpAndValueIndex() {
        let ncriteria = (!criteriaL || !criteriaL.startsWith('N >') ? 'N > 2/3' : criteriaL);
        // if (!criteriaL || !criteriaL.startsWith('N >')) return [ 0, 0 ];
        let split = ncriteria.split(' '), oidx2 = 0, vidx2 = 0;
        if (split.length === 3) {
            if (olist && olist.includes(split[1])) oidx2 = olist.indexOf(split[1]);
            if (vlist && vlist.includes(split[2])) vidx2 = vlist.indexOf(split[2]);
        }
        return [ oidx2, vidx2 ];
    }

    function onClickToOpen(e) {
        if (!editable) return;
        if (criteriaL !== criteria && typeof setCriteria === 'function') setCriteria(criteriaL);
        setOpen(true);
    }

    function onChangeToOidx(old_oidx, new_oidx) {
        if (!editable) return;
        let new_criteria = (type==='R' ? `N ${olist[new_oidx]} ${vlist[vidx]}` : `N ${olist[new_oidx]} ${absV}`);
        setCriteriaAll( new_criteria );
    }

    function onChangeToVidx(old_vidx, new_vidx) {
        if (!editable) return;
        let new_criteria = (type==='R' ? `N ${olist[oidx]} ${vlist[new_vidx]}` : `N ${olist[oidx]} ${absV}`);
        setCriteriaAll( new_criteria );
    }

    function onChangeDoneToValue(new_value) {
        if (!editable) return;
        setAbsV(new_value);
        let new_criteria = `N ${olist[oidx]} ${new_value}`;
        setCriteriaAll( new_criteria );
    }

    function onChangeToRadio(e, olds, news) {
        if (!editable) return;
        e.stopPropagation();
        let new_criteria = (news.includes('R') ? `N ${olist[oidx]} ${vlist[vidx]}` : `N ${olist[oidx]} ${absV}`);
        setCriteriaAll( new_criteria );
    }

    return (
        <PzPop.Dropdown 
            base={
                <label className={TEXTSIZE + " cursor-pointer " + className} 
                    onClick={onClickToOpen}>
                    {criteriaL}
                </label>
            }
            popover={
                <div className={"w-fit min-w-[18rem] px-4 py-4 flex flex-col justify-start items-start gap-1"}>
                    <div className={BTEXTSIZE + " w-full flex flex-row justify-between items-start gap-4"}>
                        <PzInput.Radio2 selection={type} setSelection={setType} 
                            size={size} value={'R'} text={localeMap["monitor.quest.criteria.var"]} onChange={onChangeToRadio} />
                        <PzInput.Radio2 selection={type} setSelection={setType} 
                            size={size} value={'A'} text={localeMap["monitor.quest.criteria.abs"]} onChange={onChangeToRadio} />
                    </div>
                    <div className={"w-full flex flex-row justify-between items-center pl-2"}>
                        <div className={BTEXTSIZE + " w-fit flex flex-row justify-start items-center gap-1"}>
                            <span className={BTEXTSIZE + " font-bold"}>N</span> &nbsp;
                            <PzSettingDropdown values={olist} vidx={oidx} setVidx={setOidx} size={BSIZE} 
                                onChange={onChangeToOidx} className="px-2 py-1 border border-slate-300 rounded-md font-bold" /> &nbsp;
                            { type.includes('R') ?
                                <PzSettingDropdown values={vlist} vidx={vidx} setVidx={setVidx} size={BSIZE} 
                                    onChange={onChangeToVidx} className="px-2 py-1 border border-slate-300 rounded-md font-bold" /> 
                            :
                                <PzInput.ForInt value={absV} setValue={setAbsV} size={BSIZE} 
                                    onChangeDone={onChangeDoneToValue} className="px-1 py-0 w-[5rem] font-bold" /> 
                            }
                        </div> 
                        <div className={BTEXTSIZE + " w-fit flex flex-row justify-end items-center gap-1"}>
                            { type.includes('R') &&
                                <label className={BTEXTSIZE}>{localeMap["monitor.quest.criteria.natt"]}</label>
                            }
                        </div>
                    </div>
                </div>
            } 
            ha='right' offset='4px' upward={true} 
            open={open} setOpen={editable ? setOpen : null} />
    );
}

function PzSettingCriteria2Drop ({criteria, setCriteria, size='xl', className='', locale='en'}) {
    const localeMap = utils.selectMapByLocale(locale);
    const editable = (typeof setCriteria === 'function');
    const [criteriaL, setCriteriaL] = React.useState(criteria.includes('N ') ? criteria : 'N < 1/10');
    const [open, setOpen] = React.useState(false);
    const [olist, vlist] = getOpAndValues();
    const [oidx2, vidx2] = getOpAndValueIndex();
    const [oidx, setOidx] = React.useState(oidx2);
    const [vidx, setVidx] = React.useState(vidx2);
    const [type, setType] = React.useState([criteriaL.includes('/') ? 'R' : 'A']);  // Relative_N vs Absolute_N 
    const [absV, setAbsV] = React.useState(1);
    const TEXTSIZE = 'text-' + size, BSIZE = utils.getSizeRelative(size, +1);
    const BTEXTSIZE = 'text-' + BSIZE;

    function setCriteriaAll(c) {
        setCriteriaL(c);
        if (typeof setCriteria === 'function') setCriteria(c);
    }

    function getOpAndValues() {
        let crtlist = appConfig.getCriteriaListToDrop();
        let values = [];
        for (let i = 0; i < crtlist.length; i++) {
            if (!crtlist[i].startsWith("N <")) continue;
            let split = crtlist[i].split(' ');
            if (split.length === 3 && !values.includes(split[2])) {
                values.push(split[2]);
            }
        }
        return [ [ '<', '<=' ], values ];
    }

    function getOpAndValueIndex() {
        let ncriteria = (!criteriaL || !criteriaL.startsWith('N <') ? 'N < 1/10' : criteriaL);
        // if (!criteriaL || !criteriaL.startsWith('N >')) return [ 0, 0 ];
        let split = ncriteria.split(' '), oidx2 = 0, vidx2 = 0;
        if (split.length === 3) {
            if (olist && olist.includes(split[1])) oidx2 = olist.indexOf(split[1]);
            if (vlist && vlist.includes(split[2])) vidx2 = vlist.indexOf(split[2]);
        }
        return [ oidx2, vidx2 ];
    }

    function onClickToOpen(e) {
        if (!editable) return;
        if (criteriaL !== criteria && typeof setCriteria === 'function') setCriteria(criteriaL);
        setOpen(true);
    }

    function onChangeToOidx(old_oidx, new_oidx) {
        if (!editable) return;
        let new_criteria = (type==='R' ? `N ${olist[new_oidx]} ${vlist[vidx]}` : `N ${olist[new_oidx]} ${absV}`);
        setCriteriaAll( new_criteria );
    }

    function onChangeToVidx(old_vidx, new_vidx) {
        if (!editable) return;
        let new_criteria = (type==='R' ? `N ${olist[oidx]} ${vlist[new_vidx]}` : `N ${olist[oidx]} ${absV}`);
        setCriteriaAll( new_criteria );
    }

    function onChangeDoneToValue(new_value) {
        if (!editable) return;
        setAbsV(new_value);
        let new_criteria = `N ${olist[oidx]} ${new_value}`;
        setCriteriaAll( new_criteria );
    }

    function onChangeToRadio(e, olds, news) {
        if (!editable) return;
        e.stopPropagation();
        let new_criteria = (news.includes('R') ? `N ${olist[oidx]} ${vlist[vidx]}` : `N ${olist[oidx]} ${absV}`);
        setCriteriaAll( new_criteria );
    }

    return (
        <PzPop.Dropdown 
            base={
                <label className={TEXTSIZE + " cursor-pointer " + className} 
                    onClick={onClickToOpen}>
                    {criteriaL}
                </label>
            }
            popover={
                <div className={"w-fit min-w-[18rem] px-4 py-4 flex flex-col justify-start items-start gap-1"}>
                    <div className={BTEXTSIZE + " w-full flex flex-row justify-between items-start gap-4"}>
                        <PzInput.Radio2 selection={type} setSelection={setType} 
                            size={size} value={'R'} text={localeMap["monitor.quest.criteria.var"]} onChange={onChangeToRadio} />
                        <PzInput.Radio2 selection={type} setSelection={setType} 
                            size={size} value={'A'} text={localeMap["monitor.quest.criteria.abs"]} onChange={onChangeToRadio} />
                    </div>
                    <div className={"w-full flex flex-row justify-between items-center pl-2"}>
                        <div className={BTEXTSIZE + " w-fit flex flex-row justify-start items-center gap-1"}>
                            <span className={BTEXTSIZE + " font-bold"}>N</span> &nbsp;
                            <PzSettingDropdown values={olist} vidx={oidx} setVidx={setOidx} size={BSIZE} 
                                onChange={onChangeToOidx} className="px-2 py-1 border border-slate-300 rounded-md font-bold" /> &nbsp;
                            { type.includes('R') ?
                                <PzSettingDropdown values={vlist} vidx={vidx} setVidx={setVidx} size={BSIZE} 
                                    onChange={onChangeToVidx} className="px-2 py-1 border border-slate-300 rounded-md font-bold" /> 
                            :
                                <PzInput.ForInt value={absV} setValue={setAbsV} size={BSIZE} 
                                    onChangeDone={onChangeDoneToValue} className="px-1 py-0 w-[5rem] font-bold" /> 
                            }
                        </div> 
                        <div className={BTEXTSIZE + " w-fit flex flex-row justify-end items-center gap-1"}>
                            { type.includes('R') &&
                                <label className={BTEXTSIZE}>{localeMap["monitor.quest.criteria.natt"]}</label>
                            }
                        </div>
                    </div>
                </div>
            } 
            ha='right' offset='4px' upward={true} 
            open={open} setOpen={editable ? setOpen : null} />
    );
}

