import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import { setUPoll } from "../config/upollConfig";
import PzButton from "../common/PzButton";
import PzPop from "../common/PzPop";
import { ReactComponent as IconToolViewer } from "../resource/icons/pz-tool-viewer.svg";
import { ReactComponent as IconToolEditor } from "../resource/icons/pz-tool-editor.svg";

const EvtStaffList = {
    Module:     EvtStaffListModule,
};
export default EvtStaffList;


function EvtStaffListModule({poll, setPoll=null, asPoller=true}) {
    const [cookies] = useCookies();
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const navigate = useNavigate();
    const location = useLocation();
    const localeMap = useSelector(utils.selectLocaleMap);
    const [sState, setSState] = React.useState({nt:0,nm:0,ns:0,     updated:dayjs(),folded:true});
    let sSummary = utils.completeMessageText(localeMap["poller.getready.staffs." + (sState.nt===0 ? "sum0" : "sum1")], [sState.nt]);
    const dispatch = useDispatch();
    const getready = (poll?.pstatus < 'Oo' && location.pathname.endsWith('getready'));
    const TEXTSIZE = "text-lg";

    const POLL_URL_PATH = '/v/vote?p=' + poll?.pguid;

    React.useEffect(() => {
        fetchStaffCounts();
    }, []);

    function fetchStaffCounts() {
        if (!uauth || !poll) return;
        appConfig.getAPI().post((asPoller ? "/api/mp/staff/counts" : "/api/s/staff/counts"), {pguid: poll.pguid})
        .then((response) => {
            // console.log("PzAPI 'voter/counts' success");
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const counts = pzdata.resource; // {ntotal:0, nbdate:0, nphone:0, nemail:0}
            // console.log("counts:", counts);
            setSState({ ...sState, nt:counts.ntotal, nm:counts.nmanager, ns:counts.nstaff });
            if (counts.ntotal !== poll.nstaff) {
                dispatch(setUPoll({ ...poll, nstaff: counts.ntotal }));
            }
        })
        .catch((error) => { 
            console.log("PzAPI 'staff/counts' failure (%v)", error); 
        })
        .finally(() => {});
    }

    if      (!poll) {
        return (<label>{"Event cannot be found"}</label>);
    }  

    return (
        <>
            <div className="w-full flex flex-col justify-start items-start gap-5">
                <div className="w-full flex flex-row justify-between items-center">
                    <h3>{localeMap["poller.getready.staffs"]}</h3>
                    <p className={"text-xl font-bold " + (getready ? "" : "opacity-50")}>
                        {sSummary}
                    </p>
                </div>

                { (getready || (sState.nm + sState.ns > 0)) &&
                    <div className="w-full pl-10 flex flex-row justify-between items-center gap-5">
                        <div className="w-full grid grid-cols-[auto_auto_auto_1fr] justify-start items-center gap-x-5 gap-y-1">
                            <label className={`text-right ${TEXTSIZE}`}>{localeMap["poller.getready.staffs.nm"]} :</label>
                            <label className={`text-right ${TEXTSIZE} font-bold`}>{sState.nm}</label>
                            <label>&nbsp;</label>
                            <PzPop.HelpMsg msg={localeMap["poller.getready.staffs.nm0"]} size={'lg'} />
                            <label className={`text-right ${TEXTSIZE}`}>{localeMap["poller.getready.staffs.ns"]} :</label>
                            <label className={`text-right ${TEXTSIZE} font-bold`}>{sState.ns}</label>
                            <label>&nbsp;</label>
                            <PzPop.HelpMsg msg={localeMap["poller.getready.staffs.ns0"]} size={'lg'} />
                        </div>
                        { poll.pstatus >= 'Ax' ? 
                            <PzButton.Icon Icon={IconToolViewer} size='xl' onClick={()=>{navigate("/pages/poller/stafflist")}} disabled={sState.nm + sState.ns === 0} />
                        :
                            <PzButton.Icon Icon={IconToolEditor} size='xl' onClick={()=>{navigate("/pages/poller/stafflist")}} />
                        }
                    </div>
                }
            </div>
        </>
    );
}
