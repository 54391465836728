import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import { utils } from "../../config/userConfig";
import { setUPoll } from "../../config/upollConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import PollCard from "../../event/PollCard";
import UserSplash from "../UserSplash";
import { ReactComponent as IconStepNext } from "../../resource/icons/pz-step-next.svg";
import { ReactComponent as IconStepPrev } from "../../resource/icons/pz-step-prev.svg";
import EntranceToVoteSelf from "../../entrance/EntranceToVoteSelf";

export default function VoteMyself() {
    const [cookies] = useCookies();
    const navigate = useNavigate();
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const [poll, setPoll] = React.useState(upoll);  
    const localeMap = useSelector(utils.selectLocaleMap);
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const dispatch = useDispatch();
    const [open4Transfer, setOpen4Transfer] = React.useState(false);

    // React.useEffect(() => {
    //   uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    //   if (!uauth) navigate("/");
    // }, [cookies]);

    function setPoll2(new_poll) {
        setPoll(new_poll);                  // update the poll in memory
        dispatch(setUPoll(new_poll));       // update the poll in Redux storage
    }

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'S' && upoll.xrole !== 'M' && upoll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event"} /> );
    }  

    return (
        <PzArea.TBoardInContainer>

            <div className="w-full px-5 flex flex-col justify-start items-start gap-5">
                <PollCard.Item poll={upoll} viewable={false} expandable={true} />
            </div>

            <div className="w-full flex flex-row justify-between items-center">
                <h2>{localeMap["menu.voter.vote"]}</h2>
            </div>

            <div className="w-full flex flex-col justify-start items-center gap-5 border-2 bg-white">
                <EntranceToVoteSelf.Module poll={poll} />
            </div>

            <div className="w-full flex flex-row justify-between items-center mt-10">
                <PzButton.Std Icon={IconStepPrev} text={localeMap["menu.myevents"]}   size='lg' onClick={()=>{navigate("/pages/myevents");}} />
            </div>

        </PzArea.TBoardInContainer>
  );
}
