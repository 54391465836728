import React from "react";
import { useSelector, useDispatch } from "react-redux";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import PzBasicModal from "../../common/PzBasicModal";
import PzButton from "../../common/PzButton";
import PzInput from "../../common/PzInput";
import PzIconStrength from "../../common/PzIconStrength";
import PzPop from "../../common/PzPop";
import PzTable from "../../common/PzTable";
import { ReactComponent as IconToolEditor } from "../../resource/icons/pz-tool-editor.svg";
import { ReactComponent as IconToolViewer } from "../../resource/icons/pz-tool-viewer.svg";
import { ReactComponent as IconPayCredit }  from "../../resource/icons/pz-tool-pay-credit.svg";
import { ReactComponent as IconPayFree }  from "../../resource/icons/pz-tool-pay-free.svg";
import { ReactComponent as IconPayDollar }  from "../../resource/icons/pz-tool-pay-dollar.svg";
import ModalToPayWithFQ from "./ModalToPayWithFQ";
import ModalToPayWithPG from "./ModalToPayWithPG";

const pcaAuthOptions = [
    { authtype:"A1___", allow:'A', qr:'1', check:'__', simplicity:4, security:0 },
    { authtype:"A1_n_", allow:'A', qr:'1', check:'n_', simplicity:3, security:1 },
    { authtype:"A1_nc", allow:'A', qr:'1', check:'nc', simplicity:3, security:2 },
    { authtype:"E1_n_", allow:'E', qr:'1', check:'n_', simplicity:3, security:2 },
    { authtype:"E1_nc", allow:'E', qr:'1', check:'nc', simplicity:2, security:3 },
    { authtype:"E9___", allow:'E', qr:'9', check:'__', simplicity:2, security:2 },
    { authtype:"E9_n_", allow:'E', qr:'9', check:'n_', simplicity:1, security:3 },
    { authtype:"E9_nc", allow:'E', qr:'9', check:'nc', simplicity:0, security:4 },
];


export default function GetReadyPca({poll, setPoll, size='lg', className, setSummary=()=>{}}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const pkind_initial = (poll ? poll.pkind : 'PRA');
    const pkind = pkind_initial + '_______'.substring(pkind_initial.length);
    const [balance, setBalance] = React.useState({ fq: {qtrem:0,qtmax:0,qtlast:''} });
    const [open4ModalPca, setOpen4ModalPca] = React.useState(false);
    const [open4ModalPayFQ, setOpen4ModalPayFQ] = React.useState(false);
    const [open4ModalPayMN, setOpen4ModalPayMN] = React.useState(false);
    const fqUsable = IsFreeQuotaUsable(poll.nMax, balance, pkind[4]);
    const TEXTSIZE = 'text-'+size;

    function fetchMyBalance() {
        appConfig.getAPI().post("/api/my/balance")
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            if (pzdata.respcode === 0) setBalance(pzdata.resource);
        })
        .catch((error) => { console.log("fetchMyBalance() error : ", error); })
        .finally(() => {});
    }

    function updateSummary(new_pstatus) {
        let pstatus = (new_pstatus ? new_pstatus : poll.pstatus);
        if (typeof setSummary === 'function') {
            let skey = '', urgent=false;
            if      (pstatus  <= 'Ac') { skey = "poller.getready.pca.sum0"; urgent = true; }
            else if (pstatus === 'Ar') { skey = "poller.getready.pca.sum1"; urgent = true; }
            else if (pstatus  >= 'Ax') { skey = "poller.getready.pca.sum2"; urgent = false; }
            else                       { skey = ""; urgent = false; }
            setSummary({summary:localeMap[skey], heartbeat:urgent});
        }
    }

    React.useEffect(() => {
        fetchMyBalance();
        updateSummary();
    }, []);

    function get_auth_type_description() {
        let who = localeMap["poller.getready.pca.auth." + poll.pkind.substring(2,3)];
        let chk = localeMap["poller.getready.pca.auth." + poll.pkind.substring(5,7)];
        return who + ", " + localeMap["poller.getready.pca.auth.check"] + ": " + chk;
    }

    if (!poll) return (<div></div>);
    return (
        <>
            <div className="w-full flex flex-row justify-between items-end pl-10 gap-10">

                <div className={"w-full grid grid-cols-[auto_auto_auto_1fr] justify-start items-center gap-x-5 gap-y-1 " + className}>
                    <label className={`text-right ${TEXTSIZE}`}>{localeMap["poller.getready.pca.nmin"]} :</label>
                    <p className={`text-right ${TEXTSIZE} font-bold`}>{poll.nmin > 0 && poll.nmin <= poll.nmax ? poll.nmin : 1}</p>
                    <p>&nbsp;</p>
                    <PzPop.HelpMsg msg={localeMap["poller.getready.pca.nmin.desc"]} size={size} />

                    <label className={`text-right ${TEXTSIZE}`}>{localeMap["poller.getready.pca.nmax"]} :</label>
                    <p className={`text-right ${TEXTSIZE} font-bold`}>{poll.nmax > 0 ? poll.nmax : poll.nelg > 0 ? poll.nelg : 10}</p>
                    <p>&nbsp;</p>
                    <PzPop.HelpMsg msg={localeMap["poller.getready.pca.nmax.desc"]} size={size} />

                    {/* Invitation */}
                    <label className={`text-right ${TEXTSIZE}`}>{localeMap["poller.getready.pca.inv"]} :</label>
                    { pkind[2] === 'A' ? 
                        <p className={`${TEXTSIZE} font-bold col-span-3`}>{localeMap["poller.getready.pca.offline"] }</p>
                    : pkind[4] === '0' ? 
                        <p className={`${TEXTSIZE} font-bold col-span-3`}>{localeMap["poller.getready.pca.offline"] }</p>
                    : pkind[4] === '1' ? 
                        <p className={`${TEXTSIZE} font-bold col-span-3`}>{localeMap["poller.getready.pca.online"] }</p>
                    : pkind[4] === '_' ? 
                        <p className={`${TEXTSIZE} font-bold col-span-3`}>{localeMap["poller.getready.pca.tbd"] }</p>
                    : 
                        <p className={`${TEXTSIZE} font-bold col-span-3`}>{localeMap["poller.getready.pca.tbd"]}</p>
                    }

                    {/* Entrance QR */}
                    <label className={`text-right ${TEXTSIZE}`}>{localeMap["poller.getready.pca.auth.qr"]} :</label>
                    { pkind[2] === 'A' ? 
                        <p className={`${TEXTSIZE} font-bold col-span-3`}>{localeMap["poller.getready.pca.auth.1"] }</p>
                    : pkind[3] === '1' ? 
                        <p className={`${TEXTSIZE} font-bold col-span-3`}>{localeMap["poller.getready.pca.auth.1"]}</p>
                    : pkind[3] === '9' ?
                        <p className={`${TEXTSIZE} font-bold col-span-3`}>{localeMap["poller.getready.pca.auth.9"]}</p>
                    : pkind[3] === '_' ? 
                        <p className={`${TEXTSIZE} font-bold col-span-3`}>{localeMap["poller.getready.pca.tbd"]}</p>
                    : 
                        <p></p>
                    }

                    {/* Voter Authentication */}
                    <label className={`text-right ${TEXTSIZE}`}>{localeMap["poller.getready.pca.auth"]} :</label>
                    { pkind[3] === '_' ? 
                        <p className={`${TEXTSIZE} font-bold col-span-3`}>{localeMap["poller.getready.pca.tbd"]}</p>
                    : 
                        <p className={`${TEXTSIZE} font-bold col-span-3`}>{get_auth_type_description()}</p>
                    }

                    {/* Final Price */}
                    <label className={`text-right ${TEXTSIZE}`}>{localeMap["poller.getready.pca.price.label"]} :</label>
                    <p className={`${TEXTSIZE} font-bold`}>
                        { pkind[3] === '_' || pkind[4] === '_' ? 
                            <span className={`${TEXTSIZE} font-bold col-span-3`}>{localeMap["poller.getready.pca.tbd"]}</span>
                        :
                            (appConfig.getTotalPrice(poll.nmax, poll.pqstlist.length, poll.pkind[4], true))
                        }
                    </p>
                    <p className={`text-right ${TEXTSIZE}`}>&nbsp;</p>
                    <p className={`text-right ${TEXTSIZE} opacity-50 hover:opacity-100`}>{ poll.pstatus === 'Ax' ? localeMap["poller.getready.pca.paid"] : ""}</p>
                </div>

                <div className="flex flex-col justify-end items-center gap-4">
                    { poll.pstatus < 'Ax' ?
                        <PzButton.Icon Icon={IconToolEditor} size='xl' onClick={()=>{setOpen4ModalPca(true);}} 
                            className={poll.pstatus < 'Ar' ? 'heartbeat' : ''}  />
                    :
                        <PzButton.Icon Icon={IconToolViewer} size='xl' onClick={()=>{setOpen4ModalPca(true);}} />
                    }
                    { poll.pstatus < 'Ax' && 
                    (fqUsable ? 
                        <PzButton.Icon Icon={IconPayFree}   size='xl' onClick={()=>{setOpen4ModalPayFQ(true);}} 
                            disabled={poll.pstatus < 'Ar' ? true : false} className={poll.pstatus == 'Ar' ? 'heartbeat' : ''} />
                    :
                        <PzButton.Icon Icon={IconPayDollar} size='xl' onClick={()=>{setOpen4ModalPayMN(true);}} 
                            disabled={poll.pstatus < 'Ar' ? true : false} className={poll.pstatus == 'Ar' ? 'heartbeat' : ''} />
                    )}
                </div>

            </div>

            {open4ModalPca && (
                <ModalToEditPCA poll={poll} setPoll={setPoll} balance={balance} setBalance={setBalance} 
                    onClose={(new_pstatus)=>{ setOpen4ModalPca(false); updateSummary(new_pstatus); }}/>
            )}
            {open4ModalPayFQ && (
                <ModalToPayWithFQ poll={poll} setPoll={setPoll} balance={balance} setBalance={setBalance} 
                    onClose={(new_pstatus)=>{ setOpen4ModalPayFQ(false); updateSummary(new_pstatus); }}/>
            )}
            {open4ModalPayMN && (
                <ModalToPayWithPG poll={poll} setPoll={setPoll} balance={balance} setBalance={setBalance} 
                    onClose={(new_pstatus)=>{ setOpen4ModalPayMN(false); updateSummary(new_pstatus); }}/>
            )}
        </>
    );
}

export function ModalToEditPCA({poll, setPoll, balance, setBalance, onClose}) {
    // const upoll = useSelector((state)=>state.upcfg.uPoll);
    const localeMap = useSelector(utils.selectLocaleMap);
    const pkind = poll.pkind + '_______'.substring(poll.pkind.length);
    const atype = pkind.substring(2,4) + '_' + pkind.substring(5); // like "A1___" or "E1_n_"
    const [nMin, setNMin] = React.useState(poll.nmin > 0 && poll.nmin <= poll.nmax ? poll.nmin : 1);
    const [nMax, setNMax] = React.useState(poll.nmax > 0 ? poll.nmax : poll.nelg > 0 ? poll.nelg : 10);
    const [seInv, setSeInv] = React.useState([ pkind[4] === '1' ? 'online' : 'offline' ]);
    const [seAuth, setSeAuth] = React.useState( atype[1] !== '_' ? [atype] : atype[0] === 'A' ? ['A1___'] : ['E1_n_'] );
    const fqUsable = IsFreeQuotaUsable(poll.nMax, balance, pkind[4]);
    const SIZE = 'md', TEXTSIZE = 'text-md';

    function fetchMyBalance() {
        appConfig.getAPI().post("/api/my/balance")
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            if (pzdata.respcode === 0) setBalance(pzdata.resource);
        })
        .catch((error) => { console.log("fetchMyBalance() error : ", error); })
        .finally(() => {});
    }

    React.useEffect(() => {
        fetchMyBalance();
    }, []);

    function onClickToSavePCA(e) {
        if (seAuth.length === 0) return;
        let new_pkind = poll.pkind.substring(0,2) + seAuth[0]; // "PR" + "A1_0n_" or "E9_1nc"
        new_pkind = new_pkind.substring(0,4) + (seInv[0] === 'online' ? '1' : '0') + new_pkind.substring(5)
        // console.log(`onClickToSavePCA : pguid:${poll.pguid}, pkind:${new_pkind}, nmin:${nMin}, nmax:${nMax} `);
        appConfig.getAPI().post("/api/mp/pca/settings", {pguid:poll.pguid, pkind:new_pkind, nmin:nMin, nmax:nMax})
        .then((response) => {
            // console.log("PzAPI PCA success", pzdata);
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const rtpoll = pzdata.resource; // returned poll info
            let new_poll = { ...poll, pkind : rtpoll.pkind, nmin: rtpoll.nmin, nmax: rtpoll.nmax, pstatus:rtpoll.pstatus };
            setPoll(new_poll);
            if (typeof onClose === 'function') onClose(new_poll.pstatus);
        })
        .catch((error) => { 
            console.log("PzAPI PCA failure : ", error);
        })
        .finally(() => {});
    }

    if (!poll) return (<div></div>);
    return (
        <PzBasicModal onClose={onClose} xclose={true} >
            <div className="w-full flex flex-col justify-center items-center p-5 gap-10">
                <div>
                    <h2>{localeMap["poller.getready.pca.title"]}</h2>
                </div>

                <div className="w-full flex flex-col justify-start items-start gap-5">
                    {/* Number of Participants */}
                    <div className="w-full flex flex-col justify-start items-start gap-2">
                        <h5>{localeMap["poller.getready.pca.n.label"]}</h5>
                        <div className="px-5 w-full grid grid-cols-[auto_1fr] justify-start items-center gap-x-5 gap-y-1 text-md">
                            <span className={TEXTSIZE}>{localeMap["poller.getready.pca.n.elg"]} :</span>
                            <div className="w-full flex flex-row justify-between items-center">
                                <span className={"w-[6rem] px-1.5 text-right "+TEXTSIZE}>{poll.nelg}</span>
                                <div className="flex flex-row justify-end items-center gap-5">
                                    <span className={TEXTSIZE}>{localeMap["poller.getready.pca.fq.rem"]} :</span>
                                    <span className={TEXTSIZE}>
                                        {balance.fq.qtrem === appConfig.getFQInf() ? '∞' : balance.fq.qtrem}
                                    </span>
                                </div>
                            </div>

                            <span className={TEXTSIZE}>{localeMap["poller.getready.pca.n.min"]} :</span>
                            <div className="w-full flex flex-row justify-between items-center">
                                <PzInput.ForInt className="w-[6rem]" value={nMin} setValue={setNMin} 
                                    min={0} max={poll.nelg ? poll.nelg : undefined} tabIndex={-1} disabled={poll.pstatus==='Ax'} />
                            </div>

                            <span className={TEXTSIZE}>{localeMap["poller.getready.pca.n.max"]} :</span>
                            <div className="w-full flex flex-row justify-between items-center">
                                <PzInput.ForInt className="w-[6rem]" value={nMax} setValue={setNMax} 
                                    min={0} max={poll.nelg ? poll.nelg : undefined} tabIndex={0} disabled={poll.pstatus==='Ax'} />
                                <span className={"text-fuchsia-900 "+TEXTSIZE}>{utils.completeLocaleMapText(localeMap, "poller.getready.pca.nmax.cost", [appConfig.getPricePerVoter(true)])}</span>
                            </div>
                        </div>
                    </div>

                    {/* Invitation Method */}
                    <div className="w-full flex flex-col justify-start items-start gap-2">
                        <h5>{localeMap["poller.getready.pca.inv"]}</h5>
                        <div className="w-full px-5 flex flex-col justify-start items-start gap-2">
                            <div className="w-full flex flex-row justify-between items-center ">
                                <PzInput.Radio2 value="offline" selection={seInv} setSelection={setSeInv} 
                                    text={localeMap["poller.getready.pca.offline"]} size={SIZE} disabled={poll.pstatus==='Ax'} />
                            </div>
                            <div className="w-full flex flex-row justify-between items-center ">
                                <PzInput.Radio2 value="online"  selection={seInv} setSelection={setSeInv} 
                                    text={localeMap["poller.getready.pca.online"]} size={SIZE} disabled={!poll.nelg || poll.pstatus==='Ax'} /> 
                                <span className={"text-fuchsia-900 "+TEXTSIZE}>
                                    {utils.completeLocaleMapText(localeMap, "poller.getready.pca.comm.cost", [appConfig.getPriceToInvite(true)])}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* Voter Authentication */}
                    <div className="w-full flex flex-col justify-start items-start gap-2">
                        <h5>{localeMap["poller.getready.pca.auth"]}</h5>
                        <PzTable className="pl-5"
                            data={pcaAuthOptions} rowKey="authtype" 
                            columns={[
                                { title: ' ', key: 'authtype', 
                                    render:({authtype, allow})=>{
                                        return (
                                            <PzInput.Radio2 value={authtype} selection={seAuth} setSelection={setSeAuth} 
                                                disabled={ (allow==='A' && poll.nelg) || (allow==='E' && !poll.nelg) }
                                                className={(allow==='A' && poll.nelg) || (allow==='E' && !poll.nelg) ? 'invisible' : ''}
                                                text='' size={SIZE} />
                                        );
                                    }} ,
                                { title: localeMap["poller.getready.pca.auth.qr"], key: 'qr', align:'center',
                                    render:({qr})=>{return (<p className={TEXTSIZE}>{localeMap["poller.getready.pca.auth."+qr]}</p>)} },
                                { title: localeMap["poller.getready.pca.auth.allow"], key: 'allow', align:'center',
                                    render:({allow})=>{return (<p className={TEXTSIZE}>{localeMap["poller.getready.pca.auth."+allow]}</p>)} },
                                { title: localeMap["poller.getready.pca.auth.check"], key: 'check', align:'center',
                                    render:({check})=>{return (<p className={TEXTSIZE}>{localeMap["poller.getready.pca.auth."+check]}</p>)} },
                                { title: localeMap["poller.getready.pca.auth.simplicity"], key: 'simplicity', align:'center',
                                    render:({simplicity})=>{return (<PzIconStrength value={simplicity} className='w-full'/>)} },
                                { title: localeMap["poller.getready.pca.auth.security"], key: 'security', align:'center', 
                                    render:({security})=>{return (<PzIconStrength value={security} className='w-full'/>)} },
                            ]} />
                    </div>

                    {/* Price */}
                    <div className="w-full flex flex-col justify-start items-start gap-2">
                        <h5>{localeMap["poller.getready.pca.price.label"]}</h5>
                        <div className="px-5 w-full grid grid-cols-[auto_1fr] justify-start items-center gap-x-5 gap-y-1 text-md">
                            <span className={TEXTSIZE}>{localeMap["poller.getready.pca.price.total"]} :</span>
                            <div className="w-full flex flex-row justify-between items-center">
                                <span className={"text-fuchsia-900 "+TEXTSIZE}>{appConfig.getTotalPrice(nMax, 3, seInv[0], true)}</span>
                                <span className={"text-fuchsia-900 "+TEXTSIZE}>
                                    = &nbsp; {nMax} × ({appConfig.getPricePerVoter(true)} + {seInv.includes('online') ? appConfig.getPriceToInvite(true) : appConfig.getFormattedPrice(0)})
                                </span>
                            </div>
                            { poll.pstatus < 'Ax' && <>
                                <span className={TEXTSIZE}>{localeMap["poller.getready.pca.fq.payable"]} :</span>
                                <div className="w-full flex flex-row justify-between items-center">
                                    <span className={TEXTSIZE}>{localeMap[fqUsable ? "poller.getready.pca.fq.yes" : "poller.getready.pca.fq.no"]}</span>
                                </div>
                                <span className={TEXTSIZE}>{localeMap["poller.getready.pca.price.topay"]} :</span>
                                <div className="w-full flex flex-row justify-between items-center">
                                    <span className={"text-fuchsia-900 font-bold "+TEXTSIZE}>
                                        {fqUsable ? appConfig.getFormattedPrice(0) : appConfig.getTotalPrice(nMax, 3, seInv[0], true)}
                                    </span>
                                    <div className="flex flex-row justify-end items-center gap-5">
                                        <span className={TEXTSIZE}>{localeMap["poller.getready.pca.fq.after"]} :</span>
                                        <span className={TEXTSIZE}>
                                            {balance.fq.qtrem === appConfig.getFQInf() ? '∞' : fqUsable ? (balance.fq.qtrem - nMax) : balance.fq.qtrem}
                                        </span>
                                    </div>
                                </div>
                            </> }
                        </div>
                    </div>
                </div>

                <div className="w-full flex flex-row justify-center items-center">
                    <PzButton.Std text={localeMap["modal.button.save"]} size='lg' onClick={onClickToSavePCA} disabled={seAuth.length===0} />
                </div>
            </div>
        </PzBasicModal>
    );
}

function IsFreeQuotaUsable(nmax, balance, invitation) {
    if (invitation === 'online' || invitation === '1' || !balance?.fq) return false;
    else if (balance.fq.qtrem === appConfig.getFQInf()) return true;
    else return (nmax <= balance.fq.qtrem);
}

