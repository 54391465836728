import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { motion, AnimatePresence } from "framer-motion"
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from '../common/PzBasicModal';
import PzButton from '../common/PzButton';
import { ReactComponent as IconStepNext } from "../resource/icons/pz-step-next.svg";
import { ReactComponent as IconStepPrev } from "../resource/icons/pz-step-prev.svg";
import { ReactComponent as IconButtonAdd } from "../resource/icons/pz-button-add.svg";
import { ReactComponent as IconTimeClock } from "../resource/icons/pz-time-clock.svg";
import { ReactComponent as IconVotePollbox } from "../resource/icons/pz-vote-pollbox.svg";
import PollEvent from './PollEvent';
import PollPqst from './PollPqst';

const PollCreator = {
    Dialog:         NewPollDialog,
    Button:         NewPollButton,

    postCreatePoll: postCreatePoll,
};
export default PollCreator;


function NewPollDialog({poll, setPoll, onClose=()=>{}, onSavePoll=()=>{}}) {
    const [cookies] = useCookies(['uauth']);
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const localeMap = useSelector(utils.selectLocaleMap);
    const utzone = useSelector((state)=>state.ucfg.timezone); // timezone from user settings
    const [[step, direction], setStep] = React.useState([0, +1]);
    const [qSelected, setQSelected] = React.useState(poll?.pqstlist?.length === 1 ? poll.pqstlist[0].qguid : ''); // qguid
    
    const variants = {
        init:   { x:     0, top: 0, opacity: 0, scale: 0 },
        enter:  (direction) => { return { x: (direction>0 ? +1000 : -1000), top: 0, opacity: 0, scale: 1 }; },
        center: { x:     0, top: 0, opacity: 1, scale: 1 },
        exit:   (direction) => { return { x: (direction>0 ? -1000 : +1000), top: 0, opacity: 0, scale: 1 }; },
    };

    function describePWhen(pkind, pwhen, pwhenend) {
        if (!pkind) {
            return '';
        } else if (pkind[1] === 'P') {
            return utils.getPeriodInUserTimezone(pwhen, pwhenend, utzone);
        } else if (pwhen === '') {
            return localeMap["poll01.pwhen.rightnow"];
        } else {
            return utils.getTimeInUserTimezone(pwhen, "YYYY/MM/DD HH:mm", utzone);
        }
    }

    return (
        <PzBasicModal onClose={onClose} xclose={true}>
            <div className="w-auto min-w-[34rem] p-10 flex flex-col justify-start items-stretch gap-10">

                {/* TITLE */}
                { step === 0 ?
                    <h1>{localeMap["poll.create_event"]}</h1>
                :
                    <div className="w-full flex flex-col justify-start items-stretch gap-1">
                        <div className="w-full flex flex-row justify-start items-center">
                            <h1>{poll.ptitle}</h1>
                        </div>
                        <div className="w-full flex flex-row justify-between items-center">
                            <p className="text-xl">by <span className="text-xl font-bold">{poll.uname}</span></p>
                            <div className="flex flex-row justify-end items-center gap-2">
                                <PzButton.Icon Icon={IconTimeClock} size='md' forProperty={true}/> 
                                <p className="text-xl font-bold" onClick={()=>{console.log(poll);}}>
                                    {describePWhen(poll.pkind, poll.pwhen, poll.pwhenend)}
                                </p>
                            </div>
                        </div>
                    </div>
                }

                {/* CONTENTS */}
                <div className="w-full relative  aspect-square ">
                    <AnimatePresence initial={false} >
                        <motion.div key={step}
                            variants={variants} custom={direction} 
                            initial="enter" animate="center" exit="exit"
                            className="w-full h-full absolute"
                            transition={{
                                x: { type: "spring", stiffness: 300, damping: 30 },
                                opacity: { duration: 0.2 }
                            }}>
                            { step === 0 ? 
                                <PollEvent.Editor poll={poll} setPoll={setPoll} size='xl'
                                    visibility={{k:false, t:true, u:true, w:true, e:false, l:false}}
                                    onClose={onClose} className="!p-2" />
                            : step === 1 ?
                                <div className="w-full pb-5 flex flex-col justify-start items-stretch gap-4 aspect-square overflow-y-scroll">
                                    <PollPqst.List poll={poll} setPoll={setPoll} 
                                        qSelected={qSelected} setQSelected={setQSelected} editable={true} />
                                </div>
                            :
                                <NewPollReady poll={poll} />
                            }
                        </motion.div>
                    </AnimatePresence>
                </div>

                {/* BUTTONS */}
                <div className="w-full flex flex-row justify-between items-center">
                    {/* Prev */}
                    <PzButton.Std Icon={IconStepPrev} text={localeMap["poll.create.button.prev"]} size='lg' 
                        onClick={()=>{setStep([step>=2 ? 1 : 0, -1]);}} className={step==0 ? 'invisible' : ''} />
                    {/* Next or Save */}
                    { step === 0 ?
                        <PzButton.Std Icon={IconStepNext} text={localeMap["poll.create.button.next"]} 
                            size='lg' onClick={()=>{setStep([1, +1]);}} disabled={poll.ptitle ? false : true} />
                    : uauth ?
                        <PzButton.Std text={localeMap["poll.create.button.create"]} 
                            size='lg' onClick={()=>onSavePoll()} disabled={poll.ptitle ? false : true} />
                    : step === 1 ?
                        <PzButton.Std Icon={IconStepNext} text={localeMap["poll.create.button.next"]} 
                            size='lg' onClick={()=>{setStep([2, +1]);}} disabled={poll.ptitle ? false : true} />
                    :
                        <div className="flex flex-row justify-end items-center gap-4">
                            <PzButton.Std text={localeMap["menu.auth.login"]} 
                                size='lg' onClick={()=>onSavePoll('login')}  disabled={poll.ptitle ? false : true} />
                            <PzButton.Std text={localeMap["menu.auth.signup"]} 
                                size='lg' onClick={()=>onSavePoll('signup')} disabled={poll.ptitle ? false : true} />
                        </div>
                    }
                </div>

            </div>
        </PzBasicModal>
    )
}

function NewPollButton({size='xl', className='', useicon=false, disabled=false, onSuccess=(newpguid)=>{}}) {
    const [cookies] = useCookies(['uauth']);
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const localeMap = useSelector(utils.selectLocaleMap);
    const ulocale = useSelector((state)=>state.ucfg.locale);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [poll, setPoll] = React.useState(getEmptyPoll());
    if (poll.locale !== ulocale) setPoll(getEmptyPoll());

    // React.useEffect(() => {
    // }, []);

    function getEmptyPoll() {
        const yesno = [ ...(localeMap["poll02.answers.A"] ? localeMap["poll02.answers.A"].split('\n') : []) ];
        const pqst = {
            qguid:dayjs().format('YYYYMMDDHHmmssSSS'), qseq:1, qkind:'A', 
            question:localeMap["poll02.question.example.A"], answers: yesno, nanswers:yesno.length, nwanted:1, 
            qoption:{c2win:'Majority', c2drop:'Zero'}, qroundmax:1, justadded:true,
        };

        return {
            ptitle:localeMap["poll01.ptitle.example"], pwhen:'', pkind:'PRA', pstatus:'A ', 
            uname:(uauth ? uauth.uname : localeMap["entrance.trial.example.name"]), xrole:'P', locale:ulocale, nelg:0, nmax:0, natt:0, nstaff:0,
            pqstlist:[ pqst ],
            voterlist: [],
        };
    }
    
    function getSamplePoll() {
        const yesno = [ ...(localeMap["poll02.answers.A"] ? localeMap["poll02.answers.A"].split('\n') : []) ];
        const alt12 = [ ...(localeMap["poll02.answers.S"] ? localeMap["poll02.answers.S"].split('\n') : []) ];
        const cad12 = [ ...(localeMap["poll02.answers.E"] ? localeMap["poll02.answers.E"].split('\n') : []) ];
        const cands = [ "손병희", "이승훈", "한용운", "권동진", "권병덕", "길선주", "김병조", "김완규", "나용환", "나인협", "박동완", "백용성", "박준승", "신석구", "신흥식", "양전백", "양한묵", "오세창", "오화영", "유여대", "이갑성", "이명룡", "이종일", "이종훈", "이필주", "임예환", "최성모", "홍기조", "홍병기" ];
        const qstForElection = "선출된 대표는 선출된 일자로부터 향후 2 년간 조직을 대표하며 재정과 사무의 최고책임자이자 공동의회 의장으로서의 역할을 수행합니다. 당신은 2024년도 대표로 누구를 선택하시겠습니까 ?";
        return {
            ptitle:localeMap["poll01.ptitle.example"], pwhen:'', pkind:'PRA', pstatus:'A ', 
            uname:(uauth ? uauth.uname : 'Pollerz.net'), xrole:'P', locale:ulocale, nelg:0, nmax:0, natt:0, nstaff:0,
            pqstlist:[
                {qguid:dayjs().format('YYYYMMDDHHmmss')+'001', qseq:1, qkind:'A', question:localeMap["poll02.question.example.A"], qroundmax:1, answers: yesno, nanswers:yesno.length, nwanted:1, qoption:{c2win:'Majority', c2drop:'Zero'}},
                {qguid:dayjs().format('YYYYMMDDHHmmss')+'002', qseq:2, qkind:'S', question:localeMap["poll02.question.example.S"], qroundmax:3, answers: alt12, nanswers:alt12.length, nwanted:2, qoption:{c2win:'Most',c2drop:'Zero'}},
                {qguid:dayjs().format('YYYYMMDDHHmmss')+'003', qseq:3, qkind:'E', question:localeMap["poll02.question.example.E"], qroundmax:3, answers: cad12, nanswers:cad12.length, nwanted:1, qoption:{c2win:'Majority',c2drop:'Zero'}},
                // {qguid:dayjs().format('HHmmssSSS')+'3', qseq:3, qkind:'E', question:qstForElection, qroundmax:3, answers: cands, nanswers:cands.length, nwanted:1, qoption:{c2win:'Majority',c2drop:'Zero'}},
            ],
            voterlist: [],
        };
    }
    
    function onSavePoll(login_or_signup) {
        if (!poll) return;
        if (uauth) {
            let onPostSuccess = (newpguid)=>{
                setOpen(false);
                setPoll({});
                if (typeof onSuccess === 'function') onSuccess(newpguid);
            };
            let onPostError = (error)=>{
                if (typeof error === 'string') {
                    // failure during validity check (before posting)
                } else {
                    // failure after posting the request to PzServer
                }
            };
            // post a request to server, in order to create the event
            postCreatePoll(poll, onPostSuccess, onPostError);
        } else {
            let path = (login_or_signup === 'login' ? "/auth/login" : "/auth/signup");
            setOpen(false);                                 // close the dialog
            navigate(path, { state: { newpoll: poll } });   // navigate to LogIn or SignUp page 
        }
    }

    //                          h1:  1.875   h2:  1.50  h3: 1.25    h4: 1.125   h5: 1.00    h6: 0.875
    // 5xl: 3.00    4xl: 2.25   3xl: 1.875   2xl: 1.50  xl: 1.25    lg: 1.125   md: 1.00    sm: 0.875   xs: 0.75
    let iconsize = " ", TEXTSIZE = "text-"+size, padsize = " ", gapsize = " ";
    if      (size === '4xl') { iconsize = "1.50rem "; padsize = "px-9 py-3 "; gapsize = "gap-3 "; }
    else if (size === '3xl') { iconsize = "1.25rem "; padsize = "px-9 py-3 "; gapsize = "gap-3 "; }
    else if (size === '2xl') { iconsize = "1.12rem "; padsize = "px-9 py-3 "; gapsize = "gap-3 "; }
    else if (size === 'xl')  { iconsize = "1.00rem "; padsize = "px-6 py-2 "; gapsize = "gap-2 "; }
    else if (size === 'lg')  { iconsize = "0.87rem "; padsize = "px-6 py-2 "; gapsize = "gap-2 "; }
    else if (size === 'md')  { iconsize = "0.75rem "; padsize = "px-3 py-1 "; gapsize = "gap-2 "; }
    else if (size === 'sm')  { iconsize = "0.75rem "; padsize = "px-2 py-[0.1rem] "; gapsize = "gap-1 "; }
    else if (size === 'xs')  { iconsize = "0.75rem "; padsize = "px-1 py-[0.1rem] "; gapsize = "gap-1 "; }
    const clscolor  = " cursor-pointer bg-purple-900 text-white disabled:opacity-50 " + (disabled ? " " : " hover:bg-purple-700 ");
    const clsring   = " border border-purple-900 rounded-3xl shadow-sm " + padsize  + (disabled ? " " : " ");
    const txtshape = " font-semibold " + TEXTSIZE;

    return (
        <>
            <button className={clscolor+clsring+className} onClick={()=>{setOpen(true);}} disabled={disabled} >
                <div className={"w-full flex flex-row justify-start items-center " + gapsize} >
                    { useicon &&
                        <div className="bg-purple-900 p-1 text-md text-white border border-white rounded-3xl">
                            <IconButtonAdd width={iconsize} height={iconsize} />
                        </div>
                    }
                    <p className={"w-full text-center " + txtshape}>{localeMap["poll.create_event"]}</p>
                </div>
            </button>
            { open &&
                <NewPollDialog poll={poll} setPoll={setPoll} onClose={()=>setOpen(false)} 
                    onSavePoll={onSavePoll} />
            }
        </>
    );
}

function NewPollReady({ poll, size='xl', className='' }) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const text1 = (localeMap["poll02.completed.ready"] ? localeMap["poll02.completed.ready"] : '');
    const text2 = (localeMap["poll02.completed.todo"] ? localeMap["poll02.completed.todo"] : '');
    // if (poll.pqstlist.length === 1) setQSelected(0);

    return (
        <div className={"w-full h-full flex flex-col justify-center items-stretch gap-10" + className} >

            <div className="w-full flex flex-col justify-start items-center gap-5">
                <div className="w-full flex flex-row justify-center items-center gap-1">
                    <PzButton.Icon Icon={IconVotePollbox} size="8rem"
                        className="!p-4 !bg-purple-900 !text-white !rounded-[8rem]" />
                </div>
                <div className="w-full flex flex-col justify-start items-center gap-1">
                    { text1.split("\n").map((part,idx)=>{return (
                        <div key={idx} className="w-full text-center text-xl">{part}</div>
                    );}) }
                </div>
            </div>

            <div className="w-full flex flex-col justify-start items-center gap-1">
                { text2.split("\n").map((part,idx)=>{return (
                    <div key={idx} className="w-full text-center text-xl">{part}</div>
                );}) }
            </div>

        </div>
    );
}

function postCreatePoll(poll, onSuccess=(newpguid)=>{}, onError=(error)=>{}, onFinally=()=>{}) {
    if (!poll) return;
    if (poll.ptitle === '') { onError('Event title is empty'); return; }
    for (let i = 0; i < poll.pqstlist.length; i++) {
        if (poll.pqstlist[i].question === '')   { onError(`Q${i+1} question is empty`); return; }
    }
    appConfig.getAPI().post("/api/mp/create", poll)
    .then((response) => { 
        const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
        console.log("PzAPI 'mp/create' success : ", pzdata);
        if (typeof onSuccess === 'function') onSuccess(pzdata.resource); 
    })
    .catch((error)   => { 
        console.log("PzAPI 'mp/create' failure : ", error);
        if (typeof onError   === 'function') onError(error); 
    })
    .finally(()      => { 
        if (typeof onFinally === 'function') onFinally(); 
    });
}


