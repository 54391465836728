import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import PzInput from "./PzInput";

const PzTabs = {
    MUI:        PzTabsMUI,
    Custom:     PzTabsCustom,
};
export default PzTabs;


function PzTabsMUI({tabsize='xl', className='',
        labels=['Label One', 'Label Two'], panels=[<div>Panel One</div>, <div>Panel Two</div>], }) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => { setValue(newValue); };

    function CustomTabPanel({ children, selected, index, ...other }) {
        return (
            <div role="tabpanel" id={`simple-tabpanel-${index}`}  
                hidden={value !== index} {...other} >
                {value === index && <div className="w-full py-3">{children}</div>}
            </div>
        );
    }
    
    return (
        <div className={className}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} >
                    { labels.map((label,idx)=>{
                        return <Tab key={idx} label={label} id={`simple-tab-${idx}`} />;
                    }) }
                </Tabs>
            </Box>
            { panels.map((panel,idx)=>{
                return (<CustomTabPanel key={idx} value={value} index={idx}>{panel}</CustomTabPanel>);
            }) }
        </div>
    );
}

function PzTabsCustom({className='', size='xl',
    labels=['Label One', 'Label Two'], panels=[<div>Panel One</div>, <div>Panel Two</div>]}) {
    const [selection, setSelection] = React.useState([0]);

    function CustomTabPanel({ children, selected, index, ...other }) {
        return (
            <div role="tabpanel" id={`simple-tabpanel-${index}`}  
                hidden={selected !== index} {...other} >
                {selected === index && <div className="w-full">{children}</div>}
            </div>
        );
    }

    return (
        <div className={"w-full py-4 flex flex-col justify-start items-stretch" + className}>
            <div className="w-full flex flex-row justify-end items-center gap-2">
                { labels.map((label,idx)=>{
                    return (
                        <PzInput.Radio2 key={idx} value={idx} text={label} size={size}
                            selection={selection} setSelection={setSelection} 
                            onChange={(e,oldv,newv)=>{e.stopPropagation();}}/>
                    );
                }) }
            </div>
            <div className="w-full h-[1px] bg-slate-300 mt-1 mb-4"></div>
            <div className="w-full flex flex-col justify-start items-stretch">
                { panels.map((panel,idx)=>{
                    return (<CustomTabPanel key={idx} selected={selection[0]} index={idx}>{panel}</CustomTabPanel>);
                }) }
            </div>
        </div>
    );
}

