import React from "react";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import PzSidebar from "./PzSidebar";
import Navbar from "./Navbar";
import PzBottombar from "./PzBottombar";
import LoginSessionHandler from "../auth/LoginSessionHandler";
import PzDialog from "../common/PzDialog";

export default function BasicLayout() {
    const [cookies] = useCookies();
    const uauth = (cookies && cookies.uauth ? jwtDecode(cookies.uauth) : null);
    const upoll = useSelector(state=>state.upcfg.uPoll);
    const [open4Sidebar, setOpen4Sidebar] = React.useState(false);

    return (
        <div className="w-[100vw] min-h-[100vh] overflow-y-scroll flex flex-row justify-start items-stretch relative">

            {uauth &&
                <PzSidebar uauth={uauth} upoll={upoll} open={open4Sidebar} setOpen={(open)=>{setOpen4Sidebar(open);}} />
            }

            <div className='w-full h-full min-h-[100vh] flex flex-col gap-y-5'>

                <Navbar uauth={uauth} open4Sidebar={open4Sidebar} setOpen4Sidebar={(open)=>{setOpen4Sidebar(open);}} />

                <Outlet /> 

                <PzBottombar className="pt-20 pb-10" />

                {/* {uauth && <PzDialog.SendFeedback /> } */}

            </div>

            { uauth &&    // this component will automatically terminate the session, or let user extend it 
                <LoginSessionHandler uauth={uauth} />     
            }

        </div>
    );
}
