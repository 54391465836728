import React from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PollCard from "../../event/PollCard";
import UserSplash from "../UserSplash";

export default function XStaffResult() {
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const localeMap = useSelector(utils.selectLocaleMap);
    const [currQidx, setCurrQidx] = React.useState(-1);
    const [open, setOpen] = React.useState({q:false, g:false, x:true})

    React.useEffect(() => {
        // if (upoll && upoll.pqstlist && upoll.pqstlist.length >= 1 && currQidx == -1) setCurrQidx(0);
    }, []);

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'S' && upoll.xrole !== 'M' && upoll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event"} /> );
    }  

    return (
        <PzArea.TBoardInContainer>

            <div className="w-full px-5 flex flex-col justify-start items-start gap-5">
                <PollCard.Item poll={upoll} size='lg' expandable={true} />
            </div>

            <div className="w-full flex flex-row justify-between items-center">
                <h2>{localeMap["menu.staff.sresult"]}</h2>
                <label className="text-lg font-bold">{localeMap["poll.card.staff.myrole."+upoll.xrole?.toLowerCase()]}</label>
            </div>

        </PzArea.TBoardInContainer>
    );
}
