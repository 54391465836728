import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
// import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { ReactComponent as IconChevron } from "../../resource/icons/pz-chevron-down.svg";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import PzPop from "../../common/PzPop";
import PzInput from "../../common/PzInput";
import PzUnderDev from "../../common/PzUnderDev";
import PzText from "../../common/PzText";
import PzUtcOffsetField from "../../common/PzUtcOffsetField";
import ModalToChangePWord from "../../auth/ModalToChangePWord";

export default function MyAccount() {
    const [cookies,removeCookie] = useCookies(['uauth', 'vauth']);
    const uauth = (cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const vauth = (cookies?.vauth ? jwtDecode(cookies.vauth) : null);
    const upoll = useSelector(state=>state.upcfg.uPoll);
    const ulocale = useSelector((state)=>state.ucfg.locale);
    const localeMap = useSelector(utils.selectLocaleMap);
    const utzone = useSelector((state)=>state.ucfg.timezone); // timezone from user settings
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open4ChPW, setOpen4ChPW] = React.useState(false);
    const [open4Timezone, setOpen4Timezone] = React.useState(false);
    const [open4Name, setOpen4Name] = React.useState(false);
    const [open4Rtbf, setOpen4Rtbf] = React.useState(false);
    const [open4RtbfData, setOpen4RtbfData] = React.useState(false);
    const [open4RtbfAcct, setOpen4RtbfAcct] = React.useState(false);
    const rtbf_data_keyword = localeMap["myaccount.rtbf.data.keyword"];
    const rtbf_acct_keyword = localeMap["myaccount.rtbf.acct.keyword"];
    const [text4RtbfData, setText4RtbfData] = React.useState('');
    const [text4RtbfAcct, setText4RtbfAcct] = React.useState('');
    const [loading4RtbfData, setLoading4RtbfData] = React.useState(false);
    const [loading4RtbfAcct, setLoading4RtbfAcct] = React.useState(false);
    const [settings, setSettings] = React.useState({
		uguid:    uauth?.uguid,
		uemail:   uauth?.uemail,
		uname:    uauth?.uname,
		country:  '',
		locale:   ulocale,
		timezone: utzone,
    })
    const [balance, setBalance] = React.useState({
        fq: {
            qtrem: 0,
            qtmax: 0,
            qtlast: '',
        },
    });
    const now = dayjs();
    const next_month_in_utc = now.utc().add(1, 'month').date(1).hour(0).minute(0)
    const next_refill = utils.getTimeInUserTimezone(next_month_in_utc, "YYYY/MM/DD h:mm A", utzone) ;
    const fqinf = appConfig.getFQInf();

    React.useEffect(() => {
        if (!uauth) { navigate("/"); return; }
        fetchMyAccount(settings?.uname, settings?.country);
    }, []);

    const fetchMyAccount = (new_name, new_country)=>{
        // fetch basic user settings
        appConfig.getAPI().post("/api/my/account", {uname:new_name, country:new_country, timezone:utzone, requestedat:dayjs().toISOString()})
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            setSettings(pzdata.resource);
        })
        .catch((error) => {
            console.log("PzAPI 'account' error : ", error);
        })
        .finally(() => {
        });
        // fetch user account information (Free Quota and Payment methods)
        appConfig.getAPI().post("/api/my/balance")
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            setBalance(pzdata.resource);
        })
        .catch((error) => {
            console.log("PzAPI 'balance' error : ", error);
        })
        .finally(() => {
        });
    };

    const onChangeUserName = (newname)=>{
        if (!newname || newname.length < 1) return;
        fetchMyAccount(newname, settings?.country);
    };

    function onClickToRtbfData() {
        console.log("RTBF data : ", text4RtbfData);
        setLoading4RtbfData(true);
        appConfig.getAPI().post("/api/my/rtbf/data", {uguid:uauth.uguid})
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pintro  = pzdata.resource; // {}
        })
        .catch((error) => {
            console.log("PzAPI 'rtbf/data' error : ", error);
        })
        .finally(() => { setLoading4RtbfData(false); });
    }

    function onClickToRtbfAcct() {
        setLoading4RtbfAcct(true);
        appConfig.getAPI().post("/api/my/rtbf/acct", {uguid:uauth.uguid})
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            removeCookie("uauth", null);
            navigate("/forgotten?l="+ulocale);
        })
        .catch((error) => {
            console.log("PzAPI 'rtbf/acct' error : ", error);
        })
        .finally(() => { setLoading4RtbfAcct(false); });
    }

    return (
        <PzArea.Container>
            <PzArea.MwBoard>
                <div className="w-full flex flex-row justify-between">
                    <h3>{localeMap["myaccount.title"]}</h3>
                    <div>&nbsp;</div>
                </div>

                <div className="w-[90%] grid grid-cols-[auto_1fr] justify-start items-start gap-x-10 gap-y-5">

                    <h4 className="font-bold">{localeMap["myaccount.user.label"]}</h4>      
                    <div className="text-sm grid grid-cols-[11rem_1fr] gap-x-4 gap-y-2">
                        <h4 className="">{localeMap["myaccount.username.label"]} : </h4>       
                        <div className="flex flex-row justify-start items-center gap-2">
                            <PzPop.Dropdown 
                                
                                base={
                                    <PzButton.Minimal className="px-0 py-0" gap="gap-1" >
                                        <h5>{uauth?.uname || ""}</h5>
                                        <IconChevron width='1.2rem' height='1.2rem' className={`${open4Name && `rotate-180`}`} />
                                    </PzButton.Minimal>
                                }
                                popover={
                                    <ul className="p-2 flex flex-col items-start justify-start text-left gap-0">
                                        <li key={"uname"}>
                                            <PzText.Field placeholder="" onEnter={(text)=>{ setOpen4Name(false); onChangeUserName(text); }}/>
                                        </li>
                                    </ul>
                                } 
                                ha={'left'} open={open4Name} setOpen={setOpen4Name} />
                        </div>

                        <h4 className="">{localeMap["myaccount.userid.label"]} : </h4>      
                        <div className="flex flex-row justify-start items-center gap-2">
                            <h5>{uauth?.uemail || ""}</h5>
                            {/* <PzButton.Std text={localeMap["myaccount.userid.change"]} size='sm' bold={false} /> */}
                        </div>

                        <h4 className="">{localeMap["myaccount.userpw.label"]} : </h4>      
                        <div className="flex flex-row justify-start items-center gap-2">
                            <PzButton.Std text={localeMap["myaccount.userpw.change"]} size='md' onClick={()=>{setOpen4ChPW(true);}} />
                        </div>
                    </div>

                    <h4 className="font-bold">{localeMap["myaccount.env.label"]}</h4>      
                    <div className="text-sm grid grid-cols-[11rem_1fr] gap-x-4 gap-y-2">
                        <h4 className="">{localeMap["myaccount.env.country.label"]} : </h4>   
                        <h5>{settings?.country}</h5>

                        <h4 className="">{localeMap["myaccount.env.language.label"]} : </h4>   
                        <h5>{appConfig.getLanguageFromLocale(ulocale, true)}</h5>

                        <h4 className="">{localeMap["myaccount.env.timezone.label"]} : </h4>   
                        <div className="flex flex-row justify-start">
                            <PzPop.Dropdown 
                                
                                base={
                                    <PzButton.Minimal className="px-0 py-0" gap="gap-1" >
                                        <h5>{utzone}</h5>
                                        <IconChevron width='1.2rem' height='1.2rem' className={`${open4Timezone && `rotate-180`}`} />
                                    </PzButton.Minimal>
                                }
                                popover={
                                    <div className="p-2 flex flex-col items-start justify-start text-left gap-2">
                                        { utzone !== dayjs.tz.guess() &&
                                        <PzButton.Default className="!p-1.5" onClick={()=>{setOpen4Timezone(false); dispatch(utils.setTimezone(dayjs.tz.guess()));}}>
                                            <label>{`Change to '${dayjs.tz.guess()}'`}</label>
                                        </PzButton.Default>
                                        }
                                        <PzUtcOffsetField onEnter={(text)=>{ setOpen4Timezone(false); dispatch(utils.setTimezone(text)); }}/>
                                    </div>
                                } 
                                ha={'left'} open={open4Timezone} setOpen={setOpen4Timezone} />
                        </div>
                    </div>

                    <h4 className="font-bold">{localeMap["myaccount.quota.label"]}</h4>      
                    <div className="text-sm grid grid-cols-[11rem_1fr] gap-x-4 gap-y-2">
                        <h4>{localeMap["myaccount.quota.remain.label"]} : </h4>
                        <h5>{balance.fq.qtrem === fqinf ? '∞' : balance.fq.qtrem}</h5>
                        <h4>{localeMap["myaccount.quota.monthly.label"]} : </h4>
                        <h5>{balance.fq.qtmax === fqinf ? '∞' : balance.fq.qtmax}</h5>
                        <h4>{localeMap["myaccount.quota.nextrefill.label"]} : </h4>
                        <h5>{next_refill}</h5>
                    </div>

                    <h4 className="font-bold">{localeMap["myaccount.paym.label"]}</h4>      
                    <div className="text-sm grid grid-cols-[11rem_1fr] gap-x-4 gap-y-2">
                        <PzUnderDev />
                        <p> &nbsp; </p>
                    </div>

                    <div className="flex flex-col justify-start items-center">
                        {localeMap["myaccount.rtbf.label"] ?
                            localeMap["myaccount.rtbf.label"].split('\n').map((part, idx)=>(
                                <h4 key={idx} className="font-bold">{part.trim()}</h4>      
                            ))
                        :
                            <h4 className="font-bold">RightToBeForgotten</h4>
                        }
                    </div>
                    <div className="text-sm flex flex-col justify-start items-start gap-y-2">
                        <div className="text-sm flex flex-row justify-start items-center gap-2">
                            <h4 className="!font-normal !text-slate-700">{localeMap["myaccount.rtbf.warning"]}</h4>
                            <PzButton.Minimal className="px-0 py-0" gap="gap-1" onClick={()=>{setOpen4Rtbf(!open4Rtbf);}} >
                                <IconChevron width='1.2rem' height='1.2rem' className={`${open4Rtbf && `rotate-180`}`} />
                            </PzButton.Minimal>
                        </div>
                        { open4Rtbf &&
                            <>
                            <p className="w-[90%] max-w-[30rem] text-xs text-slate-700">
                                {localeMap["myaccount.rtbf.exception"]}
                            </p>
                            <div className="text-sm flex flex-row justify-start items-center gap-2">
                                <h4>{localeMap["myaccount.rtbf.data.label"]}</h4>
                                <PzButton.Minimal className="px-0 py-0" gap="gap-1" onClick={()=>{setOpen4RtbfData(!open4RtbfData);}} >
                                    <IconChevron width='1.2rem' height='1.2rem' className={`${open4RtbfData && `rotate-180`}`} />
                                </PzButton.Minimal>
                            </div>
                            { open4RtbfData &&
                                <div className="px-5 flex flex-col justify-start items-start">
                                    <h5 className="text-slate-400">{utils.completeMessageText(localeMap["myaccount.rtbf.data.direction"], [rtbf_data_keyword])}</h5>
                                    <div className="flex flex-row justify-start items-center gap-4">
                                        <PzInput.ForText value={text4RtbfData} setValue={setText4RtbfData} />
                                        <PzButton.Std text={localeMap["myaccount.rtbf.data.button"]} size='md' 
                                            disabled={text4RtbfData !== rtbf_data_keyword} onClick={onClickToRtbfData} />
                                    </div>
                                </div>
                            }
                            <div className="text-sm flex flex-row justify-start items-center gap-2">
                                <h4>{localeMap["myaccount.rtbf.acct.label"]}</h4>
                                <PzButton.Minimal className="px-0 py-0" gap="gap-1" onClick={()=>{setOpen4RtbfAcct(!open4RtbfAcct);}} >
                                    <IconChevron width='1.2rem' height='1.2rem' className={`${open4RtbfAcct && `rotate-180`}`} />
                                </PzButton.Minimal>
                            </div>
                            { open4RtbfAcct &&
                                <div className="px-5 flex flex-col justify-start items-start">
                                    <h5 className="text-slate-400">{utils.completeMessageText(localeMap["myaccount.rtbf.acct.direction"], [rtbf_acct_keyword])}</h5>
                                    <div className="flex flex-row justify-start items-center gap-4">
                                        <PzInput.ForText value={text4RtbfAcct} setValue={setText4RtbfAcct} />
                                        <PzButton.Std text={localeMap["myaccount.rtbf.acct.button"]} size='md' 
                                            disabled={uauth.ispza || text4RtbfAcct !== rtbf_acct_keyword} onClick={onClickToRtbfAcct} />
                                    </div>
                                </div>
                            }
                            </>
                        }
                    </div>

                </div>

                { uauth?.ispza &&
                    <div className="w-full mt-6 flex flex-row justify-between items-center" >
                        <div className="flex flex-row justify-start items-center gap-2" >
                            <PzButton.Default onClick={()=>console.log(uauth)}>UAuth</PzButton.Default>
                            <PzButton.Default onClick={()=>removeCookie("uauth",null)}>xUAuth</PzButton.Default>
                        </div>
                        <div className="flex flex-row justify-start items-center gap-2" >
                            <PzButton.Default onClick={()=>console.log(upoll)}>UPoll</PzButton.Default>
                        </div>
                        <div className="flex flex-row justify-start items-center gap-2" >
                            <PzButton.Default onClick={()=>console.log(vauth)}>VAuth</PzButton.Default>
                            <PzButton.Default onClick={()=>removeCookie("vauth",null)}>xVAuth</PzButton.Default>
                        </div>
                    </div>
                }

                { open4ChPW &&
                    <ModalToChangePWord onClose={()=>{setOpen4ChPW(false);}} />
                }

            </PzArea.MwBoard>
        </PzArea.Container>
    );
}
