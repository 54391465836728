import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { setUPoll } from "../../config/upollConfig";
import PzArea from "../../common/PzArea";
import PzBasicModal from "../../common/PzBasicModal";
import PzButton from "../../common/PzButton";
import PzDialog from "../../common/PzDialog";
import PzInput from "../../common/PzInput";
import PzTable, { PztShortTooltip } from "../../common/PzTable";
import PollCard from "../../event/PollCard";
import UserSplash from "../UserSplash";
import EvtEditorForVoter from "../../event/EvtEditorForVoter";
import { ReactComponent as IconStepPrev } from "../../resource/icons/pz-step-prev.svg";
import { ReactComponent as IconButtonAdd } from "../../resource/icons/pz-button-add.svg";
import { ReactComponent as IconButtonSub } from "../../resource/icons/pz-button-sub.svg";
import { ReactComponent as IconToolEditor } from "../../resource/icons/pz-tool-editor.svg";

// export function getVoterSummary() {
//     return utils.completeMessageText(localeMap["poller.getready.voters." + (vState.nt===0 ? "sum0" : "sum1")], [vState.nt]);
// }

export default function EditVoterList() {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [cookies] = useCookies(['uauth']);
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // React.useEffect(() => {
    // }, []);

    function setUPoll2(new_poll) {
        dispatch(setUPoll(new_poll));
    }

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event."} /> );
    }  

    return (
        <PzArea.TBoardInContainer >

            <div className="w-full px-5 flex flex-col justify-start items-start gap-5">
                <PollCard.Item poll={upoll} size='lg' expandable={true} />
            </div>

            <h2>{localeMap["menu.poller.voterlist"]}</h2>

            <VoterList poll={upoll} setPoll={setUPoll2} />

            <div className="w-full flex flex-row justify-between items-center gap-1 mt-10">
                <PzButton.Icon Icon={IconStepPrev} size='xl' onClick={()=>{navigate(-1);}}/>
                <div className="w-auto flex flex-row justify-end items-center gap-5">
                    {/* <PzButton.Std Icon={IconStepNext} text={localeMap["menu.poller.invite"]}  size='lg' onClick={()=>{navigate("/pages/poller/invite");}} disabled={!ready2run}/>
                    <PzButton.Std Icon={IconStepNext} text={localeMap["menu.poller.runpoll"]} size='lg' onClick={()=>{setOpen4Run(true);}} disabled={!ready2run}/> */}
                </div>
            </div>

        </PzArea.TBoardInContainer>
    );
}

export function VoterList({poll, setPoll, className}) {
    const [cookies] = useCookies(['uauth']);
    var uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const localeMap = useSelector(utils.selectLocaleMap);
    const [pgOpts, setPgOpts] = React.useState({page:0,pageSize:10,pguid:poll?.pguid});
    const [pgData, setPgData] = React.useState({page:0,pageSize:10,pageData:[],total:0});
    const [open4List, setOpen4List] = React.useState(false);
    const [open4Add, setOpen4Add] = React.useState(false);
    const [open4Edit, setOpen4Edit] = React.useState(false);
    const [open4Clear, setOpen4Clear] = React.useState(false);
    const [text4List, setText4List] = React.useState('');
    const [currVoter, setCurrVoter] = React.useState(null);

    React.useEffect(() => {
        fetchVoterList(pgOpts);
    }, []);

    const fetchVoterList = (opts)=>{
        if (!uauth) return;
        appConfig.getAPI().post("/api/mp/voter/list", opts)
        .then((response) => {
            console.log("PzApi 'voterlist' success 1");
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // {total:108, page:1, pageSize:10, pageData:[...]}
            setPgData(pgdata);
            if (pgdata.total != poll.nelg) {
                let pkind = poll.pkind.substring(0,2) + (pgdata.total > 0 ? 'E' : 'A') + (poll.pkind.length > 3 ? poll.pkind.substring(3) : '');
                if (typeof setPoll === 'function') setPoll({ ...poll, nelg: pgdata.total, pkind: pkind });
            }
        })
        .catch((error) => {
            console.log("PzAPI 'voterlist' error : ", error);
        })
        .finally(() => {});
    };

    function onClickForExample() {
        appConfig.getAPI().post("/api/mp/voter/example", {pguid: poll.pguid, nvoters: 5})
        .then((response) => {
            // console.log("PzAPI 'voter/example' success");
            // const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            // const pgdata = pzdata.resource; // poll info
            fetchVoterList(pgOpts);
        })
        .catch((error) => { 
            console.log("PzAPI 'voter/example' failure (%v)", error); 
        })
        .finally(() => {});
    }

    function onClickToEditVoterDataset() {
        // setLoading(true);
        appConfig.getAPI().post("/api/mp/voter/list", {...pgOpts, pageSize:0, filter:''}) // fetch all
        .then((response) => {
            console.log("PzApi 'voterlist' success 2");
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // {total:108, page:1, pageSize:10, pageData:[...]}
            const text = extractTextFromVoters(pgdata.pageData);
            setText4List(text); // set the text for the TextArea of the entire list
            setOpen4List(true); // let the modal dialog open
            if (pgdata.total != poll.nelg || pgdata.pkind != poll.pkind) {
                if (typeof setPoll === 'function') setPoll({ ...poll, pkind:pgdata.pkind, nelg: pgdata.total });
            }
        })
        .catch((error) => {
            console.log("PzAPI 'voterlist' error : ", error);
        })
        .finally(() => {
            // setLoading(false);
        });
    }

    function onConfirmToClearAll() {
        // setLoading(true);
        appConfig.getAPI().post("/api/mp/voter/clearall", {pguid:poll.pguid}) // 
        .then((response) => {
            // console.log("PzAPI 'voter/clearall' success ");
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // poll info
            fetchVoterList(pgOpts);
            if (typeof setPoll === 'function') setPoll({ ...poll, nelg: 0, pkind: pgdata.pkind });
        })
        .catch((error) => {
            console.log("PzAPI 'voter/clearall' failure : ", error);
        })
        .finally(() => {
            // setLoading(false);
        });
    }

    function onClickToAdd(e) {
        setOpen4Add(true);
    }

    function onClickToEdit(e) {
        setOpen4Edit(true);
    }

    function onClickToSub(e) {
        
    }

    if (!poll) return (<div></div>);
    return (
        <div className={"w-full flex flex-col justify-start items-stretch gap-y-2 " + className}>
            <PzTable 
                data={pgData} opts={pgOpts} setOpts={setPgOpts} fetchData={fetchVoterList}
                rowKey="vid" searchable={true} configurable={true} 
                columns={[
                    { title: 'ID', key: 'vid',
                        render: ({vid})=>{return (<PztShortTooltip value={vid} stt={vid.length-14} end={vid.length}/>);} },
                    { title: 'Name', key: 'vname' },
                    { title: 'VCode', key: 'vcode', hidden:true },
                    { title: 'BDate', key: 'vbdate' },
                    { title: 'Phone', key: 'vphone' },
                    { title: 'Email', key: 'vemail' },
                ]}  
                buttonsL={ pgData.total > 0 || poll.pstatus >= 'Ax' ? [] :
                    [{ title: localeMap["poller.getready.voters.example"], onClick:()=>{onClickForExample();} }]
                } 
                onClickToSelectRow={(row)=>{setCurrVoter(row);}} />

            <div className="w-full flex flex-row justify-between items-center gap-4">
                <PzButton.Std text={localeMap["poll04.staff.action.clear"]} 
                    onClick={()=>{setOpen4Clear(true);}} disabled={pgData.total === 0 || poll.pstatus >= 'Ax'} />
                <div className="w-auto flex flex-row justify-start items-center gap-4">
                    <PzButton.Icon Icon={IconButtonSub}  onClick={onClickToSub}  disabled={currVoter === null || poll.pstatus >= 'Ax'} />
                    <PzButton.Icon Icon={IconToolEditor} onClick={onClickToEdit} disabled={currVoter === null} />
                    <PzButton.Icon Icon={IconButtonAdd}  onClick={onClickToAdd}  disabled={poll.pstatus >= 'Ax'} />
                </div>
                <PzButton.Std text={localeMap["poll04.staff.action.text"]} 
                    onClick={onClickToEditVoterDataset} disabled={poll.pstatus >= 'Ax'} />
            </div>

            {open4List && (
                <VoterModalToEditDataset poll={poll} setPoll={setPoll}
                    text4List={text4List} 
                    onClose={()=>{setOpen4List(false);}} 
                    onSuccess={()=>{fetchVoterList(pgOpts);}} />
            )}

            {open4Add && (
                <VoterModalToAdd poll={poll} setPoll={setPoll}
                    onClose={()=>{setOpen4Add(false);}} 
                    onSuccess={()=>{fetchVoterList(pgOpts);}} />
            )}

            {open4Edit && (
                <VoterModalToEdit poll={poll} setPoll={setPoll} voter={currVoter} setCurrVoter={setCurrVoter}
                    onClose={()=>{setOpen4Edit(false);}} 
                    onSuccess={()=>{fetchVoterList(pgOpts);}} />
            )}

            { open4Clear && 
                <PzDialog.Simple title={localeMap["poll04.slist.clear"]} 
                    message={"\"" + poll.ptitle + "\"\n"+ localeMap["poll04.slist.clearmsg"]} 
                    onClose={()=>{setOpen4Clear(false);}}
                    onButtonCancel={()=>{setOpen4Clear(false);}}
                    onButtonOk={onConfirmToClearAll} />
            }

        </div>
    );
}

function VoterModalToEditDataset({poll, setPoll, text4List, onClose, onSuccess}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [lastText,  setLastText] = React.useState(text4List);
    const [voterText, setVoterText] = React.useState(text4List);
    const [selection, setSelection] = React.useState('');
    const [errMsgKey, setErrMsgKey] = React.useState('');

    function onClickToImportVotersFromText(voter_text) {
        setLastText(voter_text);
        const [voters, errmsg, errtxt] = extractVotersFromText(voter_text, poll.locale);
        if (errmsg !== '') {
            setErrMsgKey('poll04.voter.errmsg.'+errmsg);
            setSelection(errtxt);
            // console.log(`errmsg: '${errmsg}'`, `  errtxt: '${errtxt}'`);
        } else {
            setErrMsgKey('');
            setSelection('');
            appConfig.getAPI().post("/api/mp/voter/dataset", {pguid:poll.pguid, dataset:voters}) // update the dataset
            .then((response) => {
                const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
                const pgdata = pzdata.resource; // poll info
                if (typeof setPoll === 'function') setPoll({ ...poll, nelg: pgdata.nelg, pkind: pgdata.pkind });
                if (typeof onSuccess === 'function') onSuccess();
            })
            .catch((error) => {
                console.log("PzAPI 'voter/dataset' error : ", error);
            })
            .finally(() => {
                if (typeof onClose === 'function') onClose();
            });
        }
    }

    if (!poll) return (<div></div>);
    return (
        <PzBasicModal onClose={onClose} >
            <div className="min-w-[600px] flex flex-col justify-start items-stretch p-5 gap-2">
                <h3>{utils.completeLocaleMapText(localeMap, "poll03.vlist.title", [], poll.pkind)}</h3>
                <h5 className="text-slate-400">
                    {localeMap["poll03.voter.direction1"]} <br/>
                    {localeMap["poll03.voter.direction2"]}
                </h5>
                <PzInput.ForTextArea name="voter" className="border-[1px] px-1 resize-y" rows={10} 
                    value={voterText} onChangeDone={(text)=>{setVoterText(text);}} selection={selection}/>
                <h5 className="text-slate-400 w-full text-right">
                    &nbsp;{localeMap[errMsgKey]}
                </h5>
                <div className="w-full flex flex-row justify-end">
                    <PzButton.Default onClick={()=>{onClickToImportVotersFromText(voterText);}} disabled={voterText === lastText} >
                        <h5>{localeMap["poll03.voter.button.save"]}</h5>
                    </PzButton.Default>
                </div>
            </div>
        </PzBasicModal>
        // <PzBasicModal onClose={onClose} >
        //     <div className="min-w-[600px] p-5 flex flex-col justify-start items-stretch gap-2">
        //         <h3>{utils.completeLocaleMapText(localeMap, "poll03.vlist.title", [], poll.pkind)}</h3>
        //         <label className="text-slate-400">{utils.completeLocaleMapText(localeMap, "poll03.voter.direction", [], poll.pkind)}</label>
        //         <textarea id={idVoter} name="voters" className="border-[1px] border-gray-300 px-1 resize-y"
        //             defaultValue={text4List} rows={10} placeholder={utils.completeLocaleMapText(localeMap, "poll03.voter.placeholder", [], poll.pkind)} />
        //         <div className="w-full flex flex-row justify-end">
        //             <PzButton.Default onClick={()=>{onImportVoterListFromText('');}}>
        //                 <h5>{localeMap["poll03.voter.button.save"]}</h5>
        //             </PzButton.Default>
        //         </div>
        //     </div>
        // </PzBasicModal>
    );
}

function VoterModalToAdd({poll, setPoll, onClose, onSuccess}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const initial = {pguid:poll.pguid, vname:'', vbdate:'', vphone:'', vemail:''};
    const [voter, setVoter] = React.useState(initial);
    const okToSave = (voter.vname !== '' && (voter.vemail==='' || utils.isValidEmail(voter.vemail)));

    function onClickToSave() {
        console.log("onSave to Add : ", voter);
        appConfig.getAPI().post("/api/mp/voter/add", voter)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // poll info
            if (typeof setPoll === 'function') setPoll({ ...poll, nelg: pgdata.nelg, pkind: pgdata.pkind });
            if (typeof onSuccess === 'function') onSuccess();
        })
        .catch((error) => {
            console.log("Staff add error : ", error);
        })
        .finally(() => {
            if (typeof onClose === 'function') onClose();
        });
    }

    return (
        <PzBasicModal onClose={onClose} >
            <div className="min-w-[400px] flex flex-col justify-start items-stretch p-5 gap-5">

                <h3>{utils.completeLocaleMapText(localeMap, "poll03.voter.action.add", [], poll.pkind)}</h3>

                <EvtEditorForVoter voter={voter} setVoter={setVoter} editable={true} editable_email={true} />

                <div className="w-full flex flex-row justify-end">
                    <PzButton.Default onClick={onClickToSave} disabled={!okToSave} >
                        <h5>{localeMap["poll03.voter.button.save"]}</h5>
                    </PzButton.Default>
                </div>

            </div>
        </PzBasicModal>
    );
}

function VoterModalToEdit({poll, setPoll, voter, setVoter, onClose, onSuccess}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const okToSave = (voter.vname !== '' && (voter.vemail==='' || utils.isValidEmail(voter.vemail)));

    function onSave() {
        console.log("onSave to Edit : ", voter);
        const payload = { ...voter, pguid: poll.pguid }
        appConfig.getAPI().post("/api/mp/voter/edit", payload)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // poll info
            if (typeof setPoll === 'function') setPoll({ ...poll, nelg: pgdata.nelg, pkind: pgdata.pkind });
            if (typeof onSuccess === 'function') onSuccess();
        })
        .catch((error) => {
            console.log("Voter edit error : ", error);
        })
        .finally(() => {
            if (typeof onClose === 'function') onClose();
        });
    }
    function onDelete() {
        console.log("onDelete : ", voter);
        const payload = { pguid: poll.pguid, vid: voter.vid }
        appConfig.getAPI().post("/api/mp/voter/delete", payload)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // poll info
            if (typeof setPoll === 'function') setPoll({ ...poll, nelg: pgdata.nelg, pkind: pgdata.pkind });
            if (typeof onSuccess === 'function') onSuccess();
        })
        .catch((error) => {
            console.log("Staff delete error : ", error);
        })
        .finally(() => {
            if (typeof onClose === 'function') onClose();
        });
    }

    return (
        <PzBasicModal onClose={onClose} >
            <div className="min-w-[400px] flex flex-col justify-start items-stretch p-5 gap-5">

                <h3>{utils.completeLocaleMapText(localeMap, "poll03.voter.action.edit", [], poll.pkind)}</h3>

                <EvtEditorForVoter voter={voter} setVoter={setVoter} editable={true} />

                <div className="w-full flex flex-row justify-between items-center">
                    <PzButton.Default onClick={()=>{onDelete();}}>
                        <h5>{localeMap["poll03.voter.button.delete"]}</h5>
                    </PzButton.Default>
                    <PzButton.Default onClick={()=>{onSave();}} disabled={!okToSave} >
                        <h5>{localeMap["poll03.voter.button.save"]}</h5>
                    </PzButton.Default>
                </div>

            </div>
        </PzBasicModal>
    );
}

export function extractTextFromVoters(voter_list, name_only=false) {
    if (name_only) {
        return voter_list.map(v=>v.vname).join(", ");
    } else {
        let text = "";
        for (let i = 0; i < voter_list.length; i++) {
            let v = voter_list[i];
            text += `${v.vname ? v.vname : ''}, ${v.vbdate ? v.vbdate : ''}, ${v.vphone ? v.vphone : ''}, ${v.vemail ? v.vemail : ''}\n`;
        }
        return text;
    }
}

export function extractVotersFromText(voter_text, locale) {
    let voters = [], errmsg = '', errtxt = '';
    const lines = voter_text.trim().split('\n');
    if (lines.length === 1 && lines[0].length === 0) {
        return [[], '', ''];
    } else if (lines.length === 1) {
        // IN CASE where all the voters are on a single line, separated by comma ","

        const parts = (lines[0].indexOf('\t') > 0 ? lines[0].split('\t') : lines[0].split(','));
        let v = { vname:'', vbdate:'', vemail:'', vphone:'' };
        for (let j = 0; j < parts.length; j++) {
            const part = parts[j].trim()
            if        (part.indexOf('@') > 0) {         // email
                const [email] = utils.parseEmailString(part);
                if (email) v.vemail = email;
            } else if (utils.isDigitOnly(part)) {       // all digits
                const [bdate] = utils.parseBDateString(part);
                if (bdate) v.vbdate = bdate;
                const [phone] = utils.parsePhoneString(part, locale);
                if (phone) v.vphone = phone;
            } else {                                    // name
                if (v.vname !== '') {
                    voters.push(v);
                    v = { vname:'', vbdate:'', vemail:'', vphone:'' };
                }
                if (part.length >= 1 && v.vname === '') {
                    v.vname = part;
                }
            }
        }
        if (v.vname !== '') voters.push(v);

    } else {
        // IN CASE where each voter is on a single line, contact info fields separated by comma ","

        for (let i = 0; i < lines.length; i++) {
            let line = lines[i];
            if (line.trim().length === 0) continue;
            const parts = (lines[i].indexOf('\t') > 0 ? lines[i].split('\t') : lines[i].split(','));
            let v = { vname:'', vbdate:'', vemail:'', vphone:'' };
            for (let j = 0; j < parts.length; j++) {
                const part = parts[j].trim()
                if        (part.indexOf('@') > 0) {         // email
                    const [email] = utils.parseEmailString(part);
                    if (email) v.vemail = email;
                    else break; 
                } else if (utils.isDigitOnly(part)) {       // all digits
                    const [bdate] = utils.parseBDateString(part);
                    if (bdate) { v.vbdate = bdate; continue; }
                    const [phone] = utils.parsePhoneString(part, locale);
                    if (phone) { v.vphone = phone; continue; }
                } else {                                    // name
                    if (part.length >= 1 && v.vname === '') v.vname = part;
                }
            }
            if (v.vname === '') { errmsg = 'no_name'; errtxt = line; break; }
            else voters.push(v);
        }

    }
    if (errmsg === '' && voters.length === 0) {
        errmsg = 'empty_list';
        errtxt = '';
    }
    return [voters, errmsg, errtxt];
}
