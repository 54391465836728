import React from "react";
import { useSelector, useDispatch } from "react-redux";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";

export default function Mission() {
    const localeMap = useSelector(utils.selectLocaleMap);

    const boostdm_parts = localeMap["home.goal.boostdm"].split("\n")
    const support_parts = localeMap["home.goal.servepp"].split("\n")
    const staysml_parts = localeMap["home.goal.staysml"].split("\n")

    return (
        <PzArea.Container>
            <PzArea.ABoard className="gap-12" >

                <label className={"text-3xl font-extrabold"}>{localeMap["home.page.mission.title"]}</label>

                <div className="w-full max-w-[44rem] px-10 flex flex-col gap-10">

                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-0">
                            {boostdm_parts.map((part, idx)=>{return (
                                <label key={idx} className={"text-2xl font-extrabold italic "+(idx>0 ? 'text-right' : '')}>{part}</label>
                            )})}
                        </div>
                        <p className="text-xl">{localeMap["home.goal.desc.boostdm"]}</p>
                    </div>

                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-0">
                            {support_parts.map((part, idx)=>{return (
                                <label key={idx} className={"text-2xl font-extrabold italic "+(idx>0 ? 'text-right' : '')}>{part}</label>
                            )})}
                        </div>
                        <p className="text-xl">{localeMap["home.goal.desc.support"]}</p>
                    </div>

                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-0">
                            {staysml_parts.map((part, idx)=>{return (
                                <label key={idx} className={"text-2xl font-extrabold italic "+(idx>0 ? 'text-right' : '')}>{part}</label>
                            )})}
                        </div>
                        <p className="text-xl">{localeMap["home.goal.desc.staysml"]}</p>
                    </div>
                </div>

            </PzArea.ABoard>
        </PzArea.Container>

    );
}

