import * as React from 'react';

import { TextField } from "@mui/material";

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
// import Stack from '@mui/material/Stack';
// import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
// import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { useLocaleText } from '@mui/x-date-pickers/internals';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const CustomCalendarHeaderRoot = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'center',
});

function CustomCalendarHeader(props) {
    const { currentMonth, onMonthChange } = props;

    const selectNextMonth = () => onMonthChange(currentMonth.add(1, 'month'), 'left');
    const selectPrevMonth = () => onMonthChange(currentMonth.subtract(1, 'month'), 'right');
    // const selectNextYear = () => onMonthChange(currentMonth.add(1, 'year'), 'left');
    // const selectPrevYear = () => onMonthChange(currentMonth.subtract(1, 'year'), 'right');

    return (
        <CustomCalendarHeaderRoot>
            {/* <Stack spacing={0} direction="row">
                <IconButton onClick={selectPrevYear} title="Previous year">
                    <KeyboardDoubleArrowLeftIcon />
                </IconButton>
                <IconButton onClick={selectPrevMonth} title="Previous month">
                    <ChevronLeft />
                </IconButton>
            </Stack> */}
            <IconButton onClick={selectPrevMonth} title="Previous month">
                <ChevronLeft />
            </IconButton>
            <Typography variant="body2">{currentMonth.format('MMMM YYYY')}</Typography>
            <IconButton onClick={selectNextMonth} title="Next month">
                <ChevronRight />
            </IconButton>
            {/* <Stack spacing={0} direction="row">
                <IconButton onClick={selectNextMonth} title="Next month">
                    <ChevronRight />
                </IconButton>
                <IconButton onClick={selectNextYear} title="Next year">
                    <KeyboardDoubleArrowRightIcon />
                </IconButton>
            </Stack> */}
        </CustomCalendarHeaderRoot>
    );
}

function CustomActionBar(props) {
    const { onSetToday, onAccept, onClear, onCancel, className } = props;
    const localeText = useLocaleText();
  
    return (
        <DialogActions className={className}>
            <Button onClick={(event) => { onSetToday(); }} >
                {localeText.todayButtonLabel}
            </Button>
            <div style={{flex: '1 0 0'}} />
            <Button onClick={(event) => { onCancel(); }} >
                {localeText.cancelButtonLabel}
            </Button>
            <Button onClick={(event) => { onAccept(); }} >
                {localeText.okButtonLabel}
            </Button>
        </DialogActions>
    );
}
  
export default function PzDateTimePicker({format, size='sm', defaultValue, value, minDate, maxDate, disabled, onChange, onAccept}) {
    let ICONSIZE = "1.1rem", TEXTSIZE = "medium", PGSIZE = " gap-x-2 gap-y-3 ";
    if      (size === '2xl') { ICONSIZE="2.0rem"; TEXTSIZE = "1.4rem"; PGSIZE = " gap-x-4 gap-y-6 "; }
    else if (size ===  'xl') { ICONSIZE="1.8rem"; TEXTSIZE = "1.3rem"; PGSIZE = " gap-x-4 gap-y-6 "; }
    else if (size ===  'lg') { ICONSIZE="1.5rem"; TEXTSIZE = "1.2rem"; PGSIZE = " gap-x-3 gap-y-5 "; }
    else if (size ===  'md') { ICONSIZE="1.3rem"; TEXTSIZE = "1.1rem"; PGSIZE = " gap-x-3 gap-y-5 "; }
    else if (size ===  'sm') { ICONSIZE="1.2rem"; TEXTSIZE = "1.0rem"; PGSIZE = " gap-x-2 gap-y-3 "; }
    else                     { ICONSIZE="1.1rem"; TEXTSIZE = "0.8rem"; PGSIZE = " gap-x-1 gap-y-2 "; }
    const text_props = { style:{width:'10rem', padding:'0', margin:'0', fontSize:TEXTSIZE} };

    return (
        <LocalizationProvider  className="w-[5rem] bg-slate-100 m-0 p-0" dateAdapter={AdapterDayjs}>
            <DateTimePicker 
                format={format ? format : "YYYY/MM/DD HH:mm" }
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                onAccept={onAccept}
                minDate={minDate}  maxDate={maxDate}
                disablePast={true}
                ampm={false}
                timeSteps={{ minutes: 10 }}
                slots={{ 
                    textField: (params) => <TextField variant='standard' {...params} />,
                    calendarHeader: CustomCalendarHeader,
                    actionBar: CustomActionBar,
                }} 
                TextFieldComponent={() => null}
                slotProps={{ // variant : 'standard', 'outlined', 'filled'
                    textField: { helperText: '', variant:'standard', margin:'none', inputProps:text_props}, // 'standard'/'outlined'/'filled'
                    openPickerIcon: { sx:{width:ICONSIZE, height:ICONSIZE} },
                    toolbar: { toolbarFormat: 'YYYY', toolbarPlaceholder: '??', hidden: true },
                    calendarHeader: { }, 
                }}
                sx={{color:'red', padding:0, margin:0}}
                disabled={disabled}
            />
        </LocalizationProvider>
    );
}
