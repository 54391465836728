import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useInterval } from "react-use";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import { setUPoll } from "../config/upollConfig";
import PzButton from "../common/PzButton";
import PzPop from "../common/PzPop";
import { ReactComponent as IconToolViewer } from "../resource/icons/pz-tool-viewer.svg";
import { ReactComponent as IconToolEditor } from "../resource/icons/pz-tool-editor.svg";
import EMWithPzServer from "../eventmonitor/EMWithPzServer";

const EvtVoterList = {
    Module:     EvtVoterListModule,
};
export default EvtVoterList;


function EvtVoterListModule({poll, setPoll=null, asPoller=true}) {
    const [cookies] = useCookies();
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const navigate = useNavigate();
    const location = useLocation();
    const localeMap = useSelector(utils.selectLocaleMap);
    const [vState, setVState] = React.useState({nt:0,np:0,ne:0,na:0,updated:dayjs(),folded:true});
    const dispatch = useDispatch();
    const getready = (poll?.pstatus < 'Oo' && location.pathname.endsWith('getready'));
    const TEXTSIZE = "text-lg";

    const POLL_URL_PATH = '/v/vote?p=' + poll?.pguid;

    React.useEffect(() => {
        fetchVoterCounts();
    }, []);

    useInterval(() => {
        if (!uauth || !poll || poll.pstatus !== 'Oo') return;
        EMWithPzServer.updateEventInfo(poll.pguid, poll, setPoll, '/api/run/event', poll.xrole);
    }, 1000);

    function getSummary() {
        if (poll.pstatus < 'Oo') {
            return utils.completeMessageText(localeMap["poller.getready.voters." + (vState.nt===0 ? "sum0" : "sum1")], [vState.nt]);
        } else if (poll.pstatus < 'Oz') {
            return utils.completeMessageText(localeMap["poller.getready.voters.sumo"], [poll.nmax, poll.natt]);
        } else {
            return utils.completeMessageText(localeMap["poller.getready.voters.sumx"], [poll.nmax, poll.natt]);
        }
    }
    function fetchVoterCounts() {
        if (!uauth || !poll) return;
        appConfig.getAPI().post((asPoller ? "/api/mp/voter/counts" : "/api/s/voter/counts"), {pguid: poll.pguid, claim: poll.xrole})
        .then((response) => {
            // console.log("PzAPI 'voter/counts' success");
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const counts = pzdata.resource; // {ntotal:0, nbdate:0, nphone:0, nemail:0, nany:0}
            // console.log("counts:", counts);
            setVState({ ...vState, folded: true, nt:counts.ntotal, np:counts.nphone, ne:counts.nemail, na:counts.nany, natt:counts.natt });
            if (counts.ntotal !== poll.nelg) {
                dispatch(setUPoll({ ...poll, nelg: counts.ntotal }));
            }
        })
        .catch((error) => { 
            console.log("PzAPI 'voter/counts' failure (%v)", error); 
        })
        .finally(() => {});
    }

    if      (!poll) {
        return (<label>{"Event cannot be found"}</label>);
    }  

    return (
        <>
            <div className="w-full flex flex-col justify-start items-start gap-5">
                <div className="w-full flex flex-row justify-between items-center">
                    <h3>{localeMap["poller.getready.voters"]}</h3>
                    <p className={"text-xl font-bold " + (getready ? "" : "opacity-50")}>
                        {getSummary()}
                    </p>
                </div>
                <div className="w-full pl-10 flex flex-row justify-between items-center gap-5">
                    <div className="w-full grid grid-cols-[auto_auto_auto_1fr] justify-start items-center gap-x-5 gap-y-1">
                        <label className={`text-right ${TEXTSIZE}`}>{localeMap["poller.getready.voters.nt"]} :</label>
                        <label className={`text-right ${TEXTSIZE} font-bold`}>{vState.nt}</label>
                        <label className={`text-right ${TEXTSIZE}`}>&nbsp;</label>
                        <PzPop.HelpMsg size={'lg'} msg={vState.nt == 0 ? localeMap["poller.getready.voters.nt0"] : localeMap["poller.getready.voters.nt1"]} />

                        { poll.pstatus < 'Oo' && <>
                            <label className={`text-right ${TEXTSIZE}`}>{localeMap["poller.getready.voters.na"]} :</label>
                            <label className={`text-right ${TEXTSIZE} font-bold`}>{vState.na}</label>
                            <label className={`text-right ${TEXTSIZE}`}>{`${vState.nt > 0 ? (vState.na*100/vState.nt).toFixed(0) : 0} %`}</label>
                            <PzPop.HelpMsg size={'lg'} msg={
                                vState.nt == 0 ? localeMap["poller.getready.voters.na0"] 
                                : vState.na < vState.nt ? utils.completeMessageText(localeMap["poller.getready.voters.na1"], [ vState.nt - vState.na ])
                                : localeMap["poller.getready.voters.na2"] 
                            } />
                        </> }

                        { poll.pstatus >= 'Oo' && <>
                            <label className={`text-right ${TEXTSIZE}`}>{localeMap["poller.getready.voters.nmax"]} :</label>
                            <label className={`text-right ${TEXTSIZE} font-bold`}>{poll.nmax}</label>
                            <label>&nbsp;</label>
                            <label>&nbsp;</label>
                            <label className={`text-right ${TEXTSIZE}`}>{localeMap["poller.getready.voters.natt"]} :</label>
                            <label className={`text-right ${TEXTSIZE} font-bold`}>{poll.natt}</label>
                            <label className={`text-right ${TEXTSIZE}`}>{`${poll.nmax > 0 ? (poll.natt*100/poll.nmax).toFixed(0) : 0} %`}</label>
                            <label>&nbsp;</label>
                        </> }
                    </div>
                    { poll.pstatus >= 'Ax' ? 
                        <PzButton.Icon Icon={IconToolViewer} size='xl' onClick={()=>{navigate("/pages/poller/voterlist")}} disabled={vState.nt === 0} />
                    :
                        <PzButton.Icon Icon={IconToolEditor} size='xl' onClick={()=>{navigate("/pages/poller/voterlist")}} />
                    }
                </div>
            </div>
        </>
    );
}
