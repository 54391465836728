import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import appConfig from "../../config/appConfig";
import PzArea from "../../common/PzArea";
import PzTable, {PztShortNavigate} from "../../common/PzTable";
import UserSplash from "../UserSplash";

export default function PzAdminArcEvents() {
    const navigate = useNavigate();
    const [cookies] = useCookies();
    const [pgOpts, setPgOpts] = React.useState({page:0,pageSize:10});
    const [pgData, setPgData] = React.useState({page:0,pageSize:10,pageData:[],total:0});
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);

    const fetchPollList = (opts)=>{
        appConfig.getAPI().post("/api/pza/arcevts", opts)
        .then((response)=>{
            // console.log("PzAPI 'pza/polls' fetched : ", response.data);
            let pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            let pgdata = pzdata.resource; // {total:100, page:0, pageSize:10, pageData:[...]}
            setPgData(pgdata);
        })
        .catch((error)=>{
            console.log("PzAPI 'pza/arcevts' error : ", error);
        })
        .finally(()=>{});
    };

    React.useEffect(() => {
        if (!uauth?.ispza) { navigate("/"); return; }
        fetchPollList(pgOpts);
    }, []);

    const onRequest = async (params, sorter, _) => {
        try {
            const res = await appConfig.getAPI().post("/api/pza/polls", { params, sorter });
            return res.resource;
        } catch {}
    };

    if (!uauth || !uauth.ispza) {
        return (<UserSplash msg={"Unauthorized access"} />);
    }  

    return (
        <PzArea.Container>
            <PzArea.AdminBoard >

                <h2>{"PzA - Public Events"}</h2>

                <PzTable 
                    data={pgData} opts={pgOpts} setOpts={setPgOpts} fetchData={fetchPollList}
                    rowKey="pguid" searchable={true} configurable={true}
                    columns={[
                        { title: "PGuid", key: "pguid", sorter: false,
                            render: ({pguid}) => { return (<PztShortNavigate value={pguid} stt={12} to="../pollinfo" state={{pguid:pguid,archived:true}} />); } },
                        { title: "Kind",  key: "pkind", },
                        { title: "Status", key: "pstatus", hidden:true, },
                        { title: "When",  key: "pwhen", sorter: false, datetime:'YYYY/MM/DD'},
                        { title: "Until",  key: "pwhenend", sorter: false, datetime:'YYYY/MM/DD'},
                        { title: "Poller", key: "uname", sorter: false, hidden: true, },
                        { title: "Title", key: "ptitle", sorter: false, },
                        { title: "NVoters", key: "nelg", sorter: false, hidden: false,
                        render: ({nelg,nmax,natt}) => { return (<p>{nelg}/{nmax}/{natt}</p>); } },
                    ]}
                />

            </PzArea.AdminBoard>
        </PzArea.Container>
    );
}
