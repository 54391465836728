import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

import dayjs from "dayjs";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import PzBasicModal from "../../common/PzBasicModal";
import PzSpin from "../../common/PzSpin";
import TrialAsPoller from "../../entrance/TrialAsPoller";
import TrialAsVoter from "../../entrance/TrialAsVoter";
import PollCreator from "../../event/PollCreator";

export default function Home() {
    const localeMap = useSelector(utils.selectLocaleMap);
    const ulocale = useSelector((state)=>state.ucfg.locale);
    const utzone  = useSelector((state)=>state.ucfg.utzone);
    const dispatch = useDispatch();
    const location = useLocation();
    const [counters, setCounters] = React.useState({p:1, v:1, c:1});
    const [simEvents, setSimEvents] = React.useState({poll:undefined, surv:undefined, quiz:undefined});
    const [isLoadingCounters, setLoadingCounters] = React.useState(false);
    const simpoll = (simEvents["poll_"+ulocale] ? simEvents["poll_"+ulocale] : simEvents["poll"]);
    // const simsurv = (simEvents["surv_"+ulocale] ? simEvents["surv_"+ulocale] : simEvents["surv"]);
    // const simquiz = (simEvents["quiz_"+ulocale] ? simEvents["quiz_"+ulocale] : simEvents["quiz"]);
    const imgsrcdif = appConfig.getBaseUrlForAPI() + (ulocale === 'ko' ? '/static/images/procedure_diff_ko.png' : '/static/images/procedure_diff_en.png');
    const [searchParams, setSearchParams] = useSearchParams();
    if (ulocale !== 'ko' && searchParams.get("l") === 'ko') {
        dispatch(utils.setLocale('ko'));
    }
    // console.log("ulocale:", ulocale);

    const fetchHomeInfo = ()=>{
        dispatch(utils.setLocale(''));
        setLoadingCounters(true);
        appConfig.getAPI().get("/api/home/info")
        .then((response)=>{
            // console.log("PzAPI 'info' success : ", response.data);
            let pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            setCounters(pzdata.resource.counters);
            setSimEvents(pzdata.resource.simevents);
            let cnation = pzdata.resource.client?.cnation;
            if (cnation && (!ulocale || ulocale < "aa" || ulocale > "zz")) {
                console.log("setting lc to user default: based on client nation '" + cnation + "'");
                if (cnation === "KR") dispatch(utils.setLocale("ko"));  // 
            }
        })
        .catch((error)=>{
            console.log("PzAPI 'home/info' error : ", error);
        })
        .finally(()=>{
            setLoadingCounters(false);
        })
    };

    React.useEffect(() => {
        // document.title = 'Pollerz.net';
        if (!utzone) {
            let system_tz = dayjs.tz.guess();
            // console.log("setting tz to system utzone : ", system_tz)
            dispatch(utils.setTimezone(system_tz));  // use system utzone
        }
        fetchHomeInfo();
    }, []);

    const title_parts = localeMap["home.intro.title"].split("\n")
    const subt_parts = localeMap["home.intro.subtitle"].split("\n")
    const diff_parts = localeMap["home.intro.diff.claim"].split("\n")
    const features = [
        { key: 0, label: localeMap["home.why.label0"], text: localeMap["home.why.desc0"] },
        { key: 1, label: localeMap["home.why.label1"], text: localeMap["home.why.desc1"] },
        { key: 2, label: localeMap["home.why.label2"], text: localeMap["home.why.desc2"] },
        { key: 3, label: localeMap["home.why.label3"], text: localeMap["home.why.desc3"] },
        { key: 4, label: localeMap["home.why.label4"], text: localeMap["home.why.desc4"] },
        // { key: 5, label: localeMap["home.why.label5"], text: localeMap["home.why.desc5"] },
    ];


    return (
        <PzArea.Container>
            <PzArea.HomeBoard className="gap-20">

                <div className="w-full flex flex-col justifiy-start items-center gap-4">
                    {title_parts.map((title_part, idx)=>{return (
                        <label key={idx} className="text-4xl font-extrabold">{title_part}</label>
                    )})}
                </div>

                <div className="w-full flex flex-col justifiy-start items-center gap-10">
                    <div className="w-full flex flex-col justifiy-start items-center gap-1">
                        {subt_parts.map((sub_part, idx)=>{return (
                            <label key={idx} className="text-xl font-bold text-slate-600">{sub_part}</label>
                        )})}
                    </div>
                    <div className="w-full">
                        <PzSpin spinning={isLoadingCounters} className="w-full">
                            <label className="w-full text-xl font-bold text-center text-slate-600">
                                {utils.completeLocaleMapText(localeMap, "home.intro.serving", [counters.p, counters.v, counters.c])}
                            </label>
                        </PzSpin>
                    </div>
                </div>

                <div className="w-auto grid grid-cols-[1fr_1fr] justify-center items-center gap-6">
                    <TrialSelectorButton simpoll={simpoll} size='2xl' className='w-full' />
                    <PollCreator.Button useicon={false}   size='2xl' className='w-full' />
                </div>

                <div>&nbsp;</div>

                {/* Difference */}
                <div className="w-full max-w-[44rem] flex flex-col justifiy-start items-center gap-10">
                    <label className="w-full text-3xl font-bold text-left">{localeMap["home.intro.diff.title"]}</label>
                    <div className="w-auto flex flex-col justify-start items-center gap-0">
                        {diff_parts.map((diff_part, idx)=>{return (
                            <label key={idx} className="text-2xl font-bold">{diff_part}</label>
                        )})}
                        <img src={imgsrcdif} alt=""  width={400} height={400} className="object-contain object-center mt-4"/>
                    </div>
                </div>

                {/* Why for You */}
                <div className="w-full max-w-[44rem] flex flex-col justifiy-start items-center gap-10">
                    <label className="w-full text-3xl font-bold text-left">{localeMap["home.intro.why.title"]}</label>
                    <div className="w-full flex flex-col justify-start  items-center gap-6">
                        {features.map((feature,idx) => 
                            <div key={idx} className="grid grid-cols-[6rem_1fr] gap-4">
                                <div className="flex flex-col justify-center items-center">
                                    {feature.label.split(" ").map((item, idx2) => (
                                        <label key={idx2} className="text-2xl font-bold">
                                            {item}
                                        </label>
                                    ))}
                                </div>
                                <div className="bg-white ">
                                    <p className="text-xl text-slate-600 mb-2 text-justify">
                                        {feature.text}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </PzArea.HomeBoard>

        </PzArea.Container>

    );
}

function TrialSelectorButton({simpoll, size='2xl', className=''}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [open4Trial, setOpen4Trial] = React.useState('');

    return (
        <>
            <PzButton.Std size={size} text={localeMap["home.button.try"]} 
                onClick={()=>{setOpen4Trial('?');}} className={className} />

            { open4Trial === '?' ? 
                <TrialSelectorDialog  setOpen4Trial={setOpen4Trial} onClose={()=>setOpen4Trial('')}/>
            : open4Trial === 'V' ?
                <TrialAsVoter.Dialog  poll={simpoll} onClose={()=>setOpen4Trial('')} /> 
            : open4Trial === 'P' ?
                <TrialAsPoller.Dialog poll={simpoll} onClose={()=>setOpen4Trial('')} /> 
            :
                <></>
            }
            
        </>
    );
}

function TrialSelectorDialog({setOpen4Trial=(open4Trial)=>{}, onClose=()=>{}}) {
    const localeMap = useSelector(utils.selectLocaleMap);

    return (
        <PzBasicModal onClose={onClose}>
            <div className="w-[30rem] flex flex-col justify-start items-center p-10 gap-10" >
                <PzButton.Std size='2xl' text={localeMap["home.button.try.asvoter"]} className='w-[20rem]'
                    onClick={()=>{setOpen4Trial('V');}} />
                <PzButton.Std size='2xl' text={localeMap["home.button.try.aspoller"]} className='w-[20rem]'
                    onClick={()=>{setOpen4Trial('P');}} />
            </div>
        </PzBasicModal>
    );
}

