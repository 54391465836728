import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function PzSpin({children, spinning, color, className=''}) {
    return (
        <div className={"relative z-0 " + className}>
            <div className="w-full flex flex-col justify-row items-stretch">
                {children}
            </div>
            {spinning &&
            <div className="absolute inset-0 flex justify-center items-center z-1">
                <CircularProgress color={color}/>
            </div>
            }
        </div>
    );
}