import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import { setUPoll } from "../config/upollConfig";
import { ReactComponent as IconMenu } from "../resource/icons/pz-hamburger-menu.svg";
import { ReactComponent as IconChevron } from "../resource/icons/pz-chevron-down.svg";
import { ReactComponent as IconCheck } from "../resource/icons/pz-check.svg";
import { ReactComponent as IconEmpty } from "../resource/icons/pz-void-empty.svg";
import { ReactComponent as IconUser } from "../resource/icons/pz-user-b1.svg";
import { ReactComponent as IconLicense } from "../resource/icons/pz-license.svg";
import { ReactComponent as IconLogOut } from "../resource/icons/pz-logout.svg";
import { ReactComponent as IconPollBox } from "../resource/icons/pz-vote-pollbox.svg";
import { ReactComponent as IconToolWorld } from "../resource/icons/pz-tool-world.svg";
import PzButton from "../common/PzButton";
import PzDialog from "../common/PzDialog";
import PzPop from "../common/PzPop";

export default function Navbar({uauth, open4Sidebar, setOpen4Sidebar}) {
    const location = useLocation();
    const isAuthPage = location.pathname.startsWith("/auth/");
    const localeMap = useSelector(utils.selectLocaleMap);
    const svScreen = (window.innerWidth <= 300 && window.innerWidth < window.innerHeight); // Note that it's not updated with resize
    const navigate = useNavigate();
    const ITEMSIZE = (window.innerWidth < 400 ? 'lg' : 'xl');
    // console.log('window.innerWidth=', window.innerWidth, '  ITEMSIZE=', ITEMSIZE);

    // Window Size Change
    // const handleWindowSizeChange = () => {
    //     setScreenSV(window.innerWidth <= 500 && window.innerWidth < window.innerHeight);
    // }
    // React.useEffect(() => {
    //     window.addEventListener('resize', handleWindowSizeChange);
    //     return () => {
    //         window.removeEventListener('resize', handleWindowSizeChange);
    //     }
    // }, []);

    return (
        <div className="w-full h-14 px-4 py-0 top-0 flex flex-row justify-between items-center">

            <div className="w-fit h-full flex flex-row items-center gap-4">
                {uauth && !open4Sidebar && 
                    <button className="w-auto h-auto cursor-pointer text-slate-600 hover:text-slate-900" onClick={()=>setOpen4Sidebar(true)} >
                        <IconMenu width="1.4rem" height="1.4rem" />
                    </button>
                }
                {!open4Sidebar && 
                    <button className="cursor-pointer text-slate-600 hover:text-slate-900" onClick={()=>navigate("/")} >
                        <label className="text-2xl font-extrabold">Pollerz.net</label>
                    </button>
                }
            </div>

            <div className={"w-fit h-full flex flex-row items-center " + (window.innerWidth < 500 ? "gap-1" : "gap-2")}>

                <NavbarItemLocale size={ITEMSIZE} />

                { uauth ?
                    <>
                        <NavbarItemMyEvents size={ITEMSIZE} />
                        <NavbarItemLoggedIn size={ITEMSIZE} />
                    </>
                : isAuthPage ?
                    <> </>
                : svScreen ?
                    <PzPop.Dropdown 
                        button={
                            <IconMenu width="1.4rem" height="1.4rem" />
                        }
                        popover={
                            <ul className="p-2 flex flex-col items-start justify-center text-center gap-0">
                                <li>
                                    <PzButton.Minimal className="px-2 py-1" onClick={()=>navigate("/getstarted")} disabled={appConfig.version==='ALPHA'} >
                                        <h4>{localeMap["menu.home.features"]}</h4>
                                    </PzButton.Minimal>
                                </li>
                                <li>
                                    <PzButton.Minimal className="px-2 py-1" onClick={()=>navigate("/pricing")} disabled={appConfig.version==='ALPHA'} >
                                        <h4>{localeMap["menu.home.pricing"]}</h4>
                                    </PzButton.Minimal>
                                </li>
                                <li>
                                    <PzButton.Minimal className="px-2 py-1" onClick={()=>navigate("/auth/login")} >
                                        <h4>{localeMap["menu.auth.login"]}</h4>
                                    </PzButton.Minimal>
                                </li>
                                <li>
                                    <PzButton.Minimal className="px-2 py-1" onClick={()=>navigate("/auth/signup")} >
                                        <h4>{localeMap["menu.auth.signup"]}</h4>
                                    </PzButton.Minimal>
                                </li>
                            </ul>
                        } 
                        ha={'right'} />
                :
                    <>
                        <PzButton.NavMenu text={localeMap["menu.home.features"]} path={"/about/features"} 
                            size={ITEMSIZE} disabled={false} className="font-semibold px-2 py-1 mb-1" />
                        <PzButton.NavMenu text={localeMap["menu.home.pricing"]} path={"/about/pricing"} 
                            size={ITEMSIZE} disabled={false} className="font-semibold px-2 py-1 mb-1" />
                        <PzButton.NavMenu text={localeMap["menu.auth.login"]} path={"/auth/login"} 
                            size={ITEMSIZE} disabled={false} className="font-semibold px-2 py-1 mb-1" />
                        <PzButton.NavMenu text={localeMap["menu.auth.signup"]} path={"/auth/signup"} 
                            size={ITEMSIZE} disabled={false} className="font-semibold px-2 py-1 mb-1" />
                    </>
                }

            </div>

        </div>
    );
}

function NavbarItemLocale({size='xl'}) {
    const ulocale = useSelector((state)=>state.ucfg.locale);
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const TEXTSIZE = "text-" + size;
    const ICONSIZE = (size==='3xl' ? "1.9rem" : size==='2xl' ? "1.6rem" : size==='xl' ? "1.3rem" : "1.0rem")
    // console.log(appConfig.getSupportedLocales());

    return (
        <PzPop.Dropdown 
            base={
                <PzButton.Minimal className="px-1" gap="gap-1">
                    <IconToolWorld width={ICONSIZE} height={ICONSIZE} />
                    <label className={TEXTSIZE + " font-bold"}>{appConfig.getLanguageFromLocale(ulocale, false)}</label>
                    <IconChevron width={ICONSIZE} height={ICONSIZE} className={`${open && `rotate-180`}`} />
                </PzButton.Minimal>
            } 
            popover={
                <ul className="p-2 flex flex-col items-start justify-start items-stretch text-left gap-0">
                    {appConfig.getSupportedLocales().map((loc) => (
                        <li key={loc}>
                            <PzButton.Minimal className="w-full px-1 py-1" onClick={()=>{ dispatch(utils.setLocale(loc)); setOpen(false); }} >
                                <div className="w-full grid grid-cols-[auto_1fr] justify-start items-center gap-2">
                                    {loc === ulocale ?
                                        <IconCheck width={18} height={18}  />
                                    :
                                        <IconEmpty width={18} height={18} />
                                    }
                                    <h5 className="w-full text-left">{appConfig.getLanguageFromLocale(loc, true)}</h5>
                                </div>
                            </PzButton.Minimal>
                        </li>
                    ))}
                    {/* <li key="clear">
                        <PzButton.Minimal className="w-full px-1 py-1" onClick={()=>{ dispatch(utils.setLocale('')); setOpen(false); }} >
                            <div className="w-full grid grid-cols-[auto_1fr] justify-start items-center gap-2">
                                <IconEmpty width={18} height={18} />
                                <h5 className="w-full text-left">Clear Locale</h5>
                            </div>
                        </PzButton.Minimal>
                    </li> */}
                </ul>
            }
            ha={'right'} open={open} setOpen={setOpen} />
    );
}

function NavbarItemMyEvents({size='xl'}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const navigate = useNavigate();
    const TEXTSIZE = 'text-' + size;

    return (
        <div className="">
            <PzButton.Minimal className="px-1" gap="gap-1" onClick={()=>{navigate('/pages/myevents')}}>
                <IconPollBox width="1.2rem" height="1.2rem" />
                <label className={TEXTSIZE + " font-bold"}>{localeMap["menu.myevents"]}</label>
            </PzButton.Minimal>
        </div>
    );
}

function NavbarItemLoggedIn({size='xl'}) {
    const [cookies, removeCookie] = useCookies();
    const dispatch = useDispatch();
    const localeMap = useSelector(utils.selectLocaleMap);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const TEXTSIZE = "text-" + size;
    let   userid = getUseridFromCookies(cookies);

    function getUseridFromCookies(cookies) {
        let uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
        let uname = (uauth?.uname ? uauth.uname : "unknown");
        return uname;
    }

    function onLogout() {
        setOpen(false);
        removeCookie("uauth", null);    // Session closed
        dispatch(setUPoll(null));       // clear the current poll selection
        navigate('/');                  // 
        // navigate(0);                 // let the current page reload
    }

    // React.useEffect(() => {
    //     userid = getUseridFromCookies(cookies);
    // }, [cookies]);

    return (
        <PzPop.Dropdown 
            base={
                <PzButton.Minimal className="px-1" gap="gap-1" >
                    <IconUser width="1.2rem" height="1.2rem" />
                    <label className={TEXTSIZE + " font-bold"}>{userid}</label>
                    <IconChevron width="1.2rem" height="1.2rem" className={`${open && `rotate-180`}`} />
                </PzButton.Minimal>
            }
            popover={
                <ul className="w-full p-2 flex flex-col justify-start items-start text-center gap-0">
                    <li>
                        <PzButton.Minimal className="w-full px-2 py-1" gap="gap-2" onClick={()=>{navigate("/pages/myaccount"); setOpen(false);}}>
                            <IconLicense width="1.2rem" height="1.2rem" />
                            <label className={TEXTSIZE + " font-bold w-full text-left"}>{localeMap["menu.myaccount"]}</label>
                        </PzButton.Minimal>
                    </li>
                    <li>
                        <PzButton.Minimal className="w-full px-2 py-1" gap="gap-2" onClick={() => onLogout()} >
                            <IconLogOut width="1.2rem" height="1.2rem" />
                            <label className={TEXTSIZE + " font-bold w-full text-left"}>{localeMap["menu.auth.logout"]}</label>
                        </PzButton.Minimal>
                    </li>
                </ul>
            } 
            ha={'right'} open={open} setOpen={setOpen} />
    );
    
}

