import React from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';

import { utils } from "../config/userConfig";
import PzButton from './PzButton'
import PzSearchField from './PzSearchField'
import PzPop from './PzPop'
import { ReactComponent as IconButtonAdd } from "../resource/icons/pz-button-add.svg";
import { ReactComponent as IconButtonEdit } from "../resource/icons/pz-tool-editor.svg";
import { ReactComponent as IconButtonView } from "../resource/icons/pz-tool-viewer.svg";
import { ReactComponent as IconChevron } from "../resource/icons/pz-chevron-down.svg";
import { ReactComponent as IconCheckbox0 } from "../resource/icons/pz-checkbox-0.svg";
import { ReactComponent as IconCheckbox1 } from "../resource/icons/pz-checkbox-1.svg";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function PzTable({data=[], columns=[], rowKey='', size='md', className='',
    searchable=false, configurable=false, allicon=false, opts={}, setOpts=()=>{}, fetchData=()=>{}, 
    buttonsL=[], buttonsR=[], 
    onClickToSelectRow=undefined, onClickToAddRow=undefined, onClickToEditRow=undefined, onClickToViewRow=undefined}) {
    // Note that MUI Table's page index is zero-based.  'PzTable' is zero-based too.
    const [cfgOpen, setCfgOpen] = React.useState(false);
    const isListed = (data && Array.isArray(data));
    const isPaged = (data && opts && data.total !== undefined && data.page !== undefined && Array.isArray(data.pageData));
    let datarows = (isPaged ? data.pageData : (isListed ? data : []));
    let vizlist = [];
    if (!isListed && !isPaged) {
        console.log("invalid 'data' for PzTable :", data);
    } else if (!Array.isArray(columns)) {
        console.log("invalid 'columns' for PzTable :", columns);
    }
    for (let i = 0; columns && i < columns.length; i++) {
        vizlist.push(columns[i].hidden ? false : true);
    }
    const [visibility, setVisibility] = React.useState(vizlist);
    const [selectedRow, setSelectedRow] = React.useState(-1);
    const utzone = useSelector((state)=>state.ucfg.timezone);  // timezone from user settings
  
    const onSearch = (text)=>{
        function cleanup_search_text(text) {
            let text_split = text.split(",");
            let clean_list = [];
            for (let i = 0; i < text_split.length; i++) {
                let entry = text_split[i].trim();
                if (!entry || entry.length==0) continue;
                let cname = '', cop = '', cval = '';
                if (entry.indexOf("<=") >= 0) {
                    let idx = entry.indexOf("<=");  cop = "<="; cname = entry.substring(0,idx); cval = entry.substring(idx+2);
                } else if (entry.indexOf("<") >= 0) {
                    let idx = entry.indexOf("<");   cop = "<";  cname = entry.substring(0,idx); cval = entry.substring(idx+1);
                } else if (entry.indexOf(">=") >= 0) {
                    let idx = entry.indexOf(">=");  cop = ">="; cname = entry.substring(0,idx); cval = entry.substring(idx+2);
                } else if (entry.indexOf(">") >= 0) {
                    let idx = entry.indexOf(">");   cop = ">";  cname = entry.substring(0,idx); cval = entry.substring(idx+1);
                } else if (entry.indexOf("==") >= 0) {
                    let idx = entry.indexOf("==");  cop = "=";  cname = entry.substring(0,idx); cval = entry.substring(idx+2);
                } else if (entry.indexOf("=") >= 0) {
                    let idx = entry.indexOf("=");   cop = "=";  cname = entry.substring(0,idx); cval = entry.substring(idx+1);
                } else if (entry.indexOf(":") >= 0) {
                    let idx = entry.indexOf(":");   cop = "=";  cname = entry.substring(0,idx); cval = entry.substring(idx+1);
                } else {
                    cop = '';  cname = '';  cval = entry;
                }
                cop = cop.trim();  cname = cname.trim().toLowerCase();  cval = cval.trim();
                if (!cval || cval.length == 0) continue;
                let found_cname = null;
                for (let cidx = 0; cidx < columns.length; cidx++) {
                    let c = columns[cidx];
                    if (c.title && c.title.toLowerCase() === cname) { found_cname = c.key; break; }
                }
                for (let cidx = 0; cidx < columns.length && !found_cname; cidx++) {
                    let c = columns[cidx];
                    if (c.key && c.key.toLowerCase() === cname) { found_cname = c.key; break; }
                }
                if ((cname == '' && cop == '') || found_cname != null) {
                    cname = found_cname;
                    clean_list.push(cname + cop + cval);
                }
            }
            return clean_list.join(",");
        }

        let new_text = cleanup_search_text(text);
        // console.log(`onSearch : with text '${text}' => '${new_text}'`);
        const new_opts = {...opts, filter:new_text};
        if (setOpts) setOpts(new_opts);
        if (fetchData) fetchData(new_opts);
    };

    const onChangePage = (event, newPage)=>{
        const new_opts = {...opts, page:newPage}; // {page:newPage, pageSize:opts.pageSize}
        if (setOpts) setOpts(new_opts);
        if (fetchData) fetchData(new_opts);
    };

    const onChangeRowsPerPage = (event)=>{
        const new_opts = {...opts, page:0, pageSize:parseInt(event.target.value, 10)};
        if (setOpts) setOpts(new_opts);
        if (fetchData) fetchData(new_opts);
    };

    function getColumnsNotHidden(columns) {
        let visible_columns = [];
        for (let i = 0; columns && i < columns.length; i++) {
            if (visibility[i]) visible_columns.push(columns[i]);
        }
        return visible_columns;
    }

    return (
        <div className={className}>
            {(searchable || (buttonsL && buttonsL.length > 0) || (buttonsR && buttonsR.length > 0)) && 
                <div className="w-full m-1 flex flex-row justify-between items-center gap-1">
                    <div className="m-1 flex flex-row justify-start items-center gap-3">
                        {buttonsL.map((btn, idx)=> 
                            <PzButton.Std key={idx} text={btn.title} size='md' onClick={btn.onClick} />
                        )}
                    </div>
                    <div className="m-1 flex flex-row justify-end items-center gap-3">
                        {searchable && <PzSearchField onEnter={onSearch} tabIndex='-1' />}
                        {buttonsR.map((btn, idx)=> 
                            <PzButton.Std key={idx} text={btn.title} size='md' onClick={btn.onClick} />
                        )}
                    </div>
                </div>
            }
            <Table size="small" className="w-full max-h-100">
                <TableHead>
                    <TableRow className="bg-slate-100">
                        {getColumnsNotHidden(columns).map((col,idx)=>
                            <TableCell key={idx} align={col.align ? col.align : 'left'} margin='auto'>{col.title}</TableCell>
                        )}
                        { typeof onClickToAddRow === 'function' ?
                            <TableCell align='center' width='18px' padding='none'>
                                <PzButton.Minimal className="px-3 py-1" onClick={onClickToAddRow}> <IconButtonAdd width={18} height={18} /> </PzButton.Minimal>
                            </TableCell>
                        : typeof onClickToEditRow === 'function' || typeof onClickToViewRow === 'function' ?
                            <TableCell align='center' width='18px' padding='none'>&nbsp;</TableCell>
                        : 
                            <></>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {datarows && datarows.map((row,idx) =>
                        <TableRow 
                            key={rowKey ? row[rowKey] : idx} 
                            className={(row['footer'] ? "bg-slate-100" : idx === selectedRow ? "bg-slate-50" : "bg-white")}
                            // style={{ background: (row['footer'] ? 'rgb(248 250 252)' : idx === selectedRow ? 'rgb(248 250 252)' : 'white') }}
                            onClick={()=>{
                                let selecting = (idx !== selectedRow);
                                setSelectedRow(selecting ? idx : -1);
                                if (typeof onClickToSelectRow === 'function') onClickToSelectRow(selecting ? row : null);
                            }}>
                            {getColumnsNotHidden(columns).map((col,idx)=>
                                <TableCell key={idx} 
                                    margin='auto'
                                    align={col.align ? col.align : 'left'}  
                                    sx={row['emphasized_column'] === col.key ? {fontWeight: 600} : {}}
                                    onClick={()=>{if (typeof col.onClick === 'function') col.onClick(row,col.key);}} > 
                                    {
                                        col.render ? 
                                            col.render(row)
                                        : col.checksign ? 
                                            (row[col.key] ? '✓' : '')
                                        : col.datetime ? // it should be like 'YYYY/MM/DD HH:mm'
                                            (row[col.key] == '' ? '' : utils.getTimeInUserTimezone(row[col.key], col.datetime, utzone))
                                        : typeof row[col.key] == 'boolean' ?
                                            (row[col.key] ? "True" : "False")
                                        : 
                                            row[col.key]
                                    } 
                                </TableCell>
                            )}
                            { typeof onClickToEditRow === 'function' && (allicon || selectedRow === idx) ?
                                <TableCell align='center' width='18px' padding='none'>
                                    <PzButton.Minimal className="px-3 py-1" onClick={()=>{onClickToEditRow(row, idx);}}> <IconButtonEdit width={18} height={18} /> </PzButton.Minimal>
                                </TableCell>
                            : typeof onClickToViewRow === 'function' && (allicon || selectedRow === idx) ?
                                <TableCell align='center' width='18px' padding='none'>
                                    <PzButton.Minimal className="px-3 py-1" onClick={()=>{onClickToViewRow(row, idx);}}> <IconButtonView width={18} height={18} /> </PzButton.Minimal>
                                </TableCell>
                            : typeof onClickToAddRow === 'function' || typeof onClickToEditRow === 'function' || typeof onClickToViewRow === 'function' ?
                                <TableCell align='center' width='18px' padding='none'>&nbsp;</TableCell>
                            :
                                <></>
                            }
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {(!isPaged && data.length == 0 || isPaged && data.total == 0) ?
                <div className="p-2 flex flex-row justify-center items-center">
                    <p>No Entry Found</p> 
                </div>
            : (isPaged || configurable) ?
                <div className="pl-3 flex flex-row justify-between items-center">
                    {configurable ?
                        <PzPop.Dropdown 
                            base={
                                <PzButton.Minimal className="px-1 py-1 flex flex-row justify-start items-center gap-1">
                                    <h5 className="text-slate-400">Visibility</h5>
                                    <IconChevron width={18} height={18} className={`text-slate-400 ${cfgOpen && `rotate-180`}`} />
                                </PzButton.Minimal>
                            }
                            popover={
                                <ul className="p-2 flex flex-col justify-start items-start gap-0">
                                    {columns.map((col, idx) => (
                                        <li key={idx} className="w-full flex flex-row justify-between items-end gap-2">
                                            <h5>{col.title}</h5>
                                            <button onClick={()=>{ 
                                                let newviz = [...visibility]; 
                                                newviz[idx] = !visibility[idx];
                                                setVisibility(newviz)}} >
                                                {visibility[idx] ?
                                                    <IconCheckbox1 width={18} height={18} />
                                                :
                                                    <IconCheckbox0 width={18} height={18} />
                                                }
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            } 
                            ha={'right'} open={cfgOpen} setOpen={setCfgOpen} />
                    :
                        <div>&nbsp;</div>
                    }
                    {isPaged ?
                        <TablePagination
                            component="div" 
                            size="small" className="p-0" sx={{ padding: 0 }} // None is working
                            count={data.total}
                            page={opts.page}
                            onPageChange={onChangePage}
                            rowsPerPage={opts.pageSize}
                            onRowsPerPageChange={onChangeRowsPerPage}
                        />
                    :
                        <div>&nbsp;</div>
                    }
                </div>
            :
                <></>
            }
        </div>
    );
}

export function PztShortTooltip({value, stt, end}) {
    // 'stt' and 'end' means the starting/ending index of the ellipsed part in 'value'
    if (!value) value = '';
    stt = (!stt ? 0 : stt >= 0 ? stt : (stt <= value.length ? value.length + stt : 0));
    end = (!end || end > value.length ? value.length : end);
    const short = (stt==0 && end==value.length ? value : value.substring(0, stt)+'...'+value.substring(end));
    return (
        <Tooltip title={value} placement="top" 
            slotProps={{
                popper: {
                    modifiers: [{ name: 'offset', options: {offset: [0, -14]} }],
                },
            }}>
            <p>{short}</p>
        </Tooltip>
    );
}

export function PztShortNavigate({value, stt, end, to, state={}}) {
    // 'stt' and 'end' means the starting/ending index of the ellipsed part in 'value'
    const navigate = useNavigate();
    if (!value) value = '';
    stt = (!stt || stt < 0 ? 0 : stt);
    end = (!end || end > value.length ? value.length : end);
    const short = (stt==0 && end==value.length ? value : value.substring(0, stt)+'...'+value.substring(end));
    return (
        <PzButton.Underlined type='U' onClick={()=>navigate(to, {state:state})} >
            {short}
        </PzButton.Underlined>
    );
}

export function PztShortTooltipNavigate({value, stt, end, to, state={}}) {
    // 'stt' and 'end' means the starting/ending index of the ellipsed part in 'value'
    const navigate = useNavigate();
    if (!value) value = '';
    stt = (!stt || stt < 0 ? 0 : stt);
    end = (!end || end > value.length ? value.length : end);
    const short = (stt==0 && end==value.length ? value : value.substring(0, stt)+'...'+value.substring(end));
    return (
        <Tooltip title={value} placement="top" 
            slotProps={{
                popper: {
                    modifiers: [{ name: 'offset', options: {offset: [0, -14]} }],
                },
            }}>
            <PzButton.Underlined type='U' onClick={() => navigate(to, {state:state})} >
                {short}
            </PzButton.Underlined>
        </Tooltip>
    );
}

