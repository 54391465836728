import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { setUPoll } from "../../config/upollConfig";
import PzBasicModal from "../../common/PzBasicModal";
import PzButton from "../../common/PzButton";

export default function ModalToPayWithPG({poll, setPoll, balance, setBalance, onClose}) {
    const [cookies] = useCookies(['uauth']);
    var uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const localeMap = useSelector(utils.selectLocaleMap);
    const pkind = poll.pkind + '_______'.substring(poll.pkind.length);
    const invitation = pkind.substring(4,5);
    const dispatch = useDispatch();
    const [fqToUse, setFqToUse] = React.useState(poll.nmax);
    // const authType = pkind.substring(2,4) + '_' + pkind.substring(5,7);
    // console.log("invitation:", poll.pkind, authType, invitation);

    React.useEffect(() => {
        fetchMyBalance();
    }, []);

    function fetchMyBalance() {
        appConfig.getAPI().post("/api/my/balance")
        .then((response) => {
            const pzdata = response.data;       // {respcode:0, respmsg:'success', resource:{...}}
            if (pzdata.respcode === 0) {
                let newbal = pzdata.resource;   // { fq: {qtrem:0,qtmax:0,qtlast:''} }
                setBalance(newbal);    
                if (!appConfig.isFreeQuotaUsable(poll.nmax, poll.pqstlist.length, newbal.fq.qtrem, invitation)) setFqToUse(0);
            }
        })
        .catch((error) => { console.log("fetchMyBalance() error : ", error); })
        .finally(() => {});
    }

    function onClickToPayWithPG() {
        const payload = { pguid: poll.pguid, nmax: poll.nmax }
        appConfig.getAPI().post("/api/mp/pay/paypg", payload)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pzpoll = pzdata.resource; 
            // console.log(pzpoll);
            const new_poll = { ...poll, pstatus: pzpoll.pstatus }
            setPoll(new_poll);
            dispatch(setUPoll(new_poll));
            if (typeof onClose === 'function') onClose(new_poll.pstatus);
        })
        .catch((error) => {
            console.log("PzAPI 'pca/paypg' error : ", error);
            if (typeof onClose === 'function') onClose();
        })
        .finally(() => {
        });
    }

    function onClickToPayByAdmin() {
        const payload = { pguid: poll.pguid, nmax: poll.nmax }
        appConfig.getAPI().post("/api/mp/pay/paypza", payload)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pzpoll = pzdata.resource; 
            const new_poll = { ...poll, pstatus: pzpoll.pstatus }
            setPoll(new_poll);
            dispatch(setUPoll(new_poll));
            if (typeof onClose === 'function') onClose(new_poll.pstatus);
        })
        .catch((error) => {
            console.log("PzAPI 'pay/paypza' error : ", error);
            if (typeof onClose === 'function') onClose();
        })
        .finally(() => {
        });
    }

    return (
        <PzBasicModal onClose={onClose} xclose={true} >
            <div className="min-w-[400px] flex flex-col justify-start items-stretch p-5 gap-10">

                <h2>{localeMap["poller.getready.paymn.title"]}</h2>

                <div className="w-full grid grid-cols-[auto_auto_1fr_auto_1fr] gap-x-5 gap-y-2 text-sm">

                    <p className="text-right">{localeMap["poller.getready.pca.nmax"]} :</p>
                    <p className="text-right">{poll.nmax}</p>
                    <p>&nbsp;</p>
                    <p className="text-right">{localeMap["poller.getready.pca.price.total"]} :</p>
                    <p className="text-right text-fuchsia-900 font-bold">
                        {appConfig.getTotalPrice(poll.nmax, poll.pqstlist.length, invitation, true)}
                    </p>

                    <p className="text-right">{localeMap["poller.getready.payfq.remaining"]} :</p>
                    <p className="text-right">{balance.fq.qtrem === appConfig.getFQInf() ? '∞' : balance.fq.qtrem}</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>

                    <p className="text-right">{localeMap["poller.getready.payfq.tobeused"]} :</p>
                    <p className="text-right">{fqToUse}</p>
                    <p>&nbsp;</p>
                    <p className="text-right">{localeMap["poller.getready.pca.price.topay"]} :</p>
                    <p className="text-right text-fuchsia-900 font-bold">
                        {appConfig.getTotalPrice(poll.nmax - fqToUse, poll.pqstlist.length, invitation, true)}
                    </p>

                    <p className="text-right">{localeMap["poller.getready.pca.fq.after"]} :</p>
                    <p className="text-right">{balance.fq.qtrem === appConfig.getFQInf() ? '∞' : (balance.fq.qtrem-fqToUse)}</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>

                </div>

                <div className="w-full flex flex-row justify-between items-center gap-2">
                    <PzButton.Std size='md' text="Purchase (Admin)" 
                        onClick={onClickToPayByAdmin} className={uauth.ispza ? "" : "invisible"} />
                    <PzButton.Std size='md' text={localeMap["poller.getready.payfq.button"]} 
                        onClick={onClickToPayWithPG} />
                </div>

            </div>
        </PzBasicModal>
    );
}

