import React from 'react';
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { motion, AnimatePresence } from "framer-motion"

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from "../common/PzBasicModal"
import PzButton from "../common/PzButton"
import PzInput from "../common/PzInput"

const TrialAsPoller = {
    Dialog:     TrialAsPollerDialog,
    Button:     TrialAsPollerButton,
};

export default TrialAsPoller;


function TrialAsPollerDialog({poll, onClose=()=>{}}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [cookies, removeCookie] = useCookies(['vauth']);
    const [step, setStep] = React.useState(0);
    const [poller, setPoller] = React.useState(poll.uname ? poll.uname : localeMap["entrance.trial.example.name"]);
    const ttext = localeMap['entrance.trial.aspoller.title'];
    const title = utils.completeMessageText((ttext ? ttext : ""), [poll?.ptitle]);
    const instr = localeMap['entrance.trial.aspoller.instr' + (step===0 ? '0' : step===1 ? '1' : '2')];

    const images = [
        appConfig.getBaseUrlForAPI() + '/static/images/concept_plan.png',
        appConfig.getBaseUrlForAPI() + '/static/images/concept_invite.png',
        appConfig.getBaseUrlForAPI() + '/static/images/concept_vote.png',
    ];
    const variants = {
        init:   { x:     0, opacity: 0, scale: 0 },
        enter:  { x: +1000, opacity: 0, scale: 1 },
        center: { x:     0, opacity: 1, scale: 1 },
        exit:   { x: -1000, opacity: 0, scale: 1 },
    };

    function onClickForButton() {
        if (step < images.length-1) {
            setStep(step + 1);
        } else {
            removeCookie("vauth", null);
            const url = appConfig.getBaseUrlForAPI() + '/y/prun?p=' + poll?.pguid + '&name=' + poller;
            const win = window.open(url.replace(":80", ":3000"), '_blank');
            if (win != null) win.focus();
            if (typeof onClose === 'function') onClose();
        }
    }
      
    return (
        <PzBasicModal onClose={onClose}>
            <div className="w-full min-w-[24rem] max-w-[32rem] flex flex-col justify-start items-center p-10 gap-10" >
                <div className="w-full flex flex-col justify-start items-center gap-5">
                    <div className="w-full flex flex-col justify-start items-center gap-1">
                        { title.split("\n").map((part,idx)=>{return (<h2 key={idx}>{part}</h2>);}) }
                    </div>
                    <div className="w-full flex flex-col justify-start items-center gap-1">
                        { instr.split("\n").map((part,idx)=>{return (<h2 key={idx}>{part}</h2>);}) }
                    </div>
                </div>
                <div className="w-[30rem] aspect-square relative">
                    <AnimatePresence initial="init" >
                        <motion.img
                            key={step}
                            src={images[step % images.length]}
                            variants={variants}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            className="absolute object-contain object-center"
                            transition={{
                                x: { type: "spring", stiffness: 300, damping: 30 },
                                opacity: { duration: 0.2 }
                            }}
                            onClick={onClickForButton}
                        />
                    </AnimatePresence>

                    { step === 0 &&
                    <div className="absolute right-[2rem] bottom-[6rem] w-full flex flex-row justify-end items-center">
                        <label className="text-xl text-white font-bold">{localeMap["monitor.event.poller.label"]}: &nbsp;</label>
                        <PzInput.ForText size='xl' value={poller} setValue={setPoller} tabIndex='-1' className="w-[10rem]"/>
                    </div>
                    }
                </div>
                <div className="w-full flex flex-col justify-start items-center gap-5">
                    <PzButton.Std 
                        text={step < images.length-1 ? localeMap["poll.create.button.next"] : localeMap["entrance.button.startevent"]} 
                        size='xl' onClick={onClickForButton} />
                </div>
            </div>
        </PzBasicModal>
    )

}

function TrialAsPollerButton({poll, size='md', className='', disabled=false}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <PzButton.Std 
                text={utils.completeLocaleMapText(localeMap, "home.try.aspoller", [], poll.pkind)} 
                size={size} onClick={()=>{setOpen(true);}} disabled={disabled} className={className} />
            { open &&
                <TrialAsPollerDialog poll={poll} onClose={()=>setOpen(false)} />
            }
        </>
    );
}

