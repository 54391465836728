import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";

// Authentication
import Login from "./auth/Login";
import Signup from "./auth/Signup";
import ResetPWord from "./auth/ResetPWord";

// User pages
import Home from "./pages/home/Home";
import MyEvents from "./pages/aspoller/MyEvents";
import MyAccount from "./pages/aspoller/MyAccount";

import EditEvent from "./pages/aspoller/EditEvent";
import EditVoterList from "./pages/aspoller/EditVoterList";
import EditStaffList from "./pages/aspoller/EditStaffList";
import GetReady from "./pages/aspoller/GetReady";
import InviteToEvent from "./pages/aspoller/InviteToEvent";
import RunPoll from "./pages/aspoller/RunPoll";
import XPollerResult from "./pages/aspoller/XPollerResult";

import PrepareEvent from "./pages/asstaff/PrepareEvent";
import SupportEvent from "./pages/asstaff/SupportEvent";
import XStaffResult from "./pages/asstaff/XStaffResult";

import VoteMyself from "./pages/asvoter/VoteMyself";

// PzAdmin pages
import PzAdminDashboard from "./pages/pza/PzAdminDashboard";
import PzAdminArcEvents from "./pages/pza/PzAdminArcEvents";
import PzAdminPubEvents from "./pages/pza/PzAdminPubEvents";
import PzAdminUserList from "./pages/pza/PzAdminUserList";
import PzAdminPollList from "./pages/pza/PzAdminPollList";
import PzAdminUserInfo from "./pages/pza/PzAdminUserInfo";
import PzAdminPollInfo from "./pages/pza/PzAdminPollInfo";
import PzAdminQstInfo from "./pages/pza/PzAdminQstInfo";
import PzAdminFeedback from "./pages/pza/PzAdminFeedback";
import PzAdminViewDesc from "./pages/pza/PzAdminViewDesc";
import PzAdminViewLogs from "./pages/pza/PzAdminViewLogs";

// Voter pages
import Checkin from "./pages/mobile/Checkin";
import DoRun from "./pages/mobile/DoRun";
import DoSupport from "./pages/mobile/DoSupport";
import DoVote from "./pages/mobile/DoVote";

// Simulation pages
import TryCheckinAsVoter  from "./pages/mobile/TryCheckinAsVoter";
import TryCheckinAsPoller from "./pages/mobile/TryCheckinAsPoller";
import TryVote  from "./pages/mobile/TryVote";
import TryRun   from "./pages/mobile/TryRun";

// About pages
import Mission from "./pages/home/Mission";
import Features from "./pages/home/Features";
import PricingPolicy from "./pages/home/PricingPolicy";
import PrivacyPolicy from "./pages/home/PrivacyPolicy";

import EmptyLayout from "./layouts/EmptyLayout";
import BasicLayout from "./layouts/BasicLayout";
import NotFound from "./pages/NotFound";
import UserForgotten from "./pages/UserForgotten";

const router = createBrowserRouter([
    {
        path: "/",                  // Pages for public (open) access
        element: <BasicLayout />,
        children: [
            { element: <Navigate to="/home" />, index: true },
            { path: "home", element: <Home /> },
            { path: "forgotten", element: <UserForgotten /> },
        ],
    },
    {
        path: "/auth",              // Pages for User Authentication
        element: <BasicLayout />,
        children: [
            { element: <Navigate to={"/auth/login"} />, index: true },
            { path: "login", element: <Login /> },
            { path: "signup", element: <Signup /> },
            { path: "resetpw", element: <ResetPWord /> },
        ],
    },
    {
        path: `/about`,             // Pages for Loged-in Users
        element: <BasicLayout />,
        children: [
            { element: <Navigate to="mission" />, index: true },
            { path: "mission", element: <Mission /> },
            { path: "features", element: <Features /> },
            { path: "pricing", element: <PricingPolicy /> },
            { path: "privacy", element: <PrivacyPolicy /> },
        ],
    },
    {
        path: `/pages`,             // Pages for Loged-in Users
        element: <BasicLayout />,
        children: [
            { element: <Navigate to="myevents" />, index: true },
            { path: "myevents", element: <MyEvents /> },
            { path: "myaccount", element: <MyAccount /> },
            { path: "poller/editevent", element: <EditEvent /> },
            { path: "poller/getready", element: <GetReady /> },
            { path: "poller/voterlist", element: <EditVoterList /> },
            { path: "poller/stafflist", element: <EditStaffList /> },
            { path: "poller/invite", element: <InviteToEvent /> },
            { path: "poller/runpoll", element: <RunPoll /> },
            { path: "poller/xresult", element: <XPollerResult /> },
            { path: "staff/prepare", element: <PrepareEvent /> },
            { path: "staff/support", element: <SupportEvent /> },
            { path: "staff/sresult", element: <XStaffResult /> },
            { path: "voter/vote", element: <VoteMyself /> },
        ],
    },
    {
        path: "/pages/pza",     // Pages for PzAdmin Users
        element: <BasicLayout />,
        children: [
            { element: <Navigate to={"dashboard"} />, index: true },
            { path: "dashboard", element: <PzAdminDashboard /> },
            { path: "users", element: <PzAdminUserList /> },
            { path: "polls", element: <PzAdminPollList /> },
            { path: "sysevts", element: <PzAdminPubEvents /> },
            { path: "archived", element: <PzAdminArcEvents /> },
            { path: "userinfo", element: <PzAdminUserInfo /> },
            { path: "pollinfo", element: <PzAdminPollInfo /> },
            { path: "qstinfo", element: <PzAdminQstInfo /> },
            { path: "feedback", element: <PzAdminFeedback /> },
            { path: "sysdesc", element: <PzAdminViewDesc /> },
            { path: "syslogs", element: <PzAdminViewLogs /> },
        ],
    },
    {
        path: "/p",                 // Mobile Pages for Poller
        element: <EmptyLayout />,
        children: [
            { element: <Navigate to={"run"} />, index: true },
            { path: "run", element: <DoRun /> },
        ],
    },
    {
        path: "/s",                 // Mobile Pages for Poller
        element: <EmptyLayout />,
        children: [
            { element: <Navigate to={"support"} />, index: true },
            { path: "support", element: <DoSupport /> },
        ],
    },
    {
        path: "/v",                 // Mobile Pages for Voters 
        element: <EmptyLayout />,
        children: [
            { element: <Navigate to={"vote"} />, index: true },
            { path: "checkin", element: <Checkin /> },
            { path: "vote", element: <DoVote /> },
        ],
    },
    {
        path: "/y",                 // Mobile Pages for Trial (Simulation) 
        element: <EmptyLayout />,
        children: [
            { element: <Navigate to={"vote"} />, index: true },
            { path: "vote", element: <TryVote  /> },                   // Simulated trial     as Voter
            { path: "prun", element: <TryRun /> },                   // Simulated trial     as Poller
            { path: "rrun", element: <TryRun /> },                   // Simulated Rehearsal as Poller
            { path: "vcheckin", element: <TryCheckinAsVoter /> },
            { path: "pcheckin", element: <TryCheckinAsPoller /> },
            { path: "rcheckin", element: <TryCheckinAsPoller /> },
        ],
    },
    {
        element: <EmptyLayout />,
        children: [
        {
            path: "*",
            index: true,
            element: <NotFound />,
        },
        ],
    },
]);

export default router;
