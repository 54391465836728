import React from 'react';
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from "../common/PzBasicModal"
import { ReactComponent as IconQRCode } from "../resource/icons/pz-qrcode.svg";
import EventTimer from "../event/EventTimer";

const EntranceForVoters = {
    Dialog:     EntranceForVotersDialog,
    Button:     EntranceForVotersButton,
};

export default EntranceForVoters;


function EntranceForVotersDialog({poll, onClose=()=>{}}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const POLL_URL_PATH = '/v/vote?p=' + poll?.pguid;
    const imgsrc = `${appConfig.getBaseUrlForAPI()}/api/entrance/event?p=${poll?.pguid}`
    const text = localeMap['entrance.qrcode.instruction'];
    const instruction = (text ? text.split("\n") : '');
    const utzone = useSelector((state)=>state.ucfg.timezone); // user's current timezone selection
    const [edate, etime] = EventTimer.getEventDateAndTime(poll, utzone);

    function onClickToQR() {
        const url = appConfig.getBaseUrlForAPI() + POLL_URL_PATH;
        const win = window.open(url, '_blank');
        if (win != null) win.focus();
        if (typeof onClose === 'function') onClose();
    }

    return (
        <PzBasicModal onClose={onClose}>
            <div className="w-full min-w-[26rem] max-w-[30rem] flex flex-col justify-start items-center p-10 gap-10" >
                <div className="w-full flex flex-col justify-start items-center gap-0">
                    <h2>{poll?.ptitle}</h2>
                    <label className="">{edate} &emsp; {etime}</label>
                </div>
                <div className="w-full flex flex-col justify-start items-center gap-4">
                    <div className="w-full flex flex-col justify-start items-center gap-0">
                        {instruction.map((part,idx)=>{return (<span key={idx} className="text-xl">{part}</span>);})}
                    </div>
                    <div className="w-full p-3 rounded-[2rem] ring-slate-200 ring-inset ring-1 pz-qr-shadow" tabIndex='-1' >
                        <img src={imgsrc} onClick={onClickToQR} alt=""  width={600} height={600} className="object-contain object-center"/>
                    </div>
                </div>
                <div className="w-full text-center text-slate-400">
                    <div className="text-xl">Hosted by <em className="font-bold">Pollerz.net</em></div>
                </div>
            </div>
        </PzBasicModal>
    )

}

function EntranceForVotersButton({poll, size='1rem', className='', disabled=false}) {
    const bttn_classes = "cursor-pointer px-2 py-1 text-sm font-semibold shadow-sm text-slate-800 disabled:text-slate-400 hover:text-blue-600 disabled:opacity-50"
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <button onClick={()=>{setOpen(true);}} className={bttn_classes + ' ' + className} disabled={disabled}>
                <IconQRCode width={size} height={size} />
            </button>
            { open &&
                <EntranceForVotersDialog poll={poll} onClose={()=>setOpen(false)}/>
            }
        </>
    );
}

