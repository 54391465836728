import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SHA256, enc } from "crypto-js";
// import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import AzArea from "./azcomp/AzArea";
import AzButton from "./azcomp/AzButton";
import AzButtonToNavigate from "./azcomp/AzButtonToNavigate";
import AzInputForVCode from "./azcomp/AzInputForVCode";
import AzInputForEmail from "./azcomp/AzInputForEmail";
import AzInputForName from "./azcomp/AzInputForName";
import AzInputForPWord from "./azcomp/AzInputForPWord";
import PollCreator from "../event/PollCreator";

export default function Signup() {
    const location = useLocation();
    const navigate = useNavigate();
    const localeMap = useSelector(utils.selectLocaleMap);
    const ulocale = useSelector((state)=>state.ucfg.locale);  // user's current locale selection
    const utzone = useSelector((state)=>state.ucfg.timezone); // user's current timezone selection
    const [uname, setUname] = React.useState({value:'', errmsg:''});
    const [email, setEmail] = React.useState({value:'', errmsg:''});
    const [vcode, setVcode] = React.useState({value:'', errmsg:''});
    const [pword1, setPword1] = React.useState({value:'', errmsg:''});
    const [pword2, setPword2] = React.useState({value:'', errmsg:''});
    const [errMsg, setErrMsg] = React.useState('');
    const [step, setStep] = React.useState(1);
    const [loading, setLoading] = React.useState(false);

    function sendEmailWithVcode() {
        setLoading(true);
        appConfig.getAPI().post("/api/auth/sendvcode", {uname:uname.value, uemail:email.value, purpose:'register'})
        .then((response)=>{
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("PzAPI 'sendvcode' success : ", pzdata.resource);
            setStep(2);
        })
        .catch((error)=>{
            if (error.code === 'ERR_NETWORK') {
                setErrMsg(localeMap["auth.errmsg.network"]); 
            } else if (error.response && error.response.status === 400) { // BAD_REQUEST
                const pzdata = error.response.data;     // {respcode:0, respmsg:'success', resource:{...}}
                switch (pzdata.respcode) {
                    case 21 : // "email already taken"
                        setErrMsg(localeMap["auth.vcode.errmsg.emailtaken"]); 
                        break;   
                    case 23 : // "failed to send email with vcode ..."
                        setErrMsg(localeMap["auth.vcode.errmsg.emailerror"]); 
                        break;   
                    default:
                        setErrMsg(pzdata.respmsg);
                        break;   
                }
            } else {
                setErrMsg(localeMap["auth.errmsg.unknown"] + ` (${error.code})`); 
                console.log("PzAPI 'signup' failure - status:", error.response?.status, ", error:", error);
            }
        })
        .finally(()=>{
            setLoading(false);
        });
    }

    function postRegister() {
        setLoading(true);
        const pwhash = SHA256(pword1.value).toString(enc.Hex);
        appConfig.getAPI().post("/api/auth/register", {uname:uname.value, uemail:email.value, pword64:pwhash, vcode:vcode.value})
        .then((response)=>{
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("PzAPI 'signup' success : ", pzdata);
            // let the user log in right away
            postLoginAfterSignup();
        })
        .catch((error)=>{
            if (error.code === 'ERR_NETWORK') {
                setErrMsg(localeMap["auth.errmsg.network"]); 
            } else if (error.response && error.response.status === 400) { // BAD_REQUEST
                const pzdata = error.response.data;     // {respcode:0, respmsg:'success', resource:{...}}
                switch (pzdata.respcode) {
                    case 20 : // "vcode mismatch"
                        setErrMsg(localeMap["auth.vcode.errmsg.nomatch"]); 
                        break;   
                    case 21 : // "email already taken"
                        setErrMsg(localeMap["auth.signup.errmsg.emailtaken"]); 
                        break;   
                    default:
                        console.log("PzAPI 'signup' failure - error:", error);
                        setErrMsg(pzdata.respmsg);
                        break;   
                }
            } else {
                setErrMsg(localeMap["auth.errmsg.unknown"] + ` (${error.code})`); 
                console.log("PzAPI 'signup' failure - status:", error.response?.status, ", error:", error);
            }
        })
        .finally(()=>{
            setLoading(false);
        });
    };
    
    function postLoginAfterSignup() {
        setLoading(true);
        const pwhash = SHA256(pword1.value).toString(enc.Hex);
        appConfig.getAPI().post("/api/auth/login", {email:email.value, pword64:pwhash, locale:ulocale, timezone:utzone})
        .then((response)=>{
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("PzAPI 'login' success", pzdata);
            // navigate to the MyEvents page
            if (location.state?.newpoll) {
                let onSuccess=(newpguid)=>{
                    // console.log("New poll created : ", newpguid);
                    navigate("/pages/myevents", {state: {pguid:newpguid}});        // default page
                };
                let onError=(error)=>{
                    navigate("/pages/myevents");        // default page
                };
                PollCreator.postCreatePoll(location.state.newpoll, onSuccess, onError);
            } else {
                navigate("/pages/myevents");        // default page
            }
        })
        .catch((error)=>{
            if (error.code === 'ERR_NETWORK') {
                setErrMsg(localeMap["auth.errmsg.network"]); 
            } else if (error.response && error.response.status === 400) { // BAD_REQUEST
                const pzdata = error.response.data;     // {respcode:0, respmsg:'success', resource:{...}}
                setErrMsg(pzdata.respmsg);
            } else {
                setErrMsg(localeMap["auth.errmsg.unknown"] + ` (${error.code})`); 
            }
        })
        .finally(()=>{
            setLoading(false);
        });
    };
    

    return (
        <AzArea.MwhBoardInContainer>

            { step <= 1 ?

                <div className="w-full flex flex-col justify-start items-center py-4 gap-4" >
                    <div className="w-full flex flex-col justify-start items-center" >
                        <h1>{localeMap["auth.signup.step1.title"]}</h1>
                        <h5 className="flex flex-row justify-center text-slate-500">
                            &nbsp;{localeMap["auth.signup.step1.subtitle"]}&nbsp;
                        </h5>
                    </div>
                    <div className="w-[16rem] flex flex-col justify-start items-stretch gap-3">
                        <div className="flex flex-col justify-start items-stretch gap-3">
                            <AzInputForName  v={uname} setV={setUname} 
                                onChange={()=>{if (errMsg!=='') setErrMsg('');}} 
                                placeholder={localeMap["auth.signup.step1.input.uname"]} className="w-full" />
                            <AzInputForEmail v={email} setV={setEmail} 
                                onChange={()=>{if (errMsg!=='') setErrMsg('');}} 
                                placeholder={localeMap["auth.signup.step1.input.email"]} className="w-full" />
                        </div>
                        <h5 className="text-xs text-center text-slate-500">&nbsp;{errMsg}&nbsp;</h5>
                    </div>
                    <div className="w-full flex flex-col justify-start items-center gap-2">
                        <AzButton type='R' className="w-[16rem] flex justify-center" 
                            disabled={email.value === '' || email.errmsg!=='' || uname.value==='' || uname.errmsg!=='' || errMsg!=='' || loading} 
                            onClick={sendEmailWithVcode} loading={loading} >
                            {localeMap["auth.signup.step1.button"]}
                        </AzButton>
                        <div className="flex flex-col justify-start items-center gap-0">
                            <AzButtonToNavigate label={localeMap["auth.signup.step1.nav1.label"]} button={localeMap["auth.signup.step1.nav1.button"]} destination={"/auth/signup"} />
                            <AzButtonToNavigate label={localeMap["auth.signup.step1.nav2.label"]} button={localeMap["auth.signup.step1.nav2.button"]} destination={"/auth/login"} />
                        </div>
                    </div>
                </div>

            :

                <div className="w-full flex flex-col justify-start items-center py-4 gap-4" >
                    <div className="w-full flex flex-col justify-start items-center" >
                        <h1>{localeMap["auth.signup.step2.title"]}</h1>
                        <h5 className="w-[80%] flex flex-row justify-center text-slate-500">
                            &nbsp;{utils.completeMessageText(localeMap["auth.signup.step2.subtitle"], [email.value])}&nbsp;
                        </h5>
                    </div>
                    <div className="w-[300px] flex flex-col justify-start items-stretch gap-3">
                        <div className="flex flex-col justify-start items-stretch gap-3">
                            <AzInputForVCode v={vcode}  setV={setVcode}  placeholder={localeMap["auth.signup.step2.input.vcode"]}  className="w-full" onChange={()=>{if (errMsg!=='') setErrMsg('');}} />
                            <AzInputForPWord   v={pword1} setV={setPword1} placeholder={localeMap["auth.signup.step2.input.pword1"]} className="w-full" onChange={()=>{if (errMsg!=='') setErrMsg('');}} />
                            <AzInputForPWord   v={pword2} setV={setPword2} placeholder={localeMap["auth.signup.step2.input.pword2"]} className="w-full" onChange={()=>{if (errMsg!=='') setErrMsg('');}} vref={pword1} />
                        </div>
                        <h5 className="text-xs text-center text-slate-500">&nbsp;{errMsg}&nbsp;</h5>
                    </div>
                    <div className="w-full flex flex-col justify-start items-center gap-2">
                        <AzButton type='R' className="w-[300px] flex justify-center" 
                            disabled={vcode.value === '' || vcode.errmsg!=='' || pword1.value === '' || pword1.errmsg!=='' || pword2.value === '' || pword2.errmsg!=='' || errMsg!=='' || loading} 
                            onClick={postRegister} loading={loading} >
                            {localeMap["auth.signup.step2.button"]}
                        </AzButton>
                        <div className="flex flex-col justify-start items-center gap-0">
                            <AzButtonToNavigate label={localeMap["auth.vcode.retry.label"]} button={localeMap["auth.vcode.retry.button"]} onClick={()=>{setStep(1);setErrMsg('');}} />
                        </div>
                    </div>
                </div>

            }
            
        </AzArea.MwhBoardInContainer>
    );
}
