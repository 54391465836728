import React from "react";
import Tooltip from '@mui/material/Tooltip';

import { ReactComponent as IconUserB1 } from "../resource/icons/pz-user-b1-sw2.svg";
import { ReactComponent as IconUserB2 } from "../resource/icons/pz-user-b2-sw2.svg";

const PzSymbol = {
    PKind: PzSymbolPKind,
    XRole: PzSymbolXRole,
    Text: PzSymbolText,
    Icon: PzSymbolIcon,
}

export default PzSymbol;


export function PzSymbolPKind({pkind, tooltip='', onClick=()=>{}}) {
    return (
        <Tooltip title={tooltip} placement="top" 
            slotProps={{
                popper: {
                    modifiers: [{ name: 'offset', options: {offset: [0, -10]} }],
                },
            }}>
            <div className="w-[1.2rem] h-[1.2rem] bg-slate-800 text-white rounded text-center">
                <p className="font-bold">{pkind?.substring(0,1)}</p>
            </div>
        </Tooltip>
    )
}

export function PzSymbolXRole({xrole, tooltip='', onClick=()=>{}}) {
    return (
        <Tooltip title={tooltip} placement="top" 
            slotProps={{
                popper: {
                    modifiers: [{ name: 'offset', options: {offset: [0, -10]} }],
                },
            }}>
            {xrole === 'P' ?
                <IconUserB1 width="1.2rem" height="1.2rem" className=" bg-slate-800 text-white rounded"/>
            :
                <IconUserB2 width="1.2rem" height="1.2rem" className=" bg-slate-800 text-white rounded" />
            }
        </Tooltip>
    )
}

export function PzSymbolText({text, size='lg', tooltip='', onClick=()=>{}, className='', op50=false}) {
    let CLASSNAME = " font-bold bg-slate-700 text-white " + (op50 ? " opacity-50 " : " ");
    if      (size === '4xl') CLASSNAME += " text-3xl px-3 py-[0.25rem] rounded-[2rem] ";
    else if (size === '3xl') CLASSNAME += " text-2xl px-2 py-[0.20rem] rounded-3xl ";
    else if (size === '2xl') CLASSNAME += " text-xl  px-2 py-[0.18rem] rounded-2xl ";
    else if (size ===  'xl') CLASSNAME += " text-lg  px-1 py-[0.16rem] rounded-xl ";
    else if (size ===  'lg') CLASSNAME += " text-md  px-1 py-[0.13rem] rounded-xl ";
    else if (size ===  'md') CLASSNAME += " text-sm  px-1 py-[0.12rem] rounded-xl ";
    else if (size ===  'sm') CLASSNAME += " text-xs  px-[0.2rem] py-[0.10rem] rounded-md ";
    else                     CLASSNAME += " text-xl  px-[0.2rem] py-[0.08rem] rounded-md ";
    text = text.replaceAll(' ', '\u00A0');

    return (
        <div>
        <Tooltip title={tooltip} placement="top" 
            slotProps={{
                popper: {
                    modifiers: [{ name: 'offset', options: {offset: [0, -10]} }],
                },
            }}>
            <span className={CLASSNAME + ' ' + className} onClick={onClick} >{text}</span>
        </Tooltip>
        </div>
    )
}

export function PzSymbolIcon({Icon, size='lg', tooltip='', onClick=()=>{}, className='', op50=false}) {
    let classes = " bg-slate-700 text-white rounded-md " + (op50 ? " opacity-50 " : " ");
    let ICONSIZE = "1rem";
    if      (size === '2xl') { ICONSIZE="2.0rem"; classes += " px-1 py-0 "; }
    else if (size ===  'xl') { ICONSIZE="1.8rem"; classes += " px-1 py-0 "; }
    else if (size ===  'lg') { ICONSIZE="1.5rem"; classes += " px-1 py-0 "; }
    else if (size ===  'md') { ICONSIZE="1.3rem"; classes += " px-1 py-0 "; }
    else if (size ===  'sm') { ICONSIZE="1.2rem"; classes += " px-[0.2rem] py-[0.08rem] "; }
    else                     { ICONSIZE="1.1rem"; classes += " px-[0.2rem] py-[0.08rem] "; }

    return (
        <div>
        <Tooltip title={tooltip} placement="top" 
            slotProps={{
                popper: {
                    modifiers: [{ name: 'offset', options: {offset: [0, -10]} }],
                },
            }}>
            <Icon width={ICONSIZE} height={ICONSIZE} className={classes + className} onClick={onClick} />
        </Tooltip>
        </div>
    )
}

