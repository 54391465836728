import React from "react";

import { utils } from "../config/userConfig";
import PzInput from "../common/PzInput";
import PzSymbol from "../common/PzSymbol";

export default function EMComQstList({poll, setPoll, size='2xl', selectable=true, deselectable=false}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const [selection, setSelection] = React.useState([]);
    // console.log("EMComQstList :  selectable:", selectable, "  deselectable:", deselectable);

    React.useEffect(() => {
        initNextQuest();
    }, []);

    function initNextQuest(qidx) {
        if (!poll) return;
        let next_qidx = -1;
        if (qidx === undefined) {
            let closed_qidx = -1, untouched = 0;
            for (let i = 0; i < poll.pqstlist.length; i++) {
                if (poll.pqstlist[i].qstatus >= 'Vx') closed_qidx = i;
                else untouched++;
            }
            next_qidx = closed_qidx + 1;
            if (next_qidx >= poll.pqstlist.length) {
                next_qidx = -1;
                if (untouched > 0) {
                    for (let i = 0; i < poll.pqstlist.length; i++) {
                        if (poll.pqstlist[i].qstatus < 'Vx') { next_qidx = i; break; }
                    }
                }
            }
        } else {
            // console.log("qidx:", qidx, "Number(qidx):", Number(qidx), "qIndex:", qIndex);
            next_qidx = Number(qidx);
        }
        setSelection([next_qidx]);
        let new_poll = { ...poll, qactive_selected: next_qidx };
        if (typeof setPoll === 'function') setPoll(new_poll);
    }

    function setSelection2(new_selection) {
        let selected = (new_selection.length <= 0 ? -1 : new_selection[0]);
        if (selected >= 0 && poll.pqstlist[selected].qstatus >= 'Vx') return;
        setSelection(new_selection);
        let new_poll = { ...poll, qactive_selected: selected };
        if (typeof setPoll === 'function') setPoll(new_poll);
    }

    if (!poll) {
        return (<h3>poll is null</h3>);
    }

    return (
        <div className="w-full flex flex-col justify-start items-center gap-3">
            {/* <div className="flex flex-col justify-start items-start gap-3"> */}
                { poll.pqstlist.map((q, qidx)=>{
                    let disabled = (q.qstatus >= 'Vx');
                    return ( selectable ?
                        <PzInput.Question key={qidx} question={q.question} size={size} className="" 
                            value={qidx} checked={selection.includes(qidx)} disabled={disabled}
                            selection={selection} setSelection={setSelection2} 
                            selectable={true} deselectable={deselectable} />
                    :
                        <PzInput.Question key={qidx} question={q.question} size={size} className="" 
                            value={qidx} checked={selection.includes(qidx)} disabled={disabled} 
                            selectable={false} deselectable={false} />
                    ); 
                })}
            {/* </div> */}
        </div>
    );
}

