import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { setUPoll } from "../../config/upollConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import PollCard from "../../event/PollCard";
import UserSplash from "../UserSplash";
import { ReactComponent as IconStepNext } from "../../resource/icons/pz-step-next.svg";
import { ReactComponent as IconStepPrev } from "../../resource/icons/pz-step-prev.svg";

export default function EditEvent() {
    const [cookies] = useCookies(['uauth']);
    var uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const dispatch = useDispatch();
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const localeMap = useSelector(utils.selectLocaleMap);
    const navigate = useNavigate();

    // React.useEffect(() => {
    // }, []);
    function setPoll2(newpoll) {
        newpoll.edited = true;
        dispatch(setUPoll(newpoll));
    }

    // function updateEventInfo(new_poll) {
    //     appConfig.getAPI().post("/api/mp/update", new_poll)
    //     .then((response) => {
    //         const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
    //         const new_pguid = pzdata.resource;
    //         console.log("PzAPI '/api/mp/update' success : ", pzdata);
    //         if (new_pguid && new_pguid.length === 32 && new_pguid !== new_poll.pguid) {
    //             console.log("pguid changed from ", new_poll.pguid, " to ", new_pguid);
    //             new_poll = { ...new_poll, pguid: new_pguid }
    //         }
    //         console.log("Update Success: ", new_poll);
    //         // dispatch(setUPoll(new_poll));
    //     })
    //     .catch((error) => {
    //         console.log("PzAPI '/api/mp/update' error : ", error);
    //     })
    //     .finally(() => { });
    // }

    // function onExpireToPWhen() {
    //     console.log("Expired");
    //     // dispatch(setUPoll(null));
    //     // navigate("/pages/myevents");
    // }

    const etype = (upoll ? localeMap["comm.event."+upoll.pkind[0].toLowerCase()] : undefined);
    const title = (etype ? `[${etype}] ` : '') + localeMap["menu.poller.editevent"];

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event."} /> );
    }  

    return (
        <PzArea.TBoardInContainer title={title} >

            <div className="w-full flex flex-col justify-start items-start gap-5">

                <PollCard.Item poll={upoll} setPoll={setPoll2} 
                    editable={true} pSelected={upoll.pguid} 
                    buttons={{delete:true, simtest:true, save:true}} />

            </div>

            <div className="w-full flex flex-row justify-between items-center mt-10">
                <PzButton.Icon Icon={IconStepPrev} size='xl' onClick={()=>{navigate(-1);}} />
                <PzButton.Std Icon={IconStepNext} text={localeMap["menu.poller.getready"]} size='lg' onClick={()=>{navigate("/pages/poller/getready");}} />
            </div>

        </PzArea.TBoardInContainer>
    );
}
