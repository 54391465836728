import React from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';

import PzPop from "./PzPop";
import { ReactComponent as IconChevron } from "../resource/icons/pz-chevron-down.svg";
import { ReactComponent as IconMenu } from "../resource/icons/pz-menu.svg";
import { ReactComponent as IconHelp } from "../resource/icons/pz-tool-lifebuoy.svg";

const PzButton = {
    Default:    PzButtonDefault,
    Minimal:    PzButtonMinimal,
    Underlined: PzButtonUnderlined,
    ToNavigate: PzButtonToNavigate,

    Std:        PzButtonStd,
    Icon:       PzButtonIcon,
    Folder:     PzButtonFolder,
    NavMenu:    PzButtonForNavMenu,

    EMMinimal:  PzButtonForEMMinimal,
    EMAction:   PzButtonForEMAction,
    EMWaiting:  PzButtonForEMWaiting,
    EMMenu:     PzButtonForEMMenu,
    EMToggle:   PzButtonForEMToggle,
};

export default PzButton;

function PzButtonDefault({children, onClick=()=>{}, disabled=false, className='', gap='gap-1'}) {
    const u_bttn = ` cursor-pointer px-2 py-1 text-sm font-semibold shadow-sm text-slate-800 hover:text-blue-600 disabled:text-slate-400 disabled:opacity-50 `;
    const u_ring = ` ring-inset ring-1 hover:ring-2 rounded-md text-slate-800 hover:text-blue-600 `;
    return (
        <button 
            onClick={onClick} disabled={disabled}
            className={u_bttn + u_ring + className} >
            <div className={"flex flex-row justify-start items-center " + gap} >
                {children}
            </div>
        </button>
    )
}

function PzButtonMinimal({children, onClick=()=>{}, disabled=false, className='', gap='gap-1'}) {
    const u_bttn = " cursor-pointer text-sm font-semibold shadow-sm text-slate-800 hover:text-blue-600 disabled:text-slate-400 disabled:opacity-50 ";
    return (
        <button 
            onClick={onClick} disabled={disabled}
            className={u_bttn + className} >
            <div className={"flex flex-row justify-start items-center " + gap} >
                {children}
            </div>
        </button>
    )
}

function PzButtonUnderlined({children, onClick=()=>{}, disabled=false, className='', gap='gap-1'}) {
    const u_undr = ` underline text-blue-500 cursor-pointer font-mono disabled:opacity-50 `;
    return (
        <button 
            onClick={onClick} disabled={disabled}
            className={u_undr + className} >
            <div className={"flex flex-row justify-start items-center " + gap} >
                {children}
            </div>
        </button>
    )
}

function PzButtonToNavigate({ children, to, state, newtab=false, onClick=undefined }) {
    const navigate = useNavigate();
    const onButtonClick = ()=>{
        if (onClick) onClick();
        if (newtab) {
            const win = window.open(to, '_blank');
            if (win != null) win.focus();
        } else if (state) {
            navigate(to, { state: state });
        } else {
            navigate(to);
        }
    };
    return (
        <button className="underline text-blue-500 cursor-pointer" onClick={onButtonClick}>
            {children}
        </button>
    );
}

function PzButtonStd({Icon=null, text='', size='md', onClick=()=>{}, disabled=false, className='', bold=true, loading=false}) {
    //                          h1:  1.875   h2:  1.50  h3: 1.25    h4: 1.125   h5: 1.00    h6: 0.875
    // 5xl: 3.00    4xl: 2.25   3xl: 1.875   2xl: 1.50  xl: 1.25    lg: 1.125   md: 1.00    sm: 0.875   xs: 0.75
    let iconsize = " ", TEXTSIZE = "text-"+size, padsize = " ", gapsize = " ";
    if      (size === '4xl') { iconsize = "2.50rem "; padsize = "px-9 py-3 "; gapsize = "gap-3 "; }
    else if (size === '3xl') { iconsize = "2.25rem "; padsize = "px-9 py-3 "; gapsize = "gap-3 "; }
    else if (size === '2xl') { iconsize = "1.87rem "; padsize = "px-9 py-3 "; gapsize = "gap-3 "; }
    else if (size === 'xl')  { iconsize = "1.50rem "; padsize = "px-6 py-2 "; gapsize = "gap-2 "; }
    else if (size === 'lg')  { iconsize = "1.25rem "; padsize = "px-6 py-2 "; gapsize = "gap-2 "; }
    else if (size === 'md')  { iconsize = "1.12rem "; padsize = "px-3 py-1 "; gapsize = "gap-2 "; }
    else if (size === 'sm')  { iconsize = "1.00rem "; padsize = "px-2 py-[0.1rem] "; gapsize = "gap-1 "; }
    else if (size === 'xs')  { iconsize = "0.87rem "; padsize = "px-1 py-[0.1rem] "; gapsize = "gap-1 "; }
    const clscolor  = " cursor-pointer bg-purple-900 text-white disabled:opacity-50 " + (disabled ? "" : " hover:bg-purple-700 ");
    const clsring   = " border border-purple-900 rounded-3xl shadow-sm " + padsize  + (disabled ? "" : " ");
    const txtshape = " font-semibold " + TEXTSIZE;

    return (
        <button className={className+clscolor+clsring} onClick={onClick} disabled={disabled} >
            { loading ?
                <CircularProgress variant="indeterminate" size={iconsize} thickness={4}/>
            :
                <div className={"w-full flex flex-row justify-start items-center " + gapsize} >
                    { Icon && <Icon width={iconsize} height={iconsize} className="" /> }
                    { text && <p className={"w-full text-center " + txtshape}>{text}</p> }
                </div>
            }
        </button>
    );
}

function PzButtonIcon({Icon, size='md', onClick=(e)=>{}, toggle=false, disabled=false, className='', 
    noBackground=false, forProperty=false, popover=null, loading=false}) {
    //                          h1:  1.875   h2:  1.50  h3: 1.25    h4: 1.125   h5: 1.00    h6: 0.875
    // 5xl: 3.00    4xl: 2.25   3xl: 1.875   2xl: 1.50  xl: 1.25    lg: 1.125   md: 1.00    sm: 0.875   xs: 0.75
    let iconsize = " ", padsize = " ";
    if      (size === '3xl') { iconsize = "2.25rem "; padsize = "px-3 py-3 "; }
    else if (size === '2xl') { iconsize = "1.87rem "; padsize = "px-3 py-3 "; }
    else if (size === 'xl')  { iconsize = "1.50rem "; padsize = "px-3 py-3 "; }
    else if (size === 'lg')  { iconsize = "1.25rem "; padsize = "px-2 py-2 "; }
    else if (size === 'md')  { iconsize = "1.12rem "; padsize = "px-2 py-2 "; }
    else if (size === 'sm')  { iconsize = "1.00rem "; padsize = "px-1 py-1 "; }
    else if (size === 'xs')  { iconsize = "0.87rem "; padsize = "px-[0.1rem] py-[0.1rem] "; }
    else if (size === '2xs') { iconsize = "0.75rem "; padsize = "px-[0.1rem] py-[0.1rem] "; }
    else if (size.endsWith('rem')) iconsize = size;
    const clsbasic  = " cursor-pointer disabled:opacity-50 ";
    const clscolor  = " bg-purple-900 text-white " + (disabled ? "" : " hover:bg-purple-700 ");
    const clsring   = " ring-inset ring-1 rounded-3xl shadow-sm " + padsize + " ";
    const [selected, setSelected] = React.useState(false);
    const clstoggle = (toggle && selected ? " bg-blue-600 text-white " : " ");
    const forprop = (forProperty ? " !p-1 !bg-purple-900 !text-white hover:cursor-pointer opacity-50 " : " ");

    function onClickToButton(e) {
        if (toggle) {
            let new_selected = !selected;
            setSelected(new_selected);
            if (typeof onClick === 'function') onClick(new_selected);
        } else {
            if (typeof onClick === 'function') onClick(e);
        }
    }

    return (
        popover ?
            <PzPop.Popover
                base={
                    <div className={"w-auto flex flex-row justify-start items-center " + clstoggle} >
                        <Icon width={iconsize} height={iconsize} />
                    </div>
                }
                popover={popover}
                ha='left' offset='5px' />
        :
            <button className={clsbasic + (noBackground ? '' : clscolor+clsring+forprop+clstoggle) + className} onClick={onClickToButton} disabled={disabled} >
                { loading ?
                    <CircularProgress variant="indeterminate" size={iconsize} thickness={4}/>
                :
                    <div className={"w-auto flex flex-row justify-start items-center " + clstoggle} >
                        <Icon width={iconsize} height={iconsize} />
                    </div>
                }
            </button>
    );
}

function PzButtonFolder({folded, setFolded=()=>{}, size='md', onChange=(foldednew)=>{}}) {
    //                          h1:  1.875   h2:  1.50  h3: 1.25    h4: 1.125   h5: 1.00    h6: 0.875
    // 5xl: 3.00    4xl: 2.25   3xl: 1.875   2xl: 1.50  xl: 1.25    lg: 1.125   md: 1.00    sm: 0.875   xs: 0.75
    let iconsize = "1.00rem";
    if      (size === '4xl') iconsize = "2.25rem";
    else if (size === '3xl') iconsize = "1.87rem";
    else if (size === '2xl') iconsize = "1.50rem";
    else if (size === 'xl')  iconsize = "1.25rem";
    else if (size === 'lg')  iconsize = "1.12rem";
    else if (size === 'md')  iconsize = "1.00rem";
    else if (size === 'sm')  iconsize = "0.87rem";
    else if (size === 'xs')  iconsize = "0.75rem";

    return (
        <IconChevron width={iconsize} height={iconsize} className={"cursor-pointer " + (folded ? "" : "rotate-180")}
            onClick={(e)=>{ e.stopPropagation(); setFolded(!folded); if (typeof onChange === 'function') onChange(!folded); }} /> 
    );
}

function PzButtonForNavMenu({text, path='', size='md', disabled=false, className='', callback=null}) {
    const navigate = useNavigate();
    return (
        <label className={"text-"+size + " cursor-pointer " + (disabled ? "opacity-50" : "") + " " + className} 
            onClick={(e)=>{ 
                if (disabled) return;
                e.stopPropagation(); 
                if (path) navigate(path); 
                else if (typeof callback === 'function') callback();
            }}>
            {text}
        </label>
    );
}

function PzButtonForEMMinimal({text='', size='xl', onClick=()=>{}, disabled=false, invisible=false, className=''}) {
    const clsshape = " px-5 py-3 text-sm font-semibold shadow-sm " + (invisible || !text ? "invisible " : "");
    const clscolor = " cursor-pointer text-slate-800 hover:text-blue-600 disabled:text-slate-400 disabled:opacity-50 ";
    const TEXTSIZE = "text-" + size;

    return (
        <button onClick={onClick} disabled={disabled} className={clsshape + clscolor + className} >
            <div className="w-auto flex flex-row justify-start items-center gap-0" >
                &nbsp;
                { text && <label className={TEXTSIZE}>{text}</label> }
                &nbsp;
            </div>
        </button>
    );
}

function PzButtonForEMAction({symbol='', text='', size='xl', onClick=()=>{}, 
    disabled=false, invisible=false, heartbeat=false, className='', loading=false}) {
    const clscolor = " cursor-pointer hover:text-white text-white bg-purple-900 disabled:opacity-50 ";
    const clsring  = " rounded-3xl px-6 py-3 pz-fab-shadow active:pz-fab-shadow-half";
    const txtshape = " text-sm font-semibold " + (invisible || (!symbol && !text) ? "invisible " : " ");
    const clsHeart = (heartbeat ? " heartbeat " : "");
    const TEXTSIZE = "text-" + size;

    if (loading) {
        return ( // "h3 + py-2" : "1.2rem"
            <button className={txtshape + clscolor + clsring + className} >
                <CircularProgress variant="indeterminate" size="1.5rem" thickness={4}/>
            </button>
        );
    } else {
        return (
            <button onClick={onClick} disabled={disabled} className={txtshape + clscolor + clsring + clsHeart + className} >
                <div className="w-full flex flex-row justify-start items-center gap-3 " >
                    { symbol && <label className={TEXTSIZE+" font-bold text-center"}>{symbol}</label> }
                    { text   && <label className={TEXTSIZE+" font-bold text-center"}>{text}</label> }
                </div>
            </button>
        );
    }
}

function PzButtonForEMWaiting({size='xl', text='', invisible=false, className='', disabled=false}) {
    const CLASSNAME = " cursor-pointer bg-white border border-slate-400 rounded-3xl p-3 pb-1 pz-fab-shadow active:pz-fab-shadow-half";
    const TEXTSIZE = "text-" + size;

    return ( invisible ?
            <></>
        :
            <PzPop.Dropdown 
                base={
                    <div className={CLASSNAME + className} disabled={disabled} >
                        <CircularProgress variant="indeterminate" size="1.5rem" thickness={4}/>
                    </div>
                }
                popover={
                    <div className={'px-3 py-1'} >
                        <label className={TEXTSIZE}>{text}</label>
                    </div>
                }
                ha={'center'} upward={true} offset='10px' />
        );
}

function PzButtonForEMMenu({size='xl', asHelp=false, invisible=false, className='', disabled=false, ha='center',
        items=[{text:'COMMAND1',callback:()=>{console.log('Item COMMAND1 is selected.')},disabled:false}] }) {
    //                          h1:  1.875   h2:  1.50  h3: 1.25    h4: 1.125   h5: 1.00    h6: 0.875
    // 5xl: 3.00    4xl: 2.25   3xl: 1.875   2xl: 1.50  xl: 1.25    lg: 1.125   md: 1.00    sm: 0.875   xs: 0.75
    let iconsize = " ", MENUSIZE = "text-"+size, padsize = " ";
    if      (size === '3xl') { iconsize = "2.25rem "; MENUSIZE="text-2xl"; padsize = "px-3 py-3 "; }
    else if (size === '2xl') { iconsize = "1.87rem "; MENUSIZE="text-xl"; padsize = "px-3 py-3 "; }
    else if (size === 'xl')  { iconsize = "1.50rem "; MENUSIZE="text-lg"; padsize = "px-3 py-3 "; }
    else if (size === 'lg')  { iconsize = "1.25rem "; MENUSIZE="text-md"; padsize = "px-2 py-2 "; }
    else if (size === 'md')  { iconsize = "1.12rem "; MENUSIZE="text-sm"; padsize = "px-2 py-2 "; }
    else if (size === 'sm')  { iconsize = "1.00rem "; MENUSIZE="text-xs"; padsize = "px-1 py-1 "; }
    else if (size === 'xs')  { iconsize = "0.87rem "; MENUSIZE="text-xs"; padsize = "px-[0.1rem] py-[0.1rem] "; }
    else if (size === '2xs') { iconsize = "0.75rem "; MENUSIZE="text-xs"; padsize = "px-[0.1rem] py-[0.1rem] "; }
    else if (size.endsWith('rem')) { iconsize = size; }
    const clscolor  = " cursor-pointer disabled:opacity-50 " + (asHelp ? " bg-white " : " bg-purple-900 text-white ");
    const clsring   = " ring-inset ring-1 rounded-3xl pz-fab-shadow active:pz-fab-shadow-half " + padsize  + (disabled ? "" : "");
    const [open, setOpen] = React.useState(false);

    return ( 
        <PzPop.Dropdown 
            base={
                <div className={clscolor+clsring+className} disabled={disabled} >
                    { asHelp ? 
                        <IconHelp width={iconsize} height={iconsize} /> 
                    :
                        <IconMenu width={iconsize} height={iconsize} /> 
                    }
                </div>
            } 
            popover={
                <div className="w-auto flex flex-col justify-start items-start p-3 gap-2">
                    { items.map((item,idx)=>{ return (
                        item.hidden ? <div key={idx} hidden={true}></div> :
                        <label key={idx} 
                            className={'w-full hover:cursor-pointer ' + MENUSIZE + (item.disabled ? ' ' : ' hover:font-semibold ') + (item.className ? item.className : '')} 
                            onClick={(e)=>{
                                if (item.disabled) return; 
                                e.stopPropagation(); 
                                setOpen(false); 
                                if (typeof item.callback === 'function') item.callback(); 
                            }} >
                            {item.text}
                        </label>
                    )}) }
                </div>
            }
            ha={ha} upward={true} open={open} setOpen={setOpen} offset='10px' />
    );
}

function PzButtonForEMToggle({Icon, size='md', toggle, setToggle, disabled=false, className=''}) {
    //                          h1:  1.875   h2:  1.50  h3: 1.25    h4: 1.125   h5: 1.00    h6: 0.875
    // 5xl: 3.00    4xl: 2.25   3xl: 1.875   2xl: 1.50  xl: 1.25    lg: 1.125   md: 1.00    sm: 0.875   xs: 0.75
    let iconsize = " ", padsize = " ";
    if      (size === '3xl') { iconsize = "2.25rem "; padsize = "px-3 py-3 "; }
    else if (size === '2xl') { iconsize = "1.87rem "; padsize = "px-3 py-3 "; }
    else if (size === 'xl')  { iconsize = "1.50rem "; padsize = "px-3 py-3 "; }
    else if (size === 'lg')  { iconsize = "1.25rem "; padsize = "px-2 py-2 "; }
    else if (size === 'md')  { iconsize = "1.12rem "; padsize = "px-2 py-2 "; }
    else if (size === 'sm')  { iconsize = "1.00rem "; padsize = "px-1 py-1 "; }
    else if (size === 'xs')  { iconsize = "0.87rem "; padsize = "px-[0.1rem] py-[0.1rem] "; }
    else if (size === '2xs') { iconsize = "0.75rem "; padsize = "px-[0.1rem] py-[0.1rem] "; }
    else if (size.endsWith('rem')) iconsize = size;
    const clsbasic  = " cursor-pointer disabled:opacity-50 ";
    const clsring   = " border border-slate-800 rounded-[2.0rem] shadow-sm " + padsize + " ";
    const [selected, setSelected] = React.useState(toggle);

    function onClickToButton(e) {
        let new_selected = !selected;
        if (typeof setToggle === 'function') setToggle(new_selected);
        setSelected(new_selected);
    }

    return (
        <div className={clsbasic + clsring + (selected ? " bg-purple-900 text-white " : " ") + className} 
            onClick={onClickToButton} disabled={disabled} >
            <Icon width={iconsize} height={iconsize} />
        </div>
    );
}

