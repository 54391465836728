import React from "react";
import { useSelector } from "react-redux";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzButton from "../common/PzButton";
import PzInput from "../common/PzInput";
import PzSymbol from "../common/PzSymbol";
import PzText from "../common/PzText";
import { ReactComponent as IconChevron } from "../resource/icons/pz-chevron-down.svg";
import { ReactComponent as IconRadio0 } from "../resource/icons/pz-button-radio-0.svg";
import { ReactComponent as IconRadio1 } from "../resource/icons/pz-button-radio-1.svg";
import { ReactComponent as IconButtonAdd } from "../resource/icons/pz-button-add.svg";
import { ReactComponent as IconButtonSub } from "../resource/icons/pz-button-sub.svg";

const PollPqstAnswer = {
    List:   PqstAnswerList,
    Item:   PollPqstAnswerItem,
};
export default PollPqstAnswer;


function PqstAnswerList({poll, setPoll, qidx, size='lg', preview=false, 
    selectable=false, selection=[], setSelection=()=>{}, editable=false, className='', onClick=(e)=>{}}) {

    const localeMap = useSelector(utils.selectLocaleMap);
    const pqst = (qidx >= 0 && qidx < poll.pqstlist.length ? poll.pqstlist[qidx] : undefined);
    const nanswers = (!pqst || pqst.answers.length === 0 || typeof pqst.answers[0] === 'string' ? pqst.answers.length : pqst.answers.length-1);
    const clsflex = (nanswers > 5 ? "flex flex-wrap gap-2" : "flex flex-col gap-2");  // TOO MANY
    const [aidxSelected, setAidxSelected] = React.useState(-1);   // answer index
    const [open4AddSub, setOpen4AddSub] = React.useState(false);
    // console.log("PqstAnswers :  nanswers:", nanswers, "  aidxSelected:", aidxSelected, "  open4AddSub:", open4AddSub);

    function callForItemToSelect(aidx) {
        if (aidx < 0 || aidx === aidxSelected) {
            setAidxSelected(-1);
        } else {
            setAidxSelected(aidx);
        }
    }

    function onClickToAddAnswer(e) {
        addPqstAnswer(poll, setPoll, qidx, localeMap); 
        setAidxSelected(-1);
        e.stopPropagation();
    }
    
    function onClickToSubAnswer(e) {
        subPqstAnswer(poll, setPoll, qidx, aidxSelected); 
        setAidxSelected(-1);
        e.stopPropagation();
    }
    
    if (!pqst || !pqst.answers || pqst.answers.length === 0) {
        return (<h3>answers is null</h3>);
    } 

    return (
        <div className={"w-full relative " + className} onClick={onClick} >
            <div className={"w-full " + clsflex + " justify-start items-start"}>
                { typeof pqst.answers[0] === 'string' ? 
                    pqst.answers.map((a, idx)=>{return (
                        <PollPqstAnswerItem key={idx} poll={poll} setPoll={setPoll} qidx={qidx} aidx={idx} size={size} 
                            selected={aidxSelected} callToSelect={callForItemToSelect}
                            editable={editable} preview={preview} setOpen4AddSub={setOpen4AddSub} />
                            // callToEditStt={()=>{setAidxSelected(idx); setOpen4AddSub(true);}} callToEditEnd={()=>{setAidxSelected(-1);}} />
                    )})
                :
                    pqst.answers.slice(1).map((a, idx)=>{return (
                        <PollPqstAnswerItem key={1+idx} poll={poll} setPoll={setPoll} qidx={qidx} aidx={1+idx} size={size} 
                            selected={aidxSelected} callToSelect={callForItemToSelect}
                            editable={editable} preview={preview} setOpen4AddSub={setOpen4AddSub} />
                            // callToEditStt={()=>{setAidxSelected(idx); setOpen4AddSub(true);}} callToEditEnd={()=>{setAidxSelected(-1);}} />
                    )})
                }
            </div>
            { (editable && pqst.qkind != 'A' && (aidxSelected >= 0 || open4AddSub)) && 
                <div className="absolute bottom-0 right-4 flex flex-col justify-end items-center gap-2">
                    { nanswers > 2 &&
                        <PzButton.Icon Icon={IconButtonSub} size='xs' onClick={onClickToSubAnswer} />
                    }
                    <PzButton.Icon Icon={IconButtonAdd} size='xs' onClick={onClickToAddAnswer} />
                </div>
            }
        </div>
    );
}

function PollPqstAnswerItem({poll, setPoll, qidx, aidx, size='lg', className='', 
    selected=-1, callToSelect=undefined, editable=false, preview=false, setOpen4AddSub=()=>{}}) {
    const pqst = (qidx >= 0 && qidx < poll.pqstlist.length ? poll.pqstlist[qidx] : undefined);
    const aobj = (pqst && aidx < 0 && aidx >= pqst.answers.length ? undefined : pqst.answers[aidx]);
    // console.log("PollPqstAnswerItem :  answers:", pqst.answers, "  aobj:", aobj);

    let iconsize = "1rem", clssize = " ";
    if      (size === '2xl') { iconsize="1.6rem"; clssize = " gap-1 px-1 py-0"; }
    else if (size ===  'xl') { iconsize="1.5rem"; clssize = " gap-1 px-1 py-0"; }
    else if (size ===  'lg') { iconsize="1.4rem"; clssize = " gap-1 px-1 py-0"; }
    else if (size ===  'md') { iconsize="1.3rem"; clssize = " gap-1 px-1 py-0"; }
    else if (size ===  'sm') { iconsize="1.2rem"; clssize = " gap-1 px-[0.2rem] py-[0.08rem]"; }
    else                     { iconsize="1.1rem"; clssize = " gap-1 px-[0.2rem] py-[0.08rem]"; }

    function setPollWithPqstAnswer(txt) {
        let new_answers = [ ...pqst.answers ];
        if (typeof pqst.answers[0] === 'string') {
            new_answers[aidx] = txt;
        } else {
            new_answers[aidx] = { ...pqst.answers[aidx], aname: txt };
        }
        setPollWithPqst({ ...pqst, answers: new_answers });
    }

    function setPollWithPqst(new_pqst) {
        if (!new_pqst) return;
        let pqstlist = [ ...poll.pqstlist ];
        pqstlist[qidx] = new_pqst;
        setPoll({ ...poll, pqstlist:pqstlist });
    }

    function callForRadioToClick(e, toSelect) {
        e.stopPropagation(); 
        if (toSelect) {
            callToSelect(aidx);
        } else {
            callToSelect(-1); 
            setOpen4AddSub(false);
        }
    }

    function callForTouchToChange(mode, txt) {
        if (mode === 'start') {
            callToSelect(aidx); 
            if (editable) setOpen4AddSub(true);
        } else if (mode === 'end') {
            setOpen4AddSub(false); 
        }
    }

    if (!pqst || !pqst.answers || pqst.answers.length === 0) {
        return (<h3>answers is null</h3>);
    } 

    return (
        <div className={clssize + " flex flex-row justify-start items-center gap-1 " + className} >

            { aidx === selected ?
                <IconRadio1 width={iconsize} height={iconsize} className={preview ? 'opacity-50' : ''}
                    onClick={(e)=>{if (!preview) callForRadioToClick(e, false)}} />
            :
                <IconRadio0 width={iconsize} height={iconsize} className={preview ? 'opacity-50' : ''}
                    onClick={(e)=>{if (!preview) callForRadioToClick(e, true);}} />
            }

            <PzText.Touch 
                text={typeof aobj === 'string' ? aobj : aobj.aname} setText={(txt)=>{setPollWithPqstAnswer(txt);}} 
                size={size} editable={editable && pqst.qkind !== 'A'} truncate={false} callToEditChange={callForTouchToChange} />

        </div>
    );
}

function addPqstAnswer(poll, setPoll, qidx, localeMap) {
    if (poll && setPoll && qidx >= 0 && qidx < poll.pqstlist.length) {
        let pqst = poll.pqstlist[qidx];
        if (pqst.qkind !== 'A') {
            const alt12 = [ ...(localeMap["poll02.answers.S"] ? localeMap["poll02.answers.S"].split('\n') : []) ];
            const candN = [ ...(localeMap["poll02.answers.E"] ? localeMap["poll02.answers.E"].split('\n') : []) ];
            let new_answers = [ ...pqst.answers ];
            let aname = ( pqst.qkind === 'S' ? alt12[0].replace('1', pqst.answers.length+1) : candN[0].replace('1', pqst.answers.length+1) );
            if (typeof pqst.answers[0] === 'string') {
                new_answers.push(aname);
            } else {
                new_answers.push({aidx:pqst.answers.length, aname:aname, adesc:null, astat:'1-', rvcount:null});
            }
            let new_pqst = { ...pqst, answers: new_answers, nanswers: new_answers.length };
            let new_list = [ ...poll.pqstlist ];
            new_list[qidx] = new_pqst;
            let new_poll = { ...poll, pqstlist: new_list };
            if (typeof setPoll === 'function') setPoll(new_poll);
        }
    }
}

function subPqstAnswer(poll, setPoll, qidx, aidx) {
    if (poll && setPoll && qidx >= 0 && qidx < poll.pqstlist.length) {
        let pqst = poll.pqstlist[qidx];
        if (aidx === -1 && pqst.answers.length > 2) aidx = pqst.answers.length - 1;
        if (aidx >= 0 && aidx < pqst.answers.length) {
            let before = (aidx === 0 ? [] : pqst.answers.slice(0, aidx));
            let after  = (aidx === pqst.answers.length-1 ? [] : pqst.answers.slice(aidx+1));
            let new_answers = before.concat(after);
            let new_pqst = { ...pqst, answers: new_answers, nanswers: new_answers.length };
            let new_list = [ ...poll.pqstlist ];
            new_list[qidx] = new_pqst;
            let new_poll = { ...poll, pqstlist: new_list };
            if (typeof setPoll === 'function') setPoll(new_poll);
        }
    }
}

