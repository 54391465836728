import React from 'react';
import { useSelector } from "react-redux";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from "../common/PzBasicModal"
import PzButton from "../common/PzButton"
import { ReactComponent as IconQRCode } from "../resource/icons/pz-qrcode.svg";

const EntranceToRehearse = {
    Dialog:      EntranceToRehearseDialog,
    Module:      EntranceToRehearseModule,
};

export default EntranceToRehearse;


function EntranceToRehearseDialog({poll, onClose=()=>{}}) {
    return (
        <PzBasicModal onClose={onClose}>
            <EntranceToRehearseModule poll={poll} onClose={onClose} />
        </PzBasicModal>
    )
}

function EntranceToRehearseModule({poll, onClose=()=>{}}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [showQR, setShowQR] = React.useState(false);
    const ttext = localeMap['entrance.rehearsal.title'];
    const title = utils.completeMessageText((ttext ? ttext : ""), [poll?.ptitle]);

    function onClickForButton() {
        const url = appConfig.getBaseUrlForAPI() + '/y/rrun?p=' + poll?.pguid ;
        const win = window.open(url.replace(":80", ":3000"), '_blank');
        if (win != null) win.focus();
        if (typeof onClose === 'function') onClose();
    }

    return (
        <div className="w-full min-w-[24rem] max-w-[32rem] flex flex-col justify-start items-center p-10 gap-10" >
            <div className="w-full flex flex-col justify-start items-center gap-5">
                <div className="w-full flex flex-col justify-start items-center gap-1">
                    { title.split("\n").map((part,idx)=>{return (<h2 key={idx}>{part}</h2>);}) }
                </div>
            </div>
            { !showQR ? 
                <div className="w-[28rem] aspect-square pt-10 overflow-clip">
                    <img src={appConfig.getBaseUrlForAPI() + '/static/images/concept_vote.png'} 
                        onClick={onClickForButton} width={600} height={600} className="object-contain object-center"/>
                </div>
            :
                <div className="w-[28rem] aspect-square p-3 rounded-[2rem] ring-slate-200 ring-inset ring-1 pz-qr-shadow">
                    <img src={`${appConfig.getBaseUrlForAPI()}/api/entrance/rehearsal?p=${poll?.pguid}`} 
                    width={600} height={600} className="object-contain object-center"/>
                </div>
            }
            <div className={"w-full flex flex-row items-center gap-5 relative " + (showQR ? "justify-between" : "justify-center")}>
                <PzButton.Std 
                    text={showQR ? '_' : localeMap["entrance.button.openevent"]} 
                    size='xl' onClick={onClickForButton} className={(showQR ? "invisible" : "")}/>
                { showQR && <label className="text-md">{localeMap["entrance.qrcode.instruction"]}</label> }
                <PzButton.Icon className="absolute left-0 bottom-0"
                    Icon={IconQRCode} size='xl' toggle={true} onClick={setShowQR} />
            </div>
        </div>
    )

}

