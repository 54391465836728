import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { utils } from "../config/userConfig";
import { ReactComponent as IconMenu } from "../resource/icons/pz-hamburger-menu.svg";
import { ReactComponent as IconPollBox } from "../resource/icons/pz-vote-pollbox.svg";
import { ReactComponent as IconUserB1 } from "../resource/icons/pz-user-b1.svg";
import { ReactComponent as IconUserB2 } from "../resource/icons/pz-user-b2.svg";
import { ReactComponent as IconUserB3 } from "../resource/icons/pz-user-b3.svg";
import { ReactComponent as IconUserVoter } from "../resource/icons/pz-user-voter.svg";
import { ReactComponent as IconChevronDown } from "../resource/icons/pz-chevron-down.svg";
import appConfig from "../config/appConfig";

const sidebarContents = [
    {
        id: "menu01", name: "menu.myevents", path: "/pages/myevents", visible: true,
        icon: <IconPollBox width="1.6rem" height="1.6rem" />,
        children: [],
    },
    {
        id: "menu02", name: "menu.poller", path: "/pages/poller", visible: true,
        icon: <IconUserB1 width="1.6rem" height="1.6rem" />,
        children: [
            { id: "menu0201", name: "menu.poller.editevent",  path: "/pages/poller/editevent", minPS:'A ', maxPS:'Az', disabled: false },
            { id: "menu0202", name: "menu.poller.getready",  path: "/pages/poller/getready", minPS:'A ', maxPS:'Az', disabled: false },
            { id: "menu0203", name: "menu.poller.invite", path: "/pages/poller/invite", minPS:'Ax', maxPS:'Oz', disabled: false },
            { id: "menu0204", name: "menu.poller.runpoll", path: "/pages/poller/runpoll", minPS:'Ax', maxPS:'Oz', disabled: false },
            { id: "menu0205", name: "menu.poller.xresult", path: "/pages/poller/xresult", minPS:'Oz', maxPS:'Xx', disabled: false },
        ],
    },
    {
        id: "menu03", name: "menu.staff", path: "/pages/staff", visible: true,
        icon: <IconUserB2 width="1.6rem" height="1.6rem" />,
        children: [
            { id: "menu0301", name: "menu.staff.prepare", path: "/pages/staff/prepare", minPS:'Ax', maxPS:'Xx', disabled: false },
            { id: "menu0302", name: "menu.staff.support", path: "/pages/staff/support", minPS:'Oo', maxPS:'Xc', disabled: false },
            { id: "menu0303", name: "menu.staff.sresult", path: "/pages/staff/sresult", minPS:'Oz', maxPS:'Xc', disabled: false },
        ],
    },
    {
        id: "menu04", name: "menu.voter", path: "/pages/voter", visible: true,
        icon: <IconUserVoter width="1.6rem" height="1.6rem" />,
        children: [
            { id: "menu0401", name: "menu.voter.vote", path: "/pages/voter/vote", minPS:'O0', maxPS:'Oz', disabled: appConfig.version==='ALPHA' },
        ],
    },
    // {
    //     id: "menu08", name: "menu.about", path: "/pages/about", visible: true,
    //     icon: <IconInfo width="1.6rem" height="1.6rem" />,
    //     children: [
    //         { id: "menu0801", name: "menu.about.howto", path: "/getstarted", disabled: appConfig.version==='ALPHA' },
    //         { id: "menu0802", name: "menu.about.pricing", path: "/pricing", disabled: appConfig.version==='ALPHA' },
    //         { id: "menu0803", name: "menu.about.privacypolicy", path: "/privacypolicy", disabled: appConfig.version==='ALPHA' },
    //         { id: "menu0804", name: "menu.about.help2translate", path: "/pages/about/help2translate", disabled: appConfig.version==='ALPHA' },
    //         { id: "menu0805", name: "menu.about.purchaseinquiry", path: "/pages/about/purchaseinquiry", disabled: appConfig.version==='ALPHA' },
    //     ],
    // },
    {
        id: "menu09", name: "PzAdmin", path: "/pages/pza", visible: false, 
        icon: <IconUserB3 width="1.6rem" height="1.6rem" />,
        children: [
            { id: "menu0901", name: "Dashboard", path: "/pages/pza/dashboard", disabled: false },
            { id: "menu0902", name: "Users", path: "/pages/pza/users", disabled: false },
            { id: "menu0903", name: "Events", path: "/pages/pza/polls", disabled: false },
            { id: "menu0904", name: "Public Events", path: "/pages/pza/sysevts", disabled: false },
            { id: "menu0905", name: "Archived Events", path: "/pages/pza/archpolls", disabled: false },
            { id: "menu0906", name: "Feedback", path: "/pages/pza/feedback", disabled: false },
            { id: "menu0908", name: "System Desc", path: "/pages/pza/sysdesc", disabled: false },
            { id: "menu0909", name: "System Logs", path: "/pages/pza/syslogs", disabled: false },
        ],
    },
];

export default function PzSidebar({uauth, upoll, open, setOpen}) {
    const navigate = useNavigate();
    const location = useLocation();
    const localeMap = useSelector(utils.selectLocaleMap);
    const xrole   = (upoll ? upoll.xrole : 'P');
    const pkind   = (upoll ? upoll.pkind : 'PRA');
    const pstatus = (upoll ? upoll.pstatus : 'Ac');
    const invalid = (!uauth || !upoll);
    const smallwidth = (window.innerWidth < 576);
    const [subMenuFolded, setSubMenuFolded] = React.useState({menu02:false,menu03:false,menu04:false,menu08:true,menu09:true});
    configureMenuItems();
    // console.log("UPoll  pguid:", upoll?.pguid, "pkind:", pkind);

    function toggleMenuFolded(id) {
        var new_state = { ...subMenuFolded }
        new_state[id] = !new_state[id];
        setSubMenuFolded(new_state);
    } 

    function configureMenuItems() {
        sidebarContents.forEach((menu) => {
            if (menu.id === 'menu02') {
                menu.children.forEach((submenu) => {
                    // submenu.disabled = (invalid);
                    submenu.disabled = (invalid || pstatus < submenu.minPS || pstatus > submenu.maxPS || xrole!=='P');
                });
            } else if (menu.id === 'menu03') {
                menu.children.forEach((submenu) => {
                    // submenu.disabled = (invalid);
                    submenu.disabled = (invalid || pstatus < submenu.minPS || pstatus > submenu.maxPS);
                });
            } else if (menu.id === 'menu04') {
                menu.children.forEach((submenu) => {
                    // submenu.disabled = (invalid);
                    submenu.disabled = (invalid || pstatus < submenu.minPS || pstatus > submenu.maxPS);
                });
            } else if (menu.id === 'menu09') {
                menu.visible = uauth?.ispza;
            }
        });
    }

    React.useEffect(() => {
    }, []);

    const MenuContent = () => (
        <>
        {sidebarContents.map(
            ({ id, name, path, icon, visible, children }) =>
            visible && (
                <div className="w-full flex flex-col items-center justify-center" key={id}>
                    {open && (
                        <button
                            className={`w-[15rem] h-[3rem] overflow-hidden rounded text-white gap-2 flex flex-row justify-start items-center
                                hover:cursor-pointer [&>h5]:hover:font-extrabold text-slate-500 ${path===location.pathname ? "bg-slate-700" : ""}`}
                            onClick={() => (children.length === 0 ? navigate(path) : toggleMenuFolded(id))}  >
                            <div className={`flex absolute left-[1.5rem] items-center`}>{icon}</div>
                            <h5 className={`absolute left-[4rem] whitespace-nowrap text-white
                                hover:font-extrabold text-slate-500 ${path===location.pathname ? "font-extrabold" : "font-normal"}`} > 
                                {id === 'menu09' ? name : localeMap[name]}
                            </h5>
                            {children.length > 0 && id >= 'menu08' && (
                                <div className={`absolute left-[13rem] flex items-center transition-all ${subMenuFolded[id] ? "rotate-0" : "rotate-180"}`} >
                                <IconChevronDown width="1.4rem" height="1.4rem" />
                                </div>
                            )}
                        </button>
                    )}
                    {open && children.length > 0 && !subMenuFolded[id] && (
                        <div className="flex flex-col items-center">
                            {children.map((submenu)=>(
                                <button
                                    key={submenu.id}
                                    className={`w-[15rem] h-[2.5rem] rounded text-white gap-2 flex items-center justify-start
                                    ${submenu.path===location.pathname ? `bg-slate-700` : ``}`}
                                    disabled={submenu.disabled}
                                    onClick={() => navigate(submenu.path)} >
                                    <h5 className={`absolute left-[4rem] whitespace-nowrap  
                                        ${submenu.disabled ? `text-slate-300` : `text-slate-50 hover:font-extrabold`}
                                        ${submenu.path===location.pathname ? `font-extrabold` : ``}`}>
                                        {id === 'menu09' ? submenu.name : utils.completeLocaleMapText(localeMap, submenu.name, null, pkind)}
                                    </h5>
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            )
        )}
        </>
    );

    return (
        <>
            { smallwidth &&
                <div className={`fixed transition-colors duration-300 top-0 right-0 bottom-0 w-full bg-black z-[50] opacity-50 bp-sm:hidden
                                ${open ? "w-full opacity-30" : "w-0 max-w-0 opacity-20"} `}
                    onClick={() => setOpen(false)} >
                </div>
            }

            <div className={!open ? 
                    "bp-sm:block h-full min-h-[100vh] bg-slate-600 overflow-hidden flex-grow-0 flex-shrink-0 transition-all flex flex-col items-center w-0 max-w-0 min-w-0"
                    : !smallwidth ?
                    "bp-sm:block h-full min-h-[100vh] bg-slate-600 overflow-hidden flex-grow-0 flex-shrink-0 transition-all flex flex-col items-center w-[16rem] min-w-[16rem] max-w-[16rem]"
                    :
                    "absolute overflow-hidden top-0 left-0 bottom-0 transition-all duration-200 bg-slate-600 z-[50] bp-sm:hidden w-[16rem] min-w-[16rem] max-w-[16rem]"
                }>

                {open &&
                    <div className="flex flex-col justify-start items-start">
                        
                        <div className="w-fit h-full flex flex-row items-center my-3 px-4 gap-4">
                            <button className="w-auto h-auto cursor-pointer text-slate-50 hover:text-slate-200" onClick={()=>{setOpen(false);}} >
                                <IconMenu width="1.4rem" height="1.4rem" />
                            </button>
                            <button className="cursor-pointer text-slate-50 hover:text-slate-200" onClick={()=>navigate("/")} >
                                <h2 className="font-extrabold">Pollerz.net</h2>
                            </button>
                        </div>

                        <MenuContent />
                    

                    </div>
                }

            </div>

        </>
    );
}
